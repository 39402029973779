import React from 'react'
import moment from 'moment'
import ModalInclusaoRapidaLancamentoContaCorrente from '../modal/ModalInclusaoRapidaLancamentoContaCorrente'
import ModalPagamentoAvulso from '../modal/ModalPagamentoAvulso'
import { Component } from 'react'
import { formataEmRealDisplayDoBD } from '../../util/Utils'
import { extensoTipo } from '../../util/extenso'

class ContaExtrato extends Component {
    constructor(props) {
        super(props)
        this.state = {
            conta: {
                nome: '', conta_correntes: {
                    possui_conta_remunerada: false
                }
            },
            saldo: this.props.saldo,
            buttonAplicar: null,
            buttonResgatar: null,
            pagamentos: [],
            saldos: [],
            incluiRapido: false,
            pagamentoAvulso: false,
            contaRemunerada: false,
            clicado: [],
            pagamento: null
        }
    }

    componentWillMount() {
        this.acessaConta(this.props.saldo.conta_id)
        this.listaPagamentosMesConta()
    }

    acessaConta(conta_id) {
        let url = global.url + 'conta/acessaConta/' + conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ conta: responseJson.conta })
                    this.checaConta(responseJson.conta)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    checaConta(conta) {
        if (conta.tipo_conta === 'contaCorrente') {
            if (conta.conta_correntes.possui_conta_remunerada) {
                this.setState({ contaRemunerada: true })
            }
        }
    }

    listaPagamentosMesConta() {
        let url = global.url + 'pagamento/listaPagamentosMesConta/' + this.state.saldo.conta_id + '/' + this.state.saldo.data

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ pagamentos: responseJson.pagamentos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    corDoValorPg(pagamento) {

        var bgDanger = ''

        this.state.clicado.map(async (cli, index1) => {
            if (cli.index === pagamento.id) {
                bgDanger = 'bg-danger'
            }
        })

        if (pagamento.tipo === 'debito') {
            return 'text-white ' + bgDanger
        } else {
            return 'rgm-cor-positivo ' + bgDanger
        }

    }

    corDoValorSd(valor) {

        if (valor < 0) {
            return 'text-white'
        } else {
            return 'rgm-cor-positivo'
        }

    }

    contaExtratos(conta) {
        this.props.contaListaExtrato(conta)
    }

    mesAnoPorExtenso(data) {
        var mesExtenso = moment(data).format('MMM');
        var anoExtenso = moment(data).format('YYYY');

        return anoExtenso + ' - ' + mesExtenso.toUpperCase()

    }

    parcela(pagamento) {

        var literal = null

        if (pagamento.frequencia === 'recorrente') {
            var ate = '999'
            if (pagamento.transacao.numero_de_parcelas !== null) {
                ate = ('000' + pagamento.transacao.numero_de_parcelas).slice(-3)
            }
            literal = ('000' + pagamento.parcela).slice(-3) + '/' + ate
        } else {
            if (pagamento.transacao.numero_de_parcelas === null) {
                literal = ''
            } else {
                literal = ('000' + pagamento.parcela).slice(-3) + '/' + ('000' + pagamento.transacao.numero_de_parcelas).slice(-3)
            }
        }

        return literal
    }

    pagamentoConsulta(pagamento) {
        if (pagamento.sub_categoria_nome_interno === 'fatura') {
            this.props.pagamentoConsultaFatura(pagamento)
        } else {
            if (pagamento.sub_categoria_nome_interno === 'pagamentoAvulso') {
                this.setState({ pagamentoAvulso: true, pagamento: pagamento })
            } else {
                this.props.pagamentoConsulta(pagamento, false)
            }
        }
    }


    click(pagamento, index) {
        var click = this.state.clicado

        var encontrou = false
        var indexFound = null

        this.state.clicado.map(async (cli, index1) => {
            if (cli.index === pagamento.id) {
                encontrou = true
                indexFound = index1
            }
        })

        if (encontrou === false) {
            var ocorrencia = { index: pagamento.id, bg: 'bg-danger' }
            click.push(ocorrencia)
        } else {
            click.splice(indexFound, 1)
        }

        this.setState({ clicado: click })

    }


    render() {
        return [
            this.renderPagamentoAvulso(),
            this.renderIncluiRapido(),
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {

        var buttonAplicar = null
        var buttonResgatar = null

        if (this.state.contaRemunerada === true) {
            buttonAplicar = <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.contaAplicarContaRemunerada(this.state.saldo.conta_id) }}>Aplicar(conta renunerada)</button>
            buttonResgatar = <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.contaResgatarContaRemunerada(this.state.saldo.conta_id) }}>Resgatar(conta remunerada)</button>
        }

        return (
            <div className="container w-100 mt-5">
                <div className="form-group row">
                    <div className="col-sm-10">
                        <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ incluiRapido: true })}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Lançamento</button>
                        <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                        <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.contaTransferir(this.state.saldo.conta_id) }}>Transferir</button>
                        {buttonAplicar}
                        {buttonResgatar}
                    </div>
                </div>
                <div className="card bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle m-2">
                            <h5>{this.state.conta.nome} | EXTRATO MENSAL</h5>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="bg-info">
                                <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                <th scope="col" className="text-center">Data</th>
                                <th scope="col">Descrição</th>
                                <th scope="col" className="text-center">Tipo</th>
                                <th scope="col" className="text-right">Valor</th>
                                <th scope="col" className="text-center">Parcela</th>
                                <th scope="col" className="text-right">Saldo</th>
                            </tr>
                        </thead>
                        <tbody className="rgm-bg-azul-esverdiado text-white">
                            {this.state.pagamentos.map((pagamento, index) => {
                                return (
                                    <tr className="">
                                        <td className="text-center align-middle">
                                            <button className="btn btn-link" onClick={() => this.pagamentoConsulta(pagamento)}>
                                                <i class="fa fa-info rgm-cor-link" title={pagamento.id}></i>
                                            </button>
                                        </td>
                                        <td className="text-center align-middle">{moment(pagamento.data_de_pagamento).format('DD/MM/YYYY')}</td>
                                        <td className="align-middle">{pagamento.descricao}</td>
                                        <td className="text-center align-middle">{extensoTipo(pagamento.tipo)}</td>
                                        <td className={'text-right font-weight-bold align-middle ' + this.corDoValorPg(pagamento)} onClick={() => this.click(pagamento, index)}>{formataEmRealDisplayDoBD(parseFloat(pagamento.valor))}</td>
                                        <td className={'text-center align-middle '}>{this.parcela(pagamento)}</td>
                                        <td className={'text-right font-weight-bold align-middle ' + this.corDoValorSd(parseFloat(pagamento.saldoTotal))}>{formataEmRealDisplayDoBD(parseFloat(pagamento.saldoTotal))}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div >
        )

    }

    volta() {
        this.setState({ incluiRapido: false, pagamentoAvulso: false })
        this.listaPagamentosMesConta()
    }

    renderIncluiRapido() {
        if (this.state.incluiRapido === true) {
            return (
                <>
                    <ModalInclusaoRapidaLancamentoContaCorrente volta={() => this.volta()} conta_id={this.state.saldo.conta_id} />
                </>
            )
        }
    }

    renderPagamentoAvulso() {
        if (this.state.pagamentoAvulso === true) {
            return (
                <>
                    <ModalPagamentoAvulso volta={() => this.volta()} pagamento={this.state.pagamento} operacao='consulta' />
                </>
            )
        }
    }

}

export default ContaExtrato