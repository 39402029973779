import React from 'react'
import { Component } from 'react';
import moment from 'moment'
import ModalAviso from '../modal/ModalAviso'


class AmigoLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            amigos: []
        }
    }

    componentWillMount() {
        this.listaAmigos();
    }

    listaAmigos() {
        let url = global.url + 'amigo/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ amigos: responseJson.amigos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    formataDataDeNascimento(data_de_nascimento) {

        if (data_de_nascimento == null) {
            return ''
        } else {
            return moment(data_de_nascimento).format('DD/MM/YYYY')
        }
    }

    //<div className="jumbotron mt-3" style={{width: '90%'}}>

    render() {
        return [
            this.renderAlert(),
            this.renderLista()
        ]

    }

    renderLista() {
        return (
            <div className="container w-100 mt-5"  >
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.amigoInclusao()}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;amigos</button>
                <div className="card bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle m-2">
                            <h5>Lista de amigos</h5>
                        </div>
                    </div>

                    <table className="table table-bordered mt-0">
                        <thead>
                            <tr className="bg-info">
                                <th scope="col">Nome</th>
                                <th className="text-center" scope="col">Nascimento</th>
                                <th scope="col">Email</th>
                                <th className="text-center" scope="col">Alteração</th>
                                <th className="text-center" scope="col">Exclusão</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.amigos.map((amigo, index) => {
                                return (
                                    <tr className="rgm-bg-azul-esverdiado">
                                        <td className="align-middle text-white">{amigo.nome}</td>
                                        <td className="align-middle text-white text-center">{this.formataDataDeNascimento(amigo.data_de_nascimento)}</td>
                                        <td className="align-middle text-white">{amigo.email}</td>
                                        <td className="align-middle text-white text-center">
                                            <button className="btn btn-link btn-sm m-2 text-center" onClick={() => this.props.amigoAlteracao(amigo)}>
                                                <i class="fa fa-pencil rgm-cor-link" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                        <td className="align-middle text-white text-center">
                                            <button className="btn btn-link btn-sm m-2 text-center" onClick={() => this.amigoExclusao(amigo)}>
                                                <i class="fa fa-trash-o rgm-cor-link" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                        {this.state.amigos.length === 0 &&
                            <tbody>
                                <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="5">
                                    <span>Não existem amigos cadastrados</span>
                                </td>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )

    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    amigoExclusao(amigo) {

        let url = global.url + 'amigo/listaPagamentosAmigo/' + amigo.id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.listaAmigos()
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            })
    }

}

export default AmigoLista