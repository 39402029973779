import React from 'react'
import { uniqueId } from "lodash";
import filesize from "filesize";
import api from "../uploadApi/api";
import { Component } from 'react';
import './style.css'
import ModalAviso from './ModalAviso'
import { Container, Content } from '../upload/styles'
import Upload from '../upload/index'
import FileList from '../uploadFileList/index'

var timeOut = 3000

class ModalAnexarArquivo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nome: '',
            uploadedFiles: []
        }
    }

    componentWillMount() {

    }


    render() {
        return [
            this.renderPrincipal()
        ]
    }

    handleUpload = files => {
        const uploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            anexo_id: null,
            anexo_tipo: this.state.viewAnexoTipo,
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }));

        this.setState({
            uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
        });

        uploadedFiles.forEach(this.processUpload);
    };

    updateFile = (id, data) => {
        this.setState({
            uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
                return id === uploadedFile.id
                ? { ...uploadedFile, ...data }
                : uploadedFile;
            })
        })
        if (data.uploaded === true) {
            var anexoRead = this.state.uploadedFiles.map(uploadedFile => {
                return id === uploadedFile.id
                ? { ...uploadedFile, ...data }
                : uploadedFile;
            })
            this.props.volta({ nome: anexoRead[0].name, url: anexoRead[0].url, size: 0 })
        }
    };

    processUpload = uploadedFile => {
        const data = new FormData();

        data.append("file", uploadedFile.file, uploadedFile.name);
        data.append("tran_id", this.props.id);
        data.append("tran_ident", this.props.ident);

        const url = global.url + 'upload/inclui'

        var token = localStorage.getItem('EGR_TOKEN')

        api.post(url, data, {
            headers: {
                authorization: token
            },
            onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total));

                this.updateFile(uploadedFile.id, {
                    progress
                });
            }
        }).then(response => {
            this.updateFile(uploadedFile.id, {
                uploaded: true,
                id: response.data._id,
                anexo_id: response.data.id,
                url: response.data.url
            })
        }).catch(() => {
            this.updateFile(uploadedFile.id, {
                error: true
            })
        })

    };

    renderPrincipal() {

        var uploadedFiles = []

        return (
            <div className="rgm_modal_fast">
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Anexar arquivo</h5>
                        </div>
                    </div>
                    <Container>
                        <Content>
                            <Upload onUpload={this.handleUpload} />
                            {!!uploadedFiles.length && (
                                <FileList files={uploadedFiles} onDelete={this.handleDelete} />
                            )}
                        </Content>
                    </Container>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} timeOut={timeOut} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} timeOut={timeOut} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

}

export default ModalAnexarArquivo