import React, { Component } from 'react'
import { verificaSeCategoriaExiste } from '../categoria/CategoriaUtil'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'


class CategoriaAlteracao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: props.categoria.id,
            nome: props.categoria.nome,
            tipo: props.categoria.tipo,
            nome_interno: props.categoria.nome_interno,
            categorias: [],
            salvaNome: props.categoria.nome,
            alerta: false,
            alertaSaida: false,
            alertaMsg: null,
            pedeConfirmacao: false
        }
    }

    componentWillMount() {
        this.listaCategorias()
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ categorias: responseJson.categorias })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    incluiCategoria() {

        var mensagem = ''

        if (this.state.nome.length < 3) {
            mensagem = 'Categoria deve ter no mínimo 3 letras'
            alert(mensagem)
            return
        }

        if (this.state.salvaNome !== this.state.nome) {
            if (verificaSeCategoriaExiste(this.state.nome, this.state.categorias)) {
                mensagem = 'Esta descrição de categoria já existe'
                alert(mensagem)
                return
            }
        }

        var categoria = { id: this.state.id, nome: this.state.nome, tipo: this.state.tipo }

        let url = global.url + 'categoria/alterar'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(categoria)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Alteração concluida com sucesso' })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    excluiCategoria() {
        this.setState({ pedeConfirmacao: true })

    }

    confirmaExcluiCategoria() {
        this.setState({ pedeConfirmacao: false })

        const nome = this.state.nome[0].toUpperCase() + this.state.nome.substr(1)
        var categoria = { id: this.state.id, nome: nome, tipo: this.state.tipo }

        let url = global.url + 'categoria/excluir'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(categoria)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Exclusão concluida com sucesso' })
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }

    handleChangeNome(event) {
        this.setState({ nome: event.target.value })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderConfirmacao(),
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {
        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border m-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Alteração de categoria</h5>
                        </div>
                    </div>
                    <div className="form-group row m-3">
                        <label for="categoria" className="col-sm-2 col-form-label">Categoria</label>
                        <div className="col-sm-5">
                            <input type="text" maxlength="40" className="form-control text-truncate" id="categoria" onChange={this.handleChangeNome.bind(this)} value={this.state.nome} />
                        </div>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.incluiCategoria() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.excluiCategoria() }}>Excluir</button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.confirmaExcluiCategoria()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a exclusão da categoria?" />
                </>
            )
        }
    }

}

export default CategoriaAlteracao