import React, { Component } from 'react'
import moment from 'moment'
import RelatorioHome from '../relatorio/RelatorioHome'
import RelatorioGastosPorCategoria from '../relatorio/RelatorioGastosPorCategoria'
import RelatorioGastosPorAmigo from '../relatorio/RelatorioGastosPorAmigo'
import RelatorioFluxoDeCaixa from '../relatorio/RelatorioFluxoDeCaixa'

class Relatorio extends Component {
    constructor(props) {
        super(props)
        this.state = {
            relatorioHome: true,
            relatorioGastosPorCategoria: true,
            relatorioGastosPorAmigo: true,
            relatorioFluxoDeCaixa: true,
            mesAnterior: moment().add(-1, 'month').format('YYYY-MM-DD'),
            mesAtual: moment().endOf('month').format('YYYY-MM-DD'),
            mesPosterior: moment().add(1, 'month').format('YYYY-MM-DD')

        }
    }

    componentWillMount() {
        global.relatoriosPrincipal = () => { this.relatorioHomeTrue() }
        this.acessaUsuario()
    }

    acessaUsuario() {
        let url = global.url + 'usuario/consulta'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.usuario.status === 'preAssinante' ||
                    responseJson.usuario.status === 'preAssinanteFaltaPagamento' ||
                    responseJson.usuario.status === 'faltaPagamento') {
                    var dias = moment(responseJson.usuario.data_fim_assinatura).diff(moment(), 'days')
                    if (dias < 0) {
                        global.telaAssinatura = dias * -1
                    }
                }
            })

    }

    setHoje(avisa) {

        var mesAnterior = moment().add(-1, 'month').format('YYYY-MM-DD')
        var mesAtual = moment().endOf('month').format('YYYY-MM-DD')
        var mesPosterior = moment().add(1, 'month').format('YYYY-MM-DD')

        mesAnterior = moment(mesAnterior).endOf('month').format('YYYY-MM-DD')
        mesAtual = moment(mesAtual).endOf('month').format('YYYY-MM-DD')
        mesPosterior = moment(mesPosterior).endOf('month').format('YYYY-MM-DD')

        this.setState({ mesAnterior: mesAnterior, mesAtual: mesAtual, mesPosterior: mesPosterior }, avisa)

    }

    setMesAnterior(avisa) {
        var mesAnterior = moment(this.state.mesAnterior).add(-1, 'month').format('YYYY-MM-DD')
        var mesAtual = this.state.mesAnterior
        var mesPosterior = this.state.mesAtual

        mesAnterior = moment(mesAnterior).endOf('month').format('YYYY-MM-DD')
        mesAtual = moment(mesAtual).endOf('month').format('YYYY-MM-DD')
        mesPosterior = moment(mesPosterior).endOf('month').format('YYYY-MM-DD')


        this.setState({ mesAnterior: mesAnterior, mesAtual: mesAtual, mesPosterior: mesPosterior }, avisa)
    }

    setMesPosterior(avisa) {
        var mesAnterior = this.state.mesAtual
        var mesAtual = this.state.mesPosterior
        var mesPosterior = moment(this.state.mesPosterior).add(1, 'month').format('YYYY-MM-DD')

        mesAnterior = moment(mesAnterior).endOf('month').format('YYYY-MM-DD')
        mesAtual = moment(mesAtual).endOf('month').format('YYYY-MM-DD')
        mesPosterior = moment(mesPosterior).endOf('month').format('YYYY-MM-DD')

        this.setState({ mesAnterior: mesAnterior, mesAtual: mesAtual, mesPosterior: mesPosterior }, avisa)

    }

    relatorioHomeTrue() {
        this.setFalseAll()
        this.setState({ relatorioHome: true })
    }

    relatorioGastosPorCategoriaTrue() {
        this.setFalseAll()
        this.setState({ relatorioGastosPorCategoria: true })
    }

    relatorioGastosPorAmigoTrue() {
        this.setFalseAll()
        this.setState({ relatorioGastosPorAmigo: true })
    }

    relatorioFluxoDeCaixaTrue() {
        this.setFalseAll()
        this.setState({ relatorioFluxoDeCaixa: true })
    }

    setFalseAll() {
        this.setState({
            relatorioHome: false,
            relatorioGastosPorCategoria: false,
            relatorioGastosPorAmigo: false
        })
    }

    render() {

        if (this.state.relatorioHome === true) {
            return (<
                RelatorioHome
                relatorioGastosPorCategoria={() => { this.relatorioGastosPorCategoriaTrue() }}
                relatorioGastosPorAmigo={() => { this.relatorioGastosPorAmigoTrue() }}
                relatorioFluxoDeCaixa={() => { this.relatorioFluxoDeCaixaTrue() }}
                getMesAnterior={this.state.mesAnterior}
                getMesAtual={this.state.mesAtual}
                getMesPosterior={this.state.mesPosterior}
                setMesAnterior={(avisa) => { this.setMesAnterior(avisa) }}
                setMesPosterior={(avisa) => { this.setMesPosterior(avisa) }}
                setHoje={(avisa) => { this.setHoje(avisa) }}
            />
            )
        }

        if (this.state.relatorioGastosPorCategoria === true) {
            return (<
                RelatorioGastosPorCategoria
                volta={(pagamentos) => { this.relatorioHomeTrue() }}
                getMesAnterior={this.state.mesAnterior}
                getMesAtual={this.state.mesAtual}
                getMesPosterior={this.state.mesPosterior}
                setMesAnterior={(avisa) => { this.setMesAnterior(avisa) }}
                setMesPosterior={(avisa) => { this.setMesPosterior(avisa) }}

            />
            )
        }

        if (this.state.relatorioGastosPorAmigo === true) {
            return (<
                RelatorioGastosPorAmigo
                volta={(pagamentos) => { this.relatorioHomeTrue() }}
                getMesAnterior={this.state.mesAnterior}
                getMesAtual={this.state.mesAtual}
                getMesPosterior={this.state.mesPosterior}
                setMesAnterior={() => { this.setMesAnterior() }}
                setMesPosterior={() => { this.setMesPosterior() }}
            />
            )
        }

        if (this.state.relatorioFluxoDeCaixa === true) {
            return (<
                RelatorioFluxoDeCaixa
                volta={(pagamentos) => { this.relatorioHomeTrue() }}
                getMesAnterior={this.state.mesAnterior}
                getMesAtual={this.state.mesAtual}
                getMesPosterior={this.state.mesPosterior}
                setMesAnterior={() => { this.setMesAnterior() }}
                setMesPosterior={() => { this.setMesPosterior() }}
            />
            )
        }

        if (this.state.relatorioHome === false &&
            this.state.relatorioGastosPorCategoria === false &&
            this.state.relatorioGastosPorAmigo === false &&
            this.state.relatorioFluxoDeCaixa === false
        ) {
            return null
        }
    }
}

export default Relatorio