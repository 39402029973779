import React, { Component } from 'react'
import OrcamentoLista from '../orcamento/OrcamentoLista'
import OrcamentoInclusao from '../orcamento/OrcamentoInclusao'
import OrcamentoAlteracao from '../orcamento/OrcamentoAlteracao'
import '../../rgm.css'
class Orcamento extends Component {
  constructor(props) {
    super(props)
    this.state = {
      orcamentoLista: true,
      orcamentoInclusao: false,
      orcamentoAlteracao: false,
      orcamento: null
    }
  }

  componentWillMount() {
    global.orcamentosPrincipal = () => { this.orcamentoListaTrue() }
  }

  orcamentoListaTrue() {
    this.setFalseAll()
    this.setState({
      orcamentoLista: true
    })
  }

  orcamentoInclusaoTrue() {
    this.setFalseAll()
    this.setState({
      orcamentoInclusao: true
    })
  }

  orcamentoAlteracaoTrue(orcamento) {
    this.setFalseAll()
    this.setState({ orcamentoAlteracao: true, orcamento: orcamento })
  }

  setFalseAll() {
    this.setState({
      orcamentoLista: false,
      orcamentoInclusao: false,
      orcamentoAlteracao: false
    })
  }


  render() {

    if (this.state.orcamentoLista === true) {
      return (<
        OrcamentoLista
        orcamentoInclusao={() => { this.orcamentoInclusaoTrue() }}
        orcamentoAlteracao={(orcamento) => { this.orcamentoAlteracaoTrue(orcamento) }}
      />
      )
    }

    if (this.state.orcamentoInclusao === true) {
      return (<
        OrcamentoInclusao
        volta={() => { this.orcamentoListaTrue() }}
      />
      )
    }

    if (this.state.orcamentoAlteracao === true) {
      return (<
        OrcamentoAlteracao
        orcamento={this.state.orcamento}
        volta={() => { this.orcamentoListaTrue() }}
      />
      )
    }


    if (this.state.orcamentoLista === false &&
      this.state.orcamentoInclusao === false &&
      this.state.orcamentoAlteracao === false
    ) {
      return null
    }

  }

}

export default Orcamento