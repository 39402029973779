import React, { Component } from 'react'
import { verificaSeSubCategoriaExiste } from '../categoria/CategoriaUtil'
import ModalAviso from '../modal/ModalAviso'

class ModalInclusaoRapidaSubcategoria extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoria: props.categoria,
            nome: '',
            categorias: []

        }
    }

    componentWillMount() {
        this.listaCategorias()
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ categorias: responseJson.categorias })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }


    incluiSubCategoria() {

        if (this.state.nome.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Subcategoria deve ter no mínimo 3 letras' })
            return
        }

        if (verificaSeSubCategoriaExiste(this.state.nome, this.state.categorias)) {
            this.setState({ alerta: true, alertaMsg: 'Esta descrição de subcategoria já existe' })
            return
        }

        const nome = this.state.nome[0].toUpperCase() + this.state.nome.substr(1)   

        var subCategoria = { nome: nome, categoria_id: this.state.categoria.id }

        let url = global.url + 'categoria/incluirSub'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(subCategoria)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.props.volta(this.state.categoria)
            } else {
                alert('responseJson.mensagem:' + responseJson.mensagem)
                this.setState({ mensagem: responseJson.mensagem })
            }
        })


    }

    handleChangeNome(event) {
        this.setState({ nome: event.target.value })
    }

    render() {

        return [
            this.renderAlert(),
            this.renderPrincipal()
        ]
    }


    renderPrincipal() {
        return (
            <div className="container w-100 mt-5"   >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div className="row m-3" >
                        <div className="col">
                            <label className="ml-2" for="categoria">Categoria</label>
                            <input id="categoria" type="text" className="form-control" disabled value={this.state.categoria.nome} />
                        </div>
                        <div className="col">
                            <label className="ml-2" for="tipo">Tipo</label>
                            <input id="tipo" type="text" className="form-control" disabled value={this.state.categoria.tipo} />
                        </div>
                    </div>
                </form>

                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Inclusão de subcategoria</h5>
                        </div>
                    </div>
                    <div className="form-group row m-2">
                        <label for="subCategoria" className="col-sm-2 col-form-label" >Subcategoria</label>
                        <div className="col-sm-10">
                            <input type="text" maxlength="40" className="form-control" id="subCategoria" onChange={this.handleChangeNome.bind(this)} value={this.state.nome} />
                        </div>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.incluiSubCategoria() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta(this.state.categoria) }}>Voltar</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

}


export default ModalInclusaoRapidaSubcategoria