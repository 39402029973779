import React from 'react'
import { Component } from 'react'


// link documentação mercado pago
//https://www.mercadopago.com.br/developers/pt/guides/online-payments/checkout-pro/introduction

class ModalPreAssinante extends Component {
    constructor(props) {
        super(props)
        this.state = {
            paginaAssinatura: false,
            litAssinatura: 'Assinatura',
            p1: '',
            p2: '',
            p3: '',
            p4: ''
        }
    }

    componentWillMount() {

        var p1 = ''
        var p2 = ''
        var p3 = ''
        var p4 = ''

        if (this.props.status === 'preAssinante' || this.props.status === 'preAssinanteFaltaPagamento') {
            p1 = 'Seu periodo de avaliação chegou ao fim, esperamos que você tenha gostado.'
            p2 = 'Estamos muito gratos por você ter nos dado a oportunidade de mostrar a nossa plataforma de controle financeiro.'
            p3 = 'O próximo passo sera você adquerir uma assinatura.'
            p4 = 'Você pode fazer sua assinatura usando seu cartão de crédito, cartão de débito ou via boleto bancário.'
        }

        if (this.props.status === 'faltaPagamento') {
            p1 = ''
            p2 = 'É necessario que você renove ou faça sua assinatura.'
            p3 = ''
            p4 = 'Você pode fazer sua assinatura usando seu cartão de crédito, cartão de débito ou via boleto bancário.'
        }

        this.setState({ p1: p1, p2: p2, p3: p3, p4: p4 })

    }

    //gerar token mercado livre
    //https://proximaweb.com.br/tutoriais/como-gerar-o-token-do-mercadopago-e-public-key-do-marcadopago-criado-em-2020/#:~:text=1.,Cadastro%20e%20fa%C3%A7a%20seu%20Login.&text=Ao%20acessar%20este%20endere%C3%A7o%2C%20voc%C3%AA,poder%C3%A1%20obter%20os%20c%C3%B3digos%20necess%C3%A1rios.

    assinar() {

        //window.location.assign('https://www.google.com.br');

        //return

        let url = global.url + 'usuario/assinatura'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            this.setState({ litAssinatura: responseJson.init_point })
            window.location.assign(responseJson.init_point)
        })

    }

    render() {

        return [
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {
        return (
            <div className="rgm_modal">
                <div className="card">
                    <div class="card-header bg-danger text-center border"><h4><i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;{this.state.litAssinatura}</h4></div>
                    <div class="card-body">
                        <p>{this.state.p1}</p>
                        <p>{this.state.p2}</p>
                        <p>{this.state.p3}</p>
                        <p>{this.state.p4}</p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="2">assinatura por 12 meses</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="rgm-bg-azul-esverdiado text-white">
                                    <td className="border font-italic" >Preço a vista</td>
                                    <td className="border font-italic" style={{ textAlign: 'center' }}>R$ 99,90</td>
                                </tr>
                            </tbody>
                            <thead>
                                <tr>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="2">Outras opções serão apresentadas ao clicar em 'Assinar'</th>
                                </tr>
                            </thead>
                        </table>
                        <div class="card-header bg-transparent border">
                            <div className="row ml-2">
                                <button className="btn btn-primary btn-lg align-middle" aria-label="Close" onClick={() => this.assinar()}>Assinar</button>
                                <button type="button" className="btn btn-link ml-2 btn-sm" aria-label="Close" onClick={() => this.props.onClose()}>Ver depois</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ModalPreAssinante