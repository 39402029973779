import React, { Component } from 'react'
import PesquisaLista from '../pesquisa/PesquisaLista'
import '../../rgm.css'

class Pesquisa extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pesquisaLista: true
    }
  }

  componentWillMount() {
    global.pesquisasPrincipal = () => { this.pesquisaListaTrue() }
  }

  pesquisaListaTrue() {
    this.setFalseAll()
    this.setState({
      pesquisaLista: true
    })
  }

  setFalseAll() {
    this.setState({
      pesquisaLista: false
    })
  }


  render() {

    if (this.state.pesquisaLista === true) {
      return (<
        PesquisaLista
      />
      )
    }


    if (this.state.pesquisaLista === false
    ) {
      return null
    }

  }

}

export default Pesquisa