import React from 'react'
import { Component } from 'react';
import moment from 'moment'
import { formataDisplayEmReal, formataDisplayEmPercentual, formataEmRealParaBD, } from '../../util/Utils'
import { extensoTipo } from '../../util/extenso'
import { Line } from "react-chartjs-2";
import ModalConfirma from '../modal/ModalConfirma'
import ModalAviso from '../modal/ModalAviso'

const data = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            label: 'Planejado',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)'
        },
        {
            label: 'Realizado',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: 'red'
        }
    ]
}

const dataCredito = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            label: 'Planejado',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)'
        },
        {
            label: 'Realizado',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: 'red'
        }
    ]
}

var receitaPlanejada = 0
var despesaPlanejada = 0
var despesaPlanejadaPerc = 0
var receitaRealizada = 0
var despesaRealizada = 0
var receitaRealizadaPerc = 0
var despesaRealizadaPerc = 0

class OrcamentoLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orcamentos: [],
            orcamento: null,
            listaTudo: true,
            listaMes: false,
            listaMesSubcategoria: false,
            categorias: [],
            categoria: null,
            anoAnterior: null,
            anoAtual: null,
            anoPosterior: null,
            possoIncluirMaisOrcamentos: [],
            listaGrafico: false,
            pedeConfirmacao: false
        }
    }

    componentWillMount() {

        var anoAtual = parseInt(moment().format('YYYY'))
        var anoAnterior = anoAtual - 1
        var anoPosterior = anoAtual + 1

        this.setState({ anoAnterior: anoAnterior, anoAtual: anoAtual, anoPosterior: anoPosterior })

        this.listaOrcamentos(anoAtual)
        this.listaCategorias()
        this.possoIncluirMaisOrcamentos()


    }

    listaOrcamentos(ano) {
        let url = global.url + 'orcamento/lista/' + ano

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ orcamentos: responseJson.orcamentos })
                    if (responseJson.orcamentos.length === 0) {
                        this.resultadoDaBuscaNaoEncontrada(ano)
                    } else {
                        this.resultadoDaBuscaEncontrada(ano, responseJson.orcamentos)
                    }
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    resultadoDaBuscaEncontrada(ano, orcamentos) {
        if (this.state.anoAtual !== ano) {
            this.setState({ anoAtual: ano, anoAnterior: (ano - 1), anoPosterior: (ano + 1) })
        }

        orcamentos.map(async (orcamento, index) => {
            data.datasets[0].data[index] = orcamento.despesa_meta
            data.datasets[1].data[index] = orcamento.despesa_realizada
            dataCredito.datasets[0].data[index] = orcamento.receita_meta
            dataCredito.datasets[1].data[index] = orcamento.receita_realizada
        })


    }

    resultadoDaBuscaNaoEncontrada(ano) {
        if (this.state.anoAtual !== ano) {
            this.listaOrcamentos(this.state.anoAtual)
        }
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ categorias: responseJson.categorias })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    possoIncluirMaisOrcamentos() {
        let url = global.url + 'orcamento/possoIncluirMaisOrcamentos/'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ possoIncluirMaisOrcamentos: responseJson.possoIncluirMaisOrcamentos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }


    formataDataDeNascimento(data) {

        if (data == null) {
            return ''
        } else {
            return moment(data).format('DD/MM/YYYY')
        }
    }

    corReceitaRealizadaPerc(perc) {

        if (formataEmRealParaBD(perc) < 90) {
            return 'font-weight-bold'
        } else {
            return ''
        }
    }

    corDespesaRealizadaPerc(perc) {

        if (formataEmRealParaBD(perc) > 90) {
            return 'font-weight-bold'
        } else {
            return ''
        }
    }

    corCategoriaRealizadaPerc(perc, tipo) {

        if (tipo === 'credito') {
            return 'rgm-cor-positivo'
        }

    }

    listaMes(orcamentoInput) {

        var orcamento = orcamentoInput

        orcamento.categorias.map(async (categoria, index) => {
            if (parseFloat(categoria.meta) === 0 && parseFloat(categoria.realizada) === 0) {
                delete orcamento.categorias[index]
            }
        })

        this.setState({ listaTudo: false, listaMes: true, listaMesSubcategoria: false, orcamento: orcamento })

    }

    listaMesCategoria(categoriaInput) {

        var categoria = categoriaInput

        this.setState({ listaTudo: false, listaMes: false, listaMesSubcategoria: true, categoria: categoria })

    }

    acessaNomeCategoria(categoria_id) {


        var categoria = this.state.categorias.find(categoria => categoria.id === categoria_id)

        if (categoria === undefined) {
            return 'categoria não cadastrada ' + categoria_id
        } else {
            return categoria.nome
        }

    }

    acessaNomeSubcategoria(subcategoria_id) {

        var categoria = this.state.categorias.find(categoria => categoria.id === this.state.categoria.id)

        if (categoria === undefined) {
            return 'categoria não cadastrada ' + this.state.categoria.id
        }

        var subcategoria = categoria.sub_categorias.find(subcategoria => subcategoria.id === subcategoria_id)


        if (subcategoria === undefined) {
            return 'subcategoria não cadastrada ' + subcategoria_id
        } else {
            return subcategoria.nome
        }

    }


    listaTudo() {
        this.setState({ listaTudo: true, listaMes: false, listaMesSubcategoria: false })
    }

    voltaMes() {
        this.setState({ listaTudo: false, listaMes: true, listaMesSubcategoria: false })
    }

    voltaAno() {
        this.listaOrcamentos(this.state.anoAnterior)
    }

    avancaAno() {
        this.listaOrcamentos(this.state.anoPosterior)
    }

    exclui() {
        this.setState({ pedeConfirmacao: true })
    }

    render() {
        if (this.state.listaGrafico !== true) {
            return [
                this.renderAlert(),
                this.renderConfirmacao(),
                this.renderTudo(),
                this.renderMesCategoria(),
                this.renderMesSubcategoria()
            ]
        } else {
            return [
                this.renderGraficoGeralDebito(),
                this.renderGraficoGeralCredito()
            ]
        }
    }

    obtemNomeDoOrcamento() {

        //if (this.state.orcamentos.length > 0) {
        return 'Orçamento de ' + this.state.anoAtual
        //} else {
        //    return 'Não existe orçamento criado'
        // }

    }

    corDaLinhaPrincipal(orcamento, index) {

        if (moment(orcamento.data).isSame(moment(), 'month')) {
            return 'bg-info'
        } else {
            return 'rgm-bg-azul-esverdiado'
        }
    }

    acumulaValores(orcamento, index) {

        //********> receitaPlanejada 
        if (index === 0) {
            receitaPlanejada = 0
        }

        receitaPlanejada = parseFloat(receitaPlanejada) + parseFloat(orcamento.receita_meta)

        receitaPlanejada = parseFloat(receitaPlanejada).toFixed(2)

        //********> despesaPlanejada 
        if (index === 0) {
            despesaPlanejada = 0
        }

        despesaPlanejada = parseFloat(despesaPlanejada) + parseFloat(orcamento.despesa_meta)

        despesaPlanejada = parseFloat(despesaPlanejada).toFixed(2)


        //********> receitaRealizada
        if (index === 0) {
            receitaRealizada = 0
        }

        receitaRealizada = parseFloat(receitaRealizada) + parseFloat(orcamento.receita_realizada)

        receitaRealizada = parseFloat(receitaRealizada).toFixed(2)

        //********> despesaRealizada
        if (index === 0) {
            despesaRealizada = 0
        }

        despesaRealizada = parseFloat(despesaRealizada) + parseFloat(orcamento.despesa_realizada)

        despesaRealizada = parseFloat(despesaRealizada).toFixed(2)


        //******* calculo de percentuais
        despesaPlanejadaPerc = ((despesaPlanejada / receitaPlanejada) * 100).toFixed(2)
        despesaPlanejadaPerc = parseFloat(despesaPlanejadaPerc).toFixed(2)

        receitaRealizadaPerc = ((receitaRealizada / receitaPlanejada) * 100).toFixed(2)
        receitaRealizadaPerc = parseFloat(receitaRealizadaPerc).toFixed(2)

        despesaRealizadaPerc = ((despesaRealizada / despesaPlanejada) * 100).toFixed(2)
        despesaRealizadaPerc = parseFloat(despesaRealizadaPerc).toFixed(2)
    }

    renderTudo() {

        if (this.state.listaTudo === true) {
            return (
                <div className="container w-100 mt-5">
                    {this.state.possoIncluirMaisOrcamentos.length > 0 &&
                        <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.orcamentoInclusao()}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;orçamentos</button>
                    }
                    <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ listaGrafico: true })}><i className="fa fa-line-chart" aria-hidden="true"></i></button>
                    {this.state.orcamentos.length > 0 &&
                        <button className="btn btn-danger btn-sm m-2" onClick={() => this.exclui()}>Excluir</button>
                    }
                    <div className="card bg-transparent">
                        <div className="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>
                                    <button className="btn btn-link btn-sm m-2 text-dark font-weight-bold" onClick={() => this.voltaAno()}><h7>{this.state.anoAnterior}&nbsp;</h7></button>
                                    {this.obtemNomeDoOrcamento()}
                                    <button className="btn btn-link btn-sm m-2 text-dark font-weight-bold" onClick={() => this.avancaAno()}><h7>&nbsp;{this.state.anoPosterior}</h7></button>
                                </h5>
                            </div>
                        </div>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th className="border text-center bg-info align-middle" COLSPAN="1" ROWSPAN="2">Meses</th>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="3">Planejado</th>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="4">Realizado</th>
                                </tr>
                                <tr>
                                    <th className="border bg-info text-center" scope="col">Receita</th>
                                    <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Despesa</th>
                                    <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Receita</th>
                                    <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Despesa</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.orcamentos.map((orcamento, index) => {
                                    this.acumulaValores(orcamento, index)
                                    return (
                                        <tr className={this.corDaLinhaPrincipal(orcamento, index)}>
                                            <td className="border align-middle text-white" ><button type="button" className="btn btn-link btn-sm m-2 rgm-cor-link" onClick={() => { this.listaMes(orcamento) }}>{moment(orcamento.data).format('MMMM')}</button></td>
                                            <td className="border align-middle rgm-cor-positivo" style={{ textAlign: 'right' }}>{formataDisplayEmReal(orcamento.receita_meta)}</td>
                                            <td className="border align-middle text-white" style={{ textAlign: 'right' }}>{formataDisplayEmReal(orcamento.despesa_meta)}</td>
                                            <td className="border align-middle text-white" style={{ textAlign: 'right' }}>{formataDisplayEmPercentual(orcamento.percentual)}</td>
                                            <td className="border align-middle rgm-cor-positivo" style={{ textAlign: 'right' }}>{formataDisplayEmReal(orcamento.receita_realizada)}</td>
                                            <td className={"border align-middle rgm-cor-positivo " + this.corReceitaRealizadaPerc(orcamento.receita_realizada_perc)} style={{ textAlign: 'right' }}>{formataDisplayEmPercentual(orcamento.receita_realizada_perc)}</td>
                                            <td className="border align-middle text-white" style={{ textAlign: 'right' }}>{formataDisplayEmReal(orcamento.despesa_realizada)}</td>
                                            <td className={"border align-middle text-white " + this.corDespesaRealizadaPerc(orcamento.despesa_realizada_perc)} style={{ textAlign: 'right' }}>{formataDisplayEmPercentual(orcamento.despesa_realizada_perc)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                            {this.state.orcamentos.length !== 0 &&
                                <tbody>
                                    <tr className='bg-info'>
                                        <td className="border align-middle text-white" style={{ textAlign: 'center' }} >T O T A L</td>
                                        <td className="border align-middle rgm-cor-positivo" style={{ textAlign: 'right' }}>{formataDisplayEmReal(receitaPlanejada)}</td>
                                        <td className="border align-middle text-white" style={{ textAlign: 'right' }}>{formataDisplayEmReal(despesaPlanejada)}</td>
                                        <td className="border align-middle text-white" style={{ textAlign: 'right' }}>{formataDisplayEmPercentual(despesaPlanejadaPerc)}</td>
                                        <td className="border align-middle rgm-cor-positivo" style={{ textAlign: 'right' }}>{formataDisplayEmReal(receitaRealizada)}</td>
                                        <td className={"border align-middle rgm-cor-positivo"} style={{ textAlign: 'right' }}>{formataDisplayEmPercentual(receitaRealizadaPerc)}</td>
                                        <td className="border align-middle text-white" style={{ textAlign: 'right' }}>{formataDisplayEmReal(despesaRealizada)}</td>
                                        <td className={"border align-middle text-white"} style={{ textAlign: 'right' }}>{formataDisplayEmPercentual(despesaRealizadaPerc)}</td>
                                    </tr>
                                </tbody>
                            }

                            {this.state.orcamentos.length === 0 &&
                                <tbody>
                                    <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="8">
                                        <span>Não existe orçamento para este ano</span>
                                    </td>
                                </tbody>
                            }

                        </table>
                    </div>
                </div >
            )
        }

    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }


    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.confirmaExcluiPagamento()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a exclusão deste orçamento?" />
                </>
            )
        }
    }

    confirmaExcluiPagamento() {

        this.setState({ pedeConfirmacao: false })

        var orcamento = {
            ano: this.state.anoAtual
        }

        var url = global.url + 'orcamento/excluir'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(orcamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alerta: true, alertaMsg: responseJson.mensagem, orcamentos: [] })
                    return
                }
            })

    }

    obtemNomeDoMesListado() {
        return 'Orçamento de ' + moment(this.state.orcamento.data).format('MMMM/YYYY')
    }

    formataPercentualCategoriaPlanejado(meta, tipo) {

        //alert('meta:' + meta)

        var geral = '0.00'

        if (meta === '0.00') {
            return '-'
        } else {
            if (tipo === 'credito') {
                geral = this.state.orcamento.receita_meta
            } else {
                if (tipo === 'debito') {
                    geral = this.state.orcamento.despesa_meta
                }
            }
        }

        if (geral === '0.00') {
            return '-'
        }

        var perc = (parseFloat(meta) / parseFloat(geral)) * 100

        perc = parseFloat(perc).toFixed(2)

        return formataDisplayEmPercentual(perc)

    }

    formataPercentualCategoriaPlanejado_dois(meta, tipo) {

        //alert('meta:' + meta)

        var geral = this.state.categoria.meta

        if (geral === '0.00') {
            return '-'
        }

        if (meta === '0.00') {
            return '-'
        }

        var perc = (parseFloat(meta) / parseFloat(geral)) * 100

        perc = parseFloat(perc).toFixed(2)

        return formataDisplayEmPercentual(perc)

    }

    formataPercentualCategoria(meta, perc) {
        if (parseFloat(meta) === 0) {
            return 'Não orçado'
        } else {
            return formataDisplayEmPercentual(perc)
        }
    }

    formataPercentualCategoriaSobreTotal(realizada, tipo) {

        var total = 0

        if (tipo === 'debito') {
            total = this.state.orcamento.despesa_realizada
        } else {
            if (tipo === 'credito') {
                total = this.state.orcamento.receita_realizada
            }
        }

        if (parseFloat(total) === 0) {
            return '-'
        }

        var perc = (parseFloat(realizada) / parseFloat(total)) * 100

        perc = parseFloat(perc).toFixed(2)

        return formataDisplayEmPercentual(perc)

    }

    formataPercentualSubcategoriaSobreCategoria(realizada) {
        var total = this.state.categoria.realizada

        if (parseFloat(total) === 0) {
            return '-'
        }

        var perc = (parseFloat(realizada) / parseFloat(total)) * 100

        perc = parseFloat(perc).toFixed(2)

        return formataDisplayEmPercentual(perc)
    }

    corDaBorda(tipo) {

        if (tipo === 'debito') {
            return 'text-danger'

        } else {
            return 'text-primary'

        }


    }

    renderMesCategoria() {
        if (this.state.listaMes === true) {
            return (
                <div className="container w-100 mt-5"  >
                    <button className="btn btn-primary btn-sm m-2" onClick={() => this.listaTudo()}>Volta</button>
                    <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.orcamentoAlteracao(this.state.orcamento)}>Alteração</button>
                    <div className="card bg-transparent">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>{this.obtemNomeDoMesListado()}</h5>
                            </div>
                        </div>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="3">Planejado do mês</th>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="4">Realizado do mês</th>
                                </tr>
                                <tr>
                                    <th className="border bg-info" scope="col" style={{ textAlign: 'center' }}>Receita</th>
                                    <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Despesa</th>
                                    <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Receita</th>
                                    <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Despesa</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="rgm-bg-azul-esverdiado text-white">
                                    <td className="border font-italic rgm-cor-positivo" style={{ textAlign: 'center' }}>{formataDisplayEmReal(this.state.orcamento.receita_meta)}</td>
                                    <td className="border font-italic" style={{ textAlign: 'center' }}>{formataDisplayEmReal(this.state.orcamento.despesa_meta)}</td>
                                    <td className="border font-italic" style={{ textAlign: 'center' }}>{formataDisplayEmPercentual(this.state.orcamento.percentual)}</td>
                                    <td className="border font-italic rgm-cor-positivo" style={{ textAlign: 'center' }}>{formataDisplayEmReal(this.state.orcamento.receita_realizada)}</td>
                                    <td className={"border font-italic rgm-cor-positivo"} style={{ textAlign: 'center' }}>{formataDisplayEmPercentual(this.state.orcamento.receita_realizada_perc)}</td>
                                    <td className="border font-italic" style={{ textAlign: 'center' }}>{formataDisplayEmReal(this.state.orcamento.despesa_realizada)}</td>
                                    <td className={"border font-italic"} style={{ textAlign: 'center' }}>{formataDisplayEmPercentual(this.state.orcamento.despesa_realizada_perc)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="border bg-info text-center" COLSPAN="2" ROWSPAN="2">Categoria</th>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="2">Planejado</th>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="2">Realizado</th>
                                    <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="1">Sobre realizado do mês</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.orcamento.categorias.map((categoria, index) => {
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado text-white">
                                            <td className="border align-middle" ><button type="button" className="btn btn-link btn-sm rgm-cor-link m-2" onClick={() => { this.listaMesCategoria(categoria) }}>{this.acessaNomeCategoria(categoria.id)}</button></td>
                                            <td className="border align-middle" >{extensoTipo(categoria.tipo)}</td>
                                            <td className={"border align-middle " + this.corCategoriaRealizadaPerc(categoria.realizada_perc, categoria.tipo)} style={{ textAlign: 'right' }}>{formataDisplayEmReal(categoria.meta)}</td>
                                            <td className={"border align-middle " + this.corCategoriaRealizadaPerc(categoria.realizada_perc, categoria.tipo)} style={{ textAlign: 'right' }}>{this.formataPercentualCategoriaPlanejado(categoria.meta, categoria.tipo)}</td>
                                            <td className={"border align-middle " + this.corCategoriaRealizadaPerc(categoria.realizada_perc, categoria.tipo)} style={{ textAlign: 'right' }}>{formataDisplayEmReal(categoria.realizada)}</td>
                                            <td className={"border align-middle " + this.corCategoriaRealizadaPerc(categoria.realizada_perc, categoria.tipo)} style={{ textAlign: 'right' }}>{this.formataPercentualCategoria(categoria.meta, categoria.realizada_perc)}</td>
                                            <td className={"border align-middle font-italic " + this.corCategoriaRealizadaPerc(categoria.realizada_perc, categoria.tipo)} data-toggle="tooltip" title="Percentual sobre o total de despesa realizado" style={{ textAlign: 'right' }}>{this.formataPercentualCategoriaSobreTotal(categoria.realizada, categoria.tipo)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div >
            )

        }
    }

    renderMesSubcategoria() {
        if (this.state.listaMesSubcategoria === true) {
            return (
                <div className="container w-100 mt-5"  >
                    <button className="btn btn-primary btn-sm m-2" onClick={() => this.voltaMes()}>Volta</button>
                    <div className="card-header rgm-bg-azul-esverdiado text-white border">
                        <h5>{this.obtemNomeDoMesListado()}</h5>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="3">Planejado do mês</th>
                                <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="4">Realizado do mês</th>
                            </tr>
                            <tr>
                                <th className="border bg-info" scope="col" style={{ textAlign: 'center' }}>Receita</th>
                                <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Despesa</th>
                                <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Receita</th>
                                <th className="border bg-info" scope="col" style={{ textAlign: 'center' }} COLSPAN="2">Despesa</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="rgm-bg-azul-esverdiado text-white">
                                <td className="border font-italic rgm-cor-positivo" style={{ textAlign: 'center' }}>{formataDisplayEmReal(this.state.orcamento.receita_meta)}</td>
                                <td className="border font-italic" style={{ textAlign: 'center' }}>{formataDisplayEmReal(this.state.orcamento.despesa_meta)}</td>
                                <td className="border font-italic" style={{ textAlign: 'center' }}>{formataDisplayEmPercentual(this.state.orcamento.percentual)}</td>
                                <td className="border font-italic rgm-cor-positivo" style={{ textAlign: 'center' }}>{formataDisplayEmReal(this.state.orcamento.receita_realizada)}</td>
                                <td className={"border font-italic rgm-cor-positivo"} style={{ textAlign: 'center' }}>{formataDisplayEmPercentual(this.state.orcamento.receita_realizada_perc)}</td>
                                <td className="border font-italic" style={{ textAlign: 'center' }}>{formataDisplayEmReal(this.state.orcamento.despesa_realizada)}</td>
                                <td className={"border font-italic"} style={{ textAlign: 'center' }}>{formataDisplayEmPercentual(this.state.orcamento.despesa_realizada_perc)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="border bg-info text-center" COLSPAN="2" ROWSPAN="2">Categoria</th>
                                <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="2">Planejado</th>
                                <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="2">Realizado</th>
                                <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="1">Sobre realizado do mês</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="rgm-bg-azul-esverdiado text-white">
                                <td className="border" >{this.acessaNomeCategoria(this.state.categoria.id)}</td>
                                <td className="border" >{extensoTipo(this.state.categoria.tipo)}</td>
                                <td className="border" style={{ textAlign: 'right' }}>{formataDisplayEmReal(this.state.categoria.meta)}</td>
                                <td className="border" style={{ textAlign: 'right' }}>{this.formataPercentualCategoriaPlanejado(this.state.categoria.meta, this.state.categoria.tipo)}</td>
                                <td className="border" style={{ textAlign: 'right' }}>{formataDisplayEmReal(this.state.categoria.realizada)}</td>
                                <td className={"border " + this.corCategoriaRealizadaPerc(this.state.categoria.realizada_perc, this.state.categoria.tipo)} style={{ textAlign: 'right' }}>{this.formataPercentualCategoria(this.state.categoria.meta, this.state.categoria.realizada_perc)}</td>
                                <td className={"border font-italic " + this.corDaBorda(this.state.categoria.tipo)} data-toggle="tooltip" title="Percentual sobre o total de despesa realizado" style={{ textAlign: 'right' }}>{this.formataPercentualCategoriaSobreTotal(this.state.categoria.realizada, this.state.categoria.tipo)}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="border bg-info text-center" COLSPAN="1" ROWSPAN="1">Subcategoria</th>
                                <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="2">Planejado</th>
                                <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="2">Realizado</th>
                                <th className="border bg-info" style={{ textAlign: 'center' }} COLSPAN="1">Sobre realizado da categoria</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.categoria.sub_categorias.map((subcategoria, index) => {
                                return (
                                    <tr className="rgm-bg-azul-esverdiado text-white">
                                        <td className="border" >{this.acessaNomeSubcategoria(subcategoria.id)}</td>
                                        <td className="border" style={{ textAlign: 'right' }}>{formataDisplayEmReal(subcategoria.meta)}</td>
                                        <td className="border" style={{ textAlign: 'right' }}>{this.formataPercentualCategoriaPlanejado_dois(subcategoria.meta, this.state.categoria.tipo)}</td>
                                        <td className="border" style={{ textAlign: 'right' }}>{formataDisplayEmReal(subcategoria.realizada)}</td>
                                        <td className="border" style={{ textAlign: 'right' }}>{formataDisplayEmPercentual(subcategoria.realizada_perc)}</td>
                                        <td className={"border font-italic"} data-toggle="tooltip" title="Percentual sobre o total de despesa realizado da categoria" style={{ textAlign: 'right' }}>{this.formataPercentualSubcategoriaSobreCategoria(subcategoria.realizada)}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>

                </div >
            )
        }
    }

    renderGraficoGeralDebito() {
        return (
            <div className="container w-100 mt-5"  >
                <div className="card-header bg-info border mt-2">
                    <h5>Despesas: Planejadas x Realizada de  {this.state.anoAtual}</h5>
                </div>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ listaGrafico: false })}>Volta para lista</button>
                <div className="jumbotron"  >
                    <Line data={data} />
                </div>
            </div >
        )
    }

    renderGraficoGeralCredito() {
        return (
            <div className="container w-100 mt-5"  >
                <div className="card-header bg-info border mt-2">
                    <h5>Receitas: Planejadas x Realizada de  {this.state.anoAtual}</h5>
                </div>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ listaGrafico: false })}>Volta para lista</button>
                <div className="jumbotron"  >
                    <Line data={dataCredito} />
                </div>
            </div >
        )
    }

}

export default OrcamentoLista