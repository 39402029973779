import React from 'react'
import moment from 'moment'
import '../loader.css'
import { Component } from 'react'
import { Line, Bar } from "react-chartjs-2";
import { formataEmRealDisplayDoBD } from '../../util/Utils'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'
import { extensoStatus } from '../../util/extenso'
import ModalInclusaoRapidaLancamentoCartaoCredito from '../modal/ModalInclusaoRapidaLancamentoCartaoCredito'

var dataCredito = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            label: '????',
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: true,
            backgroundColor: 'rgba(128,0,0,0.7)',
            borderColor: 'rgba(128,0,0,1)'
        },
    ]
}
class FaturaListaExtrato extends Component {
    constructor(props) {
        super(props)
        this.state = {
            conta: this.props.conta,
            faturas: [],
            faturasResumo: [],
            faturasTudo: [],
            loader: true,
            faturaReabrir: null,
            alerta: false,
            alertaMsg: null,
            confirma: false,
            incluiRapido: false,
            mostrar: 'resumo',
            classeMaisMenos: 'fa fa-plus',
            dataCredito: null
        }
    }

    componentWillMount() {
        //this.state.dataCredito = dataCredito;
        this.listaFaturas();
    }

    listaFaturas() {
        let url = global.url + 'cartaoCredito/listaFaturas/' + this.state.conta.id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.separaFaturas(responseJson.faturas)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    separaFaturas(faturasListadasParm) {

        var faturasListadas = []

        var indiceGravar = 0

        faturasListadasParm.map(async (fatura, index) => {
            if (fatura.status === 'liquidado') {
                indiceGravar = index
            } else {
                if (moment(fatura.fatura).format('YYYY-MM') <= moment().format('YYYY-MM')) {
                    indiceGravar = index
                } else {
                    fatura.pagamentos.map(async (pagamento, index2) => {
                        if (pagamento.sub_categoria_nome_interno !== 'fatura') {
                            if (pagamento.frequencia !== 'recorrente') {
                                indiceGravar = index
                            }
                        }
                    })
                }
            }
        })

        faturasListadasParm.map(async (fatura, index) => {
            if (index <= indiceGravar) {
                faturasListadas.push(fatura)
            }
        })

        var faturasTudo = faturasListadas

        if (global.usuarioEmail === 'rgm1301@gmail.com') {
            faturasTudo = faturasListadasParm
        }

        var faturasResumo = []

        var indexInicial = -1

        faturasListadas.map(async (fatura, index) => {
            if (fatura.pode_reabrir === true) {
                indexInicial = index
            }
        })

        if (indexInicial < 0) {
            faturasResumo = faturasListadas
        } else {
            faturasListadas.map(async (fatura, index) => {
                if (index >= indexInicial) {
                    faturasResumo.push(fatura)
                }
            })
        }

        this.setState({ faturasTudo: faturasTudo, faturasResumo: faturasResumo, faturas: faturasResumo, loader: false })

        let anoCorrente = moment().year();

        dataCredito.datasets[0].data.fill(0);

        faturasTudo.map((fatura, index) => {
            const substring1 = fatura.fatura.substring(5, 7);
            let mes = parseInt(substring1);
            mes--

            const substring2 = fatura.fatura.substring(0, 4);
            let ano = parseInt(substring2);

            if (ano === anoCorrente) {
                //anoCompleto[mes] = parseFloat(fatura.saldo_total)
                dataCredito.datasets[0].data[mes] = parseFloat(fatura.saldo_total).toFixed(2)
            }
        })
        
        dataCredito.datasets[0].label = anoCorrente;

        this.setState({
            dataCredito: dataCredito
        })

    }

    corDoValor(valor) {
        if (valor < 0) {
            return 'text-danger font-italic'
        } else {
            return 'text-primary'
        }

    }

    contaExtratos(conta) {
        this.props.contaListaExtrato(conta)
    }

    mesAnoPorExtenso(data) {
        var mesExtenso = moment(data).format('MMM');
        var anoExtenso = moment(data).format('YYYY');

        return anoExtenso + ' - ' + mesExtenso.toUpperCase()

    }

    dataPagamento(dataDePagamento) {

        if (dataDePagamento == null) {
            return '------'
        } else {
            return moment(dataDePagamento).format('DD/MM/YYYY')
        }
    }

    saldoPago(saldoPago) {
        if (saldoPago == null) {
            return '------'
        } else {
            return formataEmRealDisplayDoBD(parseFloat(saldoPago))
        }
    }

    saldoDevedor(saldoPago, saldoTotal, status) {
        if (saldoPago === null) {
            saldoPago = 0
        }

        if (status === 'emAberto') {
            return '------'
        } else {
            var saldoDevedor = saldoTotal - saldoPago
            return formataEmRealDisplayDoBD(saldoDevedor)
        }
    }

    podeReabrir(podeReabrir) {
        if (podeReabrir === true) {
            return null
        } else {
            return 'disabled'
        }
    }

    reabrirFaseI(fatura) {

        if (fatura.pode_reabrir === false) {
            return
        }

        this.setState({ faturaReabrir: fatura, pedeConfirmacao: true })

    }

    mostrar() {
        if (this.state.mostrar === 'resumo') {
            this.setState({ mostrar: 'tudo', faturas: this.state.faturasTudo, classeMaisMenos: 'fa fa-minus' })
        } else {
            this.setState({ mostrar: 'resumo', faturas: this.state.faturasResumo, classeMaisMenos: 'fa fa-plus' })
        }

    }

    render() {
        if (this.state.loader === true) {
            return [
                this.renderLoad()
            ]
        } else {
            return [
                this.renderAlert(),
                this.renderConfirmacao(),
                this.renderIncluiRapido(),
                this.renderComum(),
                this.renderGrafico()
            ]
        }
    }

    renderComum() {
        return (
            <div className="container w-100 mt-5">
                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ incluiRapido: true })}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Lançamento</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.mostrar()}>Mostrar&nbsp;<i class={this.state.classeMaisMenos} aria-hidden="true"></i></button>
                <div className="card bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle text-white m-2">
                            <h5>{this.state.conta.nome}</h5>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="bg-info">
                                <th scope="col" className="text-center">Fatura</th>
                                <th scope="col">Status</th>
                                <th scope="col" className="text-center">Vencimento</th>
                                <th scope="col" className="text-right">Total</th>
                                <th scope="col" className="text-center">Pagamento</th>
                                <th scope="col" className="text-right">Pago</th>
                                <th scope="col" className="text-right">Financiado</th>
                                <th className="text-center" scope="col">Reabrir</th>
                            </tr>
                        </thead>
                        <tbody className="rgm-bg-azul-esverdiado">
                            {this.state.faturas.map((fatura, index) => {
                                return (
                                    <tr className="text-white">
                                        <td className="text-center">
                                            <button className="btn btn-group-sm btn-primary btn-sm border-0" onClick={() => this.props.faturaExtrato(fatura)}>
                                                {moment(fatura.fatura).format('YYYY MMM').toUpperCase()}
                                            </button>
                                        </td>
                                        <td>{extensoStatus(fatura.status)}</td>
                                        <td className="text-center">{moment(fatura.data_de_vencimento).format('DD/MM/YYYY')}</td>
                                        <td className="text-right font-weight-bold">{formataEmRealDisplayDoBD(parseFloat(fatura.saldo_total))}</td>
                                        <td className="text-center">{this.dataPagamento(fatura.data_de_pagamento)}</td>
                                        <td className="text-right">{this.saldoPago((fatura.saldo_pago))}</td>
                                        <td className="text-right">{this.saldoDevedor(parseFloat(fatura.saldo_pago), parseFloat(fatura.saldo_total), fatura.status)}</td>
                                        <td className="text-center">
                                            <button className={"btn btn-link btn-sm " + this.podeReabrir(fatura.pode_reabrir)} onClick={() => { this.reabrirFaseI(fatura) }}>
                                                <i class="fa fa-check fa-2x rgm-cor-link" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    renderGrafico() {

        var anoCabec = moment().format('YYYY')

        return (
            <div className="container w-100 mt-5">

                <div className="card-header bg-info border mt-2">
                    <h7>Gráfico</h7>
                </div>
                {/* <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}>Volta para lista</button> */}
                <div className="jumbotron">
                    <Bar data={this.state.dataCredito} />
                </div>
            </div>
        )
    }

    renderLoad() {
        return (
            <div>
                <div className="card-header bg-info border mt-2">
                    <h7>{this.state.conta.nome}</h7>
                </div>
                <div className="loader text-center mt-5"></div>
                <span className="text-warning">Aguarde... Carregando...</span>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.reabrir()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a reabertura desta fatura?" />
                </>
            )
        }
    }



    reabrir() {

        var fatura = this.state.faturaReabrir

        let url = global.url + 'fatura/reabrir'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(fatura)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ resp: responseJson.resp })
                    this.setState({ pedeConfirmacao: false, alerta: true, alertaMsg: 'Reabertura concluida!' })

                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    volta() {
        this.setState({ incluiRapido: false })
        this.listaFaturas()
    }

    renderIncluiRapido() {
        if (this.state.incluiRapido === true) {
            return (
                <>
                    <ModalInclusaoRapidaLancamentoCartaoCredito volta={() => this.volta()}
                        conta_id={this.state.conta.id}
                        dia_fechamento={this.state.conta.conta_cartoes_creditos.dia_fechamento}
                        dia_pagamento={this.state.conta.conta_cartoes_creditos.dia_pagamento}
                    />
                </>
            )
        }
    }


}

export default FaturaListaExtrato