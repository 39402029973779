import React, { Component } from 'react'
import moment from 'moment'
import '../loader.css'
import { formataDisplayEmReal } from '../../util/Utils'
import { extensoFrequencia, extensoTipo } from '../../util/extenso'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
//import { data } from './data';
import { Impressao } from './impressao';

var dadosPdf =
{
    header: {
        cabec: 'Relação de gastos por amigo',
        periodo: 'MMM/AAAA',
        amigo: '?',
        total: 0
    },

    body:
        [
            {
                nome: "Mousepad",
                qtdEstoque: 4,
                qtdVendido: 10,
            },
            {
                nome: "Teclado",
                qtdEstoque: 8,
                qtdVendido: 12,
            },
            {
                nome: "Monitor",
                qtdEstoque: 2,
                qtdVendido: 14,
            },
            {
                nome: "Mouse",
                qtdEstoque: 15,
                qtdVendido: 32,
            }
        ]
}

pdfMake.vfs = pdfFonts.pdfMake.vfs;

var valorTotalAmigo = 0
class GastosPorAmigo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usuario: null,
            amigosLista: [],
            loader: true,
            categoriasGeral: [],
            categoriasTotais: [],
            amigoDetalhado: []
        }
    }

    componentWillMount() {
        this.acessaUsuario()
        this.listaAmigos()
        this.listaCategorias()
    }

    acessaUsuario() {
        let url = global.url + 'usuario/consulta'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ usuario: responseJson.usuario })
            })

    }

    obtemMovimentacaoDeUmMes(dataRequerida) {
        let url = global.url + 'pagamento/obtemMovimentacaoDeUmMes/' + dataRequerida

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustasPagamentos(responseJson.pagamentos)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    ajustasPagamentos(pagamentosTotal) {

        var categoriasGeral = []
        var categoriasTotais = []

        pagamentosTotal.map(async (pagamento, index) => {
            if (pagamento.fatura === null) {
                pagamento.categorias.map(async (categoria, index) => {
                    var categoriaNew = JSON.parse(JSON.stringify(categoria))
                    if (categoriaNew.entra_nas_estatisticas === true) {
                        if (categoriaNew.tipo === 'debito') {
                            categoriaNew.data_de_vencimento = pagamento.data_de_vencimento
                            categoriaNew.data_de_pagamento = pagamento.data_de_pagamento
                            categoriaNew.frequencia = pagamento.frequencia
                            categoriaNew.numero_de_parcelas = pagamento.transacao.numero_de_parcelas
                            categoriaNew.parcela = pagamento.parcela
                            categoriasGeral.push(categoriaNew)
                            categoriasTotais.push(JSON.parse(JSON.stringify(categoriaNew)))
                        }
                    }
                })
            }
        })

        pagamentosTotal.map(async (pagamento, index) => {
            if (pagamento.sub_categoria_nome_interno === 'fatura') {
                if (pagamento.fatura !== null) {
                    pagamento.fatura.pagamentos.map(async (pagamentos, index3) => {
                        pagamentos.categorias.map(async (categoria, index) => {
                            var categoriaNew = JSON.parse(JSON.stringify(categoria))
                            if (categoriaNew.entra_nas_estatisticas === true) {
                                if (categoriaNew.tipo === 'debito') {
                                    categoriaNew.data_de_vencimento = pagamentos.data_de_vencimento
                                    if (pagamento.data_de_pagamento !== null) {
                                        categoriaNew.data_de_pagamento = pagamento.data_de_pagamento
                                    } else {
                                        categoriaNew.data_de_pagamento = pagamento.data_de_vencimento
                                    }
                                    categoriaNew.frequencia = pagamentos.frequencia
                                    categoriaNew.numero_de_parcelas = pagamentos.transacao.numero_de_parcelas
                                    categoriaNew.parcela = pagamentos.parcela
                                    categoriasGeral.push(categoriaNew)
                                    categoriasTotais.push(JSON.parse(JSON.stringify(categoriaNew)))
                                }
                            }
                        })
                    })
                }
            }
        })


        this.setState({ categoriasGeral: categoriasGeral })
        this.setState({ categoriasTotais: categoriasTotais })

        var amigos = []
        var total = 0

        categoriasGeral.map(async (categoria, index) => {
            var indexFind = amigos.findIndex(obj => (
                obj.amigo_id === categoria.amigo_id
            ))
            if (indexFind === -1) {
                var amigoLido = { nome: this.state.usuario.nome }
                if (categoria.amigo_id !== null) {
                    amigoLido = this.state.amigosLista.find(function (obj) { return obj.id === categoria.amigo_id })
                }
                if (amigoLido === undefined) {
                    categoria.amigo_nome = '???'
                } else {
                    categoria.amigo_nome = amigoLido.nome
                }
                var valT = parseFloat(categoria.valor)
                total = total + valT
                amigos.push(categoria)
            } else {
                var valA = parseFloat(amigos[indexFind].valor)
                var valB = parseFloat(categoria.valor)
                total = total + valB
                amigos[indexFind].valor = valA + valB
                amigos[indexFind].valor = parseFloat(amigos[indexFind].valor).toFixed(2)
            }
        })

        var amigosComPercentual = []
        amigos.map(async (amigo, index) => {
            var perc = (amigo.valor / total) * 100
            perc = parseFloat(perc).toFixed(2)
            var newPerc = perc.toString().replace('.', ',')
            amigo.percNum = perc
            amigo.perc = newPerc
            amigosComPercentual.push(amigo)
        })

        var amigosSorteadas = amigosComPercentual.sort(function (a, b) {
            if (parseFloat(a.percNum) > parseFloat(b.percNum)) {
                return -1
            }

            if (a.percNum < b.percNum) {
                return 1
            }

            return 0
        })

        total = total.toFixed(2)

        this.setState({ amigos: amigosSorteadas, total: total })

        this.setState({ loader: false })

    }

    listaAmigos() {
        let url = global.url + 'amigo/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ amigosLista: responseJson.amigos })
                    this.obtemMovimentacaoDeUmMes(this.props.getMesAtual)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    selecionaCategorias(categoriasLista) {

        this.setState({ categoriasTotal: JSON.parse(JSON.stringify(categoriasLista)) })
        this.setState({ categoriasTodas: JSON.parse(JSON.stringify(categoriasLista)) })

        var result = false

        var categoriasDebito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'debito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        var categoriasCredito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'credito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        this.setState({ categoriasDebito: categoriasDebito, categorias: categoriasDebito })
        this.setState({ categoriasCredito: categoriasCredito })

        this.setState({ categoriasDebito: categoriasDebito })
        this.setState({ categoriasCredito: categoriasCredito })

    }


    detalhaAmigo(amigo) {

        this.setState({ mostraDetalheAmigo: true })

        var amigoDetalhado = []

        this.state.categoriasTotais.map(async (categoria, index) => {
            if (categoria.amigo_id === amigo.amigo_id) {
                amigoDetalhado.push(categoria)
            }
        })

        amigoDetalhado.sort((a, b) => {
            if (a.data_de_vencimento < b.data_de_vencimento) {
                return -1
            } else {
                if (a.data_de_vencimento > b.data_de_vencimento) {
                    return 1
                } else {
                    return 0
                }
            }
        })


        this.setState({ amigoDetalhado: amigoDetalhado })

    }


    render() {
        if (this.state.loader === true) {
            return [
                this.renderLoad()
            ]
        } else {
            if (this.state.mostraDetalheAmigo === true) {
                return [
                    this.renderDetalheAmigo()
                ]
            } else {
                return [
                    this.renderComum()
                ]
            }
        }
    }

    renderComum() {
        return (
            <div className="container w-100 mt-5">
                <nav className="navbar navbar-expand-md navbar-dark rgm-bg-azul-esverdiado">
                    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <button type="button" className="nav-link btn btn-link" onClick={() => { this.props.volta() }}>Voltar</button>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="card bg-transparent mt-1 text-white">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle m-2">
                            <h5>Relatório | Gastos por Amigo | {moment(this.props.getMesAtual).format('MMMM/YYYY')}</h5>
                        </div>
                    </div>

                    <table className="table table-bordered">
                        <thead>
                            <tr className="bg-info">
                                <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                <th scope="col">Amigo</th>
                                <th scope="col" className="text-right">%</th>
                                <th scope="col" className="text-right">Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.amigos.map((amigo, index) => {
                                return (
                                    <tr className="rgm-bg-azul-esverdiado text-white">
                                        <td className="text-center text-white align-middle">
                                            <button className="btn btn-link btn-sm text-center" onClick={() => this.detalhaAmigo(amigo)}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                        </td>
                                        <td>{amigo.amigo_nome}</td>
                                        <td className="text-right">{amigo.perc}%</td>
                                        <td className="text-right">{formataDisplayEmReal(amigo.valor)}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                        <thead>
                            <tr className="bg-info">
                                <th scope="col" COLSPAN="2" className="text-center">Total</th>
                                <th scope="col" className="text-right">100,00%</th>
                                <th scope="col" className="text-right">{formataDisplayEmReal(this.state.total)}</th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        )
    }

    obtemCategoria(amigo) {

        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === amigo.categoria_id })

        if (categoria === undefined) {
            return '---'
        }

        return categoria.nome

    }

    obtemSubcategoria(amigo) {

        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === amigo.categoria_id })

        if (categoria === undefined) {
            return '---'
        }

        const subCategoria = categoria.sub_categorias.find(function (obj) { return obj.id === amigo.sub_categoria_id })

        if (subCategoria === undefined) {
            return '---'
        }

        return subCategoria.nome

    }

    formataData(data) {

        if (data === null) {
            return ''
        }

        const dataFormatada = moment(data).format('DD/MM/YYYY')

        return dataFormatada
    }

    obtemParcelas(pagamento) {
        if (pagamento.frequencia === 'recorrente') {
            return ('000' + pagamento.parcela).slice(-3) + '/999'
        } else {
            if (pagamento.numero_de_parcelas === null) {
                return ''
            } else {
                return ('000' + pagamento.parcela).slice(-3) + '/' + ('000' + pagamento.numero_de_parcelas).slice(-3)
            }
        }
    }


    renderDetalheAmigo() {
        return (
            <div className="container-fluid w-100 mt-5">
                <div class="row justify-content-md-center">
                    <div className="form-group row mt-0 mb-0">
                        <div className="col-sm-12">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.setState({ mostraDetalheAmigo: false }) }}>Voltar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.visualizarImpressao() }}>PDF</button>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center">
                    <div className="card row bg-transparent mt-1 text-white">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>Relatório | Gastos por Amigo | {moment(this.props.getMesAtual).format('MMMM/YYYY')}</h5>
                            </div>
                        </div>

                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Categoria</th>
                                    <th scope="col">Subcategoria</th>
                                    <th className="text-center" scope="col">Vencimento</th>
                                    <th className="text-center" scope="col">Pagamento</th>
                                    <th scope="col">Frequência</th>
                                    <th scope="col">Parcela</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col" className="text-right">Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.amigoDetalhado.map((amigo, index) => {
                                    this.acumulaValorTotal(amigo, index)
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado text-white">
                                            <td className="rgm-diminuirParaCaber">{amigo.descricao}</td>
                                            <td className="rgm-diminuirParaCaber">{this.obtemCategoria(amigo)}</td>
                                            <td className="rgm-diminuirParaCaber">{this.obtemSubcategoria(amigo)}</td>
                                            <td className="text-center">{this.formataData(amigo.data_de_vencimento)}</td>
                                            <td className="text-center">{this.formataData(amigo.data_de_pagamento)}</td>
                                            <td>{extensoFrequencia(amigo.frequencia)}</td>
                                            <td className="text-center align-middle text-white">{this.obtemParcelas(amigo)}</td>
                                            <td>{extensoTipo(amigo.tipo)}</td>
                                            <td className="text-right">{formataDisplayEmReal(amigo.valor)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="text-center" COLSPAN="7">Total de débitos no período</th>
                                    <th scope="col" className="text-center" COLSPAN="3">{formataDisplayEmReal(valorTotalAmigo)}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    acumulaValorTotal(pagamento, index) {
        if (index === 0) {
            valorTotalAmigo = 0
        }

        valorTotalAmigo = parseFloat(valorTotalAmigo) + parseFloat(pagamento.valor)

        valorTotalAmigo = parseFloat(valorTotalAmigo).toFixed(2)

    }

    visualizarImpressao() {

        var dadosParaPdf = []

        this.state.amigoDetalhado.map(async (amigo, index) => {
            var dadoParaPdf = JSON.parse(JSON.stringify(amigo))
            dadoParaPdf.categoria_nome = this.obtemCategoria(amigo)
            dadoParaPdf.sub_categoria_nome = this.obtemSubcategoria(amigo)
            dadoParaPdf.parcela_desc = this.obtemParcelas(amigo)
            dadosParaPdf.push(dadoParaPdf)
        })

        var amigo_id = this.state.amigoDetalhado[0].amigo_id

        const amigoLido = this.state.amigosLista.find(function (obj) { return obj.id === amigo_id })

        var nome = ''

        if (amigoLido === undefined) {
            nome = '---'
        } else {
            nome = amigoLido.nome
        }

        if (this.state.amigoDetalhado[0].amigo_id === null) {
            nome = this.state.usuario.nome
        }

        dadosPdf.body = dadosParaPdf
        dadosPdf.header.amigo = nome
        dadosPdf.header.periodo = moment(this.props.getMesAtual).format('MMMM/YYYY')
        dadosPdf.header.total = valorTotalAmigo
        var documento = Impressao(dadosPdf);
        pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
    }


    renderLoad() {
        return (
            <div className="container w-100 mt-5"    >
                <div className="card-header bg-info border mt-2">
                    <h7>Lista de pagamentos</h7>
                </div>
                <div className="loader text-center mt-5"></div>
                <span className="text-warning">Aguarde... Carregando...</span>
            </div>
        )
    }
}

export default GastosPorAmigo