import React, { Component } from "react"
import ModalAviso from '../modal/ModalAviso'
import ModalConfirmaComecarDoZero from '../modal/ModalConfirmaComecarDoZero'

class UsuarioConsulta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usuario: { nome: 'xxxx' }
        }
    }

    componentWillMount() {
        this.acessaUsuario()
    }

    acessaUsuario() {
        let url = global.url + 'usuario/consulta'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ usuario: responseJson.usuario })
            })

    }


    render() {
        return ([
            this.renderAlert(),
            this.renderConfirmacao(),
            this.renderPrincipal(),
        ])
    }


    renderPrincipal() {
        return (
            <div className="container w-100 mt-5"    >
                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.alteracao(this.state.usuario) }}>Alterar</button>
                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.alteracaoSenha(this.state.usuario) }}>Mudar senha</button>
                <form className="card rgm-bg-azul-esverdiado border m-2 text-white">
                    <div class="card-header">Meus dados - Consulta</div>
                    <div className="form-group row">
                        <label for="nome" className="col-sm-1 col-form-label m-3">Nome</label>
                        <div className="col-sm-5">
                            <input disabled type="text" className="form-control m-3" id="nome" value={this.state.usuario.nome} placeholder="nome" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="email" className="col-sm-1 col-form-label m-3">Email</label>
                        <div className="col-sm-4">
                            <input disabled type="email" className="form-control m-3" id="email" value={this.state.usuario.email} placeholder="Email" />
                        </div>
                    </div>
                </form>
                <button type="button" className="btn btn-danger btn-sm m-2" onClick={() => { this.confirmaComecarDoZero() }}>Começar do zero</button>
            </div>
        )
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirmaComecarDoZero confirma={() => this.zerarDados()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a exclusão de todos os seus dados? ; !!!! Esta ação é irreversível !!!!!" />
                </>
            )
        }
    }

    confirmaComecarDoZero() {
        this.setState({ pedeConfirmacao: true })

    }

    zerarDados() {

        this.setState({ pedeConfirmacao: false })

        let url = global.url + 'usuario/comecarDoZero'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
        })

    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }




}

export default UsuarioConsulta