import moment from 'moment'
import { formataDisplayEmReal } from '../../util/Utils'


export const Impressao = (dadosParaImpressao) => {

    var corpoDocumento = criaCorpoDocumento(dadosParaImpressao.body);
    return gerarDocumento(corpoDocumento, dadosParaImpressao.header);
}

export const criaCorpoDocumento = (detalhe) => {
    const header = [
        { text: 'Descrição', bold: true, alignment: 'center', fontSize: 12, margin: [0, 4, 0, 0] },
        { text: 'Categoria', bold: true, alignment: 'center', fontSize: 12, margin: [0, 4, 0, 0] },
        { text: 'Subcategoria', bold: true, alignment: 'center', fontSize: 12, margin: [0, 4, 0, 0] },
        { text: 'Vencimento', bold: true, alignment: 'center', fontSize: 12, margin: [0, 4, 0, 0] },
        { text: 'Pagamento', bold: true, alignment: 'center', fontSize: 12, margin: [0, 4, 0, 0] },
        { text: 'Frequencia', bold: true, alignment: 'center', fontSize: 12, margin: [0, 4, 0, 0] },
        { text: 'Parcela', bold: true, alignment: 'center', fontSize: 12, margin: [0, 4, 0, 0] },
        { text: 'Valor', bold: true, fontSize: 12, alignment: 'right', margin: [0, 4, 0, 0] },
    ];
    const body = detalhe.map((prod) => {
        return [
            { text: prod.descricao, fontSize: 10 },
            { text: prod.categoria_nome, fontSize: 10 },
            { text: prod.sub_categoria_nome, fontSize: 10 },
            { text: moment(prod.data_de_vencimento).format('DD/MM/YYYY'), fontSize: 10, alignment: 'center' },
            { text: formataData(prod.data_de_pagamento), fontSize: 10, alignment: 'center' },
            { text: prod.frequencia, fontSize: 10 },
            { text: prod.parcela_desc, fontSize: 10 },
            { text: formataDisplayEmReal(prod.valor), fontSize: 10, alignment: 'right' },
        ];
    });

    const lineHeader = [
        {
            text:
                '',
            alignment: 'center',
            fontSize: 5,
            colSpan: 8,    //aqui
        },
        {},
        {},
    ];

    let content = [header, lineHeader];
    content = [...content, ...body];
    return content;
}

export const gerarDocumento = (corpoDocumento, header) => {
    const documento = {
        pageSize: 'B4',
        pageOrientation: 'landscape',
        pageMargins: [14, 53, 14, 48],
        header: function () {
            return {
                margin: [14, 12, 14, 0],
                layout: 'noBorders',
                table: {
                    widths: ['*'],
                    body: [
                        [
                            { text: header.cabec + ' no período de  ' + header.periodo, style: 'reportName' }
                        ],
                        [
                            { text: header.amigo, style: 'reportName' }
                        ],
                        [
                            { text: header.periodo, style: 'reportName' }
                        ],
                    ],
                },
            };
        },
        content: [
            {
                //layout: 'noBorders',
                table: {
                    headerRows: 3,
                    //widths: [55, 55, 55, 55, 55, 55, 55, 55],   //aqui
                    //widths: ['*', '*', '*', '*', '*', '*', '*', '*'],   //aqui
                    body: corpoDocumento
                }
            },
            {
                text: '\n'
            },
            {
                text: '\n'
            },
            {
                text: '\n'
            },
            {
                text: 'Total geral ' + formataDisplayEmReal(header.total), fontSize: 18, alignment: 'center', bold: true, color: 'red'
            },
        ],
        footer(currentPage, pageCount) {
            return {
                layout: 'noBorders',
                margin: [14, 0, 14, 22],
                table: {
                    widths: ['auto'],
                    body: [
                        [
                            {
                                text:
                                    '__________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                                alignment: 'center',
                                fontSize: 5,
                            },
                        ],
                        [
                            [
                                {
                                    text: `Página ${currentPage.toString()} de ${pageCount}`,
                                    fontSize: 7,
                                    alignment: 'right',
                                    /* horizontal, vertical */
                                    margin: [3, 0],
                                },
                                {
                                    text: '© Rogar',
                                    fontSize: 7,
                                    alignment: 'center',
                                },
                            ],
                        ],
                    ],
                },
            };
        },
        styles: {
            reportName: {
                fontSize: 10,
                bold: true,
                alignment: 'center',
                margin: [0, 4, 0, 0],
            }
        },

    };
    return documento;
}

export const formataData = (data) => {

    if (data === null) {
        return ''
    }

    const dataFormatada = moment(data).format('DD/MM/YYYY')

    return dataFormatada
}