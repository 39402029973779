import React, { Component } from "react"
import Rogar from '../../assets/img/Rogar5.png'


class Apresentacao extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillMount() {
        var url = document.URL

        var arrayOfStrings = url.split('/');

        var uuid = (arrayOfStrings.length - 1)
        var redefinir = uuid - 1

        if (arrayOfStrings[redefinir] === 'redefinir') {
            this.props.chamaLogin()
            return
        }

    }


    render() {
        return [
            this.pagina_00(),
            this.pagina_pr(),
            //this.pagina_01(),
            //this.pagina_03()
        ]

    }

    pagina_00() {
        return (
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                <a className="navbar-brand" href="#"><img src={Rogar} alt="10px" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="text-center bg-light">
                        <img src={Rogar} alt="10px" />
                    </div>
                </button>

                <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                        </li>
                    </ul>
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link text-danger" href="#"><h4>Versão Beta - restrita a alguns usuários 1.1</h4><span className="sr-only">(current)</span></a>
                        </li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <button className="btn btn-lg btn-primary" onClick={() => { this.props.chamaLogin() }} >Entrar</button>
                    </form>
                </div>
            </nav>
        )
    }

    pagina_pr() {
        return (
            <div>
                <div className="jumbotron mb-0 opacidade-9">
                    <div className="container mt-3">
                        <h1 className="display-3">Seja muito bem-vindo!</h1>
                        <p>É isto mesmo que você está pensando, mais um gerenciador de finanças pessoais, e nasceu por falta de uma opção simples e objetiva, pelo menos do nosso ponto de vista.</p>
                        <p>Provavelmente você chegou até aqui porque está querendo fazer um controle mais efetivo de suas finanças, e você vai partir do zero ou você já faz isto usando uma planilha eletrônica, “papel de pão” ou até mesmo já usa um software, mas não está satisfeito da forma como eles são organizados.</p>
                        <p>Se você já utiliza outro software, peço que pelo menos avalie nosso gerenciador, acreditamos que você vai se surpreender com a facilidade do uso.</p>
                        <p>Você não encontrara “botões flutuantes”, “desenhos animados”, “muita cor” ou qualquer coisa que tire o foco do principal, que são os números, que no fim das contas é o que importa para se fazer uma gestão efetiva de suas finanças.</p>
                        <p>O gerenciador poderá ser usado por um período de 3 meses sem nenhum custo, após este período lhe será ofertada uma assinatura com duração de 12 meses por 99,90 que poderá ser paga de várias maneiras, este valor é menor que 2 cafezinhos por mês.</p>
                        <p>Desde já agradecemos o seu interesse em usar nosso gerenciador de finanças pessoais.</p>
                    </div>
                </div>
            </div>
        )
    }

    pagina_pr_zizi() {
        return (
            <div>
                <div className="jumbotron mb-0 opacidade-9">
                    <div className="container mt-3">
                        <h1 className="text-danger text-center">ROGAR</h1>
                        <p>O controle financeiro não precisa ser complicado. Se você chegou aqui e busca um controle mais efetivo de suas finanças, você está no lugar certo.</p>
                        <p>Nosso foco é ajudá-lo a organizar suas finanças com mais eficiência para que você tenha uma vida tranquila e confortável, para isso é essencial que seu dinheiro seja bem administrado. </p>
                        <p>Em nossa plataforma você terá tudo que precisa para ter uma gestão financeira eficiente. Os 3 (três) primeiros meses são gratuitos, após esse período, será cobrado o plano mensal de R$ 10,00 (dez reais). *forma segura no pagamento de cartão de crédito ou via boleto*. </p>
                        <p>No fim das contas, queremos é que todas as pessoas possam organizar suas vidas financeiras. É simples. É seguro.</p>
                    </div>
                </div>
            </div>
        )
    }

    pagina_01() {
        return (
            <div>
                <div className="jumbotron bg-light mt-0">
                    <div className="row">
                        <div className="col-md-4">
                            <h2>Contas</h2>
                            <p>Tenha total controle de suas contas, vizualize extratos detalhados. </p>
                            <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
                        </div>
                        <div className="col-md-4">
                            <h2>Pagamentos</h2>
                            <p>Donec id elit non mi porta gravida at eget metus. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Etiam porta sem malesuada magna mollis euismod. Donec sed odio dui. </p>
                            <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
                        </div>
                        <div className="col-md-4">
                            <h2>Heading</h2>
                            <p>Donec sed odio dui. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Vestibulum id ligula porta felis euismod semper. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.</p>
                            <p><a className="btn btn-secondary" href="#" role="button">View details &raquo;</a></p>
                        </div>
                    </div>
                    <hr />
                </div>
            </div>
        )
    }

    pagina_02() {
        return (
            <div className="jumbotron mt-0 bg-light">
                <h1 className="display-4">Pagina 2</h1>
                <p>Esta é minha pagina 2 com conatiner</p>
            </div>
        )
    }

    pagina_03() {
        return (
            <div className="jumbotron">
                <h1 className="display-4">Pagina 3</h1>
                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.chamaLogin() }}>Login</button>
            </div>
        )
    }
}

export default Apresentacao