import React, { Component } from 'react'
import AmigoLista from '../amigo/AmigoLista'
import AmigoInclusao from '../amigo/AmigoInclusao'
import AmigoAlteracao from '../amigo/AmigoAlteracao'
import '../../rgm.css'
class Amigo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      amigoLista: true,
      amigoInclusao: false,
      amigoAlteracao: false,
      amigo: null
    }
  }

  componentWillMount() {
    global.amigosPrincipal = () => { this.amigoListaTrue() }
  }

  amigoListaTrue() {
    this.setFalseAll()
    this.setState({
      amigoLista: true
    })
  }

  amigoInclusaoTrue() {
    this.setFalseAll()
    this.setState({
      amigoInclusao: true
    })
  }

  amigoAlteracaoTrue(amigo) {
    this.setFalseAll()
    this.setState({ amigoAlteracao: true, amigo: amigo })
  }

  setFalseAll() {
    this.setState({
      amigoLista: false,
      amigoInclusao: false,
      amigoAlteracao: false
    })
  }


  render() {

    if (this.state.amigoLista === true) {
      return (<
        AmigoLista
        amigoInclusao={() => { this.amigoInclusaoTrue() }}
        amigoAlteracao={(amigo) => { this.amigoAlteracaoTrue(amigo) }}
      />
      )
    }

    if (this.state.amigoInclusao === true) {
      return (<
        AmigoInclusao
        volta={() => { this.amigoListaTrue() }}
      />
      )
    }

    if (this.state.amigoAlteracao === true) {
      return (<
        AmigoAlteracao
        amigo={this.state.amigo}
        volta={() => { this.amigoListaTrue() }}
      />
      )
    }


    if (this.state.amigoLista === false &&
      this.state.amigoInclusao === false &&
      this.state.amigoAlteracao === false
    ) {
      return null
    }

  }

}

export default Amigo