import React, { Component } from "react"
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'
import 'moment/locale/pt-br'
import 'react-datepicker/dist/react-datepicker.css'
import ModalAviso from '../modal/ModalAviso'
import { titleize } from '../../util/Utils'
import { formataDisplayEmReal } from '../../util/Utils'

class AdmAlteracao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usuario: props.usuario,
            nome: props.usuario.nome,
            email: props.usuario.email,
            emailAntigo: props.usuario.email,
            pagamentos: props.usuario.body_pagamento,
            pagamentosLista: false
        }
    }

    componentWillMount() {
        //alert('props.usuario' + JSON.stringify(this.props.usuario))
    }

    alteraUsuario() {

        let url = global.url + 'adm/altera'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(this.state.usuario)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.props.admListaTrue()
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }

    handleChangeNome(event) {
        var nome = titleize(event.target.value)
        this.setState({ nome: nome })
    }

    handleChangeAno(event) {
        //alert(event.target.value)

        //if (event.target.value < 2019) {
        //    return
        //}

        var usuarioCPY = this.state.usuario
        usuarioCPY.ano_recorrente = event.target.value
        this.setState({ usuario: usuarioCPY })
    }

    handleChangeEmail(event) {
        this.setState({ email: event.target.value })
    }

    handleChangeDataInicio(data_inicio_assinatura) {
        var usuarioCPY = this.state.usuario
        usuarioCPY.data_inicio_assinatura = data_inicio_assinatura
        this.setState({ usuario: usuarioCPY })
    }

    handleChangeDataFim(data_fim_assinatura) {
        var usuarioCPY = this.state.usuario
        usuarioCPY.data_fim_assinatura = data_fim_assinatura
        this.setState({ usuario: usuarioCPY })
    }

    render() {
        if (this.state.pagamentosLista === true) {
            return [
                this.renderPagamentosLista()
            ]
        } else {
            return [
                this.renderAlert(),
                this.renderAlertaSaida(),
                this.renderComum()
            ]
        }
    }

    renderComum() {

        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border m-2  text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Alteração do usuario</h5>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="nome" className="col-sm-2 col-form-label m-2">Nome</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control mt-3 text-capitalize m-2" id="nome" onChange={this.handleChangeNome.bind(this)} value={this.state.nome} placeholder="nome" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="email" className="col-sm-2 col-form-label m-2">Email</label>
                        <div className="col-sm-5">
                            <input type="email" className="form-control m-2" id="email" onChange={this.handleChangeEmail.bind(this)} value={this.state.email} placeholder="Email" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="nome" className="col-sm-2 col-form-label m-2">Status</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control mt-3 m-2" id="nome" value={this.state.usuario.status} placeholder="status" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="nome" className="col-sm-2 col-form-label m-2">Ano recorrente</label>
                        <div className="col-sm-1">
                            <input type="text" className="form-control mt-3 m-2" id="nome" onChange={this.handleChangeAno.bind(this)} value={this.state.usuario.ano_recorrente} placeholder="anoRecorrente" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="nome" className="col-sm-2 col-form-label m-2">Assinatura inicio</label>
                        <div className="col-sm-5">
                            <DatePicker
                                className="form-control text-center m-2"
                                dateFormat="dd/MM/yyyy"
                                id="dataInicio"
                                selected={moment(this.state.usuario.data_inicio_assinatura).toDate()}
                                onChange={this.handleChangeDataInicio.bind(this)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="nome" className="col-sm-2 col-form-label m-2">Assinatura fim</label>
                        <div className="col-sm-5">
                            <DatePicker
                                className="form-control text-center m-2"
                                dateFormat="dd/MM/yyyy"
                                id="dataFim"
                                selected={moment(this.state.usuario.data_fim_assinatura).toDate()}
                                onChange={this.handleChangeDataFim.bind(this)} />
                        </div>
                    </div>
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.admListaTrue() }}>Voltar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.alteraUsuario() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.setState({ pagamentosLista: true }) }}>Pagamentos</button>
                        </div>
                    </div>
                </form >
            </div >
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    formataDisplayEmRealLocal(amount) {

        amount = amount.toString()

        var result = amount.indexOf('.')

        if (result === -1) {
            amount = amount + '.00'
        }

        return formataDisplayEmReal(amount)
    }

    renderPagamentosLista() {
        return (
            <div className="container w-100 mt-5"  >
                <div className="card bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle m-2">
                            <h5>Lista de pagamentos</h5>
                        </div>
                    </div>

                    <table className="table table-sm table-bordered mt-0">
                        <thead>
                            <tr className="bg-info">
                                <th scope="col">Data</th> 
                                <th scope="col">Hora</th>
                                <th scope="col">Id</th>
                                <th scope="col">Status</th>
                                <th scope="col">Valor Total</th>
                                <th scope="col">Valor Creditado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.pagamentos.map((pagamento, index) => {
                                return (
                                    <tr className="rgm-bg-azul-esverdiado">
                                        <td className="align-middle text-white">{moment(pagamento.date_created).format('DD/MM/YYYY')}</td>
                                        <td className="align-middle text-white">{moment(pagamento.date_created).format('HH:MM:SS')}</td>
                                        <td className="align-middle text-white">{pagamento.id}</td>
                                        <td className="align-middle text-white">{pagamento.status}</td>
                                        <td className="align-middle text-white">{this.formataDisplayEmRealLocal(pagamento.transaction_details.installment_amount)}</td>
                                        <td className="align-middle text-white">{this.formataDisplayEmRealLocal(pagamento.transaction_details.net_received_amount)}</td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                        {this.state.pagamentos.length === 0 &&
                            <tbody>
                                <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="4">
                                    <span>Não existem pagamentos para este usuario</span>
                                </td>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )
    }

}

export default AdmAlteracao