import React from 'react'
import moment from 'moment'
import { Component } from 'react'
import { formataEmRealDisplayDoBD } from '../../util/Utils'
import { extensoFrequencia, extensoTipo } from '../../util/extenso'
import ModalInclusaoRapidaLancamentoCartaoCredito from '../modal/ModalInclusaoRapidaLancamentoCartaoCredito'
import ModalPagamentoAvulso from '../modal/ModalPagamentoAvulso'
import ModalAnexarArquivo from '../modal/ModalAnexarArquivos'
import filesize from "filesize";

var saldoCalculado = 0
class FaturaExtrato extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fatura: this.props.fatura,
            pagamentos: [],
            conta: { nome: null },
            incluiRapido: false,
            pagamentoAvulso: false,
            pagamento: null,
            amigos: [],
            clicado: [],
            anexos: [],
            anexarArquivo: false
        }
    }

    componentWillMount() {
        var anexos = this.state.fatura.anexos

        this.setState({ anexos: anexos })

        this.listaAmigos()
        this.listaPagamentosDeUmaFatura()
        this.acessaConta()
    }

    listaAmigos() {
        let url = global.url + 'amigo/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ amigos: responseJson.amigos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaPagamentosDeUmaFatura() {
        let url = global.url + 'pagamento/listaPagamentosDeUmaFatura/' + this.state.fatura.id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ fatura: responseJson.fatura, anexos: responseJson.fatura.anexos })
                    this.filtraPagamentos(responseJson.fatura.pagamentos)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    filtraPagamentos(pagamentos) {

        var pagamentosVar = []

        pagamentos.map(async (pagamento, index) => {
            var grava = true

            if (pagamento.sub_categoria_nome_interno === 'fatura' && (pagamento.fatura_id_pagamento !== this.state.fatura.id)) {
                //if (pagamento.sub_categoria_nome_interno === 'fatura') {
                grava = false
            }

            if (grava === true) {
                pagamentosVar.push(pagamento)
            }

        })

        this.setState({ pagamentos: pagamentosVar })
    }

    acessaConta() {

        let url = global.url + 'conta/acessaConta/' + this.state.fatura.conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ conta: responseJson.conta })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    corDoValor(tipo) {
        if (tipo === 'debito') {
            return 'text-danger'
        } else {
            return 'text-success'
        }

    }

    corDaLinha(tipo) {
        if (tipo === 'credito') {
            return 'table-success'
        } else {
            return null
        }
    }

    contaExtratos(conta) {
        this.props.contaListaExtrato(conta)
    }

    mesAnoPorExtenso(data) {
        var mesExtenso = moment(data).format('MMMM');
        var anoExtenso = moment(data).format('YYYY');

        return mesExtenso.toUpperCase() + ' de ' + anoExtenso

    }

    formataData(data) {

        if (data < '0002-12-31') {
            return '----------'
        } else {
            return moment(data).format('DD/MM/YYYY')
        }

    }

    parcela(pagamento) {
        if (pagamento.frequencia === 'recorrente') {
            const parcelamento = ('000' + pagamento.parcela).slice(-3) + '/999'
            return parcelamento
        } else {
            if (pagamento.transacao.numero_de_parcelas === null) {
                return ''
            } else {
                const parcelamento = ('000' + pagamento.parcela).slice(-3) + '/' + ('000' + pagamento.transacao.numero_de_parcelas).slice(-3)
                return parcelamento
            }
        }
    }

    voltaAnterior() {

        if (typeof this.props.voltaPagamentoConsultaFatura === 'function') {
            this.props.voltaPagamentoConsultaFatura()
        } else {
            this.props.volta(this.state.conta)
        }

    }

    corDoLancamento(pagamento, index) {

        var bgDanger = ''

        this.state.clicado.map(async (cli, index1) => {
            if (cli.index === pagamento.id) {
                bgDanger = 'bg-danger'
            }
        })

        if (pagamento.tipo === 'credito') {
            return 'rgm-cor-positivo ' + bgDanger
        } else {
            return 'text-white ' + bgDanger
        }
    }

    obtemDescricao(pagamento) {

        if (pagamento.data_de_pagamento < '0002-12-31') {
            return pagamento.descricao
        }

        if (pagamento.sub_categoria_nome_interno === 'pagamentoAvulso') {
            return pagamento.descricao
        }

        if (pagamento.categorias.length === 0) {
            return '*** PROBLEMA RELATAR *** | ' + pagamento.descricao
        }

        return pagamento.descricao

    }

    nomeAmigos(pagamento) {
        var amigos_id = []

        pagamento.categorias.map(async (categoria, index) => {
            if (categoria.amigo_id > 0) {
                amigos_id.push(categoria.amigo_id)
            }
        })

        var amigosSemDuplicados = amigos_id.filter(function (este, i) {
            return amigos_id.indexOf(este) === i;
        });

        if (amigosSemDuplicados.length > 0) {
            var tam = amigosSemDuplicados.length
            var nomes = ''
            var amigoLido = this.state.amigos.find(function (obj) { return obj.id === amigosSemDuplicados[0] })
            var nomeAx = amigoLido.nome.split(' ')
            if (tam === 1) {
                nomes = nomeAx[0]
            } else {
                nomes = nomeAx[0] + ', ...'
            }
            return nomes
        } else {
            return ''
        }
    }

    nomeAmigos2(pagamento) {
        var amigos_id = []

        pagamento.categorias.map(async (categoria, index) => {
            if (categoria.amigo_id > 0) {
                amigos_id.push(categoria.amigo_id)
            }
        })

        var amigosSemDuplicados = amigos_id.filter(function (este, i) {
            return amigos_id.indexOf(este) === i;
        });

        if (amigosSemDuplicados.length > 0) {
            var tam = (amigosSemDuplicados.length - 1)
            var nomes = ''
            amigosSemDuplicados.map(async (amigo, index) => {
                var amigoLido = this.state.amigos.find(function (obj) { return obj.id === amigosSemDuplicados[index] })
                var nomeAx = amigoLido.nome.split(' ')
                if (tam === index) {
                    nomes = nomes + nomeAx[0]
                } else {
                    nomes = nomes + nomeAx[0] + ', '
                }
            })
            return nomes
        } else {
            return ''
        }
    }



    pagamentoConsulta(pagamento) {

        if (pagamento.sub_categoria_nome_interno === 'pagamentoAvulso') {
            this.setState({ pagamentoAvulso: true, pagamento: pagamento })
        } else {
            this.props.pagamentoConsulta(pagamento)
        }

    }

    click(pagamento, index) {
        var click = this.state.clicado

        var encontrou = false
        var indexFound = null

        this.state.clicado.map(async (cli, index1) => {
            if (cli.index === pagamento.id) {
                encontrou = true
                indexFound = index1
            }
        })

        if (encontrou === false) {
            var ocorrencia = { index: pagamento.id, bg: 'bg-danger' }
            click.push(ocorrencia)
        } else {
            click.splice(indexFound, 1)
        }

        this.setState({ clicado: click })

    }

    mostraAnexoTransacao(pagamento) {
        var anexos = []

        anexos = pagamento.transacao.anexos

        if (anexos.length > 0) {
            window.open(anexos[0].url, "_blank");
        } else {
            return
        }

    }

    mostraClips(pagamento) {

        var anexos = []

        anexos = pagamento.anexos
        if (anexos.length === 0) {
            anexos = pagamento.transacao.anexos
        }

        if (anexos.length > 0) {
            return <i class="fa fa-paperclip fa-lg rgm-cor-link" aria-hidden="true"></i>
        } else {
            if (pagamento.sub_categoria_nome_interno === 'fatura') {
                //return <i class="fa fa-clock-o fa-lg rgm-cor-link" aria-hidden="true"></i>
                //return <i class="fa fa-circle-o-notch fa-spin fa-3x style='font-size:24px'"></i>
                return <i class="fa fa-circle-o-notch fa-spin style={font-size: 24px}"></i>
            } else {
                return ''
            }
        }
    }

    render() {
        return [
            this.renderPagamentoAvulso(),
            this.renderIncluiRapido(),
            this.renderAnexarArquivo(),
            this.renderComum(),
            this.renderAnexos()
        ]
    }

    mostraAnexaArquivo() {
        this.setState({ anexarArquivo: true })
    }

    renderComum() {

        var tituloAnexo = 'anexar fatura'

        this.state.pagamentos.map(async (pagamento, index) => {
            this.saldoTotal(pagamento, index)
        })



        return (
            <div className="container w-100 mt-5"    >
                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.voltaAnterior() }}>Voltar</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ incluiRapido: true })}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Lançamento</button>
                {this.state.fatura.status === 'emAberto' &&
                    <button className="btn btn-primary btn-sm m-2" onClick={() => { this.props.faturaPagamentoAvulso(this.state.fatura) }}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;PagamentoAvulso</button>
                }
                <button type="button" className="btn btn-link btn-sm text-right " title={tituloAnexo} onClick={() => { this.mostraAnexaArquivo() }}><i class="fa fa-paperclip fa-lg rgm-cor-link" aria-hidden="true"></i></button>
                <div className="card bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="align-middle text-white">
                            <h5 align="center">{this.state.conta.nome}</h5>
                            <h6 align="center"><span className="font-weight-bold">Fatura de {this.mesAnoPorExtenso(this.state.fatura.fatura)}</span> | Valor: <strong>{formataEmRealDisplayDoBD(parseFloat(saldoCalculado))}</strong></h6>
                        </div>
                    </div>
                    <table className="table table-sm">
                        <thead>
                            <tr className="bg-info">
                                <th scope="col" className="border text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                <th scope="col" className="border text-center">Data</th>
                                <th scope="col" className="border text-center">Descrição</th>
                                {this.state.amigos.length > 0 &&
                                    <th scope="col">Amigo</th>
                                }
                                <th scope="col" className="border text-center">Frequência</th>
                                <th scope="col" className="border text-center">Parcela</th>
                                <th scope="col" className="border text-center">Tipo</th>
                                <th scope="col" className="border text-center">Valor</th>
                                <th scope="col" className="text-right">Anexo</th>
                            </tr>
                        </thead>
                        <tbody className="rgm-bg-azul-esverdiado">
                            {this.state.pagamentos.map((pagamento, index) => {
                                return (
                                    <tr className={"text-white"}>
                                        <td className="border text-center align-middle" onClick={() => this.pagamentoConsulta(pagamento)}>
                                            <i class="fa fa-info rgm-cor-link btn-link btn-sm m-2" title="Detalhe"></i>
                                        </td>
                                        <td className="border text-center align-middle">{this.formataData(pagamento.data_de_pagamento)}</td>
                                        <td className="border align-middle">{this.obtemDescricao(pagamento)}</td>
                                        {this.state.amigos.length > 0 &&
                                            <td className="align-middle text-white rgm-diminuirParaCaber ">{this.nomeAmigos(pagamento)}</td>
                                        }
                                        <td className="border align-middle">{extensoFrequencia(pagamento.frequencia)}</td>
                                        <td className={'border text-center align-middle'}>{this.parcela(pagamento)}</td>
                                        <td className={'border text-center align-middle'}>{extensoTipo(pagamento.tipo)}</td>
                                        <td className={'border text-right align-middle ' + this.corDoLancamento(pagamento, index)} onClick={() => this.click(pagamento, index)}>{formataEmRealDisplayDoBD(parseFloat(pagamento.valor))}</td>
                                        <td className="align-middle text-white">
                                            <button type="button" className={"btn btn-link btn-sm text-center "} onClick={() => { this.mostraAnexoTransacao(pagamento) }}>{this.mostraClips(pagamento)}</button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

    saldoTotal(pagamento, index) {

        if (index === 0) {
            saldoCalculado = 0
        }

        var pagamentoValor = pagamento.valor

        switch (pagamento.tipo) {
            case 'debito': {
                saldoCalculado = parseFloat(saldoCalculado) + parseFloat(pagamentoValor)
                break
            }
            case 'credito': {
                saldoCalculado = parseFloat(saldoCalculado) - parseFloat(pagamentoValor)
                break
            }
            default: {
                break;
            }
        }

    }

    renderAnexos() {

        if (this.state.anexos.length > 0) {
            return (
                <div className="container w-100 mt-5 mb-0">
                    <div className="card row bg-transparent">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>Documentos anexados </h5>
                            </div>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="border align-middle">Nome</th>
                                    <th scope="col" className="border align-middle">Tamanho</th>
                                    <th scope="col" className="border align-middle text-center">Ver anexo</th>
                                    <th scope="col" className="border align-middle text-center">Excluir</th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.anexos.map((anexo, index) => {
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado m-1">
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{anexo.nome}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber text-right">{filesize(anexo.size)}</td>
                                            <td className="text-center"> <a href={anexo.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i></a></td>
                                            <td className="text-center text-white align-middle">
                                                <button className="btn btn-link btn-sm text-center" onClick={() => { this.excluiS3(anexo.id) }}><i className="fa fa-trash rgm-cor-trash" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    volta() {
        this.setState({ incluiRapido: false, pagamentoAvulso: false, anexarArquivo: false })
        this.listaPagamentosDeUmaFatura()
        this.acessaConta()
    }


    renderIncluiRapido() {
        if (this.state.incluiRapido === true) {
            return (
                <>
                    <ModalInclusaoRapidaLancamentoCartaoCredito
                        volta={() => this.volta()} conta_id={this.state.conta.id}
                        dia_fechamento={this.state.conta.conta_cartoes_creditos.dia_fechamento}
                        dia_pagamento={this.state.conta.conta_cartoes_creditos.dia_pagamento}
                    />
                </>
            )
        }
    }

    renderPagamentoAvulso() {

        var status = ''

        if (this.state.fatura.status === 'liquidado') {
            status = 'consulta'
        }

        if (this.state.fatura.status === 'emAberto') {
            status = 'altera'
        }

        if (this.state.pagamentoAvulso === true) {
            return (
                <>
                    <ModalPagamentoAvulso volta={() => this.volta()} pagamento={this.state.pagamento} operacao={status} />
                </>
            )
        }
    }

    renderAnexarArquivo() {

        if (this.state.anexarArquivo === true) {
            return (
                <>
                    <ModalAnexarArquivo
                        volta={() => this.volta()}
                        id={this.state.fatura.id}
                        ident='fatura'
                    />
                </>
            )
        }
    }

    excluiS3(id) {

        let url = global.url + 'upload/exclui/' + id

        fetch(url, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(this.state.usuario)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            this.volta()
        })

    }



}

export default FaturaExtrato