import React from 'react'
import { Component } from 'react';
import moment from 'moment'
import { extensoUsuarioStatus } from '../../util/extenso'

class AdmLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usuarios: []
        }
    }

    componentWillMount() {
        this.listaUsuarios();
    }

    listaUsuarios() {
        let url = global.url + 'adm/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var usuariosSort = responseJson.usuarios
                    usuariosSort.sort((a, b) => {
                        if (a.ultimo_login < b.ultimo_login) {
                            return 1
                        } else {
                            if (a.ultimo_login > b.ultimo_login) {
                                return -1
                            } else {
                                return 0
                            }
                        }
                    })
                    this.setState({ usuarios: usuariosSort })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    formataDataDeNascimento(data_de_nascimento) {

        if (data_de_nascimento == null) {
            return '-'
        } else {
            return moment(data_de_nascimento).format('DD/MM/YYYY')
        }
    }

    formataDataAssinatura(data, status) {

        if (data !== null) {
            return moment(data).format('DD/MM/YYYY')
        } else {
            return '-'
        }
    }
    obtemId(usuario_id) {
        return ('00000' + usuario_id).slice(-5)
    }

    render() {
        return (
            <div className="container w-100 mt-5"  >
                <div className="card row bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle m-2">
                            <h5>Lista de usuarios</h5>
                        </div>
                    </div>

                    <table className="table table-sm table-bordered mt-0">
                        <thead>
                            <tr className="bg-info">
                                <th className="text-center" scope="col">ID</th>
                                <th scope="col">Nome</th>
                                <th scope="col">Email</th>
                                <th className="text-center rgm-diminuirParaCaber" scope="col">Usuario desde</th>
                                <th className="text-center" scope="col">Status</th>
                                <th className="text-center" scope="col">Último login</th>
                                <th className="text-center rgm-diminuirParaCaber" scope="col">Assinatura inicio</th>
                                <th className="text-center rgm-diminuirParaCaber" scope="col">Assinatura fim</th>
                                <th className="text-center" scope="col">Detalhe</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.usuarios.map((usuario, index) => {
                                return (
                                    <tr className="rgm-bg-azul-esverdiado">
                                        <td className="align-middle text-white text-center">{this.obtemId(usuario.id)}</td>
                                        <td className="align-middle text-white rgm-diminuirParaCaber">{usuario.nome}</td>
                                        <td className="align-middle text-white rgm-diminuirParaCaber">{usuario.email}</td>
                                        <td className="align-middle text-white text-center rgm-diminuirParaCaber">{moment(usuario.createdAt).format('DD/MM/YYYY')}</td>
                                        <td className="align-middle text-white rgm-diminuirParaCaber">{extensoUsuarioStatus(usuario.status)}</td>
                                        <td className="align-middle text-warning font-weight-bold rgm-diminuirParaCaber">{moment(usuario.ultimo_login).format('DD/MM/YYYY HH:mm:ss')}</td>
                                        <td className="align-middle text-white text-center rgm-diminuirParaCaber">{this.formataDataAssinatura(usuario.data_inicio_assinatura, usuario.status)}</td>
                                        <td className="align-middle text-white text-center rgm-diminuirParaCaber">{this.formataDataAssinatura(usuario.data_fim_assinatura, usuario.status)}</td>
                                        <td className="align-middle text-white text-center rgm-diminuirParaCaber">
                                            <button className="btn btn-link btn-sm m-2 text-center" onClick={() => this.props.admAlteracao(usuario)}>
                                                <i class="fa fa-pencil rgm-cor-link" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )

    }

}

export default AdmLista