import React, { Component } from "react"
import 'moment/locale/pt-br';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select'
import { formataDisplayEmReal } from '../../util/Utils'
import '../conta/Conta.css'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'

class ContaConsulta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            conta: props.conta,
            //comum
            nome: null,
            tipo_conta: null,
            //conta corrente
            banco: null,
            cheque_especial_dia_cobranca: null,
            cheque_especial_limite_disp: null,
            saldo_inicial_disp: null,
            saldo_inicial_conta_remunerada_disp: null,
            //cartao de credito
            bandeira: null,
            dia_fechamento: null,
            dia_pagamento: null,
            ultima_fatura_fechada: null,
            limite_disp: null,
            conta_debito: null,
            cartao_inicio: null,
            cartao_fim: null,
            //outros
            tiposContas: [],
            bancos: [],
            bandeiras: [],
            contas: [],
            alerta: false,
            alertaHeader: null,
            alertaParagrafo: null,
            alertaSaida: false,
            pedeConfirmacao: false,
            btn_texto: null,
            msg_confirmacao: '',
            favorita: false
        }
    }

    componentWillMount() {

        if (this.state.conta.status === 'aberta') {
            this.setState({ btn_texto: 'Fechar esta conta', msg_confirmacao: 'Confirma o fechamento desta conta?' })
        } else {
            this.setState({ btn_texto: 'Reabrir esta conta', msg_confirmacao: 'Confirma a reabertura desta conta?' })
        }

        this.tabelaTipoConta()
        this.tabelaBanco()
        this.tabelaBandeira()
        this.listaContasCombo()
        this.preencheCampos()

    }

    preencheCampos() {

        this.setState({
            nome: this.props.conta.nome,
            tipo_conta: this.props.conta.tipo_conta,
            saldo_inicial_disp: formataDisplayEmReal(this.props.conta.saldo_inicial),
            favorita: this.props.conta.favorita,
            comentarios: this.props.conta.comentarios
        })

        if (this.props.conta.tipo_conta === 'contaCorrente' || this.props.conta.tipo_conta === 'contaInvestimento') {
            this.setState({
                banco: this.props.conta.conta_correntes.banco,
                cheque_especial_dia_cobranca: this.props.conta.conta_correntes.cheque_especial_dia_cobranca,
                cheque_especial_limite_disp: formataDisplayEmReal(this.props.conta.conta_correntes.cheque_especial_limite),
                saldo_inicial_conta_remunerada_disp: formataDisplayEmReal(this.props.conta.conta_correntes.saldo_inicial_conta_remunerada),
                possui_conta_remunerada: this.props.conta.conta_correntes.possui_conta_remunerada,
            })
        }

        if (this.props.conta.tipo_conta === 'cartaoCredito') {
            this.setState({
                bandeira: this.props.conta.conta_cartoes_creditos.bandeira,
                dia_fechamento: this.props.conta.conta_cartoes_creditos.dia_fechamento,
                dia_pagamento: this.props.conta.conta_cartoes_creditos.dia_pagamento,
                limite_disp: formataDisplayEmReal(this.props.conta.conta_cartoes_creditos.limite),
                conta_debito: this.props.conta.conta_cartoes_creditos.conta_debito,
                cartao_inicio: this.props.conta.conta_cartoes_creditos.cartao_inicio,
                cartao_fim: this.props.conta.conta_cartoes_creditos.cartao_fim
            })
        }
    }

    confirmaFechamento() {
        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            var contaDebito = this.state.contas.filter(option => option.id === this.state.conta_debito)
            if (contaDebito[0].status === 'fechada') {
                this.setState({ alerta: true, alertaMsg: 'Conta associada a este cartão esta fechada, faça o ajuste antes de realizar a reabertura' })
                return
            }
        }

        this.setState({ pedeConfirmacao: true })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderConfirmacao(),
            this.renderComum(),
            this.renderContaCorrente(),
            this.renderContaInvestimento(),
            this.renderContaDinheiro(),
            this.renderCartaoCredito(),
            this.renderBotoes(),
        ]
    }


    renderComum() {
        return (
            <div className="container w-100 mt-5 mb-0"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Consulta de conta</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        <div className="form-group col-md-3">
                            <label for="banco" className="">Tipo de conta</label>
                            <Select
                                id="tipo_conta"
                                name="tipo_conta"
                                value={this.state.tiposContas.filter(option => option.value === this.state.tipo_conta)}
                                disabled={true}
                                options={this.state.tiposContas}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label for="nome">Descrição</label>
                            <input
                                disabled
                                value={this.state.nome}
                                type="nome"
                                className="form-control"
                                id="nome"
                                placeholder="Nome" />
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderContaCorrente() {
        if (this.state.tipo_conta !== 'contaCorrente') {
            return null
        } else {
            return (
                <div className="container w-100"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-6">
                                <label for="banco" className="mt-3">Banco</label>
                                <Select
                                    className=""
                                    id="banco"
                                    name="banco"
                                    disabled
                                    value={this.state.bancos.filter(option => option.value === this.state.banco)}
                                    options={this.state.bancos}
                                />
                            </div>
                        </div>
                        {global.usuarioEmail === 'rgm1301@gmail.com.br' &&
                            <div className="form-row">
                                <div class="form-group row">
                                    <div class="form-check">
                                        <label class="form-check-label" for="gridCheck1">Possui conta remunerada</label>
                                        <input disabled class="form-check-input ml-2" type="checkbox" id="possui_conta_remunerada" checked={this.state.possui_conta_remunerada} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="form-row">
                            <div class="form-group row">
                                <div class="form-check">
                                    <label class="form-check-label" for="gridCheck1">Favorita</label>
                                    <input class="form-check-input ml-2" type="checkbox" id="favorita" checked={this.state.favorita} />
                                </div>
                            </div>
                        </div>
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Limite cheque especial</label>
                                <input
                                    disabled
                                    value={this.state.cheque_especial_limite_disp}
                                    type="text"
                                    className="form-control"
                                    id="cheque_especial_limite"
                                    placeholder="Limite do cheque especial" />
                            </div>
                            <div className="form-group col-md-2">
                                <label for="nome" className="mt-3">Dia cheque especial</label>
                                <input
                                    disabled
                                    value={this.state.cheque_especial_dia_cobranca}
                                    type="text"
                                    className="form-control"
                                    id="cheque_especial_limite_dia"
                                    placeholder="Dia do cheque especial" />
                            </div>
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Saldo Inicial Conta Corrente</label>
                                <input
                                    disabled
                                    value={this.state.saldo_inicial_disp}
                                    type="text"
                                    className="mr-3 form-control"
                                    id="saldo_inicial"
                                />
                            </div>
                            {this.state.possui_conta_remunerada &&
                                <div className="form-group col-md-3">
                                    <label for="nome" className="mt-3">Saldo Inicial Conta Remunerada</label>
                                    <input
                                        disabled
                                        value={this.state.saldo_inicial_conta_remunerada_disp}
                                        type="text"
                                        className="form-control"
                                        id="saldo_inicial_conta_remunerada"
                                    />
                                </div>
                            }
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                disabled
                                value={this.state.comentarios}
                                className="form-control mt-2 ml-2"
                                rows="2" id="comentarios">
                            </textarea>
                        </div>
                    </form>
                </div>
            )
        }
    }

    renderContaInvestimento() {
        if (this.state.tipo_conta !== 'contaInvestimento') {
            return null
        } else {
            return (
                <div className="container w-100"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-6">
                                <label for="banco" className="mt-3">Banco</label>
                                <Select
                                    className=""
                                    id="banco"
                                    name="banco"
                                    disabled
                                    value={this.state.bancos.filter(option => option.value === this.state.banco)}
                                    options={this.state.bancos}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div class="form-group row">
                                <div class="form-check">
                                    <label class="form-check-label" for="gridCheck1">Favorita</label>
                                    <input class="form-check-input ml-2" type="checkbox" id="favorita" checked={this.state.favorita} />
                                </div>
                            </div>
                        </div>
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Saldo Inicial</label>
                                <input
                                    disabled
                                    value={this.state.saldo_inicial_disp}
                                    type="text"
                                    className="mr-3 form-control"
                                    id="saldo_inicial"
                                />
                            </div>
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                disabled
                                value={this.state.comentarios}
                                className="form-control mt-2 ml-2"
                                rows="2" id="comentarios">
                            </textarea>
                        </div>
                    </form>
                </div>
            )
        }
    }

    renderContaDinheiro() {
        if (this.state.tipo_conta !== 'contaDinheiro') {
            return null
        } else {
            return (
                <div className="container w-100"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Saldo Inicial</label>
                                <input
                                    disabled
                                    value={this.state.saldo_inicial_disp}
                                    type="text"
                                    className="mr-3 form-control"
                                    id="saldo_inicial"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div class="form-group row">
                                <div class="form-check">
                                    <label class="form-check-label" for="gridCheck1">Favorita</label>
                                    <input class="form-check-input ml-2" type="checkbox" id="favorita" checked={this.state.favorita} />
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                disabled
                                value={this.state.comentarios}
                                className="form-control mt-2 ml-2"
                                rows="2" id="comentarios">
                            </textarea>
                        </div>
                    </form>
                </div>
            )
        }
    }


    renderCartaoCredito() {
        if (this.state.tipo_conta !== 'cartaoCredito') {
            return null
        } else {
            return (
                <div className="container w-100"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="bandeira" className="mt-3">Bandeira</label>
                                <Select
                                    id="bandeira"
                                    name="bandeira"
                                    value={this.state.bandeiras.filter(option => option.value === this.state.bandeira)}
                                    selectedValue={this.state.bandeira}
                                    disabled
                                    options={this.state.bandeiras}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label for="contaDebito" className="mt-3">Conta para débito</label>
                                <Select
                                    id="contaDebito"
                                    name="contaDebito"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.contas.filter(option => option.id === this.state.conta_debito)}
                                    disabled
                                    options={this.state.contas}
                                />
                            </div>
                            <div className="form-group col-md-1">
                                <label for="nome" className="mt-3">Cartão</label>
                                <input
                                    value={this.state.cartao_inicio}
                                    maxlength="6"
                                    type="text"
                                    className="form-control"
                                    id="cartao_inicio"
                                    placeholder="1-6"
                                />
                            </div>
                            <div className="form-group col-md-1">
                                <label for="nome" className="mt-3">Cartão</label>
                                <input
                                    value={this.state.cartao_fim}
                                    maxlength="4"
                                    type="text"
                                    className="form-control"
                                    id="cartao_fim"
                                    placeholder="**1234"
                                />
                            </div>

                        </div>
                        <div className="form-row">
                            <div class="form-group row">
                                <div class="form-check">
                                    <label class="form-check-label" for="gridCheck1">Favorita</label>
                                    <input class="form-check-input ml-2" type="checkbox" id="favorita" checked={this.state.favorita} />
                                </div>
                            </div>
                        </div>
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Limite</label>
                                <input
                                    disabled
                                    value={this.state.limite_disp}
                                    type="text"
                                    className="form-control"
                                    id="cheque_especial_limite"
                                    placeholder="Limite do cheque especial" />
                            </div>
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Dia do fechamento</label>
                                <input
                                    disabled
                                    value={this.state.dia_fechamento}
                                    type="text"
                                    className="form-control"
                                    id="dia_fechamento"
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Dia do pagamento</label>
                                <input
                                    disabled
                                    value={this.state.dia_pagamento}
                                    type="text"
                                    className="form-control"
                                    id="dia_pagamento"
                                />
                            </div>
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                disabled
                                value={this.state.comentarios}
                                className="form-control mt-2 ml-2"
                                rows="2" id="comentarios">
                            </textarea>
                        </div>
                    </form>
                </div>
            )
        }
    }

    renderBotoes() {
        return (
            <div className="container w-100">
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.contaAlteracao(this.state.conta) }}>Alterar</button>
                            <button type="button" className="btn btn-danger btn-sm m-2" onClick={() => { this.confirmaFechamento() }}>{this.state.btn_texto}</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }


    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.contaMudarStatusConfirmada()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao={this.state.msg_confirmacao} />
                </>
            )
        }
    }



    tabelaTipoConta() {
        let url = global.url + 'tabela/tipoConta'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ tiposContas: responseJson.tiposContas })
            })
    }

    tabelaBanco() {
        let url = global.url + 'tabela/banco'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ bancos: responseJson.bancos })
            })
    }

    tabelaBandeira() {
        let url = global.url + 'tabela/bandeira'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ bandeiras: responseJson.bandeiras })
            })
    }

    listaContasCombo() {
        let url = global.url + 'conta/listaCombo'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contas: responseJson.contas })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }



    contaMudarStatusConfirmada() {

        this.setState({ pedeConfirmacao: false })

        var conta_id = this.state.conta.id

        var conta = {
            conta_id: conta_id,
        }

        let url = null

        if (this.state.conta.status === 'aberta') {
            url = global.url + 'conta/fechar'
        }

        if (this.state.conta.status === 'fechada') {
            url = global.url + 'conta/reabrir'
        }


        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(conta)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ resp: responseJson.resp })
                    this.setState({ alertaSaida: true, alertaMsg: responseJson.mensagem })
                } else {
                    this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
                }
            })
    }
}

export default ContaConsulta