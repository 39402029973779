import React, { Component } from "react"


class Construcao extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <>
                <h1>E m   C o n s t r u ç ã o  !!!!!!!!!</h1>
            </>
        )
    }


    obtemCotacao() {
        let url = 'https://economia.awesomeapi.com.br/json/all'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            alert('responseJson:' + JSON.stringify(responseJson))
        })

    }
}


export default Construcao