import React from 'react'
import { Component } from 'react';
import './style.css'

class ModalConfirmaComAtraso extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    componentWillMount() { }


    render() {
        return (
            <div className="rgm_modal try-modal-overlay text-center">
                <div className="card">
                    <div class="card-header bg-danger text-white border">
                        <span>Confirmação</span>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{this.props.msgConfirmacao}</h5>
                    </div>
                    <div class="card-footer">
                        <button type="button" className="btn btn-primary btn-sm" aria-label="Close" onClick={() => this.props.confirma()}>Confirma sem atraso</button>
                        <button type="button" className="btn btn-primary ml-2 btn-sm" aria-label="Close" onClick={() => this.props.confirmaComAtraso()}>Confirma com atraso</button>
                        <button type="button" className="btn btn-danger ml-2 btn-sm" aria-label="Close" onClick={() => this.props.cancela()}>Cancela</button>
                    </div>
                </div>
            </div>
        )
    }



}

export default ModalConfirmaComAtraso