import React from 'react'
import { Component } from 'react';
import moment from 'moment'
import { formataDisplayEmReal, formataDisplayEmPercentual } from '../../util/Utils'
import { extensoStatus } from '../../util/extenso'

class EventoLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            eventos: [],
            litListados: 'Em aberto'
        }
    }

    componentWillMount() {
        this.listaEventos();
    }

    listaEventos() {
        let url = global.url + 'evento/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustaEventos(responseJson.eventos)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    ajustaEventos(eventos) {

        var eventosTodos = []
        var eventosEmAberto = []
        var eventosFinalizado = []

        var hoje = moment().format('YYYY-MM-DD')

        eventos.map(async (evento, index) => {
            var eventoAlt = JSON.parse(JSON.stringify(evento))
            var total = 0.00
            eventoAlt.categorias.map(async (categoria, index2) => {
                total = total + parseFloat(categoria.valor)
            })
            eventoAlt.valor = parseFloat(total).toFixed(2) 

            var perc = null

            if (evento.valor_previsto === 0.00 || evento.valor_previsto === '0.00' ) {
                perc = 0.00
            } else {
                perc = ((total / evento.valor_previsto) * 100).toFixed(2)
            }

            eventoAlt.percentual = perc

            if (eventoAlt.data_de_fim >= hoje) {
                eventoAlt.status = 'Em aberto'
                eventosEmAberto.push(eventoAlt)
            } else {
                eventoAlt.status = 'Finalizado'
                eventosFinalizado.push(eventoAlt)
            }

            eventosTodos.push(eventoAlt)
        })

        this.setState({ eventos: eventosEmAberto })
        this.setState({ eventosTodos: eventosTodos })
        this.setState({ eventosEmAberto: eventosEmAberto })
        this.setState({ eventosFinalizado: eventosFinalizado })

    }

    formataData(data) {

        return moment(data).format('DD/MM/YYYY')
    }


    render() {
        return (
            <div className="container w-100 mt-5">
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.eventoInclusao()}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Eventos</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ eventos: this.state.eventosEmAberto, litListados: 'Em abertos' })}>Em aberto</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ eventos: this.state.eventosFinalizado, litListados: 'Finalizados' })}>finalizados</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ eventos: this.state.eventosTodos, litListados: 'Todos' })}>Todos</button>
                <div className="card bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle m-2">
                            <h5>Lista de eventos | {this.state.litListados}</h5>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="bg-info">
                                <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                <th scope="col">Descrição</th>
                                <th scope="col" className="text-center">Inicio</th>
                                <th scope="col" className="text-center">Final</th>
                                <th scope="col" className="text-center">Previsto</th>
                                <th scope="col" className="text-center" COLSPAN="2">Realizado</th>
                                <th scope="col" className="text-center" >Status</th>
                                <th className="" scope="col">Alteração</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.eventos.map((evento, index) => {
                                return (
                                    <tr className="rgm-bg-azul-esverdiado">
                                        <td className="text-center text-white"><button className="btn btn-link btn-sm m-2" onClick={() => this.props.eventoConsulta(evento)}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button></td>
                                        <td className="align-middle text-white">{evento.descricao}</td>
                                        <td className="text-center align-middle text-white">{moment(evento.data_de_inicio).format('DD/MM/YYYY')}</td>
                                        <td className="text-center align-middle text-white">{moment(evento.data_de_fim).format('DD/MM/YYYY')}</td>
                                        <td className="text-right align-middle text-white">{formataDisplayEmReal(evento.valor_previsto)}</td>
                                        <td className="text-right align-middle text-white">{formataDisplayEmReal(evento.valor)}</td>
                                        <td className="text-right align-middle text-white">{formataDisplayEmPercentual(evento.percentual)}</td>
                                        <td className="align-middle text-white">{evento.status}</td>
                                        <td className="align-middle text-center text-white">
                                            <button className="btn btn-link btn-sm m-2" onClick={() => this.props.eventoAlteracao(evento)}>
                                                <i className="fa fa-pencil rgm-cor-link" aria-hidden="true"></i>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                        {this.state.eventos.length === 0 &&
                            <tbody>
                                <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="9">
                                    <span>Não existem eventos para esta seleção</span>
                                </td>
                            </tbody>
                        }

                    </table>
                </div>
            </div>
        )

    }

}

export default EventoLista