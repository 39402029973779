import React, { Component } from 'react'
import moment from 'moment'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../loader.css'
import { formataDisplayEmReal } from '../../util/Utils'
import { extensoFrequencia, extensoTipo } from '../../util/extenso'
import { Bar } from "react-chartjs-2";

const localizer = momentLocalizer(moment)

var evento = {
    id: 0,
    title: '',
    allDay: false,
    start: '',
    end: '',
    tipo: ''
}

var myEventsList = []

var faturasListadas = []

var totalDebitosNoPeriodo = 0
var totalCreditosNoPeriodo = 0

var debitoMesCorrentePorDia = [{ dia: 1, valor: 0 }, { dia: 2, valor: 0 }, { dia: 3, valor: 0 }, { dia: 4, valor: 0 }, { dia: 5, valor: 0 }, { dia: 6, valor: 0 }, { dia: 7, valor: 0 }, { dia: 8, valor: 0 }, { dia: 9, valor: 0 }, { dia: 10, valor: 0 }, { dia: 11, valor: 0 }, { dia: 12, valor: 0 }, { dia: 13, valor: 0 }, { dia: 14, valor: 0 }, { dia: 15, valor: 0 }, { dia: 16, valor: 0 }, { dia: 17, valor: 0 }, { dia: 18, valor: 0 }, { dia: 19, valor: 0 }, { dia: 20, valor: 0 }, { dia: 21, valor: 0 }, { dia: 22, valor: 0 }, { dia: 23, valor: 0 }, { dia: 24, valor: 0 }, { dia: 25, valor: 0 }, { dia: 26, valor: 0 }, { dia: 27, valor: 0 }, { dia: 28, valor: 0 }, { dia: 29, valor: 0 }, { dia: 30, valor: 0 }, { dia: 31, valor: 0 },]
var creditoMesCorrentePorDia = [{ dia: 1, valor: 0 }, { dia: 2, valor: 0 }, { dia: 3, valor: 0 }, { dia: 4, valor: 0 }, { dia: 5, valor: 0 }, { dia: 6, valor: 0 }, { dia: 7, valor: 0 }, { dia: 8, valor: 0 }, { dia: 9, valor: 0 }, { dia: 10, valor: 0 }, { dia: 11, valor: 0 }, { dia: 12, valor: 0 }, { dia: 13, valor: 0 }, { dia: 14, valor: 0 }, { dia: 15, valor: 0 }, { dia: 16, valor: 0 }, { dia: 17, valor: 0 }, { dia: 18, valor: 0 }, { dia: 19, valor: 0 }, { dia: 20, valor: 0 }, { dia: 21, valor: 0 }, { dia: 22, valor: 0 }, { dia: 23, valor: 0 }, { dia: 24, valor: 0 }, { dia: 25, valor: 0 }, { dia: 26, valor: 0 }, { dia: 27, valor: 0 }, { dia: 28, valor: 0 }, { dia: 29, valor: 0 }, { dia: 30, valor: 0 }, { dia: 31, valor: 0 },]
var debitoMesPosteriorPorDia = [{ dia: 1, valor: 0 }, { dia: 2, valor: 0 }, { dia: 3, valor: 0 }, { dia: 4, valor: 0 }, { dia: 5, valor: 0 }, { dia: 6, valor: 0 }, { dia: 7, valor: 0 }, { dia: 8, valor: 0 }, { dia: 9, valor: 0 }, { dia: 10, valor: 0 }, { dia: 11, valor: 0 }, { dia: 12, valor: 0 }, { dia: 13, valor: 0 }, { dia: 14, valor: 0 }, { dia: 15, valor: 0 }, { dia: 16, valor: 0 }, { dia: 17, valor: 0 }, { dia: 18, valor: 0 }, { dia: 19, valor: 0 }, { dia: 20, valor: 0 }, { dia: 21, valor: 0 }, { dia: 22, valor: 0 }, { dia: 23, valor: 0 }, { dia: 24, valor: 0 }, { dia: 25, valor: 0 }, { dia: 26, valor: 0 }, { dia: 27, valor: 0 }, { dia: 28, valor: 0 }, { dia: 29, valor: 0 }, { dia: 30, valor: 0 }, { dia: 31, valor: 0 },]
var creditoMesPosteriorPorDia = [{ dia: 1, valor: 0 }, { dia: 2, valor: 0 }, { dia: 3, valor: 0 }, { dia: 4, valor: 0 }, { dia: 5, valor: 0 }, { dia: 6, valor: 0 }, { dia: 7, valor: 0 }, { dia: 8, valor: 0 }, { dia: 9, valor: 0 }, { dia: 10, valor: 0 }, { dia: 11, valor: 0 }, { dia: 12, valor: 0 }, { dia: 13, valor: 0 }, { dia: 14, valor: 0 }, { dia: 15, valor: 0 }, { dia: 16, valor: 0 }, { dia: 17, valor: 0 }, { dia: 18, valor: 0 }, { dia: 19, valor: 0 }, { dia: 20, valor: 0 }, { dia: 21, valor: 0 }, { dia: 22, valor: 0 }, { dia: 23, valor: 0 }, { dia: 24, valor: 0 }, { dia: 25, valor: 0 }, { dia: 26, valor: 0 }, { dia: 27, valor: 0 }, { dia: 28, valor: 0 }, { dia: 29, valor: 0 }, { dia: 30, valor: 0 }, { dia: 31, valor: 0 },]

const data = {
    labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31'],
    datasets: [
        {
            label: 'Receitas',
            data: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
            fill: true,
            backgroundColor: 'green',
            borderColor: '#fff'
        },
        {
            label: 'Despesas',
            data: [null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null],
            fill: true,
            backgroundColor: 'red',
            borderColor: '#fff'
        },

    ]
}

const semana = { inicio: null, fim: null, total: null }

class PagamentoLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            saldoBancario: 0,
            saldos: [],
            mostraGraficoGeral: false,
            feriados: [],
            amigos: [],
            contas: [],
            pagamentos: [],
            pagamentosGeral: [],
            pagamentosDoMes: [],
            pagamentosDoMesPosterior: [],
            listaPgtoLit: 'Listar todos pagamentos',
            listaPgtoFla: 'mesCorrente',
            loader: true,
            cabec: 'Lista pagamentos de ' + moment().format('MMMM/YYYY'),
            cabecGrafico: 'Gráfico dos débitos em abertos de ' + moment().format('MMMM/YYYY'),
            reembolsosAbertos: [],
            listaReembolsosNaoEfetuados: false,
            debitos: 0,
            creditos: 0,
            debitosAtual: 0,
            creditosAtual: 0,
            debitosPosterior: 0,
            creditosPosterior: 0,
            semanaInicio: moment().format('YYYY-MM-DD'),
            semanaFinal: moment().format('YYYY-MM-DD'),
            mostraSoma: false,
            soma: 0,
            clicado: [],
            myEventsList: [],
            mostrarCalendario: false,
            dataInicioAntigas: null,
            dataInicioAntigasLiteral: 'lista todas vencidas',
            pagamentosRecebidos: null,
            resumoSemanal: []
        }
    }

    componentWillMount() {
        this.montaSemana();
        var dataInicioAntigas = moment().subtract(30, 'day').format('YYYY-MM-DD')
        this.setState({ dataInicioAntigas: dataInicioAntigas })
        this.listaContas()
        this.tabelaFeriado()
        this.listaPagamentos(moment().format('YYYY-MM-DD'))
        this.listaReembolsosAbertos()
        var mesCorrenteLit = moment().format('MMM/YYYY')
        var mesPosteriorLit = moment().add(1, 'month').format('MMM/YYYY')

        this.setState({ mesCorrenteLit: mesCorrenteLit, mesPosteriorLit: mesPosteriorLit })
        this.descobreSemana()
    }

    montaSemana() {
        // alert('monta semana')
        const month = 4; // abril
        const year = 2024;

        const firstDayOfMonth = moment(`${year}-${month}-01`);
        const lastDayOfMonth = firstDayOfMonth.clone().endOf('month');
        const mondays = [];

        while (firstDayOfMonth.day() !== 1) {
            firstDayOfMonth.add(1, 'day');
        }

        while (firstDayOfMonth <= lastDayOfMonth) {
            mondays.push(firstDayOfMonth.format('YYYY-MM-DD'));
            firstDayOfMonth.add(7, 'days');
        }

        // alert(mondays);

        // alert('monta semana, final')

    }

    descobreSemana() {

        var semanaFinal = moment().day(5).format('YYYY-MM-DD'); // sábado desta semana
        var semanaInicio = moment(semanaFinal).subtract(6, 'day').format('YYYY-MM-DD'); // sexta desta semana

        this.setState({ semanaInicio: semanaInicio, semanaFinal: semanaFinal })

    }

    listaAmigos() {
        let url = global.url + 'amigo/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ amigos: responseJson.amigos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contas: responseJson.contas })
                    this.saldoGeralDeContasCorrentes(responseJson.contas)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    saldoGeralDeContasCorrentes(contas) {
        var valorTotal = 0

        contas.map((conta, index) => {
            if (conta.tipo_conta === 'contaCorrente' || conta.tipo_conta === 'contaDinheiro') {
                valorTotal = parseFloat(valorTotal) + parseFloat(conta.saldoTotalLista)
            }
            return 0
        })

        valorTotal = parseFloat(valorTotal).toFixed(2)

        if (valorTotal > 0) {
            valorTotal = 0
        } else {
            valorTotal = valorTotal * -1
        }

        this.setState({ saldoBancario: valorTotal })

        console.log('******* valorTotal ********:' + valorTotal)

    }

    tabelaFeriado() {
        let url = global.url + 'tabela/feriado'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ feriados: responseJson.feriados })
            })
    }

    listaReembolsosAbertos() {
        let url = global.url + 'pagamento/listaReembolsosAbertos'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ reembolsosAbertos: responseJson.pagamentos })
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    listaPagamentos(data) {
        let url = global.url + 'pagamento/listaPorData/' + data

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ pagamentosRecebidos: responseJson.pagamentos })
                    this.ajustasPagamentos(responseJson.pagamentos)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    ajustasPagamentos(pagamentosTotal) {
        var pagamentosGeral = []
        var pagamentosDoMes = []
        var pagamentosDoMesPosterior = []

        const dataFinal = moment().endOf('month').format('YYYY-MM-DD')
        const dataInicio = moment().startOf('month').format('YYYY-MM-DD')

        const mesPosteriorFim = moment().add(1, 'month').endOf('month').format('YYYY-MM-DD')
        const mesPosteriorIni = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD')

        this.setState({ mesPosteriorIni: mesPosteriorIni, mesPosteriorFim: mesPosteriorFim })

        debitoMesCorrentePorDia.reduce((acc, o) => {
            var obj = o
            obj.valor = 0

            return obj;

        }, []);

        debitoMesPosteriorPorDia.reduce((acc, o) => {
            var obj = o
            obj.valor = 0

            return obj;

        }, []);

        creditoMesCorrentePorDia.reduce((acc, o) => {
            var obj = o
            obj.valor = 0

            return obj;

        }, []);

        creditoMesPosteriorPorDia.reduce((acc, o) => {
            var obj = o
            obj.valor = 0

            return obj;

        }, []);

        pagamentosTotal.map(async (pagamento, index) => {
            var salva = true

            if (pagamento.sub_categoria_nome_interno === 'pagamentoDentroDeUmaFatura') {
                salva = false
            }

            var salva2 = true

            if (pagamento.categoria_nome_interno === 'especial' && pagamento.sub_categoria_nome_interno === 'fatura') {
                salva2 = true
            } else {
                if (pagamento.categoria_nome_interno === 'especial') {
                    salva2 = false
                } else {
                    if (pagamento.fatura_id !== null) {
                        salva2 = false
                    }
                }

            }

            if (salva === true && salva2 === true) {
                if (pagamento.tipo === 'debito') {
                    if (pagamento.data_de_vencimento >= dataInicio && pagamento.data_de_vencimento <= dataFinal) {
                        const dia = parseInt(moment(pagamento.data_de_vencimento).format('D'))

                        debitoMesCorrentePorDia.reduce((acc, o) => {
                            var obj = o
                            if (obj.dia === dia) {
                                obj.valor = obj.valor + parseFloat(pagamento.valor)
                            }

                            return obj;

                        }, []);
                    }
                }

                if (pagamento.tipo === 'credito') {
                    if (pagamento.data_de_vencimento >= dataInicio && pagamento.data_de_vencimento <= dataFinal) {
                        const dia = parseInt(moment(pagamento.data_de_vencimento).format('D'))
                        creditoMesCorrentePorDia.reduce((acc, o) => {
                            var obj = o
                            if (obj.dia === dia) {
                                obj.valor = obj.valor + parseFloat(pagamento.valor)
                            }

                            return obj;

                        }, []);
                    }
                }

                if (pagamento.tipo === 'debito') {
                    if (pagamento.data_de_vencimento >= mesPosteriorIni && pagamento.data_de_vencimento <= mesPosteriorFim) {
                        const dia = parseInt(moment(pagamento.data_de_vencimento).format('D'))

                        debitoMesPosteriorPorDia.reduce((acc, o) => {
                            var obj = o
                            if (obj.dia === dia) {
                                obj.valor = obj.valor + parseFloat(pagamento.valor)
                            }

                            return obj;

                        }, []);

                    }
                }


                if (pagamento.tipo === 'credito') {
                    if (pagamento.data_de_vencimento >= mesPosteriorIni && pagamento.data_de_vencimento <= mesPosteriorFim) {
                        const dia = parseInt(moment(pagamento.data_de_vencimento).format('D'))
                        creditoMesPosteriorPorDia.reduce((acc, o) => {
                            var obj = o
                            if (obj.dia === dia) {
                                obj.valor = obj.valor + parseFloat(pagamento.valor)
                            }

                            return obj;

                        }, []);

                    }
                }

            }

            if (pagamento.status !== 'emAberto') {
                salva = false
            }

            if (salva === true) {
                pagamentosGeral.push(pagamento)
            }

            if (salva === true) {
                if (pagamento.data_de_vencimento >= this.state.dataInicioAntigas && pagamento.data_de_vencimento <= dataFinal) {
                    pagamentosDoMes.push(pagamento)
                }
            }

            if (salva === true) {
                if (pagamento.data_de_vencimento >= mesPosteriorIni && pagamento.data_de_vencimento <= mesPosteriorFim) {
                    pagamentosDoMesPosterior.push(pagamento)
                }
            }

        })

        var pagamentos = pagamentosDoMes

        if (pagamentosDoMes.length === 0) {
            if (pagamentosDoMesPosterior.length > 0) {
                this.setState({ cabec: 'Lista pagamentos de ' + moment().add(1, 'month').format('MMMM/YYYY') })
                pagamentos = pagamentosDoMesPosterior
            }
        }

        this.setState({ pagamentosGeral: pagamentosGeral })
        this.setState({ pagamentosDoMes: pagamentosDoMes })
        this.setState({ pagamentosDoMesPosterior: pagamentosDoMesPosterior })
        this.setState({ pagamentos: pagamentos })
        this.setState({ loader: false })


        var creditos = 0
        creditoMesCorrentePorDia.map(async (mesValor, index) => {
            data.datasets[0].data[index] = parseFloat(mesValor.valor).toFixed(2)
            creditos = creditos + mesValor.valor
        })

        var debitos = 0
        debitoMesCorrentePorDia.map(async (mesValor, index) => {
            data.datasets[1].data[index] = parseFloat(mesValor.valor).toFixed(2)
            debitos = debitos + mesValor.valor
        })

        this.setState({ creditos: parseFloat(creditos).toFixed(2) })
        this.setState({ debitos: parseFloat(debitos).toFixed(2) })

        // creditoMesCorrentePorDia.map(async (mesValor, index) => {
        //     data.datasets[2].data[index] = creditos
        // })

        // debitoMesCorrentePorDia.map(async (mesValor, index) => {
        //     data.datasets[3].data[index] = debitos
        // })

        pagamentos.map(async (pagamento, index) => {
            this.acumulaDebitosCalendario(pagamento, index, pagamentos.length)
        })

    }

    obtemParcelas(pagamento) {
        if (pagamento.frequencia === 'recorrente') {
            return ('000' + pagamento.parcela).slice(-3) + '/999'
        } else {
            if (pagamento.transacao.numero_de_parcelas === null) {
                return ''
            } else {
                return ('000' + pagamento.parcela).slice(-3) + '/' + ('000' + pagamento.transacao.numero_de_parcelas).slice(-3)
            }
        }
    }

    pagamentoConsulta(pagamento) {
        if (pagamento.sub_categoria_nome_interno === 'fatura') {
            this.props.pagamentoConsultaFatura(pagamento)
        } else {
            this.props.pagamentoConsulta(pagamento, false)
        }
    }

    pagamentoPagar(pagamento) {
        if (pagamento.sub_categoria_nome_interno === 'fatura') {
            this.props.pagamentoPagarFatura(pagamento)
        } else {
            this.props.pagamentoPagar(pagamento)
        }

    }

    corLinha(pagamento) {
        var dataAtual = moment().format('YYYY-MM-DD')
        var dataVencimento = moment(pagamento.data_de_vencimento).format('YYYY-MM-DD')

        var bgCor = null

        if (dataVencimento <= this.state.semanaFinal && dataVencimento >= this.state.semanaInicio) {
            bgCor = ' rgm-bg-azul-esverdiado-semana'
            bgCor = null
        }

        if (dataVencimento < dataAtual) {
            return 'text-danger ' + bgCor
        } else {
            if (moment(dataVencimento).format('YYYY-MM') > moment(dataAtual).format('YYYY-MM')) {
                return 'text-muted ' + bgCor
            } else {
                if (dataAtual === dataVencimento) {
                    return 'text-primary ' + bgCor
                } else {
                    return 'text-dark ' + bgCor
                }
            }
        }
    }

    btnPgto(pagamento, index) {

        if (index === 0) {
            faturasListadas = []
        }

        var dataAtual = moment().format('YYYY-MM-DD')
        var dataVencimento = moment(pagamento.data_de_vencimento).format('YYYY-MM-DD')

        if (pagamento.frequencia === 'mensal' || pagamento.frequencia === 'recorrente') {
            var parcela = 0
            if (pagamento.transacao.ultima_parcela_paga !== null) {
                parcela = pagamento.transacao.ultima_parcela_paga.valueOf()
            }
            parcela = parcela + 1
            if (pagamento.parcela !== parcela) {
                return true
            }
        }

        if (pagamento.sub_categoria_nome_interno === 'fatura') {
            var faturaLida = faturasListadas.find(function (obj) { return obj.conta_id === pagamento.fatura.conta_id })
            if (faturaLida === undefined) {
                faturasListadas.push({ conta_id: pagamento.fatura.conta_id })
            } else {
                return true
            }
        }


        if (moment(dataVencimento).format('YYYY-MM') > moment(dataAtual).format('YYYY-MM')) {
            return true
        }

        return false
    }

    listaPgto() {

        this.setState({ listaReembolsosNaoEfetuados: false })

        if (this.state.listaPgtoFla === 'mesCorrente') {
            this.setState({ listaPgtoFla: 'mesGeral', listaPgtoLit: 'Listar apenas pagamentos do mês corrente' })

            this.setState({ cabec: 'Lista pagamentos de todos os meses' })
        } else {
            this.setState({ listaPgtoFla: 'mesCorrente', listaPgtoLit: 'Listar todos pagamentos' })
            this.setState({ pagamentos: this.state.pagamentosDoMes })
            this.setState({ cabec: 'Lista pagamentos de ' + moment().format('MMMM/YYYY') })
        }
    }

    listaPgtoCorrente() {

        let url = global.url + 'pagamento/listaPorData/' + moment().format('YYYY-MM-DD')

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustasPagamentos(responseJson.pagamentos)
                    this.listaPgtoCorrenteDois()
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    listaPgtoCorrenteDois() {

        var creditos = 0
        creditoMesCorrentePorDia.map(async (mesValor, index) => {
            data.datasets[0].data[index] = parseFloat(mesValor.valor).toFixed(2)
            creditos = creditos + mesValor.valor
        })

        var debitos = 0
        debitoMesCorrentePorDia.map(async (mesValor, index) => {
            data.datasets[1].data[index] = parseFloat(mesValor.valor).toFixed(2)
            debitos = debitos + mesValor.valor
        })

        this.setState({ creditos: parseFloat(creditos).toFixed(2) })
        this.setState({ debitos: parseFloat(debitos).toFixed(2) })


        // creditoMesCorrentePorDia.map(async (mesValor, index) => {
        //     data.datasets[2].data[index] = creditos
        // })

        // debitoMesCorrentePorDia.map(async (mesValor, index) => {
        //     data.datasets[3].data[index] = debitos
        // })

        this.setState({ pagamentos: this.state.pagamentosDoMes })
        this.setState({ cabec: 'Lista pagamentos de ' + moment().format('MMMM/YYYY') })
        this.setState({ cabecGrafico: 'Gráfico dos débitos em abertos de ' + moment().format('MMMM/YYYY') })
    }

    listaPgtoPosterior() {
        let url = global.url + 'pagamento/listaPorData/' + this.state.mesPosteriorIni

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustasPagamentos(responseJson.pagamentos)
                    this.listaPgtoPosteriorDois()
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    listaPgtoPosteriorDois() {
        var creditos = 0
        creditoMesPosteriorPorDia.map(async (mesValor, index) => {
            data.datasets[0].data[index] = parseFloat(mesValor.valor).toFixed(2)
            creditos = creditos + mesValor.valor
        })

        var debitos = 0
        debitoMesPosteriorPorDia.map(async (mesValor, index) => {
            data.datasets[1].data[index] = parseFloat(mesValor.valor).toFixed(2)
            debitos = debitos + mesValor.valor
        })

        this.setState({ creditos: parseFloat(creditos).toFixed(2) })
        this.setState({ debitos: parseFloat(debitos).toFixed(2) })

        // creditoMesPosteriorPorDia.map(async (mesValor, index) => {
        //     data.datasets[2].data[index] = creditos
        // })

        // debitoMesPosteriorPorDia.map(async (mesValor, index) => {
        //     data.datasets[3].data[index] = debitos
        // })

        this.setState({ pagamentos: this.state.pagamentosDoMesPosterior })
        this.setState({ cabec: 'Lista pagamentos de ' + moment().add(1, 'month').format('MMMM/YYYY') })
        this.setState({ cabecGrafico: 'Gráfico dos débitos em abertos de ' + moment().add(1, 'month').format('MMMM/YYYY') })
    }

    listaPgtoTudo() {
        let url = global.url + 'pagamento/listaPorData/9999-99-99'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustasPagamentos(responseJson.pagamentos)
                    this.listaPgtoTudoDois()
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })

    }

    listaPgtoTudoDois() {
        this.setState({ pagamentos: this.state.pagamentosGeral })
        this.setState({ cabec: 'Lista pagamentos de todos os meses' })
    }

    listaReemAbertos() {

        this.setState({ listaReembolsosNaoEfetuados: true })

        this.setState({ pagamentos: this.state.reembolsosAbertos })
        this.setState({ cabec: 'Lista pagamentos de reembolsos não realizados' })

    }

    acumulaDebitos(pagamento, index, total) {

        if (index === 0) {
            totalDebitosNoPeriodo = 0
            totalCreditosNoPeriodo = 0
        }

        if (pagamento.tipo === 'debito') {
            totalDebitosNoPeriodo = parseFloat(totalDebitosNoPeriodo) + parseFloat(pagamento.valor)
        }

        if (pagamento.tipo === 'credito') {
            totalCreditosNoPeriodo = parseFloat(totalCreditosNoPeriodo) + parseFloat(pagamento.valor)
        }

        totalDebitosNoPeriodo = parseFloat(totalDebitosNoPeriodo).toFixed(2)
        totalCreditosNoPeriodo = parseFloat(totalCreditosNoPeriodo).toFixed(2)
    }

    acumulaDebitosCalendario(pagamento, index, total) {

        if (index === 0) {
            myEventsList = []
        }

        var achou = false

        myEventsList.map(async (meuEvento, index) => {
            var start = moment(pagamento.data_de_vencimento).format('YYYY-MM-DD')
            var meuStart = moment(meuEvento.start).format('YYYY-MM-DD')
            if (meuStart === start && meuEvento.tipo === pagamento.tipo) {
                achou = true
                myEventsList[index].title = parseFloat(myEventsList[index].title) + parseFloat(pagamento.valor)
            }
        })

        if (achou === false) {
            var eventoNew = JSON.parse(JSON.stringify(evento))
            eventoNew.title = parseFloat(pagamento.valor)
            eventoNew.start = moment(pagamento.data_de_vencimento)
            eventoNew.end = moment(pagamento.data_de_vencimento)
            eventoNew.tipo = pagamento.tipo
            myEventsList.push(eventoNew)
        }

        //alert('myEventsList:' + JSON.stringify(myEventsList) + ' index:' + index + ' total - 1:' + (total - 1))

        if (index === (total - 1)) {
            myEventsList.map(async (evento, index) => {
                var cor = ''
                if (evento.tipo === 'credito') {
                    cor = 'text-warning'
                } else {
                    cor = 'text-white'
                }
                myEventsList[index].title = <div className={"text-right small " + cor}>{formataDisplayEmReal(parseFloat(myEventsList[index].title).toFixed(2))}</div>
            })
            this.setState({ myEventsList: myEventsList })
        }
    }

    corDoLancamento(pagamento) {

        var bg = ''

        this.state.clicado.map(async (cli, index1) => {
            if (cli.index === pagamento.id) {
                bg = 'bg-dark'
            }
        })

        if (pagamento.tipo === 'credito') {
            return 'rgm-cor-positivo ' + bg
        } else {
            return 'text-white ' + bg
        }
    }

    corDescricao(pagamento) {

        var dias = this.calculaDias(pagamento.data_de_vencimento)

        var corDoTexto = null
        var venceHoje = false

        if (pagamento.data_de_vencimento < moment().format('YYYY-MM-DD')) {
            corDoTexto = 'text-warning'
        } else {
            if (pagamento.data_de_vencimento === moment().format('YYYY-MM-DD')) {
                venceHoje = true
                corDoTexto = 'text-white'
            } else {
                corDoTexto = 'text-white'
            }
        }

        if (venceHoje === true) {
            return corDoTexto + ' text-danger font-weight-bold bg-warning'
        } else {
            if (dias > 0 && dias < 8) {
                return corDoTexto + ' text-danger font-weight-bold bg-info'
            } else {
                if (pagamento.sub_categoria_nome_interno === 'fatura') {
                    return corDoTexto + ' font-weight-bold bg-primary'
                } else {
                    return corDoTexto
                }
            }
        }

    }

    calculaDias(data_de_vencimento) {
        if (data_de_vencimento < moment().format('YYYY-MM-DD')) {
            var dias = moment().diff(moment(data_de_vencimento), 'days');
            return dias
        } else {
            if (data_de_vencimento === moment().format('YYYY-MM-DD')) {
                return 0
            } else {
                return 0
            }
        }

    }

    obtemDescricao(pagamento) {

        var erro = ''

        if (pagamento.sub_categoria_nome_interno !== 'fatura') {
            if (pagamento.categorias.length === 0) {
                erro = '*** PROBLEMA RELATAR *** | '
            }
        }

        if (pagamento.data_de_vencimento < moment().format('YYYY-MM-DD')) {
            var dias = moment().diff(moment(pagamento.data_de_vencimento), 'days');
            var diaLit = ''
            if (dias === 1) {
                diaLit = ' dia **'
            } else {
                diaLit = ' dias **'
            }
            return erro + pagamento.descricao + ' ** em atraso, a ' + dias + diaLit
        } else {
            if (pagamento.data_de_vencimento === moment().format('YYYY-MM-DD')) {
                return erro + pagamento.descricao + ' ** vence hoje **'
            } else {
                return erro + pagamento.descricao
            }
        }
    }

    corVencimento(data) {

        var semana = moment(data).isoWeekday()

        var cor = ''

        switch (semana) {
            case 6:
            case 7:
                cor = 'font-weight-light text-success';
                break;
            default:
                cor = '';
                break;
        }

        const feriado = this.state.feriados.find(feriado => feriado.value === data);

        if (feriado !== undefined) {
            cor = 'font-weight-light text-success';
        }

        return cor

    }

    formataVencimento(data) {

        const dataFormatada = moment(data).format('DD/MM/YYYY')

        return dataFormatada


    }

    formataVencimentoSemana(data) {

        var diaDaSemana = ''

        var semana = moment(data).isoWeekday()

        switch (semana) {
            case 1:
                diaDaSemana = 'seg';
                break;
            case 2:
                diaDaSemana = 'ter';
                break;
            case 3:
                diaDaSemana = 'qua';
                break;
            case 4:
                diaDaSemana = 'qui';
                break;
            case 5:
                diaDaSemana = 'sex';
                break;
            case 6:
                diaDaSemana = 'sab';
                break;
            case 7:
                diaDaSemana = 'dom';
                break;
            default:
                diaDaSemana = '';
                break;
        }

        const feriado = this.state.feriados.find(feriado => feriado.value === data);
        if (feriado !== undefined) {
            diaDaSemana = diaDaSemana + '*'
        }

        return diaDaSemana


    }

    obtemNomeDiaSemana(data) {
        var semana = moment(data).isoWeekday()

        switch (semana) {
            case 1:
                return 'seg';
            case 2:
                return 'ter';
            case 3:
                return 'qua';
            case 4:
                return 'qui';
            case 5:
                return 'sex';
            case 6:
                return 'sab';
            case 7:
                return 'dom';
            default:
                return '';
        }
    }

    nomeConta(conta_id) {
        const conta = this.state.contas.find(conta => conta.id === conta_id);

        if (conta === undefined) {
            return ''
        } else {
            return conta.nome
        }
    }

    nomeAmigos(pagamento) {
        var amigos_id = []

        pagamento.categorias.map(async (categoria, index) => {
            if (categoria.amigo_id > 0) {
                amigos_id.push(categoria.amigo_id)
            }
        })

        var amigosSemDuplicados = amigos_id.filter(function (este, i) {
            return amigos_id.indexOf(este) === i;
        });

        if (amigosSemDuplicados.length > 0) {
            var tam = amigosSemDuplicados.length
            var nomes = ''
            var amigoLido = this.state.amigos.find(function (obj) { return obj.id === amigosSemDuplicados[0] })
            var nomeAx = amigoLido.nome.split(' ')
            if (tam === 1) {
                nomes = nomeAx[0]
            } else {
                nomes = nomeAx[0] + ', ...'
            }
            return nomes
        } else {
            return ''
        }
    }

    nomeAmigos2(pagamento) {
        var amigos_id = []

        pagamento.categorias.map(async (categoria, index) => {
            if (categoria.amigo_id > 0) {
                amigos_id.push(categoria.amigo_id)
            }
        })

        var amigosSemDuplicados = amigos_id.filter(function (este, i) {
            return amigos_id.indexOf(este) === i;
        });

        if (amigosSemDuplicados.length > 0) {
            var tam = (amigosSemDuplicados.length - 1)
            var nomes = ''
            amigosSemDuplicados.map(async (amigo, index) => {
                var amigoLido = this.state.amigos.find(function (obj) { return obj.id === amigosSemDuplicados[index] })
                var nomeAx = amigoLido.nome.split(' ')
                if (tam === index) {
                    nomes = nomes + nomeAx[0]
                } else {
                    nomes = nomes + nomeAx[0] + ', '
                }
            })
            return nomes
        } else {
            return ''
        }
    }


    mudaVisao() {
        var mostraGraficoGeral = !this.state.mostraGraficoGeral
        this.setState({ mostraGraficoGeral: mostraGraficoGeral })
    }

    click(pagamento, index) {

        var click = this.state.clicado
        var soma = this.state.soma

        var encontrou = false
        var indexFound = null

        this.state.clicado.map(async (cli, index1) => {
            if (cli.index === pagamento.id) {
                encontrou = true
                indexFound = index1
            }
        })

        if (encontrou === false) {
            var ocorrencia = { index: pagamento.id, bg: 'bg-warning' }
            click.push(ocorrencia)
            if (pagamento.tipo === 'debito') {
                soma = parseFloat(soma) - parseFloat(pagamento.valor)
            } else {
                soma = parseFloat(soma) + parseFloat(pagamento.valor)
            }
        } else {
            click.splice(indexFound, 1)
            if (pagamento.tipo === 'debito') {
                soma = parseFloat(soma) + parseFloat(pagamento.valor)
            } else {
                soma = parseFloat(soma) - parseFloat(pagamento.valor)
            }
        }

        if (click.length === 0) {
            this.setState({ mostraSoma: false })
            soma = 0
        } else {
            this.setState({ mostraSoma: true })
        }

        soma = parseFloat(soma).toFixed(2)

        this.setState({ clicado: click, soma: soma })
    }

    zerarSoma() {
        this.setState({ mostraSoma: false, clicado: [], soma: 0 })
    }


    render() {
        if (this.state.loader === true) {
            return [
                this.renderLoad()
            ]
        } else {
            if (this.state.mostraGraficoGeral === true) {
                return [
                    this.renderGraficoGeral(),
                ]
            } else {
                return [
                    this.renderComum(),
                    this.renderResumoPorSemana(),
                    this.renderCalendario()
                ]
            }
        }
    }

    // <button id="listaReembolso" type="button" className="btn btn-primary btn-sm m-2" onClick={() => this.listaReemAbertos()} >Reembolsos não realizados</button> */
    clickCalendario() {
        alert('clicou')
    }

    mostrarCalendario() {
        this.setState({ mostrarCalendario: !this.state.mostrarCalendario })
    }

    mostraAnexoTransacao(pagamento) {
        var anexos = []

        if (pagamento.sub_categoria_nome_interno === 'fatura') {
            anexos = pagamento.fatura.anexos
        } else {
            anexos = pagamento.anexos
            if (anexos.length === 0) {
                anexos = pagamento.transacao.anexos
            }
        }

        if (anexos.length > 0) {
            window.open(anexos[0].url, "_blank");
        } else {
            return
        }

    }

    mostraClips(pagamento) {

        var anexos = []

        if (pagamento.sub_categoria_nome_interno === 'fatura') {
            anexos = pagamento.fatura.anexos
        } else {
            anexos = pagamento.anexos
            if (anexos.length === 0) {
                anexos = pagamento.transacao.anexos
            }
        }

        if (anexos.length > 0) {
            return <i class="fa fa-paperclip fa-lg rgm-cor-link" aria-hidden="true"></i>
        } else {
            if (pagamento.sub_categoria_nome_interno === 'fatura') {
                //return <i class="fa fa-clock-o fa-lg rgm-cor-link" aria-hidden="true"></i>
                //return <i class="fa fa-circle-o-notch fa-spin fa-3x style='font-size:24px'"></i>
                return <i class="fa fa-circle-o-notch fa-spin style={font-size: 24px}"></i>
            } else {
                return ''
            }
        }
    }

    mostrarAntigos() {

        if (this.state.dataInicioAntigas === '1990-01-01') {
            var dataInicioAntigas = moment().subtract(30, 'day').format('YYYY-MM-DD')
            this.setState({ dataInicioAntigas: dataInicioAntigas, dataInicioAntigasLiteral: 'lista todas vencidas' }, () => { this.ajustasPagamentos(this.state.pagamentosRecebidos) })
        } else {
            this.setState({ dataInicioAntigas: '1990-01-01', dataInicioAntigasLiteral: 'lista vencidas a 30 dias' }, () => { this.ajustasPagamentos(this.state.pagamentosRecebidos) })
        }



    }

    renderComum() {
        const tooTipDataDeVencimento = 'A cor esverdeada com uma letra do lado indica que o data caiu em um sábado(s), domingo(d) ou feriado(f)  '

        var colunas = 7

        if (this.state.amigos.length > 0) {
            colunas = 8
        }

        var totalGeral = (parseFloat(totalCreditosNoPeriodo) - (parseFloat(totalDebitosNoPeriodo) + this.state.saldoBancario))

        totalGeral = parseFloat(totalGeral).toFixed(2)

        var literalTotalGeral = ''

        if (totalGeral < 0) {
            literalTotalGeral = 'Saldo final ficou negativo'
        } else {
            literalTotalGeral = 'Saldo final ficou positivo'
        }

        return (
            <div className="container-fluid w-100 mt-5">
                <div class="row justify-content-md-center">
                    <div className="form-group row mt-0 mb-0">
                        <div className="col-sm-12">
                            <button id="novoPgto" type="button" className="btn btn-primary btn-sm m-2" onClick={() => this.props.pagamentoInclusao()} ><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Novo</button>
                            <div className="btn-group" role="group" aria-label="Exemplo básico">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => this.listaPgtoCorrente()}>{this.state.mesCorrenteLit}</button>
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => this.listaPgtoPosterior()}>{this.state.mesPosteriorLit}</button>
                                <button type="button" className="btn ntn-sm btn-primary" onClick={() => this.listaPgtoTudo()}>Todos Pagamentos</button>
                            </div>
                            <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}><i class="fa fa-line-chart" aria-hidden="true"></i></button>
                            <button className="btn btn-success m-2" data-toggle="tooltip" data-placement="top" title='Total de créditos de mês'>{formataDisplayEmReal(this.state.creditos)}</button>
                            <button className="btn btn-danger m-2" data-toggle="tooltip" data-placement="top" title='Total de débitos de mês'>{formataDisplayEmReal(this.state.debitos)}</button>
                            {this.state.mostraSoma &&
                                <button className="btn btn-dark m-2" data-toggle="tooltip" data-placement="top" title='Auxilia soma' onClick={() => this.zerarSoma()}>Soma = {formataDisplayEmReal(this.state.soma)}</button>
                            }
                            <button className="btn btn-link btn-sm align-middle" onClick={() => { this.mostrarCalendario() }}><i class="fa fa-calendar fa-lg" aria-hidden="true"></i></button>
                            <button id="mostrarAntigos" type="button" className="btn btn-danger btn-sm m-2 blink_me" onClick={() => this.mostrarAntigos()} ><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;{this.state.dataInicioAntigasLiteral}</button>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center">
                    <div className="card row bg-transparent">
                        <div class="card-header w-auto border rgm-bg-azul-esverdiado text-white">
                            <h5>{this.state.cabec}</h5>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col" className="text-center" data-toggle="tooltip" data-placement="top" title={tooTipDataDeVencimento} COLSPAN="2">Vencimento</th>
                                    <th scope="col">Conta</th>
                                    {this.state.amigos.length > 0 &&
                                        <th scope="col">Amigo</th>
                                    }
                                    <th scope="col">Frequência</th>
                                    <th scope="col" className="text-center">Parcela</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col" className="text-right">Valor</th>
                                    {!this.state.listaReembolsosNaoEfetuados &&
                                        <th scope="col" className="text-center">Pagar</th>
                                    }
                                    <th scope="col" className="text-right">Anexo</th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.pagamentos.map((pagamento, index) => {
                                    this.acumulaDebitos(pagamento, index, this.state.pagamentos.length)
                                    return (
                                        <tr className={'rgm-bg-azul-esverdiado text-white ' + this.corLinha(pagamento)}>
                                            <td className="text-center text-white align-middle">
                                                <button className="btn btn-link btn-sm text-center" onClick={() => { this.pagamentoConsulta(pagamento) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                            </td>
                                            <td className={"align-middle rgm-diminuirParaCaber " + this.corDescricao(pagamento)}>{this.obtemDescricao(pagamento)}</td>
                                            <td className={"text-center align-middle text-white rgm-diminuirParaCaber " + this.corVencimento(pagamento.data_de_vencimento)} data-toggle="tooltip" data-placement="top" title={tooTipDataDeVencimento}>{this.formataVencimento(pagamento.data_de_vencimento)}</td>
                                            <td className={"text-center align-middle text-white rgm-diminuirParaCaber " + this.corVencimento(pagamento.data_de_vencimento)} data-toggle="tooltip" data-placement="top" title={tooTipDataDeVencimento}>{this.formataVencimentoSemana(pagamento.data_de_vencimento)}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber ">{this.nomeConta(pagamento.conta_id)}</td>
                                            {this.state.amigos.length > 0 &&
                                                <td className="align-middle text-white rgm-diminuirParaCaber ">{this.nomeAmigos(pagamento)}</td>
                                            }
                                            <td className="align-middle text-white">{extensoFrequencia(pagamento.frequencia)}</td>
                                            <td className="text-center align-middle text-white">{this.obtemParcelas(pagamento)}</td>
                                            <td className="align-middle text-white">{extensoTipo(pagamento.tipo)}</td>
                                            <td className={"text-right align-middle " + this.corDoLancamento(pagamento)} onClick={() => this.click(pagamento, index)}>{formataDisplayEmReal(pagamento.valor)}</td>
                                            {!this.state.listaReembolsosNaoEfetuados &&
                                                <td className="text-center"><button className="btn btn-link btn-sm align-middle" disabled={this.btnPgto(pagamento, index)} onClick={() => { this.pagamentoPagar(pagamento) }}><i class="fa fa-check fa-2x rgm-cor-link" aria-hidden="true"></i></button></td>
                                            }
                                            <td className="align-middle text-white">
                                                <button type="button" className={"btn btn-link btn-sm text-center "} onClick={() => { this.mostraAnexoTransacao(pagamento) }}>{this.mostraClips(pagamento)}</button>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                            {this.state.pagamentos.length === 0 &&
                                <tbody>
                                    <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="11">
                                        <span>Não existem pagamentos para sua seleção</span>
                                    </td>
                                </tbody>
                            }
                            {this.state.pagamentos.length !== 0 &&
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col" className="text-center" COLSPAN={colunas}>Total de débitos</th>
                                        <th scope="col" className="text-right" COLSPAN="4">{formataDisplayEmReal(totalDebitosNoPeriodo)}</th>
                                    </tr>
                                    <tr className="bg-info">
                                        <th scope="col" className="text-center" COLSPAN={colunas}>Saldo Negativo das contas correntes</th>
                                        <th scope="col" className="text-right" COLSPAN="4">{formataDisplayEmReal(this.state.saldoBancario)}</th>
                                    </tr>
                                    <tr className="bg-info">
                                        <th scope="col" className="text-center" COLSPAN={colunas}>Total de créditos</th>
                                        <th scope="col" className="text-right rgm-cor-positivo" COLSPAN="4">{formataDisplayEmReal(totalCreditosNoPeriodo)}</th>
                                    </tr>
                                    <tr className="bg-info">
                                        <th scope="col" className="text-center" COLSPAN={colunas}>{literalTotalGeral}</th>
                                        {totalGeral < 0 &&
                                            <th scope="col" className="text-right" COLSPAN="4">{formataDisplayEmReal(totalGeral)}</th>
                                        }
                                        {totalGeral >= 0 &&
                                            <th scope="col" className="text-right rgm-cor-positivo" COLSPAN="4">{formataDisplayEmReal(totalGeral)}</th>
                                        }
                                    </tr>
                                </thead>
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderResumoPorSemana() {
        return (
            <div className="container-fluid w-100 mt-5">
                <div class="row justify-content-md-center">
                    <div className="card row bg-transparent">
                        <div class="card-header w-auto border rgm-bg-azul-esverdiado text-white">
                            <h5>RESUMO SEMANAL</h5>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col">Descrição</th>
                                    <th scope="col" className="text-right">Valor</th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.resumoSemanal.map((resumo, index) => {
                                    return (
                                        <tr className={'rgm-bg-azul-esverdiado text-white'}>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{this.obtemDescricao(resumo)}</td>
                                            <td className={"text-right align-middle " + this.corDoLancamento(resumo)} onClick={() => this.click(resumo, index)}>{formataDisplayEmReal(resumo.valor)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )

    }


    renderCalendario() {

        if (this.state.mostrarCalendario === false) {
            return
        }

        if (this.state.pagamentos.length === 0) {
            return
        }

        return (
            <div className="container-fluid w-75">
                <Calendar
                    view="month"
                    views={['month']}
                    localizer={localizer}
                    events={this.state.myEventsList}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500, backgroundColor: 'rgba(49, 116, 173)' }}
                    resizable
                />
            </div>
        )

    }

    renderGraficoGeral() {
        return (
            <div className="container w-100 mt-5">

                <div className="card-header bg-info border mt-2">
                    <h7>{this.state.cabecGrafico}</h7>
                </div>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}>Volta para lista</button>
                <button className="btn btn- btn-success m-2" data-toggle="tooltip" data-placement="top" title='Total de créditos de mês'>{formataDisplayEmReal(this.state.creditos)}</button>
                <button className="btn btn-primary btn-danger m-2" data-toggle="tooltip" data-placement="top" title='Total de débitos de mês'>{formataDisplayEmReal(this.state.debitos)}</button>
                <div className="jumbotron">
                    <Bar data={data} />
                </div>
            </div>
        )
    }


    renderLoad() {
        return (
            <div className="container w-100 mt-5">
                <div className="card-header bg-info border mt-2">
                    <h7>Lista de pagamentos</h7>
                </div>
                <div className="loader text-center mt-5"></div>
                <span className="text-warning">Aguarde... Carregando...</span>
            </div>
        )
    }
}

export default PagamentoLista