import React, { Component } from "react"
import 'moment/locale/pt-br';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select'
import { formataDisplayEmReal, formataEmRealParaBD } from '../../util/Utils'
import '../conta/Conta.css'
import ModalAviso from '../modal/ModalAviso'
import { verificaSeContaExiste } from '../conta/ContaUtil'


const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}



class ContaAlteracao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            conta: props.conta,
            //comum
            nome: null,
            tipo_conta: null,
            //conta corrente
            banco: null,
            cheque_especial_dia_cobranca: null,
            cheque_especial_limite_disp: null,
            saldo_inicial_disp: null,
            saldo_inicial_conta_remunerada_disp: null,
            possui_conta_remunerada: null,
            favorita: null,
            //cartao de credito
            bandeira: null,
            dia_fechamento: null,
            dia_pagamento: null,
            ultima_fatura_fechada: null,
            limite_disp: null,
            conta_debito: null,
            cartao_inicio: null,
            cartao_fim: null,
            //outros
            tiposContas: [],
            bancos: [],
            bandeiras: [],
            contas: [],
            alertaHeader: null,
            alertaParagrafo: null,
            alerta: false,
            alertaMsg: null,
            confirma: false
        }
    }

    componentWillMount() {
        this.tabelaTipoConta()
        this.tabelaBanco()
        this.tabelaBandeira()
        this.listaContasCombo()
        this.preencheCampos()
    }

    preencheCampos() {

        this.setState({
            nome: this.props.conta.nome,
            tipo_conta: this.props.conta.tipo_conta,
            saldo_inicial_disp: formataDisplayEmReal(this.props.conta.saldo_inicial),
            favorita: this.props.conta.favorita,
            comentarios: this.props.conta.comentarios
        })

        if (this.props.conta.tipo_conta === 'contaCorrente' || this.props.conta.tipo_conta === 'contaInvestimento'  ) {
            this.setState({
                banco: this.props.conta.conta_correntes.banco,
                cheque_especial_dia_cobranca: this.props.conta.conta_correntes.cheque_especial_dia_cobranca,
                cheque_especial_limite_disp: formataDisplayEmReal(this.props.conta.conta_correntes.cheque_especial_limite),
                saldo_inicial_conta_remunerada_disp: formataDisplayEmReal(this.props.conta.conta_correntes.saldo_inicial_conta_remunerada),
                possui_conta_remunerada: this.props.conta.conta_correntes.possui_conta_remunerada

            })
        }

        if (this.props.conta.tipo_conta === 'cartaoCredito') {
            this.setState({
                bandeira: this.props.conta.conta_cartoes_creditos.bandeira,
                dia_fechamento: this.props.conta.conta_cartoes_creditos.dia_fechamento,
                dia_pagamento: this.props.conta.conta_cartoes_creditos.dia_pagamento,
                limite_disp: formataDisplayEmReal(this.props.conta.conta_cartoes_creditos.limite),
                conta_debito: this.props.conta.conta_cartoes_creditos.conta_debito,
                cartao_inicio: this.props.conta.conta_cartoes_creditos.cartao_inicio,
                cartao_fim: this.props.conta.conta_cartoes_creditos.cartao_fim
            })
        }
    }


    handleChangeNome(event) {
        this.setState({ nome: event.target.value })
    }

    handleChangeTipoConta(event) {
        this.setState({ alertaMsg: 'O tipo da conta não pode ser alterado', alerta: true })
        //this.setState({ tipo_conta: event.value })
    }

    handleChangeBanco(event) {
        this.setState({ banco: event.value })
    }

    handleChangeChequeEspecial(event) {

        var valor = event.target.value

        if (valor.length === 0) {
            valor = '0'
        }

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        if (formataDisplayEmReal(valor).includes("-", 0)) {
            return
        }

        this.setState({ cheque_especial_limite_disp: formataDisplayEmReal(valor) })
    }

    handleChangeChequeEspecialDia(event) {

        var dia = event.target.value

        if (dia !== '') {
            if (event.target.value < 1 || event.target.value > 31) {
                dia = 1
            } else {
                dia = event.target.value
            }
        }

        if (dia !== '' && isNaN(dia)) {
            dia = 1
        }

        this.setState({ cheque_especial_dia_cobranca: dia })
    }

    handleChangeSaldoInicialContaRemunerada(event) {

        var valor = event.target.value

        if (valor.length === 0) {
            valor = '0'
        }

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        if (formataDisplayEmReal(valor).includes("-", 0)) {
            return
        }

        this.setState({ saldo_inicial_conta_remunerada_disp: formataDisplayEmReal(valor) })
    }

    handleChangeSaldoInicial(event) {
        var valor = event.target.value

        if (valor.length === 0) {
            valor = '0'
        }

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        this.setState({ saldo_inicial_disp: formataDisplayEmReal(valor) })
    }

    handleChangeBandeira(event) {
        this.setState({ bandeira: event.value })
    }

    handleChangeContaDebito(event) {
        if (event.tipo_conta === 'cartaoCredito') {
            this.setState({ alertaMsg: 'Tipo de conta invalido', alerta: true })
            return null
        }

        this.setState({ conta_debito: event.id })
    }

    handleChangeCartaoInicio(event) {
        var cartao_inicio = ''

        cartao_inicio = event.target.value

        this.setState({ cartao_inicio: cartao_inicio })
    }

    handleChangeCartaoFim(event) {
        var cartao_fim = ''

        cartao_fim = event.target.value

        this.setState({ cartao_fim: cartao_fim })
    }

    handleChangeLimite(event) {

        var valor = event.target.value

        if (valor.length === 0) {
            valor = '0'
        }

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        if (formataDisplayEmReal(valor).includes("-", 0)) {
            return
        }

        this.setState({ limite_disp: formataDisplayEmReal(valor) })
    }

    handleChangeDiaPagamento(event) {
        var dia = 0

        if (dia !== '') {
            if (event.target.value < 1 || event.target.value > 31) {
                dia = 0
            } else {
                dia = event.target.value
            }
        }

        if (dia !== '' && isNaN(dia)) {
            dia = 1
        }

        this.setState({ dia_pagamento: dia })

    }

    handleChangeDiaFechamento(event) {
        var dia = 0

        if (dia !== '') {
            if (event.target.value < 1 || event.target.value > 31) {
                dia = 0
            } else {
                dia = event.target.value
            }
        }

        if (dia !== '' && isNaN(dia)) {
            dia = 1
        }

        this.setState({ dia_fechamento: dia })

    }

    handleChangeCheck(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        var possui_conta_remunerada = value
        this.setState({ possui_conta_remunerada: possui_conta_remunerada })

        if (possui_conta_remunerada === false) {
            this.setState({ saldo_inicial_conta_remunerada_disp: 'R$ 0,00' })
        }
    }

    handleChangeCheckFavorita(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        var favorita = value
        this.setState({ favorita: favorita })
    }

    handleChangeComentarios(event) {
        this.setState({
            comentarios: event.target.value
        })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderComum(),
            this.renderContaCorrente(),
            this.renderContaInvestimento(),
            this.renderContaDinheiro(),
            this.renderCartaoCredito(),
            this.renderBotao()
        ]
    }

    renderComum() {
        return (
            <div className="container w-100 mt-5">
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Alteração de conta</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        <div className="form-group col-md-3">
                            <label for="banco" className="">Tipo de conta</label>
                            <Select
                                //disabled={true}
                                styles={customStyles}
                                id="tipo_conta"
                                name="tipo_conta"
                                value={this.state.tiposContas.filter(option => option.value === this.state.tipo_conta)}
                                onChange={this.handleChangeTipoConta.bind(this)}
                                options={this.state.tiposContas}
                            />
                        </div>
                        <div className="form-group col-md-6">
                            <label for="nome">Descrição</label>
                            <input
                                onChange={this.handleChangeNome.bind(this)}
                                value={this.state.nome}
                                type="nome"
                                className="form-control"
                                id="nome"
                                placeholder="Nome" />
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderContaCorrente() {
        if (this.state.tipo_conta !== 'contaCorrente') {
            return null
        } else {
            return (
                <div className="container w-100"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-6">
                                <label for="banco" className="mt-3">Banco</label>
                                <Select
                                    styles={customStyles}
                                    className=""
                                    id="banco"
                                    name="banco"
                                    onChange={this.handleChangeBanco.bind(this)}
                                    value={this.state.bancos.filter(option => option.value === this.state.banco)}
                                    options={this.state.bancos}
                                />
                            </div>
                        </div>
                        {global.usuarioEmail === 'rgm1301@gmail.com.br' &&
                            <div className="form-row">
                                <div class="form-group row">
                                    <div class="form-check">
                                        <label class="form-check-label" for="gridCheck1">Possui conta remunerada</label>
                                        <input class="form-check-input ml-2" type="checkbox" id="possui_conta_remunerada" onChange={this.handleChangeCheck.bind(this)} checked={this.state.possui_conta_remunerada} />
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="form-row">
                            <div class="form-group row">
                                <div class="form-check">
                                    <label class="form-check-label" for="gridCheck1">Favorita</label>
                                    <input class="form-check-input ml-2" type="checkbox" id="favorita" onChange={this.handleChangeCheckFavorita.bind(this)} checked={this.state.favorita} />
                                </div>
                            </div>
                        </div>

                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Limite cheque especial</label>
                                <input
                                    onChange={this.handleChangeChequeEspecial.bind(this)}
                                    value={this.state.cheque_especial_limite_disp}
                                    type="text"
                                    className="form-control"
                                    id="cheque_especial_limite"
                                    placeholder="Limite do cheque especial" />
                            </div>
                            <div className="form-group col-md-2">
                                <label for="nome" className="mt-3">Dia cheque especial</label>
                                <input
                                    onChange={this.handleChangeChequeEspecialDia.bind(this)}
                                    value={this.state.cheque_especial_dia_cobranca}
                                    type="text"
                                    className="form-control"
                                    id="cheque_especial_limite_dia"
                                    placeholder="Dia do cheque especial" />
                            </div>
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Saldo Inicial Conta Corrente</label>
                                <input
                                    onChange={this.handleChangeSaldoInicial.bind(this)}
                                    value={this.state.saldo_inicial_disp}
                                    type="text"
                                    className="mr-3 form-control"
                                    id="saldo_inicial"
                                />
                            </div>
                            {this.state.possui_conta_remunerada &&
                                <div className="form-group col-md-3">
                                    <label for="nome" className="mt-3">Saldo Inicial Conta Remunerada</label>
                                    <input
                                        onChange={this.handleChangeSaldoInicialContaRemunerada.bind(this)}
                                        value={this.state.saldo_inicial_conta_remunerada_disp}
                                        type="text"
                                        className="form-control"
                                        id="saldo_inicial_conta_remunerada"
                                    />
                                </div>
                            }
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                value={this.state.comentarios}
                                className="form-control mt-2 ml-2"
                                rows="2" id="comentarios"
                                onChange={this.handleChangeComentarios.bind(this)}>
                            </textarea>
                        </div>
                    </form>
                </div>
            )
        }
    }

    renderContaInvestimento() {
        if (this.state.tipo_conta !== 'contaInvestimento') {
            return null
        } else {
            return (
                <div className="container w-100"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-6">
                                <label for="banco" className="mt-3">Banco</label>
                                <Select
                                    styles={customStyles}
                                    className=""
                                    id="banco"
                                    name="banco"
                                    onChange={this.handleChangeBanco.bind(this)}
                                    value={this.state.bancos.filter(option => option.value === this.state.banco)}
                                    options={this.state.bancos}
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div class="form-group row">
                                <div class="form-check">
                                    <label class="form-check-label" for="gridCheck1">Favorita</label>
                                    <input class="form-check-input ml-2" type="checkbox" id="favorita" onChange={this.handleChangeCheckFavorita.bind(this)} checked={this.state.favorita} />
                                </div>
                            </div>
                        </div>

                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Saldo Inicial</label>
                                <input
                                    onChange={this.handleChangeSaldoInicial.bind(this)}
                                    value={this.state.saldo_inicial_disp}
                                    type="text"
                                    className="mr-3 form-control"
                                    id="saldo_inicial"
                                />
                            </div>
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                value={this.state.comentarios}
                                className="form-control mt-2 ml-2"
                                rows="2" id="comentarios"
                                onChange={this.handleChangeComentarios.bind(this)}>
                            </textarea>
                        </div>
                    </form>
                </div>
            )
        }
    }

    renderContaDinheiro() {
        if (this.state.tipo_conta !== 'contaDinheiro') {
            return null
        } else {
            return (
                <div className="container w-100"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Saldo Inicial</label>
                                <input
                                    onChange={this.handleChangeSaldoInicial.bind(this)}
                                    value={this.state.saldo_inicial_disp}
                                    type="text"
                                    className="mr-3 form-control"
                                    id="saldo_inicial"
                                />
                            </div>
                        </div>
                        <div className="form-row">
                            <div class="form-group row">
                                <div class="form-check">
                                    <label class="form-check-label" for="gridCheck1">Favorita</label>
                                    <input class="form-check-input ml-2" type="checkbox" id="favorita" onChange={this.handleChangeCheckFavorita.bind(this)} checked={this.state.favorita} />
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                value={this.state.comentarios}
                                className="form-control mt-2 ml-2"
                                rows="2" id="comentarios"
                                onChange={this.handleChangeComentarios.bind(this)}>
                            </textarea>
                        </div>
                    </form>
                </div>
            )
        }
    }


    renderCartaoCredito() {
        if (this.state.tipo_conta !== 'cartaoCredito') {
            return null
        } else {
            return (
                <div className="container w-100"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="bandeira" className="mt-3">Bandeira</label>
                                <Select
                                    styles={customStyles}
                                    id="bandeira"
                                    name="bandeira"
                                    value={this.state.bandeiras.filter(option => option.value === this.state.bandeira)}
                                    selectedValue={this.state.bandeira}
                                    onChange={this.handleChangeBandeira.bind(this)}
                                    options={this.state.bandeiras}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label for="contaDebito" className="mt-3">Conta para débito</label>
                                <Select
                                    styles={customStyles}
                                    id="contaDebito"
                                    name="contaDebito"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.contas.filter(option => option.id === this.state.conta_debito)}
                                    onChange={this.handleChangeContaDebito.bind(this)}
                                    options={this.state.contas}
                                />
                            </div>
                            <div className="form-group col-md-1">
                                <label for="nome" className="mt-3">Cartão</label>
                                <input
                                    onChange={this.handleChangeCartaoInicio.bind(this)}
                                    value={this.state.cartao_inicio}
                                    maxlength="6"
                                    type="text"
                                    className="form-control"
                                    id="cartao_inicio"
                                    placeholder="1-6"
                                />
                            </div>
                            <div className="form-group col-md-1">
                                <label for="nome" className="mt-3">Cartão</label>
                                <input
                                    onChange={this.handleChangeCartaoFim.bind(this)}
                                    value={this.state.cartao_fim}
                                    maxlength="4"
                                    type="text"
                                    className="form-control"
                                    id="cartao_fim"
                                    placeholder="**1234"
                                />
                            </div>

                        </div>
                        <div className="form-row">
                            <div class="form-group row">
                                <div class="form-check">
                                    <label class="form-check-label" for="gridCheck1">Favorita</label>
                                    <input class="form-check-input ml-2" type="checkbox" id="favorita" onChange={this.handleChangeCheckFavorita.bind(this)} checked={this.state.favorita} />
                                </div>
                            </div>
                        </div>
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Limite</label>
                                <input
                                    onChange={this.handleChangeLimite.bind(this)}
                                    value={this.state.limite_disp}
                                    type="text"
                                    className="form-control"
                                    id="cheque_especial_limite"
                                    placeholder="Limite do cheque especial" />
                            </div>
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Dia do fechamento</label>
                                <input
                                    onChange={this.handleChangeDiaFechamento.bind(this)}
                                    value={this.state.dia_fechamento}
                                    type="text"
                                    className="form-control"
                                    id="dia_fechamento"
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label for="nome" className="mt-3">Dia do pagamento</label>
                                <input
                                    onChange={this.handleChangeDiaPagamento.bind(this)}
                                    value={this.state.dia_pagamento}
                                    type="text"
                                    className="form-control"
                                    id="dia_pagamento"
                                />
                            </div>
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                value={this.state.comentarios}
                                className="form-control mt-2 ml-2"
                                rows="2" id="comentarios"
                                onChange={this.handleChangeComentarios.bind(this)}>
                            </textarea>
                        </div>
                    </form>
                </div>
            )
        }
    }

    renderBotao() {
        return (
            <div className="container w-100 mt-1">
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div className="ml-3 form-row">
                        <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.alteraConta() }}>Salvar</button>
                        <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }



    tabelaTipoConta() {
        let url = global.url + 'tabela/tipoConta'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ tiposContas: responseJson.tiposContas })
            })
    }

    tabelaBanco() {
        let url = global.url + 'tabela/banco'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ bancos: responseJson.bancos })
            })
    }

    tabelaBandeira() {
        let url = global.url + 'tabela/bandeira'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ bandeiras: responseJson.bandeiras })
            })
    }

    listaContasCombo() {
        let url = global.url + 'conta/listaCombo'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.retiraCartaoCreditoEcontasFechadas(responseJson.contas)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    retiraCartaoCreditoEcontasFechadas(contasLidas) {

        var contas = []

        contasLidas.map(async (conta, index) => {
            if (conta.tipo_conta !== 'cartaoCredito' && conta.status !== 'fechada') {
                if (conta.id !== this.state.conta.id) {
                    contas.push(conta)
                }
            }
        })

        this.setState({ contas: contas })

    }

    alteraConta() {

        var id_conta = this.state.conta.id
        var id_conta_cartao = 0
        var id_conta_corrente = 0
        var cheque_especial_limite = 0
        var saldo_inicial = 0
        var saldo_inicial_conta_remunerada = 0
        var limite = 0
        var cheque_especial_dia_cobranca = 0

        if (this.state.nome === null || this.state.nome.length === 0) {
            this.setState({ alertaMsg: 'Descrição da conta é obrigatória', alerta: true })
            return null
        }

        if (this.state.nome.length < 3) {
            this.setState({ alertaMsg: 'Descrição deve ter no mínimo 3 letras', alerta: true })
            return null
        }


        if (verificaSeContaExiste(this.state.nome, this.state.contas)) {
            this.setState({ alertaMsg: 'Este nome de conta já existe', alerta: true })
            return null
        }


        if (this.state.tipo_conta === 'contaCorrente') {

            if (this.state.banco === null) {
                this.setState({ alertaMsg: 'O banco é obrigatório para conta corrente', alerta: true })
                return null
            }

            if (this.state.cheque_especial_dia_cobranca < 1 || this.state.cheque_especial_dia_cobranca > 31) {
                cheque_especial_dia_cobranca = 1
            } else {
                cheque_especial_dia_cobranca = this.state.cheque_especial_dia_cobranca
            }

            cheque_especial_limite = formataEmRealParaBD(this.state.cheque_especial_limite_disp)
            saldo_inicial = formataEmRealParaBD(this.state.saldo_inicial_disp)
            saldo_inicial_conta_remunerada = formataEmRealParaBD(this.state.saldo_inicial_conta_remunerada_disp)

            id_conta_corrente = this.state.conta.conta_correntes.id
        }

        if (this.state.tipo_conta === 'contaInvestimento') {

            if (this.state.banco === null) {
                this.setState({ alertaMsg: 'O banco é obrigatório para conta corrente', alerta: true })
                return null
            }

            saldo_inicial = formataEmRealParaBD(this.state.saldo_inicial_disp)
            saldo_inicial_conta_remunerada = formataEmRealParaBD(this.state.saldo_inicial_conta_remunerada_disp)

            id_conta_corrente = this.state.conta.conta_correntes.id
        }

        if (this.state.tipo_conta === 'contaDinheiro') {

            saldo_inicial = formataEmRealParaBD(this.state.saldo_inicial_disp)

        }

        if (this.state.tipo_conta === 'cartaoCredito') {

            if (this.state.bandeira === null) {
                this.setState({ alertaMsg: 'A bandeira é obrigatório para cartão de crédito', alerta: true })
                return null
            }

            if (this.state.conta_debito === null) {
                this.setState({ alertaMsg: 'A conta de débito é obrigatório para cartão de crédito', alerta: true })
                return null
            }

            if (this.state.cartao_inicio !== null) {
                if (this.state.cartao_inicio.length > 0 && this.state.cartao_inicio.length < 6) {
                    this.setState({ alertaMsg: 'Cartão inicial, quando preenchido deve ter 6 posições', alerta: true })
                    return null
                }
            }

            if (this.state.cartao_fim !== null) {
                if (this.state.cartao_fim.length > 0 && this.state.cartao_fim.length < 4) {
                    this.setState({ alertaMsg: 'Cartão final, quando preenchido deve ter 4 posições', alerta: true })
                    return null
                }
            }


            if (this.state.dia_fechamento === null || this.state.dia_fechamento < 1 || this.state.dia_fechamento > 31) {
                this.setState({ alertaMsg: 'O dia fechamento é obrigatório para cartão de crédito', alerta: true })
                return null
            }

            if (this.state.dia_pagamento === null || this.state.dia_pagamento < 1 || this.state.dia_pagamento > 31) {
                this.setState({ alertaMsg: 'O dia pagamento é obrigatório para cartão de crédito', alerta: true })
                return null
            }

            limite = formataEmRealParaBD(this.state.limite_disp)

            id_conta_cartao = this.state.conta.conta_cartoes_creditos.id

        }

        var conta = {
            id_conta: id_conta,
            id_conta_cartao: id_conta_cartao,
            id_conta_corrente: id_conta_corrente,
            nome: this.state.nome,
            tipo_conta: this.state.tipo_conta,
            banco: this.state.banco,
            cheque_especial_limite: cheque_especial_limite,
            cheque_especial_dia_cobranca: cheque_especial_dia_cobranca,
            saldo_inicial: saldo_inicial,
            saldo_inicial_conta_remunerada: saldo_inicial_conta_remunerada,
            possui_conta_remunerada: this.state.possui_conta_remunerada,
            limite: limite,
            bandeira: this.state.bandeira,
            cartao_inicio: this.state.cartao_inicio,
            cartao_fim: this.state.cartao_fim,
            dia_pagamento: this.state.dia_pagamento,
            dia_fechamento: this.state.dia_fechamento,
            conta_debito: this.state.conta_debito,
            favorita: this.state.favorita,
            comentarios: this.state.comentarios
        }

        let url = global.url + this.state.tipo_conta + '/alterar'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(conta)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Alteração concluída...' })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }


}

export default ContaAlteracao