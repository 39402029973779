import React from 'react'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Component } from 'react';
import { formataEmRealParaBD, preencheComboFatura } from '../../util/Utils'
import './style.css'
import ModalAviso from './ModalAviso'

class ModalAlteracaoContaFatura extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: this.props.pagamento,
            alerta: false,
            alertaMsg: null,
            confirma: false,
            contas: [],
            faturas: [],
            faturasLidas: [],
            conta_id: this.props.pagamento.conta_id,
            fatura: this.props.pagamento.fatura.fatura,
            conta: null
        }
    }

    componentWillMount() {
        this.listaContas()
        this.listaFaturas(this.state.conta_id)

        if (this.state.pagamento.fatura_id !== null) {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_vencimento)
            this.setState({ faturas: faturas })
        }
    }

    handleChangeVencimento(data_de_vencimento) {
        
        var dataInicial = moment().add(-1, 'year').format('YYYY-MM-DD')
        var dataFinal = moment().add(1, 'year').format('YYYY-MM-DD')
        var dataDigitada = moment(data_de_vencimento).format('YYYY-MM-DD')

        if (moment(dataDigitada).isAfter(dataFinal)) {
            return
        }

        if (moment(dataDigitada).isBefore(dataInicial)) {
            return
        }


        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.data_de_vencimento = data_de_vencimento
        pagamentoCPY.fatura.fatura = null
        this.setState({ pagamento: pagamentoCPY })

        if (this.state.pagamento.fatura_id !== null) {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_vencimento, this.state.conta.conta_cartoes_creditos.dia_fechamento, this.state.conta.conta_cartoes_creditos.dia_pagamento)
            this.setState({ faturas: faturas })
        }

    }


    handleChangeConta(conta) {

        var contaLe = this.state.contas.filter(option => option.id === conta.id)
        this.setState({conta: contaLe[0]})

        if (conta.tipo_conta === 'cartaoCredito') {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_vencimento, contaLe[0].conta_cartoes_creditos.dia_fechamento, contaLe[0].conta_cartoes_creditos.dia_pagamento)
            this.setState({ faturas: faturas })
            this.listaFaturas(conta.id)
        } else {
            this.setState({ faturas: [], fatura: null })
        }

        this.setState({ conta_id: conta.id })

    }

    handleChangeFatura(fatura) {
      
        var faturaLida = this.state.faturasLidas.find(function (obj) { return obj.fatura === fatura.value })

        if (faturaLida !== undefined && faturaLida.status === 'liquidado') {
            this.setState({ alerta: true, alertaMsg: 'Esta fatura já esta liquidada, não pode ser alterada!!' })
            return
        }

        if (this.state.faturasLidas.length > 0) {
            if (this.state.faturasLidas[0].status === 'liquidado') {
                if (this.state.faturasLidas[0].fatura > fatura.value) {
                    this.setState({ alerta: true, alertaMsg: 'Tentativa de incluir fatura menor que uma fatura já liquidada' })
                    return
                }
            }
        }

        this.setState({ fatura: fatura.value })
    }

    render() {
        if (this.state.alerta) {
            return [this.renderAlert()]
        } else
            if (this.state.alertaSaida) {
                return [this.renderAlertSaida()]
            } else {
                return [this.renderPrincipal()]
            }
    }

    renderPrincipal() {
        return (
            <div className="rgm_modal">
                <div className="card">
                    <div class="card-header bg-info border">
                        <span>Alteração da conta/fatura</span>
                    </div>
                    <form className="bg-light border">
                        <div className="ml-3 form-row" style={{ width: '800px' }}>
                            <div className="col-md-4">
                                <label className="" >Vencimento</label>
                                <DatePicker
                                    className="form-control text-center"
                                    dateFormat="dd/MM/yyyy"
                                    id="dataDeVencimento"
                                    selected={moment(this.state.pagamento.data_de_vencimento).toDate()}
                                    onChange={this.handleChangeVencimento.bind(this)} />
                            </div>
                            <div className="form-group col-md-4">
                                <label for="categoria" className="">Conta</label>
                                <Select
                                    id="conta"
                                    name="conta"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    selectedValue={this.state.conta_id}
                                    value={this.state.contas.filter(option => option.id === this.state.conta_id)}
                                    onChange={this.handleChangeConta.bind(this)}
                                    options={this.state.contas}
                                />
                            </div>
                            {this.state.faturas.length !== 0 &&
                                <div className="form-group col-md-3 mr-1">
                                    <label for="subCategoria" className="">Fatura</label>
                                    <Select
                                        id="fatura"
                                        name="fatura"
                                        value={this.state.faturas.filter(option => option.value === this.state.fatura)}
                                        onChange={this.handleChangeFatura.bind(this)}
                                        options={this.state.faturas}
                                    />
                                </div>
                            }
                        </div>
                    </form>
                    <div class="card-footer">
                        <button type="button" className="btn btn-primary btn-sm" aria-label="Close" onClick={() => this.altera()}>Confirma</button>
                        <button type="button" className="btn btn-danger ml-2 btn-sm" aria-label="Close" onClick={() => this.props.cancela()}>Cancela</button>
                    </div>
                </div>
            </div>)
    }

    renderAlert() {
        return (
            <>
                <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
            </>
        )
    }

    renderAlertSaida() {
        return (
            <>
                <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
            </>
        )
    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contas: responseJson.contas })
                    var conta = responseJson.contas.filter(option => option.id === this.state.conta_id)
                    this.setState({conta: conta[0]})
                    var faturas = preencheComboFatura(this.state.pagamento.data_de_vencimento, conta[0].conta_cartoes_creditos.dia_fechamento,  conta[0].conta_cartoes_creditos.dia_pagamento)
                    this.setState({faturas: faturas})
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaFaturas(conta_id) {
        let url = global.url + 'cartaoCredito/listaFaturas/' + conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({
                        faturasLidas: responseJson.faturas
                    })
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    altera() {

        if (formataEmRealParaBD(this.state.pagamento.valor) === 0) {
            this.setState({ alerta: true, alertaMsg: 'Valor obrigatório' })
            return
        }

        if (moment(this.state.pagamento.data_de_vencimento).format('YYYY-MM-DD') === 'Invalid date') {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento valida é obrigatória' })
            return
        }

        if (this.state.pagamento.descricao === null || this.state.pagamento.descricao.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Descrição deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.pagamento.categorias.length === 0) {
            this.setState({ alerta: true, alertaMsg: 'Categoria/Subcategoria obrigatória' })
            return
        }

        if (this.state.pagamento.conta_id === null) {
            this.setState({ alerta: true, alertaMsg: 'Conta obrigatória' })
            return
        }

        var categorias = []

        this.state.pagamento.categorias.map(async (categoria, index) => {
            if (categoria.valor === null) {
                categoria.valor = formataEmRealParaBD(this.state.pagamento.valor)
            } else {
                categoria.valor = formataEmRealParaBD(categoria.valor)
            }

            if (categoria.descricao === null) {
                categoria.descricao = this.state.pagamento.descricao
            }

            if (categoria.entra_nas_estatisticas !== true && categoria.entra_nas_estatisticas !== false) {
                categoria.entra_nas_estatisticas = true
            }

            categorias.push(categoria)
        })

        var pagamento = {
            descricao: this.state.pagamento.descricao,
            valor: formataEmRealParaBD(this.state.pagamento.valor),
            parcela: this.state.pagamento.parcela,
            status: 'emAberto',
            data_de_vencimento_ano_mes: moment(this.state.pagamento.data_de_vencimento).format('YYYY-MM-01'),
            data_de_vencimento: moment(this.state.pagamento.data_de_vencimento).format('YYYY-MM-DD'),
            data_de_pagamento: null,
            frequencia: this.state.pagamento.frequencia,
            numero_de_parcelas: this.state.pagamento.numero_de_parcelas,
            tipo: this.state.pagamento.tipo,
            receberei_reembolso: this.state.pagamento.receberei_reembolso,
            pagarei_reembolso: this.state.pagamento.pagarei_reembolso,
            pagamento_id_reembolso: this.state.pagamento.pagamento_id_reembolso,
            comentarios: this.state.pagamento.comentarios,
            conta_id: this.state.pagamento.conta_id,
            transacao_id: this.state.pagamento.transacao_id,
            fatura: this.state.pagamento.fatura,
            categorias: categorias,
            para: { conta: this.state.conta_id, fatura: this.state.fatura }
        }

        var url = global.url + 'fatura/alteracaoDaContaFatura'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Mudança de conta/fatura realizada' })
                } else {
                    this.setState({ alerta: true, mensagem: responseJson.mensagem })
                }
            })
    }


}

export default ModalAlteracaoContaFatura