import React from 'react'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import { Component } from 'react';
import { formataDisplayEmReal, formataEmRealParaBD, preencheComboFatura } from '../../util/Utils'
import './style.css'
import ModalAviso from './ModalAviso'

var timeOut = 3000

class ModalPagamentoAvulso extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamentos: [],
            pagamento: this.props.pagamento,
            conta: { descricao: '' },
            contaCredito: { descricao: '' },
            alerta: false,
            alertaMsg: null,
            confirma: false
        }
    }

    componentWillMount() {
        this.obtemPagamentosDeUmaTransacao(this.props.pagamento.transacao_id)
        //this.acessaConta(this.props.pagamento.conta_id)
    }



    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {

        return (
            <div className="rgm_modal_fast">

                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Pagamento avulso</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-5">
                            <label for="descricao">Descrição</label>
                            <input
                                isDisabled="true"
                                data-toggle="tooltip" data-placement="top"
                                title="ex: Conta de luz, Plano de saúde, etc.."
                                value={this.state.pagamento.descricao}
                                type="text"
                                maxlength="50"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                        <div className="form-group col-md-3">
                            <label for="valor">Valor</label>
                            <input
                                isDisabled="true"
                                value={formataDisplayEmReal(this.state.pagamento.valor)}
                                type="valor"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                        <div className="col-md-2">
                            <label className="" >Vencimento</label>
                            <DatePicker
                                isDisabled="true"
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeVencimento"
                                selected={moment(this.state.pagamento.data_de_vencimento).toDate()} />
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        <div className="col-md-5">
                            <div className="form-group">
                                <label for="descricao">Conta débito</label>
                                <input
                                    isDisabled="true"
                                    data-toggle="tooltip" data-placement="top"
                                    title="ex: Conta de luz, Plano de saúde, etc.."
                                    value={this.state.conta.nome}
                                    type="text"
                                    maxlength="50"
                                    className="form-control"
                                    id="descricao"
                                    placeholder="Conta débito" />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="form-group">
                                <label for="descricao">Conta Credito</label>
                                <input
                                    isDisabled="true"
                                    data-toggle="tooltip" data-placement="top"
                                    title="ex: Conta de luz, Plano de saúde, etc.."
                                    value={this.state.contaCredito.nome}
                                    type="text"
                                    maxlength="50"
                                    className="form-control"
                                    id="descricao"
                                    placeholder="Conta débito" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row ml-2">
                            {this.props.operacao === 'altera' &&
                                <button type="button" className="btn btn-primary btn-sm" aria-label="Close" onClick={() => this.excluiPagamentoAvulso()}>Exclui</button>
                            }
                            <button type="button" className="btn btn-primary ml-2 btn-sm" aria-label="Close" onClick={() => this.props.volta()}>Volta</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} timeOut={timeOut} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} timeOut={timeOut} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    obtemPagamentosDeUmaTransacao(transacao_id) {
        let url = global.url + 'pagamento/obtemPagamentosDeUmaTransacao/' + transacao_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.acessaContaCredito(responseJson.pagamentos)
                    this.acessaContaDebito(responseJson.pagamentos)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    acessaContaCredito(pagamentos) {

        var pagamentoCredito = null

        pagamentos.map(async (pagamento, index) => {
            if (pagamento.tipo === 'credito') {
                pagamentoCredito = pagamento
            }
        })

        let url = global.url + 'conta/acessaConta/' + pagamentoCredito.conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contaCredito: responseJson.conta })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    acessaContaDebito(pagamentos) {

        var pagamentoDebito = null

        pagamentos.map(async (pagamento, index) => {
            if (pagamento.tipo === 'debito') {
                pagamentoDebito = pagamento
            }
        })

        let url = global.url + 'conta/acessaConta/' + pagamentoDebito.conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ conta: responseJson.conta })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    acessaConta(conta_id) {
        let url = global.url + 'conta/acessaConta/' + conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ conta: responseJson.conta })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }


    excluiPagamentoAvulso() {

        let url = global.url + 'transferencia/excluiPagamentoAvulso/' + this.state.pagamento.transacao_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Pagamento avulso excluído...' })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }


}

export default ModalPagamentoAvulso