import React, { Component } from 'react'
import ModalAviso from '../modal/ModalAviso'
import { verificaSeCategoriaExiste } from '../categoria/CategoriaUtil'


class ModalInclusaoRapidaCategoria extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nome: '',
            tipo: 'debito',
            checkDebi: true,
            checkCred: false,
            categorias: []
        }
    }

    componentWillMount() {
        this.listaCategorias()
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ categorias: responseJson.categorias })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    incluiCategoria() {

        if (this.state.nome.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Categoria deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.nome === 'limpa' || this.state.nome === 'limpaTudo') {
            alert('vai limpar dados da sua conta')
        } else {
            if (verificaSeCategoriaExiste(this.state.nome, this.state.categorias)) {
                this.setState({ alerta: true, alertaMsg: 'Esta descrição de categoria já existe' })
                return
            }
        }

        let url = global.url + 'categoria/incluir'

        if (this.state.nome === 'limpa' || this.state.nome === 'limpaTudo') {
            url = global.url + 'wteste/limpa'
        }

        const nome = this.state.nome[0].toUpperCase() + this.state.nome.substr(1)
        var categoria = { nome: nome, tipo: this.state.tipo }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(categoria)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Inclusão concluida...' })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    handleChangeNome(event) {

        this.setState({ nome: event.target.value })
    }

    handleChangeDebito(event) {
        this.setState({ checkCred: false, checkDebi: true, tipo: 'debito' })
    }

    handleChangeCredito(event) {
        this.setState({ checkCred: true, checkDebi: false, tipo: 'credito' })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {
        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Inclusão de Categoria</h5>
                        </div>
                    </div>
                    <div className="form-group row m-3">
                        <label for="categoria" className="col-sm-2 col-form-label">Categoria</label>
                        <div className="col-sm-5">
                            <input type="text" maxlength="40" className="form-control text-truncate" id="categoria" onChange={this.handleChangeNome.bind(this)} value={this.state.nome} />
                        </div>
                    </div>
                    <fieldset className="form-group">
                        <div className="row m-3">
                            <legend className="col-form-label col-sm-2 pt-0">Tipo</legend>
                            <div className="col-sm-5">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="rdDebito"
                                        id="debito"
                                        value="debito"
                                        checked={this.state.checkDebi}
                                        onChange={this.handleChangeDebito.bind(this)} />
                                    <label className="form-check-label" for="debito">Débito</label>
                                </div>
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="rdCredito"
                                        id="credito"
                                        value="credito"
                                        checked={this.state.checkCred}
                                        onChange={this.handleChangeCredito.bind(this)} />
                                    <label className="form-check-label" for="credito">Crédito</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.incluiCategoria() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

}

export default ModalInclusaoRapidaCategoria