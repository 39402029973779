import React, { Component } from 'react'
import { extensoTipo } from '../../util/extenso'

class CategoriaLista extends Component {
    constructor(props) {
        super(props)

        this.state = {
            categorias: [],
        }
    }

    componentWillMount() {
        this.listaCategorias();
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    selecionaCategorias(categoriasLista) {

        var result = false

        var categorias = categoriasLista.filter((obj) => {
            if (obj.tipo === 'credito' || obj.tipo === 'debito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }
 

            return result

        })

        categorias.sort((a, b) => {
            var compA = a.tipo + a.nome
            var compB = b.tipo + b.nome
            if (compA < compB) {
                return -1
            } else {
                return 0
            }
        })

        this.setState({ categorias: categorias })
    }

    buttonClickedPencil(categoria) {
        this.props.categoriaAlteracao(categoria)
    }

    buttonClickedSubCategoria(categoria) {
        this.props.subCategoriaLista(categoria)
    }

    corCategoria(categoria) {
        return 'text-white'
        
        if (categoria.tipo === 'debito') {
            return 'text-danger'
        } else {
            return 'text-primary'
        }
    }

    obtemNumeroSubcategorias(categoria) {

        return categoria.sub_categorias.length

    }

    render() {
        return (
            <div className="container w-100 mt-5"    >
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.categoriaInclusao()}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;categorias</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.categoriaMover()}>Mover</button>
                <div className="card  bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle m-2">
                            <h5>Lista de categorias</h5>
                        </div>
                    </div>
                    <table className="table table-bordered mt-0">
                        <thead>
                            <tr className="bg-info">
                                <th className="border" scope="col">Subcategoria</th>
                                <th className="border" scope="col">Categoria</th>
                                <th className="border" scope="col">Tipo</th>
                                <th className="border" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody className="rgm-bg-azul-esverdiado">
                            {this.state.categorias.map((categoria, index) => {
                                return (
                                    <tr className="rgm-bg-azul-esverdiado">
                                        <td className="border align-middle"><button className="btn btn-link btn-sm m-2" onClick={() => this.buttonClickedSubCategoria(categoria)}><ins className="font-italic rgm-cor-link font-weight-bold">Lista Subcategorias</ins></button></td>
                                        <td className="align-middle text-white">{categoria.nome}</td>
                                        <td className={"border align-middle text-white " + this.corCategoria(categoria)}>{extensoTipo(categoria.tipo)}</td>
                                        <td className="border align-middle text-white text-center" onClick={() => this.buttonClickedPencil(categoria)}>
                                            <i className="btn btn-link btn-sm m-2 fa fa-pencil rgm-cor-link"></i>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}



export default CategoriaLista
