import React, { Component } from 'react';
import dotenv from 'dotenv'
import '../src/rgm.css'
import Login from '../src/ui/login/Login'
import UsuarioNew from '../src/ui/usuario/UsuarioNew'
import Menu from '../src/ui/menu/Menu'
import Apresentacao from './ui/apresentacao/Apresentacao';

dotenv.config()

global.telaAssinatura = 0
global.url = process.env.REACT_APP_GLOBAL_URL
global.urlInicio = process.env.REACT_APP_GLOBAL_URL_INICIO
global.pagamentosPrincipal = () => { }
global.pesquisasPrincipal = () => { }
global.contasPrincipal = () => { }
global.amigosPrincipal = () => { }
global.categoriasPrincipal = () => { }
global.eventosPrincipal = () => { }
global.orcamentosPrincipal = () => { }
global.relatoriosPrincipal = () => { }
global.usuarioEmail = ''

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      logged: false,
      newUser: false,
      esqueceuSenha: false,
      apresentacao: true
    }
  }

  offLogged() {
    this.setState({ logged: false })
    this.setState({ newUser: false })
  }

  render() {
    return (
      this.renderBody()
    )
  }

  renderBody() {

    if (this.state.logged === false && this.state.newUser === false && this.state.apresentacao === false) {
      return (
        <Login
          onLogged={() => {
            this.setState({ logged: true })
          }}
          onNewUser={() => {
            this.setState({ newUser: true })
          }}
          chamaLogin={() => { this.setState({ apresentacao: false }) }}
          onEsqueceuSenha={() => {
            this.setState({ esqueceuSenha: true })
          }} />
      )
    }

    if (this.state.newUser === true) {
      return (
        <UsuarioNew offLogged={() => {
          this.offLogged()
        }}
        />
      )
    }

    if (this.state.apresentacao === true) {
      return (
        <Apresentacao chamaLogin={() => { this.setState({ apresentacao: false }) }} />
      )
    }

    if (this.state.logged === true) {
      return (
        <Menu
          offLogOff={() => {
            this.setState({ logged: false, newUser: false, apresentacao: true })
          }}
        />
      )
    }

  }
}

export default App