import React from 'react'
import moment from 'moment'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import { Component } from 'react';
import { formataDisplayEmReal, formataEmRealParaBD, preencheComboFatura } from '../../util/Utils'
import './style.css'
import ModalAviso from './ModalAviso'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}

var consideraNosRelatorios = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' }
]

var timeOut = 3000

var tipos = [
    { value: 'debito', label: 'Débito' },
    { value: 'credito', label: 'Crédito' }
]

var categoriaInicial = {
    categoria_id: null,
    sub_categoria_id: null,
    amigo_id: null,
    evento_id: null,
    descricao: null,
    tipo: null,
    comentarios: null,
    valor: 'R$ 0,00',
    entra_nas_estatisticas: true
}

class ModalInclusaoRapidaLancamentoContaCorrente extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: {
                descricao: null,
                valor: 'R$ 0,00',
                status: null,
                data_de_vencimento_ano_mes: null,
                data_de_vencimento: moment().toDate(),
                data_de_pagamento: null,
                frequencia: 'unica',
                numero_de_parcelas: null,
                tipo: 'debito',
                receberei_reembolso: false,
                pagarei_reembolso: false,
                pagamento_id_reembolso: null,
                comentarios: null,
                conta_id: this.props.conta_id,
                fatura: null,
                categorias: [],
                alertaSaida: false
            },
            amigos: [],
            eventos: [],
            categorias: [],
            subCategorias: [],
            categoria: JSON.parse(JSON.stringify(categoriaInicial)),
            alerta: false,
            alertaMsg: null,
            confirma: false
        }
    }

    componentWillMount() {
        this.listaCategorias()
        this.acessaConta(this.props.conta_id)
        this.listaAmigos()
        this.listaEventos()
    }

    listaAmigos() {
        let url = global.url + 'amigo/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var amigo = {
                        id: null,
                        nome: '------'
                    }
                    responseJson.amigos.push(amigo)
                    this.setState({ amigos: responseJson.amigos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaEventos() {
        let url = global.url + 'evento/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var evento = {
                        id: null,
                        descricao: '------'
                    }
                    responseJson.eventos.push(evento)
                    this.setState({ eventos: responseJson.eventos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    handleChangeDescricao(event) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.descricao = event.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeValor(event) {
        var valor = event.target.value

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.valor = formataDisplayEmReal(valor)

        if (formataEmRealParaBD(pagamentoCPY.valor) === 0) {
            pagamentoCPY.categorias = []
            this.setState({ categoria: JSON.parse(JSON.stringify(categoriaInicial)) })
        }

        this.setState({ pagamento: pagamentoCPY })

    }

    handleChangeTipo(tipo) {
        var pagamentoCPY = this.state.pagamento

        pagamentoCPY.tipo = tipo.value
        pagamentoCPY.pagarei_reembolso = false
        pagamentoCPY.receberei_reembolso = false
        pagamentoCPY.pagamento_id_reembolso = null
        
        pagamentoCPY.categorias = []
        
        this.setState({ pagamento: pagamentoCPY })

        if (tipo.value === 'credito') {
            this.setState({ categorias: this.state.categoriasCredito, categoria: JSON.parse(JSON.stringify(categoriaInicial)) })
        } else {
            this.setState({ categorias: this.state.categoriasDebito, categoria: JSON.parse(JSON.stringify(categoriaInicial)) })
        }

    }

    handleChangeVencimento(data_de_vencimento) {
        var dataInicial = moment().add(-1, 'year').format('YYYY-MM-DD')
        var dataFinal = moment().add(1, 'year').format('YYYY-MM-DD')
        var dataDigitada = moment(data_de_vencimento).format('YYYY-MM-DD')

        if (moment(dataDigitada).isAfter(dataFinal)) {
            return
        }

        if (moment(dataDigitada).isBefore(dataInicial)) {
            return
        }

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.data_de_vencimento = data_de_vencimento
        pagamentoCPY.fatura = null
        this.setState({ pagamento: pagamentoCPY })

        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_vencimento)
            this.setState({ faturas: faturas })
        }
    }

    handleChangeCategoria(categoria) {
        var categoriaCPY = this.state.categoria

        categoriaCPY.categoria_id = categoria.id
        categoriaCPY.sub_categoria_id = null
        categoriaCPY.amigo_id = null
        categoriaCPY.descricao = this.state.pagamento.descricao
        categoriaCPY.tipo = this.state.pagamento.tipo
        categoriaCPY.valor = this.state.pagamento.valor

        this.setState({
            categoria: categoriaCPY,
            subCategorias: categoria.sub_categorias
        })
    }

    handleChangeSubCategoria(subCategoria) {
        var categoriaCPY = this.state.categoria
        categoriaCPY.sub_categoria_id = subCategoria.id
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeAmigo(amigo) {
        // var categoria = this.state.categoria
        // categoria.amigo_id = amigo.id
        // this.setState({ categoria: categoria })


        var categoriaCPY = this.state.categoria
        categoriaCPY.amigo_id = amigo.id
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeEvento(evento) {
        // var categoria = this.state.categoria
        // categoria.evento_id = evento.id
        // this.setState({ categoria: categoria })

        var categoriaCPY = this.state.categoria
        categoriaCPY.evento_id = evento.id
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeConsideraNosRelatorios(event) {
        // var categoria = this.state.categoria
        // categoria.entra_nas_estatisticas = event.value
        // this.setState({ categoria: categoria })


        var categoriaCPY = this.state.categoria
        categoriaCPY.entra_nas_estatisticas = event.value
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {
        var mostraAmigo = true

        if (this.state.amigos.length === 1) {
            mostraAmigo = false
        }

        if (this.state.pagamento.categorias.length !== 1) {
            mostraAmigo = false
        }

        var mostrarEvento = true

        if (this.state.eventos.length === 1) {
            mostrarEvento = false
        }

        if (this.state.pagamento.categorias.length !== 1) {
            mostrarEvento = false
        }

        var mostrarEntraNosRelatorios = true

        if (this.state.pagamento.categorias.length !== 1) {
            mostrarEntraNosRelatorios = false
        }


        return (
            <div className="rgm_modal_fast">

                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Inclusão rápida de lançamento</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-2">
                            <label for="tipo" className="">Tipo</label>
                            <Select
                                styles={customStyles}
                                id="tipo"
                                name="tipo"
                                value={tipos.filter(option => option.value === this.state.pagamento.tipo)}
                                onChange={this.handleChangeTipo.bind(this)}
                                options={tipos}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label for="valor">Valor</label>
                            <input
                                onChange={this.handleChangeValor.bind(this)}
                                value={this.state.pagamento.valor}
                                type="valor"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                        <div className="col-md-2">
                            <label className="" >Vencimento</label>
                            <DatePicker
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeVencimento"
                                selected={this.state.pagamento.data_de_vencimento}
                                onChange={this.handleChangeVencimento.bind(this)} />
                        </div>
                        <div className="form-group col-md-5">
                            <label for="descricao">Descrição</label>
                            <input
                                data-toggle="tooltip" data-placement="top"
                                title="ex: Conta de luz, Plano de saúde, etc.."
                                onChange={this.handleChangeDescricao.bind(this)}
                                value={this.state.pagamento.descricao}
                                type="text"
                                maxlength="50"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        <div className="form-group col-md-4">
                            <label for="categoria" className="">Categoria</label>
                            <Select
                                styles={customStyles}
                                id="categoria"
                                name="categoria"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                value={this.state.categorias.filter(option => option.id === this.state.categoria.categoria_id)}
                                onChange={this.handleChangeCategoria.bind(this)}
                                options={this.state.categorias}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="subCategoria" className="">Subcategoria</label>
                            <Select
                                styles={customStyles}
                                id="subCategoria"
                                name="subCategoria"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                value={this.state.subCategorias.filter(option => option.id === this.state.categoria.sub_categoria_id)}
                                onChange={this.handleChangeSubCategoria.bind(this)}
                                options={this.state.subCategorias}
                            />
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        {mostraAmigo === true &&
                            <div className="form-group col-md-4">
                                <label for="amigo" className="">Amigo</label>
                                <Select
                                    styles={customStyles}
                                    id="amigo"
                                    name="amigo"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.amigos.filter(option => option.id === this.state.categoria.amigo_id)}
                                    onChange={this.handleChangeAmigo.bind(this)}
                                    options={this.state.amigos}
                                />
                            </div>
                        }
                        {mostrarEvento === true &&
                            <div className="form-group col-md-4">
                                <label for="evento" className="">Evento</label>
                                <Select
                                    styles={customStyles}
                                    id="evento"
                                    name="evento"
                                    getOptionLabel={({ descricao }) => descricao}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.eventos.filter(option => option.id === this.state.categoria.evento_id)}
                                    onChange={this.handleChangeEvento.bind(this)}
                                    options={this.state.eventos}
                                />
                            </div>
                        }
                        {mostrarEntraNosRelatorios === true &&
                            <div className="form-group col-md-3">
                                <label for="tipo" className="">Considerar nos relatórios</label>
                                <Select
                                    styles={customStyles}
                                    id="consideraNoRelatorio"
                                    name="consideraNoRelatorio"
                                    value={consideraNosRelatorios.filter(option => option.value === this.state.categoria.entra_nas_estatisticas)}
                                    onChange={this.handleChangeConsideraNosRelatorios.bind(this).bind(this)}
                                    options={consideraNosRelatorios}
                                />
                            </div>
                        }
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row ml-2">
                            <button type="button" className="btn btn-primary btn-sm" aria-label="Close" onClick={() => this.incluiPagamento()}>Confirma</button>
                            <button type="button" className="btn btn-danger ml-2 btn-sm" aria-label="Close" onClick={() => this.props.volta()}>Cancela</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} timeOut={timeOut} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} timeOut={timeOut} onClose={() => this.props.volta()} />
                </>
            )
        }
    }


    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    acessaConta(conta_id) {
        let url = global.url + 'conta/acessaConta/' + conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ conta: responseJson.conta })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    selecionaCategorias(categoriasLista) {

        this.setState({ categoriasDivisaoTotal: JSON.parse(JSON.stringify(categoriasLista)) })
        this.setState({ categoriasTodas: JSON.parse(JSON.stringify(categoriasLista)) })

        var result = false

        var categoriasDebito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'debito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        var categoriasCredito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'credito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        this.setState({ categoriasDebito: categoriasDebito, categorias: categoriasDebito })
        this.setState({ categoriasCredito: categoriasCredito })

        this.setState({ categoriasDivisaoDebito: categoriasDebito })
        this.setState({ categoriasDivisaoCredito: categoriasCredito })

    }

    incluiPagamento() {

        if (formataEmRealParaBD(this.state.pagamento.valor) === 0) {
            this.setState({ alerta: true, alertaMsg: 'Valor obrigatório' })
            return
        }

        if (moment(this.state.pagamento.data_de_vencimento).format('YYYY-MM-DD') === 'Invalid date') {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento valida é obrigatória' })
            return
        }

        if (this.state.pagamento.descricao === null || this.state.pagamento.descricao.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Descrição deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.pagamento.categorias.length === 0) {
            this.setState({ alerta: true, alertaMsg: 'Categoria/Subcategoria obrigatória' })
            return
        }

        if (this.state.pagamento.conta_id === null) {
            this.setState({ alerta: true, alertaMsg: 'Conta obrigatória' })
            return
        }

        var categorias = []

        this.state.pagamento.categorias.map(async (categoria, index) => {
            categoria.valor = formataEmRealParaBD(this.state.pagamento.valor)

            if (categoria.descricao === null) {
                categoria.descricao = this.state.pagamento.descricao
            }

            if (categoria.entra_nas_estatisticas !== true && categoria.entra_nas_estatisticas !== false) {
                categoria.entra_nas_estatisticas = true
            }

            categorias.push(categoria)
        })

        var pagamento = {
            descricao: this.state.pagamento.descricao,
            valor: formataEmRealParaBD(this.state.pagamento.valor),
            status: 'emAberto',
            data_de_vencimento_ano_mes: moment(this.state.pagamento.data_de_vencimento).format('YYYY-MM-01'),
            data_de_vencimento: moment(this.state.pagamento.data_de_vencimento).format('YYYY-MM-DD'),
            data_de_pagamento: null,
            frequencia: this.state.pagamento.frequencia,
            numero_de_parcelas: this.state.pagamento.numero_de_parcelas,
            tipo: this.state.pagamento.tipo,
            receberei_reembolso: this.state.pagamento.receberei_reembolso,
            pagarei_reembolso: this.state.pagamento.pagarei_reembolso,
            pagamento_id_reembolso: this.state.pagamento.pagamento_id_reembolso,
            comentarios: this.state.pagamento.comentarios,
            conta_id: this.state.pagamento.conta_id,
            fatura: this.state.pagamento.fatura,
            categorias: categorias
        }

        var url = global.url + 'pagamento/incluirDiretoNaConta'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ resp: responseJson.resp })
                    timeOut = 3000
                    this.setState({ alertaSaida: true, alertaMsg: 'Inclusão efetuada com sucesso!!' })
                } else {
                    timeOut = 13000
                    this.setState({ alertaSaida: true, alertaMsg: responseJson.mensagem })
                }
            })
    }


}

export default ModalInclusaoRapidaLancamentoContaCorrente