import React, { Component } from 'react'
import moment from 'moment'
import '../loader.css'
import { formataDisplayEmReal } from '../../util/Utils'

class GastosPorCategoria extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoriasDebito: [],
            categorias: [],
            loader: true,
            sortTipo: 'percentual'
        }
    }

    componentWillMount() {
        this.listaCategorias()
    }

    obtemMovimentacaoDeUmMes(dataRequerida) {
        let url = global.url + 'pagamento/obtemMovimentacaoDeUmMes/' + dataRequerida
        this.setState({ mesCorrenteExtenso: moment(dataRequerida).format('MMMM/YYYY') })

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustasPagamentos(responseJson.pagamentos)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    ajustasPagamentos(pagamentosTotal) {

        var categoriasGeral = []

        pagamentosTotal.map(async (pagamento, index) => {
            if (pagamento.fatura === null) {
                pagamento.categorias.map(async (categoria, index) => {
                    if (categoria.entra_nas_estatisticas === true) {
                        if (categoria.tipo === 'debito') {
                            categoriasGeral.push(categoria)
                        }
                    }
                })
            }
        })

        pagamentosTotal.map(async (pagamento, index) => {
            //if (pagamento.sub_categoria_nome_interno !== 'fatura') {
            if (pagamento.sub_categoria_nome_interno === 'fatura') {
                if (pagamento.fatura !== null) {
                    pagamento.fatura.pagamentos.map(async (pagamentos, index3) => {
                        pagamentos.categorias.map(async (categoria, index) => {
                            if (categoria.entra_nas_estatisticas === true) {
                                if (categoria.tipo === 'debito') {
                                    categoriasGeral.push(categoria)
                                }
                            }
                        })
                    })
                }
            }
        })

        var categorias = []
        var total = 0

        categoriasGeral.map(async (categoria, index) => {
            var indexFind = categorias.findIndex(obj => (
                obj.categoria_id === categoria.categoria_id &&
                obj.sub_categoria_id === categoria.sub_categoria_id
            ))
            if (indexFind === -1) {
                var categoriaLida = this.state.categoriasDebito.find(function (obj) { return obj.id === categoria.categoria_id })
                var subCategoriaLida = categoriaLida.sub_categorias.find(function (obj) { return obj.id === categoria.sub_categoria_id })
                categoria.categoria_descricao = categoriaLida.nome
                categoria.sub_categoria_descricao = subCategoriaLida.nome
                var valT = parseFloat(categoria.valor)
                total = total + valT
                categorias.push(categoria)
            } else {
                var valA = parseFloat(categorias[indexFind].valor)
                var valB = parseFloat(categoria.valor)
                total = total + valB
                categorias[indexFind].valor = valA + valB
                categorias[indexFind].valor = parseFloat(categorias[indexFind].valor).toFixed(2)
            }
        })

        var categoriasComPercentual = []
        categorias.map(async (categoria, index) => {
            var perc = (categoria.valor / total) * 100
            perc = parseFloat(perc).toFixed(2)
            var newPerc = perc.toString().replace('.', ',')
            categoria.percNum = perc
            categoria.perc = newPerc
            categoriasComPercentual.push(categoria)
        })

        var categoriasSorteadas = categoriasComPercentual.sort(function (a, b) {
            if (parseFloat(a.percNum) > parseFloat(b.percNum)) {
                return -1
            }

            if (a.percNum < b.percNum) {
                return 1
            }

            return 0
        })

        var categoriasSorteadasPorCategoriaAx = JSON.parse(JSON.stringify(categoriasComPercentual))

        var categoriasSorteadasPorCategoria = categoriasSorteadasPorCategoriaAx.sort(function (a, b) {

            var sortA = a.categoria_descricao + a.sub_categoria_descricao
            var sortB = b.categoria_descricao + b.sub_categoria_descricao

            if (sortA > sortB) {
                return 1
            }

            if (sortA < sortB) {
                return -1
            }

            return 0
        })


        total = total.toFixed(2)

        this.setState({
            categorias: categoriasSorteadas,
            categoriasSorteadasPorPerc: categoriasSorteadas,
            categoriasSorteadasPorCategoria: categoriasSorteadasPorCategoria,
            total: total
        })

        this.setState({ loader: false })



    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    selecionaCategorias(categoriasLista) {

        var result = false

        var categoriasDebito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'debito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }

            return result

        })

        this.setState({ categoriasDebito: categoriasDebito })

        this.obtemMovimentacaoDeUmMes(this.props.getMesAtual)

    }

    mudaSort() {
        if (this.state.sortTipo === 'percentual') {
            this.setState({ sortTipo: 'categoria', categorias: this.state.categoriasSorteadasPorCategoria })
        } else {
            this.setState({ sortTipo: 'percentual', categorias: this.state.categoriasSorteadasPorPerc })
        }
    }


    render() {
        if (this.state.loader === true) {
            return [
                this.renderLoad()
            ]
        } else {
            return [
                this.renderComum()
            ]
        }
    }

    renderComum() {
        return (
            <div className="container w-100 mt-5"    >
                <nav className="navbar navbar-expand-md navbar-dark bg-info">
                    <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                        <ul className="navbar-nav">
                            <li className="nav-item active">
                                <button type="button" className="nav-link btn btn-link" onClick={() => { this.props.volta() }}>Voltar</button>
                            </li>
                        </ul>
                    </div>
                </nav>

                <div className="card bg-transparent mt-1 text-white">
                    <div className="card bg-transparent mt-1">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>Relatório | Gastos por categoria | {moment(this.props.getMesAtual).format('MMMM/YYYY')}</h5>
                            </div>
                        </div>
                        <table className="table table-bordered">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" onClick={() => this.mudaSort()}>Categoria</th>
                                    <th scope="col">Subcategoria</th>
                                    <th scope="col" className="text-right">%</th>
                                    <th scope="col" className="text-right">Valor</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.categorias.map((categoria, index) => {
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado">
                                            <td>{categoria.categoria_descricao}</td>
                                            <td>{categoria.sub_categoria_descricao}</td>
                                            <td className="text-right">{categoria.perc}%</td>
                                            <td className="text-right">{formataDisplayEmReal(categoria.valor)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                            <thead>
                                <tr className="bg-info">
                                    <th className="text-center align-middle" scope="col" COLSPAN="2"><h5>Total</h5></th>
                                    <th scope="col" className="text-right align-middle">100,00%</th>
                                    <th scope="col" className="text-right align-middle">{formataDisplayEmReal(this.state.total)}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderLoad() {
        return (
            <div className="container w-100 mt-5"    >
                <div className="card-header bg-info border mt-2">
                    <h7>Lista de pagamentos</h7>
                </div>
                <div className="loader text-center mt-5"></div>
                <span className="text-warning">Aguarde... Carregando...</span>
            </div>
        )
    }
}

export default GastosPorCategoria