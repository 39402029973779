import React, { Component } from 'react'
import Select from 'react-select'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}

class CategoriaMover extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alerta: false,
            alertaSaida: false,
            alertaMsg: null,
            pedeConfirmacao: false,
            muda: {
                categoriaDe: { categoria: null, subcategoria: null },
                categoriaPara: { categoria: null, subcategoria: null }
            },
            categoriasDe: [],
            subCategoriasDe: [],
            categoriasPara: [],
            subCategoriasPara: [],
            mudancaTipo: null
        }
    }

    componentWillMount() {
        this.listaCategorias()
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ categoriasDe: responseJson.categorias })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    handleChangeCategoriaDe(categoria) {
        var mudaCPY = this.state.muda

        var categoriasPara = this.state.categoriasPara
        var subCategoriasPara = this.state.subCategoriasPara

        mudaCPY.categoriaDe.categoria = categoria.id
        mudaCPY.categoriaDe.subcategoria = null

        if (this.state.mudancaTipo !== categoria.tipo) {
            mudaCPY.categoriaPara.categoria = null
            mudaCPY.categoriaPara.subcategoria = null
            categoriasPara = this.state.categoriasDe
            subCategoriasPara = []
        }

        this.setState({
            muda: mudaCPY,
            subCategoriasDe: categoria.sub_categorias,
            categoriasPara: categoriasPara,
            subCategoriasPara: subCategoriasPara,
            mudancaTipo: categoria.tipo
        })

    }

    handleChangeSubCategoriaDe(subCategoria) {
        var mudaCPY = this.state.muda

        mudaCPY.categoriaDe.subcategoria = subCategoria.id

        this.setState({ muda: mudaCPY })


    }

    handleChangeCategoriaPara(categoria) {
        if (categoria.tipo !== this.state.mudancaTipo) {
            this.setState({ alerta: true, alertaMsg: 'A troca de categoria deve ser do mesmo tipo, crédito para crédito, ou débito para débito' })
            return
        }

        var mudaCPY = this.state.muda

        mudaCPY.categoriaPara.categoria = categoria.id
        mudaCPY.categoriaPara.subcategoria = null

        this.setState({
            muda: mudaCPY,
            subCategoriasPara: categoria.sub_categorias
        })
    }

    handleChangeSubCategoriaPara(subCategoria) {
        var mudaCPY = this.state.muda

        mudaCPY.categoriaPara.subcategoria = subCategoria.id

        this.setState({ muda: mudaCPY })


    }

    mudaCategoria() {

        if (this.state.muda.categoriaDe.categoria === null || this.state.muda.categoriaDe.subcategoria === null ||
            this.state.muda.categoriaPara.categoria === null || this.state.muda.categoriaPara.subcategoria === null) {
            this.setState({ alerta: true, alertaMsg: 'Todos as informações devem se preenchidas' })
            return
        }

        if (this.state.muda.categoriaDe.subcategoria === this.state.muda.categoriaPara.subcategoria ) {
            this.setState({ alerta: true, alertaMsg: 'De/Para não pode ser os mesmos' })
            return
        }

        this.setState({ pedeConfirmacao: true })

    }


    confirmaMudarCategoria() {
        this.setState({ pedeConfirmacao: false })

        let url = global.url + 'categoria/mudar'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(this.state.muda)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: responseJson.mensagem })
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderConfirmacao(),
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {
        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border m-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Movimentação de categoria</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row m-3">
                        <div className="form-group col-md-4">
                            <label for="categoria" className="">Categoria de:</label>
                            <Select
                                styles={customStyles}
                                id="categoria"
                                name="categoria"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                value={this.state.categoriasDe.filter(option => option.id === this.state.muda.categoriaDe.categoria)}
                                onChange={this.handleChangeCategoriaDe.bind(this)}
                                options={this.state.categoriasDe}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="subCategoria" className="">Subcategoria de:</label>
                            <Select
                                styles={customStyles}
                                id="subCategoria"
                                name="subCategoria"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                value={this.state.subCategoriasDe.filter(option => option.id === this.state.muda.categoriaDe.subcategoria)}
                                onChange={this.handleChangeSubCategoriaDe.bind(this)}
                                options={this.state.subCategoriasDe}
                            />
                        </div>
                    </div>
                    <div className="ml-3 form-row m-3">
                        <div className="form-group col-md-4">
                            <label for="categoria" className="">Categoria para:</label>
                            <Select
                                styles={customStyles}
                                id="categoria"
                                name="categoria"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                value={this.state.categoriasPara.filter(option => option.id === this.state.muda.categoriaPara.categoria)}
                                onChange={this.handleChangeCategoriaPara.bind(this)}
                                options={this.state.categoriasPara}
                            />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="subCategoria" className="">Subcategoria para:</label>
                            <Select
                                styles={customStyles}
                                id="subCategoria"
                                name="subCategoria"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                value={this.state.subCategoriasPara.filter(option => option.id === this.state.muda.categoriaPara.subcategoria)}
                                onChange={this.handleChangeSubCategoriaPara.bind(this)}
                                options={this.state.subCategoriasPara}
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.mudaCategoria() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.excluiCategoria() }}>Excluir</button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.confirmaMudarCategoria()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a movimentação da categoria?" />
                </>
            )
        }
    }

}

export default CategoriaMover