import React from 'react'
import Iframe from 'react-iframe'
import { Component } from 'react';
import '../relatorio/relatorio.css'
import { formataDisplayEmReal } from '../../util/Utils'
import { extensoFrequencia, extensoTipo } from '../../util/extenso'
import moment from 'moment';
import PagamentoConsulta from '../pagamento/PagamentoConsulta'
import PagamentoConsultaFatura from '../pagamento/PagamentoConsultaFatura'
import meuPdf from '../../assets/pdf/2021_02_17_credicardZero.pdf'

var totalDebitosNoPeriodo = 0
var totalCreditosNoPeriodo = 0

//https://bootsnipp.com/tags/card

class RelatorioHome extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loader: true,
            contas: [],
            creditos: 0,
            debitos: 0,
            corSaldoContas: null,
            corReceitaMenosDespesa: null,
            resultado: '',
            resultadoBg: '',
            saldoTotal: '',
            tooltipQuadroReceita: 'Soma de todas as receitas',
            tooltipQuadroDespesa: 'Soma de todas as despesas',
            tooltipQuadroReceitaMenosDespesa: 'Resultado entre a subtração de despesas das receitas',
            tooltipQuadroSaldoBancario: 'Soma dos saldos de todas as contas',
            cabec: 'lista transações pagas',
            pagamentos: [],
            pagamentosGeral: [],
            pagamento: null,
            relatorioPagas: true,
            relatorioPagasConsulta: false,
            cabecResultado: '***',
            mostraAtrasados: true,
            mostraAtrasadosXX: false,
            pagamentosDebito: [],
            pagamentosCredito: [],
            pagamentosRecorrente: [],
            pagamentosParcelado: [],
            listaEstas: [],
            listaEstasFlag: false
        }
    }

    componentWillMount() {
        this.listaContas()
        this.obtemMovimentacaoDeUmMes(this.props.getMesAtual)
        this.listaPagamentos()
    }

    obtemMovimentacaoDeUmMes(dataRequerida) {

        this.setState({ creditos: '', debitos: '', resultado: '', pagamentos: [], parcelado: '', recorrente: '', atrasada: '' })

        let url = global.url + 'pagamento/listaPorData/' + dataRequerida

        this.setState({ mesCorrenteExtenso: moment(dataRequerida).format('MMMM/YYYY') })

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.separaValores(responseJson.pagamentos)

                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    separaValores(pagamentos) {

        var dataFinal = this.props.getMesAtual
        var dataInicial = moment(this.props.getMesAtual).startOf('month').format('YYYY-MM-DD')

        var dataCorrenteInicio = moment().startOf('month').format('YYYY-MM-DD')

        if (dataCorrenteInicio === dataInicial) {
            this.setState({ mostraAtrasados: true })
        } else {
            this.setState({ mostraAtrasados: false })
        }

        var debitos = 0
        var creditos = 0
        var resultado = 0
        var atrasada = 0
        var parcelado = 0
        var recorrente = 0
        var dataCorrente = moment().format('YYYY-MM-DD')

        var categoriasGeral = this.separaEmCategoria(pagamentos)

        var pagamentosDebito = []
        var pagamentosCredito = []
        var pagamentosRecorrente = []
        var pagamentosParcelado = []

        categoriasGeral.map(async (pagamento, index) => {


            var podeProcessar = false

            if (pagamento.status === 'liquidado') {
                if ((pagamento.data_de_pagamento >= dataInicial && pagamento.data_de_pagamento <= dataFinal)) {
                    podeProcessar = true
                }
            } else {
                if ((pagamento.data_de_vencimento >= dataInicial && pagamento.data_de_vencimento <= dataFinal)) {
                    podeProcessar = true
                }
            }

            var pagamentoValor = Number(pagamento.valor)

            if (podeProcessar === true) {

                if (pagamento.tipo === 'credito') {
                    creditos = creditos + pagamentoValor
                    pagamentosCredito.push(pagamento)
                } else {
                    pagamentosDebito.push(pagamento)
                    debitos = debitos + pagamentoValor
                    if (pagamento.fatura_id !== null || pagamento.sub_categoria_nome_interno === 'fatura') {
                        if (pagamento.status === 'emAberto' && pagamento.data_de_vencimento < dataCorrente) {
                            atrasada = atrasada + pagamentoValor
                        }
                    }
                    // if (pagamento.status === 'liquidado' && pagamento.data_de_vencimento < pagamento.data_de_pagamento) {
                    //     atrasada = atrasada + pagamentoValor
                    // }
                    if (pagamento.frequencia === 'recorrente') {
                        pagamentosRecorrente.push(pagamento)
                        recorrente = recorrente + pagamentoValor
                    }
                    if (pagamento.frequencia === 'mensal') {
                        pagamentosParcelado.push(pagamento)
                        parcelado = parcelado + pagamentoValor
                    }
                }
            } else {
                if (pagamento.fatura_id !== null || pagamento.sub_categoria_nome_interno === 'fatura') {
                    if (pagamento.status === 'emAberto' && pagamento.data_de_vencimento < dataCorrente) {
                        atrasada = atrasada + pagamentoValor
                    }
                }
            }
        })


        this.setState({ pagamentosDebito: pagamentosDebito })
        this.setState({ pagamentosCredito: pagamentosCredito })
        this.setState({ pagamentosRecorrente: pagamentosRecorrente })
        this.setState({ pagamentosParcelado: pagamentosParcelado })

        resultado = creditos - debitos

        creditos = parseFloat(creditos).toFixed(2)
        debitos = parseFloat(debitos).toFixed(2)
        resultado = parseFloat(resultado).toFixed(2)
        atrasada = parseFloat(atrasada).toFixed(2)
        recorrente = parseFloat(recorrente).toFixed(2)
        parcelado = parseFloat(parcelado).toFixed(2)

        if (resultado < 0) {
            this.setState({ resultadoBg: 'bg-danger' })
        } else {
            this.setState({ resultadoBg: 'rgm-cor-positivo' })
        }

        var debitosDisp = formataDisplayEmReal(debitos.toString())
        var creditosDisp = formataDisplayEmReal(creditos.toString())
        var resultadoDisp = formataDisplayEmReal(resultado.toString())
        var atrasadaDisp = formataDisplayEmReal(atrasada.toString())
        var recorrenteDisp = formataDisplayEmReal(recorrente.toString())
        var parceladoDisp = formataDisplayEmReal(parcelado.toString())

        this.setState({
            debitos: debitosDisp,
            creditos: creditosDisp,
            resultado: resultadoDisp,
            atrasada: atrasadaDisp,
            parcelado: parceladoDisp,
            recorrente: recorrenteDisp
        })

        if (resultado < 0) {
            this.setState({ corReceitaMenosDespesa: 'text-danger' })
        } else {
            this.setState({ corReceitaMenosDespesa: 'text-primary' })
        }

        this.setState({ loader: false })
    }

    separaEmCategoria(pagamentos) {

        var categoriasGeral = []

        pagamentos.map(async (pagamento, index) => {
            if (pagamento.fatura === null) {
                pagamento.categorias.map(async (categoria, index) => {
                    var categoriaNew = JSON.parse(JSON.stringify(categoria))
                    categoriaNew.data_de_vencimento = pagamento.data_de_vencimento
                    categoriaNew.data_de_pagamento = pagamento.data_de_pagamento
                    categoriaNew.parcela = pagamento.parcela
                    categoriaNew.numeroDeParcelas = pagamento.transacao.numero_de_parcelas
                    categoriaNew.conta_id = pagamento.conta_id
                    categoriaNew.frequencia = pagamento.frequencia
                    categoriaNew.status = pagamento.status
                    if (categoriaNew.entra_nas_estatisticas !== false) {
                        categoriasGeral.push(categoriaNew)
                    }
                })
            }
        })

        pagamentos.map(async (pagamento, index) => {
            if (pagamento.sub_categoria_nome_interno === 'fatura') {
                if (pagamento.fatura !== null) {
                    pagamento.fatura.pagamentos.map(async (pagamentos, index3) => {
                        pagamentos.categorias.map(async (categoria, index) => {
                            var categoriaNew = JSON.parse(JSON.stringify(categoria))
                            categoriaNew.data_de_vencimento = pagamento.data_de_vencimento
                            categoriaNew.data_de_pagamento = pagamento.data_de_pagamento
                            categoriaNew.parcela = pagamento.parcela
                            categoriaNew.numeroDeParcelas = pagamento.transacao.numero_de_parcelas
                            categoriaNew.conta_id = pagamento.conta_id
                            categoriaNew.frequencia = pagamentos.frequencia
                            categoriaNew.status = pagamento.status
                            if (categoriaNew.entra_nas_estatisticas !== false) {
                                categoriasGeral.push(categoriaNew)
                            }
                        })
                    })
                }
            }
        })

        return categoriasGeral


    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contas: responseJson.contas })
                    this.calculaSaldoTotalContas(responseJson.contas)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    calculaSaldoTotalContas(contas) {

        var saldoTotal = 0
        var saldoTotalCC = 0

        contas.map(async (conta, index) => {
            if (conta.tipo_conta === 'contaCorrente' || conta.tipo_conta === 'contaDinheiro') {
                saldoTotal = saldoTotal + conta.saldoTotalLista
            }
            if (conta.tipo_conta === 'cartaoCredito') {
                saldoTotalCC = saldoTotalCC + conta.saldoTotalLista
            }
        })

        if (saldoTotal < 0) {
            this.setState({ corSaldoContas: 'btn-danger' })
        } else {
            this.setState({ corSaldoContas: 'btn-primary' })
        }

        saldoTotal = parseFloat(saldoTotal).toFixed(2)
        saldoTotalCC = parseFloat(saldoTotalCC).toFixed(2)

        var saldoTotalDisp = formataDisplayEmReal(saldoTotal.toString())
        var saldoTotalCCDisp = formataDisplayEmReal(saldoTotalCC.toString())

        this.setState({
            saldoTotal: saldoTotalDisp,
            saldoTotalCC: saldoTotalCCDisp
        })

    }

    setMesAnterior() {
        var avisa = (() => { this.avisa() })
        this.props.setMesAnterior(avisa)
    }

    setMesPosterior() {
        var avisa = (() => { this.avisa() })
        this.props.setMesPosterior(avisa)
    }

    setHoje() {
        var avisa = (() => { this.avisa() })
        this.props.setHoje(avisa)
    }

    avisa() {
        this.obtemMovimentacaoDeUmMes(this.props.getMesAtual)
        this.obtemPagamentosDataSolicitada(this.props.getMesAtual)


        var dataIni = moment(this.props.getMesAtual).startOf('month').format('YYYY-MM-DD')
        var dataFim = moment(this.props.getMesAtual).endOf('month').format('YYYY-MM-DD')

        var pagamentosAtuais = []

        this.state.pagamentosGeral.map(async (pagamento, index) => {
            if (pagamento.data_de_pagamento >= dataIni && pagamento.data_de_pagamento <= dataFim) {
                pagamentosAtuais.push(pagamento)
            }
        })

        this.setState({ pagamentos: pagamentosAtuais })
    }

    acumulaValores(pagamento, index) {
        if (index === 0) {
            totalDebitosNoPeriodo = 0
            totalCreditosNoPeriodo = 0
        }

        if (pagamento.tipo === 'debito') {
            totalDebitosNoPeriodo = parseFloat(totalDebitosNoPeriodo) + parseFloat(pagamento.valor)
        }

        if (pagamento.tipo === 'credito') {
            totalCreditosNoPeriodo = parseFloat(totalCreditosNoPeriodo) + parseFloat(pagamento.valor)
        }

        totalDebitosNoPeriodo = parseFloat(totalDebitosNoPeriodo).toFixed(2)
        totalCreditosNoPeriodo = parseFloat(totalCreditosNoPeriodo).toFixed(2)

    }


    corValor(resultado) {

        var negativo = resultado.includes("-", 0);

        if (negativo === true) {
            return 'text-white'
        } else {
            return 'rgm-cor-positivo'
        }

    }


    nomeConta(conta_id) {
        const conta = this.state.contas.find(conta => conta.id === conta_id);

        if (conta === undefined) {
            return ' '
        } else {
            return conta.nome

        }
    }

    pagamentoConsulta(pagamento) {
        this.setState({ pagamento: pagamento, relatorioPagas: false, relatorioPagasConsulta: true })
    }

    listaCreditos() {
        this.setState({ listaEstasFlag: true })
        this.setState({ listaEstas: this.state.pagamentosCredito })
        this.setState({ cabec: 'lista dos crédito' })

    }

    listaDebitos() {
        this.setState({ listaEstasFlag: true })
        this.setState({ listaEstas: this.state.pagamentosDebito })
        this.setState({ cabec: 'lista dos débitos' })
    }

    listaParcelados() {
        this.setState({ listaEstasFlag: true })
        this.setState({ listaEstas: this.state.pagamentosParcelado })
        this.setState({ cabec: 'lista dos parcelados' })
    }

    listaRecorrentes() {
        this.setState({ listaEstasFlag: true })
        this.setState({ listaEstas: this.state.pagamentosRecorrente })
        this.setState({ cabec: 'lista dos recorrentes' })
    }

    render() {
        if (this.state.listaEstasFlag === true) {
            return [
                this.renderListaEstas()
            ]
        } else {
            if (this.state.loader === true) {
                return [
                    this.renderLoad()
                ]
            } else {
                return [
                    this.renderRelatorioPrincipal(),
                    this.renderRelatorioPagas(),
                    //this.renderRelatorioPDF(),
                    this.renderRelatorioPagasConsulta()
                ]
            }
        }
    }

    renderRelatorioPrincipal() {
        if (this.state.relatorioPagas === true) {
            return (
                <div className="container w-100 mt-5">

                    <div>
                        <button className={"btn m-2 " + this.state.corSaldoContas} data-toggle="tooltip" data-placement="top" title='Saldo bancario'>Saldo Bancário:<span className="font-weight-bold"> {this.state.saldoTotal}</span></button>
                        <button className="btn btn-danger m-2" data-toggle="tooltip" data-placement="top" title='Saldo a pagar dos cartões'>Saldo a pagar dos cartões:<span className="font-weight-bold"> {this.state.saldoTotalCC}</span></button>
                    </div>

                    <nav className="navbar border navbar-expand-md navbar-dark bg-info mt-0 mb-0">
                        <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item active">
                                    <button type="button" className="nav-link btn btn-link" onClick={() => { this.props.relatorioGastosPorCategoria() }}>Gastos por categoria</button>
                                </li>
                                <li className="nav-item active">
                                    <button type="button" className="nav-link btn btn-link" onClick={() => { this.props.relatorioGastosPorAmigo() }}>Gastos por amigo</button>
                                </li>
                                <li className="nav-item active">
                                    <button type="button" className="nav-link btn btn-link" onClick={() => { this.props.relatorioFluxoDeCaixa() }}>Fluxo de caixa</button>
                                </li>
                            </ul>
                        </div>
                    </nav>

                    <div class="card-header mb-2 text-white bg-info border">
                        <span>Relatório | Panorama Geral de suas finanças |&nbsp;</span>
                        <div class="btn-group" role="group" aria-label="Exemplo básico">
                            <button type="button" class="btn btn-secondary" onClick={() => this.setMesAnterior()}>{moment(this.props.getMesAnterior).format('MMM/YYYY')}</button>
                            <button type="button" class="btn btn-primary">{moment(this.props.getMesAtual).format('MMMM/YYYY')}</button>
                            <button type="button" class="btn btn-secondary" onClick={() => this.setMesPosterior()}>{moment(this.props.getMesPosterior).format('MMM/YYYY')}</button>
                            <button type="button" class="btn btn-secondary" onClick={() => this.setHoje()}>Hoje</button>
                        </div>
                    </div>

                    <table className="table table-bordered mt-0">
                        <tbody className="rgm-bg-azul-esverdiado">
                            <tr>
                                <td className={"align-middle rgm-diminuirParaCaber text-white nav-link btn btn-link text-left"} onClick={() => this.listaCreditos()}><h5>Receitas</h5></td>
                                <td className="text-right align-middle rgm-cor-positivo"><h5>{this.state.creditos}</h5></td>
                            </tr>
                            <tr>
                                <td className={"align-middle rgm-diminuirParaCaber text-white nav-link btn btn-link text-left"} onClick={() => this.listaDebitos()}><h5>Despesas</h5></td>
                                <td className="text-right align-middle text-white"><h5>{this.state.debitos}</h5></td>
                            </tr>
                            <tr>
                                <td className={"align-middle rgm-diminuirParaCaber text-white"}><h5>Receitas - Despesas</h5></td>
                                <td className={"card-title text-right " + this.corValor(this.state.resultado)}><h5>{this.state.resultado}</h5></td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="table table-bordered mt-0">
                        <tbody className="rgm-bg-azul-esverdiado">
                            <tr>
                                <td className={"align-middle rgm-diminuirParaCaber text-white nav-link btn btn-link text-left"} onClick={() => this.listaParcelados()}><h5>Parcelados</h5></td>
                                <td className="text-right align-middle text-white"><h5>{this.state.parcelado}</h5></td>
                            </tr>
                            <tr>
                                <td className={"align-middle rgm-diminuirParaCaber text-white nav-link btn btn-link text-left"} onClick={() => this.listaRecorrentes()}><h5>Recorrentes</h5></td>
                                <td className="text-right align-middle text-white"><h5>{this.state.recorrente}</h5></td>
                            </tr>
                        </tbody>
                    </table>
                    {this.state.mostraAtrasadosXX &&
                        <table className="table table-bordered mt-0">
                            <tbody className="bg-danger">
                                <tr>
                                    <td className={"align-middle rgm-diminuirParaCaber text-white"}><h5>Em atraso</h5></td>
                                    <td className={"card-title text-right text-white"}><h5>{this.state.atrasada}</h5></td>
                                </tr>
                            </tbody>
                        </table>
                    }
                </div >
            )
        }
    }

    renderRelatorioPagas() {

        if (this.state.relatorioPagas === true && this.state.pagamentos.length > 0) {
            return (
                <div className="container-fluid w-100 mt-5">
                    <div class="row justify-content-md-center">
                        <div className="card row bg-transparent">
                            <div class="card-header border rgm-bg-azul-esverdiado text-white">
                                <h5>Transações pagas</h5>
                            </div>
                            <table className="table table-bordered mt-0">
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                        <th scope="col">Descrição</th>
                                        <th scope="col" className="text-center">Pagamento</th>
                                        <th scope="col">Conta</th>
                                        <th scope="col">Frequência</th>
                                        <th scope="col" className="text-center">Parcela</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col" className="text-right">Valor</th>
                                    </tr>
                                </thead>
                                <tbody className="rgm-bg-azul-esverdiado">
                                    {this.state.pagamentos.map((pagamento, index) => {
                                        this.acumulaValores(pagamento, index)
                                        return (
                                            <tr className={'rgm-bg-azul-esverdiado text-white ' + this.corLinha(pagamento)}>
                                                <td className="text-center text-white align-middle">
                                                    <button className="btn btn-link btn-sm text-center" onClick={() => { this.pagamentoConsulta(pagamento) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                                </td>
                                                <td className={"align-middle rgm-diminuirParaCaber " + this.corDescricao(pagamento)}>{this.obtemDescricao(pagamento)}</td>
                                                <td className="text-center align-middle text-white">{moment(pagamento.data_de_pagamento).format('DD/MM/YYYY')}</td>
                                                <td className="align-middle text-white rgm-diminuirParaCaber ">{this.nomeConta(pagamento.conta_id)}</td>
                                                <td className="align-middle text-white">{extensoFrequencia(pagamento.frequencia)}</td>
                                                <td className="text-center align-middle text-white">{this.obtemParcelas(pagamento)}</td>
                                                <td className="align-middle text-white">{extensoTipo(pagamento.tipo)}</td>
                                                <td className={"text-right align-middle " + this.corDoLancamento(pagamento)}>{formataDisplayEmReal(pagamento.valor)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                {this.state.pagamentos.length === 0 &&
                                    <tbody>
                                        <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="8">
                                            <span>Não existem pagamentos para sua seleção</span>
                                        </td>
                                    </tbody>
                                }
                                {this.state.pagamentos.length !== 0 &&
                                    <thead>
                                        <tr className="bg-info">
                                            <th scope="col" className="text-center" COLSPAN="7">Total de débitos efetuados</th>
                                            <th scope="col" className="text-center text-white" COLSPAN="3">{formataDisplayEmReal(totalDebitosNoPeriodo)}</th>
                                        </tr>
                                    </thead>
                                }
                                {this.state.pagamentos.length !== 0 &&
                                    <thead>
                                        <tr className="bg-info">
                                            <th scope="col" className="text-center" COLSPAN="7">Total de creditos efetuados</th>
                                            <th scope="col" className="text-center text-warning" COLSPAN="3">{formataDisplayEmReal(totalCreditosNoPeriodo)}</th>
                                        </tr>
                                    </thead>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderRelatorioPagasConsulta() {

        if (this.state.relatorioPagasConsulta === true && this.state.pagamento.sub_categoria_nome_interno !== 'fatura') {
            return (<
                PagamentoConsulta pagamento={
                    this.state.pagamento
                }
                volta={() => { this.setState({ relatorioPagas: true, relatorioPagasConsulta: false }) }}
            />
            )
        }

        if (this.state.relatorioPagasConsulta === true && this.state.pagamento.sub_categoria_nome_interno === 'fatura') {
            return (<
                PagamentoConsultaFatura pagamento={this.state.pagamento}
                volta={() => { this.setState({ relatorioPagas: true, relatorioPagasConsulta: false }) }}
            />
            )
        }
    }

    formataData(data) {

        if (data === null) {
            return '-'
        } else {
            return moment(data).format('DD/MM/YYYY')
        }
    }

    obtemParcela(pagamento) {

        if (pagamento.frequencia === 'unica') {
            return ''
        } else {
            if (pagamento.frequencia === 'recorrente') {
                return ('000' + pagamento.parcela).slice(-3) + '/999'
            } else {
                return ('000' + pagamento.parcela).slice(-3) + '/' + ('000' + pagamento.numeroDeParcelas).slice(-3)
            }

        }

    }

    renderListaEstas() {

        return (
            <div className="container w-100 mt-5">
                <div>
                    <button className={"btn m-2 btn-primary"} onClick={() => this.setState({ listaEstasFlag: false })} >Volta</button>
                </div>
                <div class="row justify-content-md-center">
                    <div className="card row bg-transparent">
                        <div class="card-header border rgm-bg-azul-esverdiado text-white">
                            <h5>{this.state.cabec}</h5>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col" className="text-center">Vencimento</th>
                                    <th scope="col" className="text-center">Pagamento</th>
                                    <th scope="col">Parcela</th>
                                    <th scope="col">Conta</th>
                                    <th scope="col">Frequência</th>
                                    <th scope="col">Tipo</th>
                                    <th scope="col" className="text-right">Valor</th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.listaEstas.map((pagamento, index) => {
                                    this.acumulaValores(pagamento, index)
                                    return (
                                        <tr className={'rgm-bg-azul-esverdiado text-white ' + this.corLinha(pagamento)}>
                                            <td className="text-center text-white align-middle">
                                                <button className="btn btn-link btn-sm text-center" onClick={() => { this.pagamentoConsulta(pagamento) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                            </td>
                                            <td className={"align-middle rgm-diminuirParaCaber " + this.corDescricao(pagamento)}>{this.obtemDescricao(pagamento)}</td>
                                            <td className="text-center align-middle text-white">{moment(pagamento.data_de_vencimento).format('DD/MM/YYYY')}</td>
                                            <td className="text-center align-middle text-white">{this.formataData(pagamento.data_de_pagamento)}</td>
                                            <td className="text-center align-middle text-white">{this.obtemParcela(pagamento)}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber ">{this.nomeConta(pagamento.conta_id)}</td>
                                            <td className="align-middle text-white">{extensoFrequencia(pagamento.frequencia)}</td>
                                            <td className="align-middle text-white">{extensoTipo(pagamento.tipo)}</td>
                                            <td className={"text-right align-middle " + this.corDoLancamento(pagamento)}>{formataDisplayEmReal(pagamento.valor)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                            {this.state.listaEstas.length === 0 &&
                                <tbody>
                                    <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="8">
                                        <span>Não existem pagamentos para sua seleção</span>
                                    </td>
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderRelatorioPDF() {
        if (this.state.relatorioPagas === true) {
            return (
                <div className="container-fluid w-100 mt-5">
                    <div class="row justify-content-md-center">
                        <Iframe url={meuPdf}
                            position="absolute"
                            width="75%"
                            id="myId"
                            className="myClassname"
                            height="100%" />
                    </div>
                </div>
            )
        }
    }



    listaPagamentos() {
        let url = global.url + 'pagamento/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustasPagamentos(responseJson.pagamentos)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    ajustasPagamentos(pagamentosTotal) {
        var pagamentosGeral = []

        pagamentosTotal.map(async (pagamento, index) => {
            var salva = true

            if (pagamento.sub_categoria_nome_interno === 'pagamentoDentroDeUmaFatura') {
                salva = false
            }

            if (pagamento.sub_categoria_nome_interno === 'transferencia') {
                salva = false
            }

            if (pagamento.sub_categoria_nome_interno === 'pagamentoAvulso') {
                salva = false
            }

            if (pagamento.status !== 'liquidado') {
                salva = false
            }

            if (pagamento.sub_categoria_nome_interno !== 'fatura') {
                if (pagamento.fatura_id !== null) {
                    salva = false
                }
            }

            if (salva === true) {
                pagamentosGeral.push(pagamento)
            }

        })

        this.obtemPagamentosDataSolicitada(this.props.getMesAtual)


        pagamentosGeral.sort((a, b) => {
            var a_deb_cred = 0
            var b_deb_cred = 0

            if (a.tipo === 'credito') {
                a_deb_cred = -1
            } else {
                a_deb_cred = 1
            }

            if (b.tipo === 'credito') {
                b_deb_cred = -1
            } else {
                b_deb_cred = 1
            }

            var a_valor = parseFloat(a.valor) * a_deb_cred
            var b_valor = parseFloat(b.valor) * b_deb_cred
            var a_dataPagamento = moment(a.data_de_pagamento)
            var b_dataPagamento = moment(b.data_de_pagamento)
            var a_compara = a_dataPagamento + a_valor
            var b_compara = b_dataPagamento + b_valor
            if (a_compara < b_compara) {
                return -1
            } else {
                return 0
            }
        })

        var pagamentosAtuais = []

        var dataIni = moment().startOf('month').format('YYYY-MM-DD')
        var dataFim = moment().endOf('month').format('YYYY-MM-DD')

        pagamentosGeral.map(async (pagamento, index) => {
            if (pagamento.data_de_pagamento >= dataIni && pagamento.data_de_pagamento <= dataFim) {
                pagamentosAtuais.push(pagamento)
            }
        })


        this.setState({ pagamentosGeral: pagamentosGeral })
        this.setState({ pagamentos: pagamentosAtuais })

    }

    obtemPagamentosDataSolicitada(dataRequerida) {

    }

    obtemParcelas(pagamento) {
        if (pagamento.frequencia === 'recorrente') {
            return ('000' + pagamento.parcela).slice(-3) + '/999'
        } else {
            if (pagamento.transacao.numero_de_parcelas === null) {
                return ''
            } else {
                return ('000' + pagamento.parcela).slice(-3) + '/' + ('000' + pagamento.transacao.numero_de_parcelas).slice(-3)
            }
        }
    }

    corLinha(pagamento) {
        var dataAtual = moment().format('YYYY-MM-DD')
        var dataVencimento = moment(pagamento.data_de_vencimento).format('YYYY-MM-DD')

        if (dataVencimento < dataAtual) {
            return 'text-danger'
        } else {
            if (moment(dataVencimento).format('YYYY-MM') > moment(dataAtual).format('YYYY-MM')) {
                return 'text-muted'
            } else {
                if (dataAtual === dataVencimento) {
                    return 'text-primary'
                } else {
                    return 'text-dark'
                }
            }
        }
    }

    corDoLancamento(pagamento) {
        if (pagamento.tipo === 'credito') {
            return 'rgm-cor-positivo'
        } else {
            return 'text-white'
        }
    }

    corDescricao(pagamento) {

        var corDoTexto = null

        if (pagamento.data_de_vencimento < pagamento.data_de_pagamento) {
            corDoTexto = 'text-warning'
        } else {
            if (pagamento.data_de_vencimento === moment().format('YYYY-MM-DD')) {
                corDoTexto = 'text-white'
            } else {
                corDoTexto = 'text-white'
            }
        }


        if (pagamento.sub_categoria_nome_interno === 'fatura') {
            return corDoTexto + ' font-weight-bold'
        } else {
            return corDoTexto
        }
    }

    obtemDescricao(pagamento) {

        if (pagamento.data_de_vencimento < pagamento.data_de_pagamento) {
            var dias = moment(pagamento.data_de_pagamento).diff(moment(pagamento.data_de_vencimento), 'days');
            return pagamento.descricao + ' ** pago com atraso de ' + dias + ' dias **'
        } else {
            return pagamento.descricao
        }
    }

    renderLoad() {
        return (
            <div className="container w-100 mt-5">
                <div className="card-header bg-info border mt-2">
                    <h7>Lista de pagamentos</h7>
                </div>
                <div className="loader text-center mt-5"></div>
                <span className="text-warning">Aguarde... Carregando...</span>
            </div>
        )
    }
}

export default RelatorioHome