import React, { Component } from 'react'
import '../login/Login.css'
import '../../rgm.css'
import Rogar from '../../assets/img/Rogar5.png'
import ModalAviso from '../modal/ModalAviso'
var validator = require("email-validator");

class Login extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.showMessage = this.showMessage.bind(this);
        localStorage.removeItem('EGR_TOKEN');

        this.state = {
            email: '',
            password: '',
            mensagem: undefined,
            hidden: undefined,
            esqueciMinhaSenha: false,
            emailRecuperaSenha: '',
            esqueciMinhaSenhaTransacao: false,
            uuid: null,
            passwordEsqueci: null,
            password2Esqueci: null

        };
    }

    componentWillMount() {

        var url = document.URL

        var arrayOfStrings = url.split('/');

        var uuid = (arrayOfStrings.length - 1)
        var redefinir = uuid - 1

        if (arrayOfStrings[redefinir] === 'redefinir') {
            this.setState({ esqueciMinhaSenhaTransacao: true, uuid: arrayOfStrings[uuid] })
        }

    }

    handleSubmit(e) {

        window.history.pushState({}, null, global.urlInicio)

        e.preventDefault();

        if (this.state.password === undefined || this.state.email === undefined) {
            this.setState({ mensagem: 'email e senha são obrigatórios' });
            return;
        }

        this.setState({ mensagem: undefined });

        let dataToSend = {
            userData: {
                email: this.state.email,
                password: this.state.password
            }
        };

        let url = global.url + 'login';

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response.json();
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    localStorage.setItem('EGR_TOKEN', responseJson.token);
                    this.props.onLogged();
                    this.setState({ logged: true, error: undefined });
                } else {
                    this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
                    return
                }
            });
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        });
    }

    showMessage() {
        if (this.state.mensagem !== undefined) {
            return (
                <div>
                    <div className="alert alert-danger" role="alert" align="center">
                        {this.state.mensagem}
                    </div>
                </div>
            );
        }
    }

    render() {
        if (this.state.esqueciMinhaSenhaTransacao === true) {
            return (
                [
                    this.renderAlert(),
                    this.renderEsqueciMinhaSenhaTransacao_00(),
                    this.renderEsqueciMinhaSenhaTransacao_01()
                ]
            )
        } else {
            if (this.state.esqueciMinhaSenha === true) {
                return (
                    [
                        this.renderAlert(),
                        this.renderAlertaSaida(),
                        this.renderEsqueciMinhaSenha()
                    ]
                )
            } else {
                return (
                    [
                        this.renderAlert(),
                        this.renderPrincipal()
                    ]
                )
            }

        }
    }


    renderPrincipal() {
        return (
            <div className="rgm-container">
                <div className="card card-login mx-auto col-3 bg-transparent">
                    <div className="card-header text-center rgm-bg-black-transparente">
                        <div className="text-center m-3">
                            <img src={Rogar} alt="10px" />
                        </div>
                    </div>
                    <div className="card-body rgm-bg-black-transparente">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group m-3 bg-secondary text-white">
                                <input type="email" onChange={this.handleEmailChange} className="form-control bg-secondary text-white" name="email" id="email" value={this.state.email}
                                    placeholder="Email" />
                            </div>
                            <div className="form-group m-3  bg-secondary text-white">
                                <input type="password" onChange={this.handlePasswordChange} className="form-control  bg-secondary text-white" name="senha" id="senha" value={this.state.password}
                                    placeholder="Senha" />
                            </div>
                            <div className="form-group m-3">
                                <button type="submit" id="buttonLogin" name="buttonLogin" className="btn btn-danger btn-block">Login</button>
                            </div>
                            <div className="text-center">
                                <button id="buttonNew" className="btn btn-link" onClick={() => { this.props.onNewUser() }}>Criar uma conta</button>
                            </div>
                            <div className="text-center">
                                <button id="buttonNew" className="btn btn-link" onClick={() => { this.setState({ esqueciMinhaSenha: true }) }}>Esqueci minha senha</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.chamaLogin()} />
                </>
            )
        }
    }


    renderEsqueciMinhaSenha() {
        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Recuperação de senha</h5>
                        </div>
                    </div>
                    <div className="form-group row m-2">
                        <label for="email" className="col-sm-2 col-form-label ml-2">Email</label>
                        <div className="col-sm-6">
                            <input type="email" className="form-control" id="email" onChange={this.handleChangeEmail.bind(this)} value={this.state.emailRecuperaSenha} placeholder="Email" />
                        </div>
                    </div>
                    <div class="card-header border">
                        <div className="row">
                            <button id="buttonGravar" type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.recuperaSenha() }}>Recuperar senha</button>
                            <button id="buttonGravar" type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.setState({ esqueciMinhaSenha: false, emailRecuperaSenha: null }) }}>Cancela</button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }

    handlePasswordChangeEsqueci(e) {
        this.setState({ passwordEsqueci: e.target.value })
    }

    handlePassword2ChangeEsqueci(e) {
        this.setState({ password2Esqueci: e.target.value })
    }

    chamaLogin() {

        //http://localhost/redefinir/2d15b373-315e-4b1d-8c0c-c100881f6e43 

        this.setState({ esqueciMinhaSenha: false, alertaSaida: false, emailRecuperaSenha: null })

        window.history.pushState({}, null, global.urlInicio)

        this.props.chamaLogin()

    }



    renderEsqueciMinhaSenhaTransacao_00() {
        return (
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                <a className="navbar-brand" href="#"><img src={Rogar} alt="10px" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="text-center bg-light">
                        <img src={Rogar} alt="10px" />
                    </div>
                </button>

                <div className="collapse navbar-collapse" id="navbarsExampleDefault">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                        </li>
                    </ul>
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item active">
                            <a className="nav-link text-danger" href="#"><h4>Versão Beta - restrita a alguns usuários</h4><span className="sr-only">(current)</span></a>
                        </li>
                    </ul>
                    <form className="form-inline my-2 my-lg-0">
                        <button className="btn btn-primary" onClick={() => { this.chamaLogin() }} >Entrar</button>
                    </form>
                </div>
            </nav>
        )
    }

    renderEsqueciMinhaSenhaTransacao_01() {
        return (
            <div className="container w-100">
                <form className="card rgm-bg-azul-esverdiado bordertext-white mt-5">
                    <div class="card-header mt-5 border">
                        <div className="row">
                            <h5>Redefinir senha</h5>
                        </div>
                    </div>
                    <div className="form-group m-3">
                        <div className="form-row">
                            <div className="col-md-6">
                                <label for="password">Senha</label>
                                <input type="password" maxLength="8" onChange={this.handlePasswordChangeEsqueci.bind(this)} className="form-control" name="senha" id="password" value={this.state.passwordEsqueci} placeholder="Senha" />
                            </div>
                            <div className="col-md-6">
                                <label for="password">Repita sua senha</label>
                                <input type="password" maxLength="8" onChange={this.handlePassword2ChangeEsqueci.bind(this)} className="form-control" name="senha2" id="password2" value={this.state.password2Esqueci} placeholder="Repita sua senha" />
                            </div>
                        </div>
                    </div>
                    <div class="card-header border">
                        <div className="row ml-2">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => { this.redefineSenha() }}>Redefinir</button>
                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.chamaLogin() }}>Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }


    handleChangeEmail(event) {
        this.setState({ emailRecuperaSenha: event.target.value })
    }


    recuperaSenha() {

        var email = this.state.emailRecuperaSenha

        if (email != null) {
            if (email === '' || ' ' === email.substring(0, 1)) {
                email = null
            }
        }

        if (email === null) {
            this.setState({ alerta: true, alertaMsg: 'Email inválido.' })
            return
        }


        if (!validator.validate(email)) {
            this.setState({ alerta: true, alertaMsg: 'Email inválido.' })
            return
        }


        window.location = global.urlInicio

        let url = global.url + 'user/esqueciMinhaSenha';

        var trocaSenha = {
            email: this.state.emailRecuperaSenha,
        };

        this.setState({ alertaSaida: true, alertaMsg: 'Instruções passadas para seu email, confira' })

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(trocaSenha)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                //this.setState({ alertaSaida: true, alertaMsg: 'Instruções passadas para seu email, confira' })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    redefineSenha() {


        if (this.state.password2Esqueci !== this.state.passwordEsqueci) {
            this.setState({ alerta: true, alertaMsg: 'As senhas devem ser iguais' })
            return
        }

        if (this.state.password2Esqueci === null || this.state.password2Esqueci.length < 6) {
            this.setState({ alerta: true, alertaMsg: 'A senha deve ter no minimo 6 posições' })
            return
        }

        window.location = global.urlInicio
        this.setState({ esqueciMinhaSenhaTransacao: false })

        let url = global.url + 'user/esqueciMinhaSenhaTroca';

        var trocaSenha = {
            password: this.state.passwordEsqueci,
            password2: this.state.password2Esqueci,
            uuid: this.state.uuid
        };

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(trocaSenha)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Inclusão concluida...' })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })


    }



}

export default Login