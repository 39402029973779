import React, { Component } from "react"
import moment from 'moment'
import 'moment/locale/pt-br'
import 'react-datepicker/dist/react-datepicker.css'
import ModalAviso from '../modal/ModalAviso'
import { titleize } from '../../util/Utils'

var validator = require("email-validator")

class UsuarioAlteracao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usuario: props.usuario,
            nome: props.usuario.nome,
            email: props.usuario.email,
            emailAntigo: props.usuario.email
        }
    }

    componentWillMount() { }

    alteraUsuario() {
        var mensagem = null

        if (this.state.nome == null || this.state.nome === '' || ' ' === this.state.nome.substring(0, 1)) {
            mensagem = 'você não digitou o nome ou o nome começou com espaço'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        var email = this.state.email

        if (email != null) {
            if (email === '' || ' ' === email.substring(0, 1)) {
                email = null
            }
        }

        if (email != null) {
            if (!validator.validate(email)) {
                mensagem = 'email invalido'
                this.setState({ alerta: true, alertaMsg: mensagem })
                return
            }
        } else {
            mensagem = 'email invalido'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        let usuario = {
            id: this.state.usuario.id,
            nome: this.state.nome,
            email: this.state.email
        }

        let url = global.url + 'usuario/altera'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(usuario)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                var usuarioCopy = JSON.parse(JSON.stringify(this.state.usuario))
                usuarioCopy.nome = this.state.nome
                usuarioCopy.email = this.state.email
                this.setState({ usuario: usuarioCopy })
                this.setState({ alerta: true, alertaMsg: 'Alteração concluida' })
                if (this.state.email !== this.state.emailAntigo) {
                    this.props.desconecta()
                } else {
                    this.props.volta(this.state.usuario)
                }
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }

    handleChangeNome(event) {
        var nome = titleize(event.target.value)
        this.setState({ nome: nome })
    }

    handleChangeEmail(event) {
        this.setState({ email: event.target.value })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderComum()
        ]
    }

    renderComum() {

        var data_inicio_assinatura = null
        var labelAssinatura = null

        if (this.state.usuario.status === 'experimentando') {
            data_inicio_assinatura = moment(this.state.usuario.created_at).format('DD/MM/YYYY')
            labelAssinatura = 'Avaliando desde'
        }

        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border m-2  text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Meus dados - Alteração</h5>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="nome" className="col-sm-2 col-form-label m-2">Nome</label>
                        <div className="col-sm-5">
                            <input type="text" className="form-control mt-3 text-capitalize m-2" id="nome" onChange={this.handleChangeNome.bind(this)} value={this.state.nome} placeholder="nome" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="email" className="col-sm-2 col-form-label m-2">Email</label>
                        <div className="col-sm-5">
                            <input type="email" className="form-control m-2" id="email" onChange={this.handleChangeEmail.bind(this)} value={this.state.email} placeholder="Email" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="nome" className="col-sm-2 col-form-label m-2">{labelAssinatura}</label>
                        <div className="col-sm-2">
                            <input type="text" disabled className="form-control m-2 text-center" id="nome" value={data_inicio_assinatura} placeholder="nome" />
                        </div>
                    </div>

                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.alteraUsuario() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                        </div>
                    </div>
                </form >
            </div >
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

}

export default UsuarioAlteracao