import React, { Component } from 'react'
import moment from 'moment'
import { formataDisplayEmReal } from '../../util/Utils'

class BovespaLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            papeis: []
        }
    }

    componentWillMount() {

        var bovespa = [
            { data_da_operacao: '2021-01-01', operacao: 'compra', quantidade: 100, papel: 'CIEL3', preco_unitario: '3.90', despesas: '1.00', valor_ir: '0.01', corretora: 1, comentarios: '', preco_total: '390.00', preco_total_final: '389.00'  },
            { data_da_operacao: '2021-01-01', operacao: 'venda', quantidade: 100, papel: 'CIEL3', preco_unitario: '4.00', despesas: '1.00', valor_ir: '0.01', corretora: 1, comentarios: '', preco_total: '400.00', preco_total_final: '399.00'  },
        ]

        //this.setState({ papeis: bovespa })

        this.listaBovespas()



    }

    listaBovespas() {
        let url = global.url + 'bovespa/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ papeis: responseJson.papeis })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    render() {
        if (this.state.loader === true) {
            return [
                this.renderLoad()
            ]
        } else {

            return [
                this.renderComum()
            ]
        }
    }


    // <button id="listaReembolso" type="button" className="btn btn-primary btn-sm m-2" onClick={() => this.listaReemAbertos()} >Reembolsos não realizados</button> */

    renderComum() {
        return (
            <div className="container-fluid w-100 mt-5">
                <div class="row justify-content-md-center">
                    <div className="form-group row mt-0 mb-0">
                        <div className="col-sm-12">
                            <button id="novoPgto" type="button" className="btn btn-primary btn-sm m-2">&nbsp;Novo</button>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-md-center">
                    <div className="card row bg-transparent">
                        <div class="card-header w-auto border rgm-bg-azul-esverdiado text-white">
                            <h5>Lista dos papéis bovespa</h5>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                    <th scope="col">Data</th>
                                    <th scope="col">Corretora</th>
                                    <th scope="col">Operação</th>
                                    <th scope="col">Quantidade</th>
                                    <th scope="col">Papel</th>
                                    <th scope="col">Preço Unitario</th>
                                    <th scope="col">Preço Total</th>
                                    <th scope="col">Despesas</th>
                                    <th scope="col">Preço Total Final</th>
                                </tr>
                            </thead>

                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.papeis.map((papel, index) => {
                                    return (
                                        <tr className={'rgm-bg-azul-esverdiado text-white'}>
                                            <td className="text-center text-white align-middle">
                                                <button className="btn btn-link btn-sm text-center" onClick={() => { this.pagamentoConsulta(papel) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                            </td>
                                            <td className={"align-middle"}>{moment(papel.data_da_operacao).format('DD/MM/YYYY')}</td>
                                            <td className={"align-middle"}>{papel.corretora}</td>
                                            <td className={"align-middle"}>{papel.operacao}</td>
                                            <td className={"text-right align-middle"}>{papel.quantidade}</td>
                                            <td className={"align-middle"}>{papel.papel}</td>
                                            <td className={"text-right align-middle"}>{formataDisplayEmReal(papel.preco_unitario)}</td>
                                            <td className={"text-right align-middle"}>{formataDisplayEmReal(papel.preco_total)}</td>
                                            <td className={"text-right align-middle"}>{formataDisplayEmReal(papel.despesas)}</td>
                                            <td className={"text-right align-middle"}>{formataDisplayEmReal(papel.preco_total_final)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    renderLoad() {
        return (
            <div className="container w-100 mt-5">
                <div className="card-header bg-info border mt-2">
                    <h7>Lista de pagamentos</h7>
                </div>
                <div className="loader text-center mt-5"></div>
                <span className="text-warning">Aguarde... Carregando...</span>
            </div>
        )
    }
}

export default BovespaLista