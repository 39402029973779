import React, { Component } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'
import 'moment/locale/pt-br'
import { formataDisplayEmReal, formataEmRealParaBD, preencheComboFatura } from '../../util/Utils'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}

var categoriaInicial = {
    categoria_id: null,
    sub_categoria_id: null,
    amigo_id: null,
    evento_id: null,
    descricao: null,
    tipo: null,
    comentarios: null,
    valor: 'R$ 0,00',
    fatura: null,
    entra_nas_estatisticas: true
}

class PagamentoPagarFatura extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: this.props.pagamento,
            contas: [],
            conta: { tipo_conta: null },
            faturas: [],
            faturasLidas: [],
            alerta: false,
            alertaMsg: null,
            confirma: false

        }
    }

    componentWillMount() {

        var mesExtenso = moment(this.state.pagamento.fatura.fatura).format('MMMM')
        var anoExtenso = moment(this.state.pagamento.fatura.fatura).format('YYYY')
        var fatura = mesExtenso.toUpperCase() + '/' + anoExtenso
        this.setState({ fatura: fatura })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.data_de_pagamento = this.state.pagamento.data_de_vencimento
        pagamentoCPY.frequencia = this.state.pagamento.transacao.frequencia
        pagamentoCPY.numero_de_parcelas = this.state.pagamento.transacao.numero_de_parcelas
        pagamentoCPY.valor = formataDisplayEmReal(this.state.pagamento.valor)
        this.setState({ pagamento: pagamentoCPY })

        this.listaContas()
    }

    handleChangeDescricao(event) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.descricao = event.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeValor(event) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.valor = formataDisplayEmReal(event.target.value)

        if (formataEmRealParaBD(pagamentoCPY.valor) === 0) {
            pagamentoCPY.categorias = []
            this.setState({ categoria: JSON.parse(JSON.stringify(categoriaInicial)) })
        }

        this.setState({ pagamento: pagamentoCPY })

    }

    handleChangeTipo(tipo) {
        var pagamentoCPY = this.state.pagamento

        pagamentoCPY.tipo = tipo.value

        this.setState({ pagamento: pagamentoCPY })

        if (tipo.value === 'credito') {
            this.setState({ categorias: this.state.categoriasCredito })
        } else {
            this.setState({ categorias: this.state.categoriasDebito })
        }
    }

    handleChangeVencimento(data_de_pagamento) {
        var dataInicial = moment().add(-1, 'year').format('YYYY-MM-DD')
        var dataFinal = moment().add(1, 'year').format('YYYY-MM-DD')
        var dataDigitada = moment(data_de_pagamento).format('YYYY-MM-DD')

        if (moment(dataDigitada).isAfter(dataFinal)) {
            return
        }

        if (moment(dataDigitada).isBefore(dataInicial)) {
            return
        }

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.data_de_pagamento = data_de_pagamento
        pagamentoCPY.fatura = null
        this.setState({ pagamento: pagamentoCPY })

        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_pagamento, this.state.conta.conta_cartoes_creditos.dia_fechamento, this.state.conta.conta_cartoes_creditos.dia_pagamento)
            this.setState({ faturas: faturas })
        }
    }

    handleChangeConta(conta) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.conta_id = conta.id

        if (conta.tipo_conta === 'cartaoCredito') {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_pagamento, this.state.conta.conta_cartoes_creditos.dia_fechamento, this.state.conta.conta_cartoes_creditos.dia_pagamento)
            this.setState({ faturas: faturas })
            this.listaFaturas(conta.id)
        } else {
            this.setState({ faturas: [] })
            pagamentoCPY.fatura = null
            pagamentoCPY.fatura_pg = null
        }

        this.setState({ pagamento: pagamentoCPY })

        this.setState({ conta: conta })
    }

    handleChangeFatura(fatura) {

        var faturaLida = this.state.faturasLidas.find(function (obj) { return obj.fatura === fatura.value })

        if (faturaLida !== undefined && faturaLida.status === 'liquidado') {
            this.setState({ alerta: true, alertaMsg: 'Esta fatura já esta liquidada, não pode ser alterada!!' })
            return
        }

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.fatura_pg = fatura.value
        this.setState({ pagamento: pagamentoCPY })
    }


    handleChangeComentarios(comentarios) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.comentarios = comentarios.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    voltaDaLista(pagamento) {
        this.setState({ pagamento: pagamento, categoriaLista: false })

        if (this.state.pagamento.categorias.length !== 1) {
            return
        }

        if (this.state.pagamento.categorias.length === 1) {
            const categoriaCPY = this.state.pagamento.categorias[0]
            this.setState({ categoria: categoriaCPY })
        }

        const categoria_id = this.state.pagamento.categorias[0].categoria_id

        const categoria = this.state.categorias.find(function (obj) { return obj.id === categoria_id })

        this.setState({ subCategorias: categoria.sub_categorias })

    }

    confirma() {
        
        if (isNaN(formataEmRealParaBD(this.state.pagamento.valor))) {
            this.setState({ alerta: true, alertaMsg: 'Valor invalido' })
            return
        }


        if (formataEmRealParaBD(this.state.pagamento.valor) < 0) {
            this.setState({ alerta: true, alertaMsg: 'Valor a pagar não pode ser negativo' })
            return
        }

        if (moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD') === 'Invalid date') {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento valida é obrigatória' })
            return
        }

        if (this.state.pagamento.descricao === null || this.state.pagamento.descricao.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Descrição deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.pagamento.conta_id === null) {
            this.setState({ alerta: true, alertaMsg: 'Conta obrigatória' })
            return
        }

        this.setState({ pedeConfirmacao: true })
    }

    render() {
        if (this.state.categoriaLista === true) {
            return [
                this.renderCategoria()
            ]
        } else {
            return [
                this.renderAlert(),
                this.renderAlertaSaida(),
                this.renderConfirmacao(),
                this.renderComum()
            ]
        }
    }

    renderComum() {

        //input fatura
        var inputFatura = null

        var inputFaturaPreenchido =
            <div className="form-group col-md-3">
                <label for="fatura" className="">Fatura</label>
                <Select
                    styles={customStyles}
                    id="fatura"
                    name="fatura"
                    value={this.state.faturas.filter(option => option.value === this.state.pagamento.fatura_pg)}
                    onChange={this.handleChangeFatura.bind(this)}
                    options={this.state.faturas}
                />
            </div>


        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            inputFatura = inputFaturaPreenchido
        } else {
            inputFatura = null
        }


        return (

            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Pagar esta fatura</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-2">
                            <label for="valor">Valor</label>
                            <input
                                onChange={this.handleChangeValor.bind(this)}
                                value={this.state.pagamento.valor}
                                type="valor"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                        <div className="col-md-2">
                            <label className="" >Vencimento</label>
                            <DatePicker
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeVencimento"
                                selected={moment(this.state.pagamento.data_de_pagamento).toDate()}
                                onChange={this.handleChangeVencimento.bind(this)} />
                        </div>
                        <div className="form-group col-md-5">
                            <label for="descricao">Descrição</label>
                            <input
                                onChange={this.handleChangeDescricao.bind(this)}
                                value={this.state.pagamento.descricao}
                                type="text"
                                maxlength="50"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        <div className="form-group col-md-4">
                            <label for="conta" className="">Conta Débito</label>
                            <Select
                                styles={customStyles}
                                id="conta"
                                name="conta"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                selectedValue={this.state.conta_id}
                                value={this.state.contas.filter(option => option.id === this.state.pagamento.conta_id)}
                                onChange={this.handleChangeConta.bind(this)}
                                options={this.state.contas}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label for="descricao" className="font-weight-bold">Fatura a ser paga:</label>
                            <input
                                value={this.state.fatura}
                                type="text"
                                className="form-control"
                                id="fatura"
                                placeholder="Descrição" />
                        </div>
                        {inputFatura}
                    </div>
                    <div class="form-group col-md-11">
                        <label className="m-2" for="comment">Comentários</label>
                        <textarea
                            value={this.state.comentarios}
                            className="form-control m-2"
                            rows="2" id="comum:comentarios"
                            onChange={this.handleChangeComentarios.bind(this)}>
                        </textarea>
                    </div>
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.confirma() }}>Pagar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.efetuarPagamento()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma o pagamento desta fatura?" />
                </>
            )
        }
    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.somenteContasAbertas(responseJson.contas)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    somenteContasAbertas(contasInput) {
        var contas = []

        contasInput.map(async (conta, index) => {
            if (conta.status === 'aberta') {
                if (conta.tipo_conta !== 'cartaoCredito') {
                    contas.push(conta)
                }
            }
        })

        this.setState({ contas: contas })
    }


    listaFaturas(conta_id) {
        let url = global.url + 'cartaoCredito/listaFaturas/' + conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({
                        faturasLidas: responseJson.faturas
                    })
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    efetuarPagamento() {

        this.setState({ pedeConfirmacao: false })

        if (isNaN(formataEmRealParaBD(this.state.pagamento.valor))) {
            this.setState({ alerta: true, alertaMsg: 'Valor invalido' })
            return
        }


        if (formataEmRealParaBD(this.state.pagamento.valor) < 0) {
            this.setState({ alerta: true, alertaMsg: 'Valor a pagar não pode ser negativo' })
            return
        }

        if (moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD') === 'Invalid date') {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento valida é obrigatória' })
            return
        }

        if (this.state.pagamento.descricao === null || this.state.pagamento.descricao.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Descrição deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.pagamento.conta_id === null) {
            this.setState({ alerta: true, alertaMsg: 'Conta obrigatória' })
            return
        }

        var fatura_pg = null

        if (this.state.pagamento.fatura_pg !== undefined) {
            fatura_pg = this.state.pagamento.fatura_pg
        }


        var pagamento = {
            id: this.state.pagamento.id,
            transacao_id: this.state.pagamento.transacao_id,
            fatura_id: this.state.pagamento.fatura_id,
            parcela: this.state.pagamento.parcela,
            descricao: this.state.pagamento.descricao,
            valor: formataEmRealParaBD(this.state.pagamento.valor),
            status: 'liquidado',
            data_de_vencimento_ano_mes: this.state.pagamento.data_de_vencimento_ano_mes,
            data_de_vencimento: this.state.pagamento.data_de_vencimento,
            data_de_pagamento: moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD'),
            frequencia: this.state.pagamento.frequencia,
            numero_de_parcelas: this.state.pagamento.numero_de_parcelas,
            tipo: this.state.pagamento.tipo,
            receberei_reembolso: this.state.pagamento.receberei_reembolso,
            pagarei_reembolso: this.state.pagamento.pagarei_reembolso,
            pagamento_id_reembolso: this.state.pagamento.pagamento_id_reembolso,
            comentarios: this.state.pagamento.comentarios,
            conta_id: this.state.pagamento.conta_id,
            fatura: this.state.pagamento.fatura,
            fatura_pg: fatura_pg,
            categorias: null,
            transacao: this.state.pagamento.transacao
        }

        let url = null

        if (fatura_pg !== null) {
            url = global.url + 'fatura/pagarFaturaDentroDeOutraFatura'
        } else {
            url = global.url + 'fatura/pagarFatura'
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ resp: responseJson.resp })
                    this.setState({ alertaSaida: true, alertaMsg: 'Pagamento da fatura concluido' })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

}

export default PagamentoPagarFatura