import React, { Component } from 'react'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/pt-br';
import moment from 'moment'
import { formataDisplayEmReal, formataEmRealParaBD, formataEmRealDisplayDoBD } from '../../util/Utils'
import { extensoTipo } from '../../util/extenso'
import ModalAviso from '../modal/ModalAviso'
import './style.css'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}

var consideraNosRelatorios = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' }
]

var tipos = [
    { value: 'debito', label: 'Débito' },
    { value: 'credito', label: 'Crédito' }
]

var categoriaInicial = {
    categoria_id: null,
    sub_categoria_id: null,
    amigo_id: null,
    evento_id: null,
    descricao: null,
    tipo: null,
    comentarios: null,
    valor: 'R$ 0,00',
    entra_nas_estatisticas: true
}

var consulta = false

class PagamentoCategoria extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: this.props.pagamento,
            categoriaLista: true,
            categoriaInclui: false,
            categoriaAltera: false,
            categoriaConsulta: false,
            categoriaExclui: false,
            categoriaIndex: null,
            sinal: null,
            categoriasTodas: [],
            categorias: [],
            subCategorias: [],
            amigos: [],
            eventos: [],
            categoria: categoriaInicial,
            cabecalho: '',
            orcamentos: [],
            dataVencimento: null
        }
    }

    componentWillMount() {

        var dataVencimento = moment(this.state.pagamento.data_de_vencimento).startOf('month').format('YYYY-MM-DD')

        this.setState({ dataVencimento: dataVencimento })

        var anoAtual = parseInt(moment(this.state.pagamento.data_de_vencimento).format('YYYY'))
        this.listaOrcamentos(anoAtual)

        if (this.props.operacao === 'consulta') {
            consulta = true
        }

        if (this.state.pagamento.tipo === 'debito') {
            this.setState({ sinal: '-' })
        } else {
            this.setState({ sinal: '+' })
        }

        this.listaCategorias()
        this.listaAmigos()
        this.listaEventos()
    }

    listaOrcamentos(ano) {
        let url = global.url + 'orcamento/lista/' + ano

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ orcamentos: responseJson.orcamentos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    obtemOrcamentoAteAgora() {

        let orcamento = {
            data: this.state.pagamento.data_de_vencimento,
            categorias: this.state.pagamento.categorias

        }

        let url = global.url + 'orcamento/listaOrcamentoParcial'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(orcamento)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ resp: responseJson.resp })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    selecionaCategorias(categoriasLista) {

        this.setState({ categoriasTotal: JSON.parse(JSON.stringify(categoriasLista)) })
        this.setState({ categoriasTodas: JSON.parse(JSON.stringify(categoriasLista)) })

        var result = false

        var categoriasDebito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'debito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        var categoriasCredito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'credito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        this.setState({ categoriasDebito: categoriasDebito, categorias: categoriasDebito })
        this.setState({ categoriasCredito: categoriasCredito })

        this.setState({ categoriasDebito: categoriasDebito })
        this.setState({ categoriasCredito: categoriasCredito })

    }

    listaAmigos() {
        let url = global.url + 'amigo/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var amigo = {
                        id: null,
                        nome: '------'
                    }
                    responseJson.amigos.push(amigo)
                    this.setState({ amigos: responseJson.amigos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaEventos() {
        let url = global.url + 'evento/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var evento = {
                        id: null,
                        descricao: '------'
                    }
                    responseJson.eventos.push(evento)
                    this.setState({ eventos: responseJson.eventos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    acessaValorPlanejado(categoria) {

        var valorRetornado = '0.00'

        this.state.orcamentos.map(async (orcamento, index1) => {
            if (orcamento.data === this.state.dataVencimento) {
                orcamento.categorias.map(async (categoriaOc, index2) => {
                    if (categoriaOc.id === categoria.categoria_id) {
                        categoriaOc.sub_categorias.map(async (subcategoria, index3) => {
                            if (categoria.sub_categoria_id === subcategoria.id) {
                                valorRetornado = subcategoria.meta
                            }
                        })
                    }
                })
            }
        })

        return formataDisplayEmReal(valorRetornado)
    }

    acessaValorRealizado(categoria) {

        var valorRetornado = '0.00'

        this.state.orcamentos.map(async (orcamento, index1) => {
            if (orcamento.data === this.state.dataVencimento) {
                orcamento.categorias.map(async (categoriaOc, index2) => {
                    if (categoriaOc.id === categoria.categoria_id) {
                        categoriaOc.sub_categorias.map(async (subcategoria, index3) => {
                            if (categoria.sub_categoria_id === subcategoria.id) {
                                valorRetornado = subcategoria.realizada
                            }
                        })
                    }
                })
            }
        })

        return formataDisplayEmReal(valorRetornado)
    }

    render() {
        return [
            this.renderAlert(),
            this.renderCategoriaLista(),
            this.renderCategoriaDetalhe()
        ]
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderCategoriaLista() {


        if (this.state.categoriaLista === true) { 
            return (
                <div className="container w-100 mt-5 mb-0">
                    <div className="form-group row mt-0 mb-0">
                        <div className="col-sm-10">
                            {this.props.operacao !== 'consulta' &&
                                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.preparaIncluiCategoria() }}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Novo</button>
                            }
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.voltaPrincipal() }}>Voltar</button>
                        </div>
                    </div>
                    <div className="card row bg-transparent">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>Lista de categorias dentro do pagamento | <span className="text-white">{formataDisplayEmReal(this.state.pagamento.valor)}</span></h5>
                            </div>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="border text-center align-middle" ROWSPAN="2"><i class="fa fa-info" aria-hidden="true"></i></th>
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>id</h6></th>
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Tipo</h6></th>
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Categoria</h6></th>
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Subcategoria</h6></th>
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Descrição</h6></th>
                                    <th scope="col" className="border align-middle text-center" COLSPAN="2"><h6>Orçamento</h6></th>
                                    <th scope="col" className="border align-middle text-center" COLSPAN="1" ROWSPAN="2"><h6>Valor</h6></th>
                                    {this.props.operacao !== 'consulta' &&
                                        <th scope="col" COLSPAN="1" ROWSPAN="2"></th>
                                    }
                                </tr>
                                <tr className="bg-info">
                                    <th className="border align-middle text-center" COLSPAN="1" ><h6>Planejado</h6></th>
                                    <th className="border align-middle text-center" COLSPAN="1" ><h6>Realizado</h6></th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.pagamento.categorias.map((categoria, index) => {
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado m-1">
                                            <td className="align-middle text-white text-center"><button className="btn btn-link btn-sm m-2" onClick={() => { this.categoriaConsulta(categoria, index) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button></td>
                                            <td className="align-middle text-white  text-right">{categoria.id}</td>
                                            <td className="align-middle text-white">{extensoTipo(categoria.tipo)}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{this.acessaNomeCategoria(categoria)}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{this.acessaNomeSubCategoria(categoria)}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{categoria.descricao}</td>
                                            <td className="align-middle text-white text-right">{this.acessaValorPlanejado(categoria)}</td>
                                            <td className="align-middle text-white text-right">{this.acessaValorRealizado(categoria)}</td>
                                            <td className="align-middle text-white text-right">{formataDisplayEmReal(categoria.valor)}</td>
                                            {this.props.operacao !== 'consulta' &&
                                                <td className="border align-middle" ><button className="btn btn-link btn-sm" onClick={() => { this.categoriaAltera(categoria, index) }}><i class="fa fa-pencil rgm-cor-link" aria-hidden="true"></i></button></td>
                                            }
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    renderCategoriaDetalhe() {

        var varDisabled = false

        if (this.state.categoriaConsulta === true) {
            varDisabled = true
        }

        if (this.state.categoriaLista === false) {
            return (
                <div className="container w-100 mt-5"  >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div class="card-header border">
                            <div className="row">
                                <h5>{this.state.cabecalho}</h5>
                            </div>
                        </div>

                        <div className="ml-3 form-row mt-2">
                            <div className="form-group col-md-2">
                                <label for="tipo" className="">Tipo</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled={varDisabled}
                                    id="tipo"
                                    name="tipo"
                                    value={tipos.filter(option => option.value === this.state.categoria.tipo)}
                                    onChange={this.handleChangeTipo.bind(this)}
                                    options={tipos}
                                />
                            </div>
                            <div className="form-group col-md-2">
                                <label for="valor">Valor</label>
                                <input
                                    readOnly={varDisabled}
                                    onChange={this.handleChangeValor.bind(this)}
                                    value={formataDisplayEmReal(this.state.categoria.valor)}
                                    type="text"
                                    className="form-control"
                                    id="valor"
                                    placeholder="Nom" />
                            </div>
                            <div className="form-group col-md-6">
                                <label for="descricao">Descrição</label>
                                <input
                                    readOnly={varDisabled}
                                    onChange={this.handleChangeDescricao.bind(this)}
                                    value={this.state.categoria.descricao}
                                    type="text"
                                    maxlength="50"
                                    className="form-control"
                                    id="descricao"
                                    placeholder="Descrição" />
                            </div>
                        </div>
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-4">
                                <label for="categoria" className="">Categoria</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled={varDisabled}
                                    id="categoria"
                                    name="categoria"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.categorias.filter(option => option.id === this.state.categoria.categoria_id)}
                                    onChange={this.handleChangecategoria.bind(this)}
                                    options={this.state.categorias}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label for="subCategoria" className="">Subcategoria</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled={varDisabled}
                                    id="subCategoria"
                                    name="subCategoria"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.subCategorias.filter(option => option.id === this.state.categoria.sub_categoria_id)}
                                    onChange={this.handleChangeSubcategoria.bind(this)}
                                    options={this.state.subCategorias}
                                />
                            </div>
                        </div>
                        <div className="ml-3 form-row">
                            <div className="form-group col-md-4">
                                <label for="amigo" className="">Amigo</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled={varDisabled}
                                    id="amigo"
                                    name="amigo"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.amigos.filter(option => option.id === this.state.categoria.amigo_id)}
                                    onChange={this.handleChangeAmigo.bind(this)}
                                    options={this.state.amigos}
                                />
                            </div>
                            <div className="form-group col-md-4">
                                <label for="evento" className="">Evento</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled={varDisabled}
                                    id="evento"
                                    name="evento"
                                    getOptionLabel={({ descricao }) => descricao}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.eventos.filter(option => option.id === this.state.categoria.evento_id)}
                                    onChange={this.handleChangeEvento.bind(this)}
                                    options={this.state.eventos}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label for="tipo" className="">Considerar nos relatórios</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled={varDisabled}
                                    id="consideraNoRelatorio"
                                    name="consideraNoRelatorio"
                                    value={consideraNosRelatorios.filter(option => option.value === this.state.categoria.entra_nas_estatisticas)}
                                    onChange={this.handleChangeConsideraNosRelatorios.bind(this).bind(this)}
                                    options={consideraNosRelatorios}
                                />
                            </div>
                        </div>
                        <div class="form-group col-md-11">
                            <label className="m-2" for="comment">Comentários</label>
                            <textarea
                                readOnly={varDisabled}
                                value={this.state.categoria.comentarios}
                                className="form-control mt-2"
                                rows="2" id="comum:comentarios"
                                onChange={this.handleChangeComentarios.bind(this)}>
                            </textarea>
                        </div>
                        <div class="card-header border">
                            <div className="row">
                                {this.renderBotoesInclui()}
                                {this.renderBotoesAltera()}
                                {this.renderBotoesConsulta()}
                            </div>
                        </div>
                    </form>
                </div>
            )
        } else {
            return null
        }
    }

    renderBotoesInclui() {
        if (this.state.categoriaInclui === true) {
            return (
                <div className="container w-100"  >
                    <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.setState({ categoriaLista: true, categoriaInclui: false }) }}>Voltar</button>
                    <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.saveCategoria() }}>Salvar</button>
                </div>
            )
        }
    }

    renderBotoesAltera() {
        if (this.state.categoriaAltera === true) {
            return (
                <div className="container w-100"  >
                    <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.setState({ categoriaLista: true, categoriaAltera: false }) }}>Voltar</button>
                    <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.saveCategoria() }}>Salvar</button>
                    {this.state.pagamento.categorias.length > 1 &&
                        <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.excluiCategoria() }}>Excluir</button>
                    }
                </div>
            )
        }
    }

    renderBotoesConsulta() {
        if (this.state.categoriaConsulta === true) {
            return (
                <div className="container w-100"  >
                    <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.setState({ categoriaLista: true, categoriaConsulta: false }) }}>Voltar</button>
                </div>
            )
        }
    }

    acessaNomeCategoria(categoriaListada) {
        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === categoriaListada.categoria_id })

        if (categoria === undefined) {
            return '---'
        }

        return categoria.nome
    }

    acessaNomeSubCategoria(categoriaListada) {
        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === categoriaListada.categoria_id })

        if (categoria === undefined) {
            return '---'
        }

        const subCategoria = categoria.sub_categorias.find(function (obj) { return obj.id === categoriaListada.sub_categoria_id })

        if (subCategoria === undefined) {
            return '---'
        }

        return subCategoria.nome
    }

    acessaNomeAmigo(amigo_id) {
        const amigo = this.state.amigos.find(function (obj) { return obj.id === amigo_id })

        if (amigo === undefined) {
            return '---'
        }

        return amigo.nome
    }

    acessaDescricaoEvento(evento_id) {
        const evento = this.state.eventos.find(function (obj) { return obj.id === evento_id })

        if (evento === undefined) {
            return '---'
        }

        return evento.descricao
    }

    preparaIncluiCategoria() {
        var categoria = JSON.parse(JSON.stringify(categoriaInicial))
        this.setState({ cabecalho: 'Inclusão de categoria dentro do pagamento' })
        this.setState({ categoriaLista: false, categoriaInclui: true })
        this.setState({ categoria: categoria, subcategoria: '' })
    }

    categoriaConsulta(categoria, index) {

        if (categoria.tipo === 'debito') {
            var categoria_id = categoria.categoria_id
            var categoriaLida = this.state.categoriasTodas.find(function (obj) { return obj.id === categoria_id })
            this.setState({ categorias: this.state.categoriasDebito, subCategorias: categoriaLida.sub_categorias })
        } else {
            this.setState({ categorias: this.state.categoriasCredito })
        }

        this.setState({ cabecalho: 'Consulta de categoria dentro do pagamento' })

        this.setState({
            categoriaLista: false,
            categoriaConsulta: true
        })

        this.setState({ categoria: categoria })

    }

    categoriaAltera(categoria, index) {

        if (this.props.operacao === 'consulta') {
            return
        }

        if (categoria.tipo === 'debito') {
            var categoria_id = categoria.categoria_id
            var categoriaLida = this.state.categoriasTodas.find(function (obj) { return obj.id === categoria_id })
            this.setState({ categorias: this.state.categoriasDebito, subCategorias: categoriaLida.sub_categorias })
        } else {
            this.setState({ categorias: this.state.categoriasCredito })
        }

        this.setState({ cabecalho: 'Alteração de categoria dentro do pagamento' })

        this.setState({
            categoriaLista: false,
            categoriaAltera: true,
            categoriaIndex: index
        })

        this.setState({ categoria: categoria })

    }


    handleChangeTipo(tipo) { 
        var categoria = this.state.categoria
        categoria.tipo = tipo.value
        categoria.categoria_id = null
        categoria.sub_categoria_id = null
        this.setState({ categoria: categoria })

        if (tipo.value === 'credito') {
            this.setState({ categorias: this.state.categoriasCredito })
        } else {
            this.setState({ categorias: this.state.categoriasDebito })
        }
    }

    handleChangeValor(event) {

        var valor = event.target.value

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        var categoria = this.state.categoria
        categoria.valor = formataDisplayEmReal(valor)

        if (categoria.valor.includes("-", 0)) {
            return
        }

        this.setState({ categoria: categoria })
    }

    handleChangeDescricao(descricao) {
        var categoria = this.state.categoria
        categoria.descricao = descricao.target.value
        this.setState({ categoria: categoria })
    }

    handleChangecategoria(categoriaInp) {
        var categoria = this.state.categoria
        categoria.categoria_id = categoriaInp.id
        categoria.sub_categoria_id = null
        this.setState({ categoria: categoria, subCategorias: categoriaInp.sub_categorias })
    }

    handleChangeSubcategoria(subCategoria) {
        var categoria = this.state.categoria
        categoria.sub_categoria_id = subCategoria.id
        this.setState({ categoria: categoria })
    }

    handleChangeAmigo(amigo) {
        var categoria = this.state.categoria
        categoria.amigo_id = amigo.id
        this.setState({ categoria: categoria })
    }

    handleChangeEvento(evento) {
        var categoria = this.state.categoria
        categoria.evento_id = evento.id
        this.setState({ categoria: categoria })
    }

    handleChangeComentarios(comentarios) {
        var categoria = this.state.categoria
        categoria.comentarios = comentarios.target.value
        this.setState({ categoria: categoria })
    }

    handleChangeConsideraNosRelatorios(event) {
        var categoria = this.state.categoria
        categoria.entra_nas_estatisticas = event.value
        this.setState({ categoria: categoria })
    }


    excluiCategoria() {

        this.setState({ categoriaExclui: true }, () => { this.saveCategoria() })
    }

    saveCategoria() {

        if (moment(this.state.pagamento.data_de_vencimento).format('YYYY-MM-DD') === 'Invalid date') {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento valida é obrigatória' })
            return
        }

        if (this.state.categoria.tipo === null) {
            this.setState({ alerta: true, alertaMsg: 'Tipo é obrigatória' })
            return
        }



        if (formataEmRealParaBD(this.state.categoria.valor) === 0) {
            this.setState({ alerta: true, alertaMsg: 'Valor obrigatório' })
            return
        }

        if (this.state.categoria.descricao === null || this.state.categoria.descricao.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Descrição deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.categoria.categoria_id === null || this.state.categoria.sub_categoria_id === null) {
            this.setState({ alerta: true, alertaMsg: 'Categoria/Subcategoria obrigatória' })
            return
        }

        //this.setState({ alerta: true, alertaMsg: 'As alterações só serão salvas na primeira tela' })

        var pagamentoCPY = this.state.pagamento

        if (this.state.categoriaInclui === true) {
            pagamentoCPY.categorias.push(this.state.categoria)
        }

        if (this.state.categoriaAltera === true) {
            pagamentoCPY.categorias.splice(this.state.categoriaIndex, 1)
            if (this.state.categoriaExclui === false) {
                pagamentoCPY.categorias.push(this.state.categoria)
            }
        }

        var totalApurado = 0.00

        this.state.pagamento.categorias.map(async (categoria, index) => {
            var valor = 0.00
            valor = formataEmRealParaBD(categoria.valor)
            if (categoria.tipo === 'debito') {
                totalApurado = totalApurado - valor
            } else {
                totalApurado = totalApurado + valor
            }
        })

        totalApurado = parseFloat(totalApurado)

        if (totalApurado < 0) {
            totalApurado = totalApurado * -1
            this.setState({ sinal: '-' })
        } else {
            this.setState({ sinal: '+' })
        }

        pagamentoCPY.valor = formataEmRealDisplayDoBD(totalApurado)

        this.setState({
            pagamento: pagamentoCPY,
            categoriaLista: true,
            categoriaInclui: false,
            categoriaAltera: false,
            categoriaExclui: false
        })

    }

    voltaPrincipal() {
        if (this.state.pagamento.categorias.length > 0) {
            if (this.state.pagamento.tipo === 'debito' && this.state.sinal !== '-') {
                this.setState({ alerta: true, alertaMsg: 'Transação de débito não pode ter o total positivo' })
                return
            }

            if (this.state.pagamento.tipo === 'credito' && this.state.sinal !== '+') {
                this.setState({ alerta: true, alertaMsg: 'Transação de crédito não pode ter o total negativo' })
                return
            }
        }

        this.props.volta(this.state.pagamento)
    }

}

export default PagamentoCategoria