import React, { Component } from 'react'
import UsuarioConsulta from '../usuario/UsuarioConsulta'
import UsuarioAlteracao from '../usuario/UsuarioAlteracao'
import UsuarioAlteracaoSenha from '../usuario/UsuarioAlteracaoSenha'
class Usuario extends Component {
    constructor(props) {
        super(props)
        this.state = {
            usuarioConsulta: true,
            usuarioAlteracao: false,
            usuarioAlteracaoSenha: false,
            usuario: null
        }
    }

    usuarioConsultaTrue(usuario) {
        this.setFalseAll()
        this.setState({ usuarioConsulta: true, usuario: usuario })
    }

    usuarioAlteracaoSenhaTrue(usuario) {
        this.setFalseAll()
        this.setState({ usuarioAlteracaoSenha: true, usuario: usuario })
    }

    usuarioAlteracaoTrue(usuario) {
        this.setFalseAll()
        this.setState({ usuarioAlteracao: true, usuario: usuario })
    }

    setFalseAll() {
        this.setState({
            usuarioConsulta: false,
            usuarioAlteracao: false,
            usuarioAlteracaoSenha: false
        })
    }

    render() {

        if (this.state.usuarioConsulta === true) {
            return (<
                UsuarioConsulta
                usuario={this.state.usuario}
                alteracao={(usuario) => { this.usuarioAlteracaoTrue(usuario) }}
                alteracaoSenha={(usuario) => { this.usuarioAlteracaoSenhaTrue(usuario) }}
            />
            )
        }

        if (this.state.usuarioAlteracaoSenha === true) {
            return (<
                UsuarioAlteracaoSenha
                usuario={this.state.usuario}
                desconecta={() => { this.props.logOff() }}
                volta={(usuario) => {this.usuarioConsultaTrue(usuario)} }

            />
            )
        }

        if (this.state.usuarioAlteracao === true) {
            return (<
                UsuarioAlteracao
                usuario={this.state.usuario}
                desconecta={() => { this.props.logOff() }}
                volta={(usuario) => {this.usuarioConsultaTrue(usuario)} }

            />
            )
        }


        if (this.state.usuarioConsulta === false &&
            this.state.usuarioAlteracaoSenha === false &&
            this.state.usuarioAlteracao === false
        ) {
            return null
        }

    }

}

export default Usuario