import React from 'react'
import { Component } from 'react';
import moment from 'moment'
import DatePicker from 'react-datepicker';
import { formataDisplayEmReal, formataEmRealParaBD } from '../../util/Utils'
import { extensoFrequencia, extensoTipo } from '../../util/extenso'
import 'react-datepicker/dist/react-datepicker.css';
import ModalAviso from '../modal/ModalAviso'
import PagamentoConsulta from '../pagamento/PagamentoConsulta'
import PagamentoConsultaFatura from '../pagamento/PagamentoConsultaFatura'


import { Alert } from 'react-bootstrap';


class PesquisaLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamentos: [],
            contas: [],
            pesquisa: true,
            lista: false,
            descricao: '',
            valorInicial: '',
            valorFinal: '',
            pagamento: null
        }
    }

    componentWillMount() {
        this.listaContas()
    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contas: responseJson.contas })
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }



    mudaParaLista() {
        this.setState({ pesquisa: false, lista: true })
        let url = global.url + 'pesquisa/lista'


        var descricao = null
        var valorInicial = null
        var valorFinal = null


        if (this.state.valorInicial === '') {
            valorInicial = null
            valorFinal = null
        } else {
            valorInicial = formataEmRealParaBD(this.state.valorInicial)
            if (this.state.valorFinal === '') {
                valorFinal = formataEmRealParaBD(this.state.valorInicial)
            } else {
                valorFinal = formataEmRealParaBD(this.state.valorFinal)
            }
        }

        if (this.state.descricao === '') {
            descricao = null
        } else {
            descricao = this.state.descricao
        }


        let pesquisa = {
            descricao: descricao,
            valores: {
                valorInicial: valorInicial,
                valorFinal: valorFinal
            }
        }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(pesquisa)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ pagamentos: responseJson.pagamentos })
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }

    mudaParaUltimasTransacoes() {
        this.setState({ pesquisa: false, lista: true })
        let url = global.url + 'pesquisa/listaUltimasTransacoes'

        let pesquisa = {
            descricao: this.state.descricao
        }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(pesquisa)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ pagamentos: responseJson.pagamentos })
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }


    mudaParaPesquisa() {
        this.setState({ pesquisa: true, lista: false })
    }

    handleChangeDescricao(event) {

        var descricao = event.target.value

        this.setState({ descricao: descricao })

        this.setState({ valorInicial: '', valorFinal: '' })
    }

    handleChangeValorInicial(event) {

        this.setState({ descricao: '' })

        var valor = event.target.value

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        valor = formataDisplayEmReal(valor)

        if (valor.includes("-", 0)) {
            return
        }

        this.setState({ valorInicial: valor })

    }

    handleChangeValorFinal(event) {

        if (this.state.valorInicial === '') {
            return
        }

        this.setState({ descricao: null })

        var valor = event.target.value

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        valor = formataDisplayEmReal(valor)

        if (valor.includes("-", 0)) {
            return
        }

        this.setState({ valorFinal: valor })

    }


    obtemDescricao(pagamento) {

        var dias = 0

        if (pagamento.status === 'liquidado') {
            if (pagamento.data_de_vencimento < pagamento.data_de_pagamento) {
                dias = moment(pagamento.data_de_pagamento).diff(moment(pagamento.data_de_vencimento), 'days');
                return pagamento.descricao + ' ** pago com atraso de ' + dias + ' dias **'
            } else {
                return pagamento.descricao
            }
        } else {
            if (pagamento.data_de_vencimento < moment().format('YYYY-MM-DD')) {
                dias = moment().diff(moment(pagamento.data_de_vencimento), 'days');
                return pagamento.descricao + ' ** em atraso, a ' + dias + ' dias **'
            } else {
                if (pagamento.data_de_vencimento === moment().format('YYYY-MM-DD')) {
                    return pagamento.descricao + ' ** vence hoje **'
                } else {
                    return pagamento.descricao
                }
            }
        }
    }

    nomeConta(conta_id) {
        const conta = this.state.contas.find(conta => conta.id === conta_id);

        return conta.nome
    }

    formataVencimento(data) {

        const dataFormatada = moment(data).format('DD/MM/YYYY')

        return dataFormatada

    }

    formataPagamento(data) {

        if (data === null) {
            return '-'
        }

        const dataFormatada = moment(data).format('DD/MM/YYYY')

        return dataFormatada

    }

    obtemParcelas(pagamento) {
        if (pagamento.frequencia === 'recorrente') {
            return ('000' + pagamento.parcela).slice(-3) + '/999'
        } else {
            if (pagamento.transacao.numero_de_parcelas === null) {
                return ''
            } else {
                return ('000' + pagamento.parcela).slice(-3) + '/' + ('000' + pagamento.transacao.numero_de_parcelas).slice(-3)
            }
        }
    }


    pagamentoConsulta(pagamento) {
        this.setState({ pagamento: pagamento, lista: false, relatorioPagasConsulta: true })
    }

    render() {
        if (this.state.pesquisa === true) {
            return [
                this.renderAlert(),
                this.renderPesquisa()
            ]
        }

        return [
            this.renderLista(),
            this.renderRelatorioPagasConsulta()
        ]


    }

    renderPesquisa() {
        return (
            <div className="container w-100 mt-5"    >
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.ultimasTransacoes()}>Manutenções recentes</button>
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Argumentos de pesquisa</h5>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label for="descricao" className="col-sm-2 col-form-label ml-2 mt-3">Descrição</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control mt-3" id="descricao" onChange={this.handleChangeDescricao.bind(this)} value={this.state.descricao} placeholder="Descrição" />
                        </div>
                    </div>
                    <hr />
                    <div className="form-group row">
                        <label for="descricao" className="col-sm-2 col-form-label ml-2 mt-3">Valores</label>
                        <div className="col-sm-3">
                            <input type="text" className="form-control mt-3" id="valorInicial" onChange={this.handleChangeValorInicial.bind(this)} value={this.state.valorInicial} placeholder="Valor inicial" />
                        </div>
                        <div className="col-sm-3">
                            <input type="text" className="form-control mt-3" id="valorFinal" onChange={this.handleChangeValorFinal.bind(this)} value={this.state.valorFinal} placeholder="Valor final" />
                        </div>
                    </div>
                    <div class="card-header border">
                        <div className="row">
                            <button id="buttonPesquisar" type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.pesquisar() }}>Pesquisar</button>
                        </div>
                    </div>

                </form>
            </div>

        )

    }

    renderLista() {
        if (this.state.lista === true) {
            return (
                <div className="container-fluid w-100 mt-5">
                    <div className="form-group row mt-0 mb-0">
                        <div className="col-sm-2" />
                        <div className="col-sm-6">
                            <button id="novoPgto" type="button" className="btn btn-primary btn-sm m-2" onClick={() => this.mudaParaPesquisa()} ><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Nova Pesquisa</button>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div className="card row bg-transparent">
                            <div class="card-header w-auto border rgm-bg-azul-esverdiado text-white">
                                <h5>Transações selecionadas</h5>
                            </div>
                            <table className="table table-bordered mt-0">
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                        <th scope="col">Descrição</th>
                                        <th scope="col" className="text-center">Vencimento</th>
                                        <th scope="col" className="text-center">Pagamento</th>
                                        <th scope="col" className="text-center">Status</th>
                                        <th scope="col">Conta</th>
                                        <th scope="col">Frequência</th>
                                        <th scope="col" className="text-center">Parcela</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col" className="text-right">Valor</th>
                                    </tr>
                                </thead>
                                <tbody className="rgm-bg-azul-esverdiado">
                                    {this.state.pagamentos.map((pagamento, index) => {
                                        return (
                                            <tr className={'rgm-bg-azul-esverdiado text-white '}>
                                                <td className="text-center text-white align-middle">
                                                    <button className="btn btn-link btn-sm text-center" onClick={() => { this.pagamentoConsulta(pagamento) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                                </td>
                                                <td className={"align-middle rgm-diminuirParaCaber "}>{this.obtemDescricao(pagamento)}</td>
                                                <td className={"text-center align-middle text-white "} >{this.formataVencimento(pagamento.data_de_vencimento)}</td>
                                                <td className={"text-center align-middle text-white "} >{this.formataPagamento(pagamento.data_de_pagamento)}</td>
                                                <td className={"text-center align-middle text-white "} >{pagamento.status}</td>
                                                <td className="align-middle text-white rgm-diminuirParaCaber ">{this.nomeConta(pagamento.conta_id)}</td>
                                                <td className="align-middle text-white">{extensoFrequencia(pagamento.frequencia)}</td>
                                                <td className="text-center align-middle text-white">{this.obtemParcelas(pagamento)}</td>
                                                <td className="align-middle text-white">{extensoTipo(pagamento.tipo)}</td>
                                                <td className={"text-right align-middle "}>{formataDisplayEmReal(pagamento.valor)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                {this.state.pagamentos.length === 0 &&
                                    <tbody>
                                        <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="10">
                                            <span>Não existem pagamentos para sua seleção</span>
                                        </td>
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </div>

            )
        }

    }

    renderRelatorioPagasConsulta() {

        if (this.state.relatorioPagasConsulta === true && this.state.pagamento.sub_categoria_nome_interno !== 'fatura') {
            return (<
                PagamentoConsulta pagamento={
                    this.state.pagamento
                }
                volta={() => { this.setState({ lista: true, relatorioPagasConsulta: false }) }}
            />
            )
        }

        if (this.state.relatorioPagasConsulta === true && this.state.pagamento.sub_categoria_nome_interno === 'fatura') {
            return (<
                PagamentoConsultaFatura pagamento={this.state.pagamento}
                volta={() => { this.setState({ lista: true, relatorioPagasConsulta: false }) }}
            />
            )
        }
    }


    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    pesquisar() {

        if (this.state.valorInicial !== '') {
            var valorInicial = formataEmRealParaBD(this.state.valorInicial)
            var valorFinal = formataEmRealParaBD(this.state.valorFinal)
            if (valorInicial > valorFinal) {
                this.setState({ alerta: true, alertaMsg: 'Valor inicial não pode ser maior que o valor final.' })
                return
            }
        }

        this.mudaParaLista()
    }

    ultimasTransacoes() {
        this.mudaParaUltimasTransacoes()
    }

}

export default PesquisaLista