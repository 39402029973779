import moment from 'moment';

/**
 * recebe uma string e devolve esta string no formato monetario
 * exemplo: recebe 123 e devolve R$ 1,23
 */
export const formataDisplayEmReal = (amount) => {

    if (amount === undefined) {
        alert('valor não definido')
    }

    if (typeof (amount) !== 'string') {
        amount = amount.toString()
    }


    var lengthString = amount.length;
    var traco = amount.substring(lengthString - 1, lengthString);

    if (traco !== '-') {
        traco = amount.substring(0, 1);
    }

    var tmp = amount;
    var numStr = tmp.replace(/[^0-9]/g, '');
    var atual = (parseInt(numStr, 10) / 100)

    if (traco === '-') {
        atual = atual * -1
    }

    var f = atual.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    return (f);
}

export const formataDisplayEmPercentual = (percentual) => {

    return (formataDisplayEmReal(percentual).replace('R$', '') + '%').trim()

}

export const formataEmRealParaBD = (amount) => {

    if (typeof (amount) === 'number') {
        alert('****** ERRO INESPERADO ****** já é um número comunique *******:' + typeof (amount) + ' ' + amount)
        throw new Error()
    }

    var lengthString = amount.length;
    var traco = amount.substring(lengthString - 1, lengthString);

    if (traco !== '-') {
        traco = amount.substring(0, 1);
    }

    var tmp = amount;
    var numStr = tmp.replace(/[^0-9]/g, '');
    var f = (parseInt(numStr, 10) / 100)

    if (traco === '-') {
        f = f * -1
    }

    return (f);
}


export const formataEmRealDisplayDoBD = (amount) => {
    if (amount == null) {
        return null
    }

    var f = amount.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })

    return (f);
}

export const preencheComboFatura = (date, diaFechamento, diaPagamento) => {
    var check = moment(date, 'YYYY/MM/DD');

    var dayChecagem = check.format('D');

    var mesesSomar = 0

    if (dayChecagem > diaFechamento) {
        mesesSomar = 1
    }

    if (diaFechamento > diaPagamento) {
        mesesSomar = mesesSomar + 1
    }

    var checkAux = moment(check).add(mesesSomar, 'month').format('YYYY/MM/DD')
    check = moment(checkAux, 'YYYY/MM/DD')

    var month = check.format('M');
    var day = check.format('D');
    var year = check.format('YYYY');

    day = 1;

    var minhaData = moment().year(year).month(month - 1).date(day).format('DD-MM-YYYY')

    var myDate = moment(minhaData, 'DD-MM-YYYY').toDate();

    var faturas = []

    var fatura = { value: null, label: null };

    var maximo = 2
    var dataEnd = moment(myDate).add(0, "months")

    for (var i = 0; i < maximo; i++) {
        dataEnd = moment(myDate).add(i, "months")
        fatura = { value: moment(dataEnd).format("YYYY-MM-DD"), label: moment(dataEnd).format("MM/YYYY") }
        faturas.push(fatura)
    }
    return faturas;

}

export const preencheComboDataOrcamento = (data, vezes) => {

    var minhaData = moment(data).startOf('year').format('YYYY-MM-DD');

    var mesesOrcamentos = []

    var mesesOrcamentosModelo = { value: null, label: null };

    var maximo = vezes

    for (var i = 0; i < maximo; i++) {
        var minhaDataWork = moment(minhaData).add(i, "year")
        mesesOrcamentosModelo = { value: moment(minhaDataWork).format("YYYY-MM-DD"), label: moment(minhaDataWork).format("YYYY") }
        mesesOrcamentos.push(mesesOrcamentosModelo)
    }

    return mesesOrcamentos

}


export const preencheComboReferencia = () => {

    var referencias = [];

    var check = moment();
    var month = check.format('M');
    var day = check.format('D');
    var year = check.format('YYYY');

    var yearInt = parseInt(year, 10)

    var yearIni = yearInt - 2;
    var yearFim = yearInt + 2;

    month = 0;
    day = 1
    var dataIni = moment().year(yearIni.toString()).month(month).date(day);

    month = 11;
    day = 31
    var dataFim = moment().year(yearFim.toString()).month(month).date(day);

    var duration = (moment(dataFim).diff(dataIni, "months") + 1)

    month = 0;
    day = 31
    dataFim = moment().year(yearIni.toString()).month(month).date(day);

    var dataIniOc = dataIni;
    var dataFimOc = dataFim;

    for (var i = 0; i < duration; i++) {

        dataIniOc = moment(dataIni).add(i, 'month')
        dataFimOc = moment(dataFim).add(i, 'month')

        var currMonthName = moment(dataIniOc).format('MMM');
        var yearText = moment(dataIniOc).format('YYYY');

        var referencia = { value: '', label: '' };

        referencia.value = dataIniOc.format("YYYY-MM-DD") + '|' + dataFimOc.format("YYYY-MM-DD")


        referencia.label = currMonthName + '/' + yearText

        referencias.push(referencia)
    }

    return referencias;
}

export const preencheReferenciaInicial = () => {

    var referencia = { value: '' }

    var dataIni = moment();
    var month = dataIni.format('M');
    var day = dataIni.format('D');
    var year = dataIni.format('YYYY');
    day = 1
    var dataIni = moment().year(year).month(month - 1).date(day);
    dataIni.format("YYYY-MM-DD")

    var dataFim = moment();
    var monthF = dataFim.format('M');
    var dayF = dataFim.format('D');
    var yearF = dataFim.format('YYYY');
    dayF = moment(dataFim).daysInMonth()
    referencia.value = dataIni.format("YYYY-MM-DD") + '|' + moment().year(yearF).month(monthF - 1).date(dayF).format("YYYY-MM-DD");

    return referencia.value;
}

export const obtemDataComHoraZerada = () => {
    var dataComHoraZerada = moment();
    dataComHoraZerada.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

    return dataComHoraZerada

}

//tirar espaços do inicio e do fim, e do meio tirar espacos duplicados
export const tiraEspacosDuplicados = (dado) => {
    var dadoOut = dado.trim()
    return dadoOut.replace(/( )+/g, ' ')
}

//transforma a primeira letra de cada palavra em maiusculo
export const titleize = (text) => {

    return text.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase()) //primeiras letras em maiusculo

}