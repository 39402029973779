import React, { Component } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import 'moment/locale/pt-br'
import { formataDisplayEmReal, formataEmRealParaBD, preencheComboFatura } from '../../util/Utils'
import PagamentoCategoria from '../pagamento/PagamentoCategoria'
import ModalAviso from '../modal/ModalAviso'
import ModalAvisoOperacao from '../modal/ModalAvisoOperacao'
import ModalConfirma from '../modal/ModalConfirma'
import ModalConfirmaComAtraso from '../modal/ModalConfirmaComAtraso'
import ModalAnexarArquivo from '../modal/ModalAnexarArquivos'
import filesize from "filesize";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}

var tipos = [
    { value: 'debito', label: 'Débito' },
    { value: 'credito', label: 'Crédito' }
]

var categoriaInicial = {
    categoria_id: null,
    sub_categoria_id: null,
    amigo_id: null,
    evento_id: null,
    descricao: null,
    tipo: null,
    comentarios: null,
    valor: 'R$ 0,00',
    entra_nas_estatisticas: true,
    categoriasDebito: [],
    categoriasCredito: [],

}

class PagamentoPagar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: this.props.pagamento,
            categorias: [],
            subCategorias: [],
            categoria: JSON.parse(JSON.stringify(categoriaInicial)),
            categoriaLista: false,
            contas: [],
            conta: { tipo_conta: null },
            faturas: [],
            pagamentosReembolsar: [],
            alerta: false,
            alertaMsg: null,
            confirma: false,
            anexos: [],
            anexarArquivo: false,
            saltar: false
        }
    }

    componentWillMount() {

        var anexos = this.props.pagamento.anexos

        this.setState({ anexos: anexos })

        this.buscaPagamentosParaSeremReembolsados()


        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.data_de_pagamento = this.state.pagamento.data_de_vencimento
        pagamentoCPY.frequencia = this.state.pagamento.transacao.frequencia
        pagamentoCPY.numero_de_parcelas = this.state.pagamento.transacao.numero_de_parcelas
        pagamentoCPY.valor = formataDisplayEmReal(this.state.pagamento.valor)
        this.setState({ pagamento: pagamentoCPY })

        if (this.state.pagamento.categorias.length === 1) {
            this.setState({ categoria: this.state.pagamento.categorias[0] })
        }

        this.listaCategorias()
        this.listaContas()
    }

    handleChangeDescricao(event) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.descricao = event.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeValor(event) {
        var valor = event.target.value

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        var pagamentoCPY = this.state.pagamento

        pagamentoCPY.valor = formataDisplayEmReal(valor)

        if (pagamentoCPY.valor.includes("-", 0)) {
            return
        }

        var categoriaCpy = JSON.parse(JSON.stringify(pagamentoCPY.categorias[0]))
        categoriaCpy.valor = pagamentoCPY.valor
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCpy)

        this.setState({ pagamento: pagamentoCPY })

    }

    handleChangeTipo(tipo) {
        // var pagamentoCPY = this.state.pagamento

        // pagamentoCPY.tipo = tipo.value

        // this.setState({ pagamento: pagamentoCPY })

        // if (tipo.value === 'credito') {
        //     this.setState({ categorias: this.state.categoriasCredito })
        // } else {
        //     this.setState({ categorias: this.state.categoriasDebito })
        // }
    }

    handleChangeVencimento(data_de_pagamento) {
        var dataInicial = moment().add(-1, 'year').format('YYYY-MM-DD')
        var dataFinal = moment().add(1, 'year').format('YYYY-MM-DD')
        var dataDigitada = moment(data_de_pagamento).format('YYYY-MM-DD')

        if (moment(dataDigitada).isAfter(dataFinal)) {
            return
        }

        if (moment(dataDigitada).isBefore(dataInicial)) {
            return
        }

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.data_de_pagamento = data_de_pagamento
        pagamentoCPY.fatura = null
        this.setState({ pagamento: pagamentoCPY })

        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_pagamento, this.state.conta.conta_cartoes_creditos.dia_fechamento, this.state.conta.conta_cartoes_creditos.dia_pagamento)
            this.setState({ faturas: faturas })
        }
    }

    handleChangeCategoria(categoria) {
        var categoriaCPY = this.state.categoria

        categoriaCPY.categoria_id = categoria.id
        categoriaCPY.sub_categoria_id = null
        categoriaCPY.amigo_id = null
        categoriaCPY.descricao = this.state.pagamento.descricao
        categoriaCPY.tipo = this.state.pagamento.tipo
        categoriaCPY.valor = this.state.pagamento.valor

        this.setState({
            categoria: categoriaCPY,
            subCategorias: categoria.sub_categorias
        })
    }

    handleChangeSubCategoria(subCategoria) {
        var categoriaCPY = this.state.categoria
        categoriaCPY.sub_categoria_id = subCategoria.id
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeConta(conta) {

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.conta_id = conta.id

        if (conta.tipo_conta === 'cartaoCredito') {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_pagamento, conta.conta_cartoes_creditos.dia_fechamento, conta.conta_cartoes_creditos.dia_pagamento)
            this.setState({ faturas: faturas })
        } else {
            this.setState({ faturas: [] })
            pagamentoCPY.fatura = null
        }

        this.setState({ pagamento: pagamentoCPY })

        this.setState({ conta: conta })
    }

    handleChangeFrequencia(frequencia) {
        // var pagamentoCPY = this.state.pagamento       
        // pagamentoCPY.frequencia = frequencia 
        // this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeParcelas(event) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.numero_de_parcelas = event.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeFatura(fatura) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.fatura = fatura.value
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangePagamentoIdReembolso(pagamentoReembolsado) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.pagamento_id_reembolso = pagamentoReembolsado.id
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeComentarios(comentarios) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.comentarios = comentarios.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    avancado() {
        this.setState({ categoriaLista: true })
    }

    voltaDaLista(pagamento) {
        this.setState({ pagamento: pagamento, categoriaLista: false })

        if (pagamento.tipo === 'credito') {
            this.setState({ categorias: this.state.categoriasCredito })
        } else {
            this.setState({ categorias: this.state.categoriasDebito })
        }

        if (this.state.pagamento.categorias.length === 0) {
            this.setState({ categoria: JSON.parse(JSON.stringify(categoriaInicial)) })
        }

        if (this.state.pagamento.categorias.length !== 1) {
            return
        }

        if (this.state.pagamento.categorias.length === 1) {
            const categoriaCPY = this.state.pagamento.categorias[0]
            this.setState({ categoria: categoriaCPY })
        }

        var categorias = []
        if (pagamento.tipo === 'credito') {
            categorias = this.state.categoriasCredito
        } else {
            categorias = this.state.categoriasDebito
        }

        const categoria_id = this.state.pagamento.categorias[0].categoria_id
        const categoria = categorias.find(function (obj) { return obj.id === categoria_id })
        this.setState({ subCategorias: categoria.sub_categorias })

    }

    handleChange(event) {

        var pagamentoCPY = this.state.pagamento

        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const id = target.id

        if (id === 'receberei_reembolso') {
            pagamentoCPY.receberei_reembolso = value
        } else {
            if (id === 'pagarei_reembolso') {
                pagamentoCPY.pagarei_reembolso = value
            }
        }

        this.setState({ pagamento: pagamentoCPY })

    }


    render() {
        if (this.state.categoriaLista === true) {
            return [
                this.renderCategoria()
            ]
        } else {
            return [
                this.renderAvisoOperacao(),
                this.renderAlert(),
                this.renderConfirmacao(),
                this.renderConfirmacaoComAtraso(),
                this.renderAnexarArquivo(),
                this.renderComum(),
                this.renderAnexos(),
            ]
        }
    }

    renderCategoria() {
        return (
            <
                PagamentoCategoria
                pagamento={this.state.pagamento}
                volta={(pagamento) => { this.voltaDaLista(pagamento) }}
            />
        )
    }

    mostraAnexaArquivo() {
        this.setState({ anexarArquivo: true })
    }

    renderComum() {

        var tituloAnexo = 'anexar recibos'

        var corUnica = 'btn-light'
        var corMensal = 'btn-light'
        var corRecorrente = 'btn-light'

        if (this.state.pagamento.frequencia === 'unica') {
            corUnica = 'btn-danger'
            corMensal = 'btn-light'
            corRecorrente = 'btn-light'
        }

        if (this.state.pagamento.frequencia === 'mensal') {
            corUnica = 'btn-light'
            corMensal = 'btn-danger'
            corRecorrente = 'btn-light'
        }

        if (this.state.pagamento.frequencia === 'recorrente') {
            corUnica = 'btn-light'
            corMensal = 'btn-light'
            corRecorrente = 'btn-danger'
        }


        //Input da parcela
        var inputParcela = null

        var inputParcelaPreenchido =
            <div className="form-group col-md-1">
                <label for="parcelas">Parcelas</label>
                <input
                    disabled
                    onChange={this.handleChangeParcelas.bind(this)}
                    value={this.state.pagamento.numero_de_parcelas}
                    type="parcelas"
                    className="form-control"
                    id="parcelas"
                    placeholder="Parcelas" />
            </div>

        if (this.state.pagamento.frequencia === 'mensal') {
            inputParcela = inputParcelaPreenchido
        } else {
            inputParcela = null
        }

        //input fatura
        var inputFatura = null

        var inputFaturaPreenchido =
            <div className="form-group col-md-3">
                <label for="fatura" className="">Fatura</label>
                <Select
                    styles={customStyles}
                    id="fatura"
                    name="fatura"
                    value={this.state.faturas.filter(option => option.value === this.state.pagamento.fatura)}
                    onChange={this.handleChangeFatura.bind(this)}
                    options={this.state.faturas}
                />
            </div>


        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            inputFatura = inputFaturaPreenchido
        } else {
            inputFatura = null
        }

        var inibirCategoria = false

        if (formataEmRealParaBD(this.state.pagamento.valor) === 0) {
            inibirCategoria = true
        }

        var inputCategoria =
            <div className="form-group col-md-4">
                <label for="categoria" className="">Categoria</label>
                <Select
                    styles={customStyles}
                    isDisabled={inibirCategoria}
                    id="categoria"
                    name="categoria"
                    getOptionLabel={({ nome }) => nome}
                    getOptionValue={({ id }) => id}
                    value={this.state.categorias.filter(option => option.id === this.state.categoria.categoria_id)}
                    onChange={this.handleChangeCategoria.bind(this)}
                    options={this.state.categorias}
                />
            </div>

        var inputSubCategoria =
            <div className="form-group col-md-4">
                <label for="subCategoria" className="">Subcategoria</label>
                <Select
                    styles={customStyles}
                    isDisabled={inibirCategoria}
                    id="subCategoria"
                    name="subCategoria"
                    getOptionLabel={({ nome }) => nome}
                    getOptionValue={({ id }) => id}
                    value={this.state.subCategorias.filter(option => option.id === this.state.categoria.sub_categoria_id)}
                    onChange={this.handleChangeSubCategoria.bind(this)}
                    options={this.state.subCategorias}
                />
            </div>


        var varDisabled = false

        if (this.state.pagamento.categorias.length > 1) {
            varDisabled = true
            inputCategoria =
                <div className="form-group col-md-4">
                    <label for="valor">Categoria</label>
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Usar avançado" />
                </div>

            inputSubCategoria =
                <div className="form-group col-md-4">
                    <label for="valor">Subcategoria</label>
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Usar avançado" />
                </div>

        }

        var linhaReembolso = null

        if (this.state.pagamento.tipo === 'debito') {
            linhaReembolso =
                <div className="form-check">
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            onChange={this.handleChange.bind(this)}
                            className="form-check-input"
                            id="receberei_reembolso"
                            checked={this.state.pagamento.receberei_reembolso}></input>
                        Este débito será reembolsado
                    </label>
                </div>
        }
        linhaReembolso = null

        if (this.state.pagamento.tipo === 'credito') {
            linhaReembolso =
                <div className="form-check">
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            onChange={this.handleChange.bind(this)}
                            className="form-check-input"
                            id="pagarei_reembolso"
                            checked={this.state.pagamento.pagarei_reembolso}></input>
                        Este crédito vai pagar um reembolso
                    </label>
                </div>
        }

        if (this.state.pagamento.frequencia !== 'unica') {
            linhaReembolso = null
        }


        var botaoSaltar = false

        if (this.state.pagamento.frequencia === 'recorrente') {
            if (this.state.conta.tipo_conta !== 'cartaoCredito') {
                botaoSaltar = true
            }
        }

        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Pagar esta conta</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-2">
                            <label for="tipo" className="">Tipo</label>
                            <Select
                                styles={customStyles}
                                isDisabled='true'
                                id="tipo"
                                name="tipo"
                                value={tipos.filter(option => option.value === this.state.pagamento.tipo)}
                                onChange={this.handleChangeTipo.bind(this)}
                                options={tipos}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label for="valor">Valor</label>
                            <input
                                onChange={this.handleChangeValor.bind(this)}
                                readOnly={varDisabled}
                                value={this.state.pagamento.valor}
                                type="valor"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                        <div className="col-md-2">
                            <label className="" >Pagamento</label>
                            <DatePicker
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeVencimento"
                                selected={moment(this.state.pagamento.data_de_pagamento).toDate()}
                                onChange={this.handleChangeVencimento.bind(this)} />
                        </div>
                        <div className="form-group col-md-5">
                            <label for="descricao">Descrição</label>
                            <input
                                onChange={this.handleChangeDescricao.bind(this)}
                                value={this.state.pagamento.descricao}
                                type="text"
                                maxlength="50"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        {inputCategoria}
                        {inputSubCategoria}
                        <div className="form-group col-md-3">
                            <label className="" >Avançado</label>
                            <div>
                                <button id="unica" type="button" className={"btn " + (corUnica) + " mr-1"} onClick={() => { this.avancado() }} ><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Opções</button>
                            </div>
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        <div className="form-group col-md-4">
                            <label for="conta" className="">Conta</label>
                            <Select
                                styles={customStyles}
                                id="conta"
                                name="conta"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                selectedValue={this.state.conta_id}
                                value={this.state.contas.filter(option => option.id === this.state.pagamento.conta_id)}
                                onChange={this.handleChangeConta.bind(this)}
                                options={this.state.contas}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label className="" >Frequência</label>
                            <div>
                                <button disabled id="unica" type="button" className={"btn btn-sm " + (corUnica) + " mr-1"} onClick={() => { this.handleChangeFrequencia("unica") }} >Única</button>
                                <button disabled id="mensal" type="button" className={"btn btn-sm " + (corMensal) + " mr-1"} onClick={() => { this.handleChangeFrequencia("mensal") }} >Mensal</button>
                                <button disabled id="recorrente" type="button" className={"btn btn-sm " + (corRecorrente) + " mr-1"} onClick={() => { this.handleChangeFrequencia("recorrente") }} >Recorrente</button>
                            </div>
                        </div>
                        {inputParcela}
                        {inputFatura}
                    </div>
                    <div className="mt-3 ml-2 form-row">
                        {linhaReembolso}
                        {this.state.pagamento.pagarei_reembolso &&
                            <div className="form-group col-md-4 m-0">
                                <Select
                                    styles={customStyles}
                                    placeholder="Selecione uma pagamento.."
                                    id="pagamentosReembolsar"
                                    name="pagamentosReembolsar"
                                    getOptionLabel={({ descricao, valor, data_de_pagamento }) => (descricao + ' | ' + formataDisplayEmReal(valor) + ' | ' + moment(data_de_pagamento).format('DD/MM/YYYY'))}
                                    getOptionValue={({ id }) => id}
                                    selectedValue={this.state.pagamento_id_reembolso}
                                    value={this.state.pagamentosReembolsar.filter(option => option.id === this.state.pagamento.pagamento_id_reembolso)}
                                    onChange={this.handleChangePagamentoIdReembolso.bind(this)}
                                    options={this.state.pagamentosReembolsar}
                                />
                            </div>
                        }
                    </div>
                    <div class="form-group col-md-11">
                        <label className="m-2" for="comment">Comentários</label>
                        <textarea
                            value={this.state.pagamento.comentarios}
                            className="form-control mt-2 ml-2"
                            rows="2" id="comum:comentarios"
                            onChange={this.handleChangeComentarios.bind(this)}>
                        </textarea>
                    </div>
                    <div class="card-header border">
                        <div className="row ml-2">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.efetuarPagamento() }}>Pagar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                            <button type="button" className="btn btn-link btn-sm text-right " title={tituloAnexo} onClick={() => { this.mostraAnexaArquivo() }}><i class="fa fa-paperclip fa-lg rgm-cor-link" aria-hidden="true"></i></button>
                            {botaoSaltar &&
                                <button type="button" className="btn btn-outline-danger btn-sm m-2" onClick={() => { this.efetuarPagamentoSaltar() }}>Saltar</button>
                            }
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAnexos() {

        if (this.state.anexos.length > -1) {
            return (
                <div className="container w-100 mt-5 mb-0">
                    <div className="card row bg-transparent">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>Documentos anexados </h5>
                            </div>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="border align-middle">Nome</th>
                                    <th scope="col" className="border align-middle">Tamanho</th>
                                    <th scope="col" className="border align-middle text-center">Ver anexo</th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.anexos.map((anexo, index) => {
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado m-1">
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{anexo.nome}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber text-right">{filesize(anexo.size)}</td>
                                            <td className="text-center"> <a href={anexo.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i></a></td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    volta(anexo) {

        var anexos = this.state.anexos
        anexos.push(anexo)
        this.setState({ anexos: anexos })

        this.setState({ incluiRapido: false, pagamentoAvulso: false, anexarArquivo: false })
    }

    renderAnexarArquivo() {

        if (this.state.anexarArquivo === true) {
            return (
                <>
                    <ModalAnexarArquivo
                        volta={(anexo) => this.volta(anexo)}
                        id={this.state.pagamento.id}
                        ident='pagamento'
                    />
                </>
            )
        }
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderConfirmacao() {
        let msgCon = "Confirma este pagamento? ; ** Se atente a data de pagamento, ela dever ser igual a do seu extrato Bancário ** ; ** Verifique se a conta onde este pagamento sera efetuado esta correta **"

        if (moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
            msgCon = "** ATENÇÃO, ATENÇÃO, ATENÇÃO ** ; ** Data de pagamento esta maior que a data corrente, quer continuar assim mesmo? ** ; ** Verifique se a conta onde este pagamento sera efetuado esta correta **"
        }

        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.confirma()} cancela={() => this.setState({ pedeConfirmacao: false, saltar: false })}
                        msgConfirmacao={msgCon}
                    />
                </>
            )
        }
    }

    renderConfirmacaoComAtraso() {
        if (this.state.pedeConfirmacaoComAtraso) {
            return (
                <>
                    <ModalConfirmaComAtraso
                        confirma={() => this.confirmaSemAtraso()}
                        confirmaComAtraso={() => this.confirma()}
                        cancela={() => this.setState({ pedeConfirmacaoComAtraso: false })}
                        msgConfirmacao="Confirma o pagamento desta conta?" />
                </>
            )
        }
    }

    renderAvisoOperacao() {
        if (this.state.alertaOperacao) {
            return (
                <>
                    <ModalAvisoOperacao aviso={this.state.alertaOperacaoMsg} volta={() => this.props.volta()} />
                </>
            )
        }
    }

    acessaNomeCategoria(categoriaListada) {
        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === categoriaListada.categoria_id })
        return categoria.nome
    }

    acessaNomeSubCategoria(categoriaListada) {
        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === categoriaListada.categoria_id })
        const subCategoria = categoria.sub_categorias.find(function (obj) { return obj.id === categoriaListada.sub_categoria_id })
        return subCategoria.nome
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    selecionaCategorias(categoriasLista) {

        this.setState({ categoriasDivisaoTotal: JSON.parse(JSON.stringify(categoriasLista)) })
        this.setState({ categoriasTodas: JSON.parse(JSON.stringify(categoriasLista)) })

        var result = false

        var categoriasDebito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'debito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        var categoriasCredito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'credito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }

            return result

        })

        this.setState({ categoriasDebito: categoriasDebito })
        this.setState({ categoriasCredito: categoriasCredito })

        if (this.state.pagamento.categorias.length === 1) {
            if (this.state.pagamento.tipo === 'debito') {
                var categorias = categoriasDebito
                var categoria_id = this.state.categoria.categoria_id
                var subCategorias = categorias.find(function (obj) { return obj.id === categoria_id })
                this.setState({ categorias: categorias, subCategorias: subCategorias.sub_categorias })
            } else {
                categorias = categoriasCredito
                categoria_id = this.state.categoria.categoria_id
                subCategorias = categorias.find(function (obj) { return obj.id === categoria_id })
                if (subCategorias === undefined) {
                    this.setState({ categorias: categorias, subCategorias: [] })
                } else {
                    this.setState({ categorias: categorias, subCategorias: subCategorias.sub_categorias })
                }
            }
        }

    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.somenteContasAbertas(responseJson.contas)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    somenteContasAbertas(contasInput) {

        var contaFechado = false

        var contas = []



        contasInput.map(async (conta, index) => {
            if (conta.status === 'aberta') {
                contas.push(conta)
            } else {
                if (conta.status === 'fechada' && conta.id === this.state.pagamento.conta_id) {
                    contaFechado = true
                }
            }
        })

        if (contaFechado === true) {
            var pagamentoCPY = this.state.pagamento
            pagamentoCPY.conta_id = null
            this.setState({ pagamento: pagamentoCPY })
        }

        this.setState({ contas: contas })
    }

    buscaPagamentosParaSeremReembolsados() {
        let url = global.url + 'pagamento/buscaPagamentosParaSeremReembolsados/' + this.state.pagamento.pagamento_id_reembolso

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ pagamentosReembolsar: responseJson.pagamentos })

                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    efetuarPagamentoSaltar() {

        this.setState({ saltar: true })
        this.efetuarPagamento()

    }


    efetuarPagamento() {

        //if (formataEmRealParaBD(this.state.pagamento.valor) === 0) {
        //    this.setState({ alerta: true, alertaMsg: 'Valor obrigatório' })
        //    return
        //}

        if (formataEmRealParaBD(this.state.pagamento.valor) === 0) {
            this.setState({ alerta: true, alertaMsg: 'Valor não pode ser zero' })
            return
        }

        if (moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD') === 'Invalid date') {
            this.setState({ alerta: true, alertaMsg: 'Data de pagamento obrigatória' })
            return
        }


        // if (moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
        //     this.setState({ alerta: true, alertaMsg: 'Data de pagamento não pode ser maior que a data corrente' })
        //     return
        // }

        if (this.state.pagamento.descricao === null || this.state.pagamento.descricao.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Descrição deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.pagamento.categorias.length === 0) {
            this.setState({ alerta: true, alertaMsg: 'Categoria/Subcategoria obrigatória' })
            return
        }

        if (this.state.pagamento.conta_id === null) {
            this.setState({ alerta: true, alertaMsg: 'Conta anterior fechada, vincular outra conta a esse pagamento' })
            return
        }

        if (this.state.conta.tipo_conta === 'cartaoCredito' && this.state.pagamento.fatura === null) {
            this.setState({ alerta: true, alertaMsg: 'Você deve escolher uma fatura para este pagamento' })
            return
        }

        if (this.state.pagamento.pagarei_reembolso === true && this.state.pagamento.pagamento_id_reembolso === undefined) {
            this.setState({ alerta: true, alertaMsg: 'Pagamento de reembolso deve aponta para um débito' })
            return
        }

        if (this.state.pagamento.pagarei_reembolso === true && this.state.pagamento.pagamento_id_pagamento === null) {
            this.setState({ alerta: true, alertaMsg: 'Pagamento de reembolso deve aponta para um débito' })
            return
        }

        if (this.state.pagamento.data_de_vencimento < moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD')) {
            this.setState({ pedeConfirmacaoComAtraso: true })
        } else {
            this.setState({ pedeConfirmacao: true })
        }

    }

    confirmaSemAtraso() {

        var dataPagamento = moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD')

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.data_de_vencimento = dataPagamento
        this.setState({ pagamento: pagamentoCPY }, function () { this.confirma() })
    }

    confirma() {

        this.setState({ pedeConfirmacao: false, pedeConfirmacaoComAtraso: false })

        var categorias = []

        this.state.pagamento.categorias.map(async (categoria, index) => {
            if (categoria.valor === null) {
                categoria.valor = formataEmRealParaBD(this.state.pagamento.valor)
            } else {
                categoria.valor = formataEmRealParaBD(categoria.valor)
            }

            if (categoria.descricao === null) {
                categoria.descricao = this.state.pagamento.descricao
            }

            categorias.push(categoria)
        })

        var pagamento = {
            id: this.state.pagamento.id,
            transacao_id: this.state.pagamento.transacao_id,
            fatura_id: this.state.pagamento.fatura_id,
            parcela: this.state.pagamento.parcela,
            descricao: this.state.pagamento.descricao,
            valor: formataEmRealParaBD(this.state.pagamento.valor),
            status: 'liquidado',
            data_de_vencimento_ano_mes: this.state.pagamento.data_de_vencimento_ano_mes,
            data_de_vencimento: this.state.pagamento.data_de_vencimento,
            data_de_pagamento: moment(this.state.pagamento.data_de_pagamento).format('YYYY-MM-DD'),
            frequencia: this.state.pagamento.frequencia,
            numero_de_parcelas: this.state.pagamento.numero_de_parcelas,
            tipo: this.state.pagamento.tipo,
            receberei_reembolso: this.state.pagamento.receberei_reembolso,
            pagarei_reembolso: this.state.pagamento.pagarei_reembolso,
            pagamento_id_reembolso: this.state.pagamento.pagamento_id_reembolso,
            comentarios: this.state.pagamento.comentarios,
            conta_id: this.state.pagamento.conta_id,
            fatura: this.state.pagamento.fatura,
            categorias: categorias,
            transacao: this.state.pagamento.transacao
        }

        if (this.state.saltar === true) {
            pagamento.valor = 0
            pagamento.categoria_nome_interno = 'especial'
            pagamento.sub_categoria_nome_interno = 'saltado'
        } else {
            if (pagamento.sub_categoria_nome_interno === 'saltado') {
                pagamento.categoria_nome_interno = ''
                pagamento.sub_categoria_nome_interno = ''
            }
        }

        let url = null

        if (this.state.pagamento.fatura !== null) {
            url = global.url + 'fatura/pagarPagamentoDentroDeUmaFatura'
        } else {
            url = global.url + 'pagamento/pagar'
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaOperacao: true, alertaOperacaoMsg: 'Pagamento realizado com sucesso!!' })
                    return
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

}

export default PagamentoPagar