import React, { Component } from 'react'

class SubCategoriaLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoria: props.categoria            
        }
    }

    componentWillMount() {
        this.listaCategorias();
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    selecionaCategorias(categoriasLista) {

        var categoria = null

        categoriasLista.filter((obj) => {
            if (obj.id === this.props.categoria.id) {
                categoria = obj
            }

            return 0

        })        


        this.setState({ categoria: categoria })
    }

    render() {
        return (
            <div className="container w-100 mt-5">
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.subCategoriaInclusao(this.state.categoria)}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;subcategorias</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.volta()}><i class="" aria-hidden="true">volta</i></button>
                <div className="card  bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle m-2">
                            <h5>Lista de subcategorias</h5>
                        </div>
                    </div>
                    <table className="table table-bordered mt-0 text-white">
                        <thead>
                            <tr className="bg-info">
                                <th className="border" scope="col">Categoria</th>
                                <th className="border text-center" scope="col">Tipo</th>
                            </tr>
                        </thead>
                        <tbody className="rgm-bg-azul-esverdiado">
                            <tr className="rgm-bg-azul-esverdiado">
                                <td className="border align-middle">{this.state.categoria.nome}</td>
                                <td className="border align-middle text-center">{this.state.categoria.tipo}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="card bg-transparent text-white">
                    <table className="table table-bordered mt-0">
                        <thead>
                            <tr className="bg-info">
                                <th className="border" scope="col">Subcategorias</th>
                                <th className="border text-center" scope="col">Editar</th>
                            </tr>
                        </thead>
                        <tbody className="rgm-bg-azul-esverdiado">
                            {this.state.categoria.sub_categorias.map((subCategoria, index) => {
                                return (
                                    <tr className="rgm-bg-azul-esverdiado">
                                        <td className="border align-middle">{subCategoria.nome}</td>
                                        <td className="border align-middle text-center" onClick={() => this.props.subCategoriaAlteracao(this.state.categoria, subCategoria)}>
                                            <i className="btn btn-link btn-sm m-2 fa fa-pencil rgm-cor-link"></i>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div >
        )
    }

}

export default SubCategoriaLista