import React from 'react'
import { Component } from 'react';
import './style.css'

class ModalConfirmaComecarDoZero extends Component {
    constructor(props) {
        super(props)
        this.state = {
            msgConfirma: [''],
            contador: 3
        }
    }

    componentWillMount() {

        var resultado = this.props.msgConfirmacao.split(/\s*;\s*/);

        this.setState({ msgConfirma: resultado })

    }

    confirma() {
        if (this.state.contador === 1) {
            this.props.confirma()
        } else {
            var contador = this.state.contador - 1
            this.setState({ contador: contador })
        }
    }


    render() {
        return (
            <div className="rgm_modal try-modal-overlay text-center">
                <div className="card">
                    <div class="card-header bg-danger text-white border">
                        <span>Confirmação</span>
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{this.state.msgConfirma[0]}</h5>
                        {this.state.msgConfirma.length > 1 &&
                            <h6 class="card-title">{this.state.msgConfirma[1]}</h6>
                        }
                        {this.state.msgConfirma.length > 2 &&
                            <h6 class="card-title">{this.state.msgConfirma[2]}</h6>
                        }
                        <h6 class="card-title">Restam {this.state.contador} confirmações</h6>
                    </div>
                    {this.state.contador === 3 &&
                        <div class="card-footer">
                            <button type="button" className="btn btn-primary btn-sm" aria-label="Close" onClick={() => this.confirma()}>Confirma</button>
                            <button type="button" className="btn btn-danger ml-2 btn-sm" aria-label="Close" onClick={() => this.props.cancela()}>Cancela</button>
                        </div>
                    }
                    {this.state.contador === 2 &&
                        <div class="card-footer">
                            <button type="button" className="btn btn-danger btn-sm" aria-label="Close" onClick={() => this.props.cancela()}>Cancela</button>
                            <button type="button" className="btn btn-primary ml-2 btn-sm" aria-label="Close" onClick={() => this.confirma()}>Confirma</button>
                        </div>
                    }
                    {this.state.contador === 1 &&
                        <div class="card-footer">
                            <button type="button" className="btn btn-primary btn-sm" aria-label="Close" onClick={() => this.confirma()}>Confirma</button>
                            <button type="button" className="btn btn-danger ml-2 btn-sm" aria-label="Close" onClick={() => this.props.cancela()}>Cancela</button>
                        </div>
                    }
                </div>
            </div>
        )
    }



}

export default ModalConfirmaComecarDoZero