import { tiraEspacosDuplicados } from '../../util/Utils'

export const verificaSeCategoriaExiste = (nome, categorias) => {
 
    var nomeLocal = tiraEspacosDuplicados(nome) 

    var existe = false

    categorias.map(async (categoria, index) => {

        if (categoria.nome.toLowerCase() === nomeLocal.toLowerCase()) {
            existe = true
        }

    })
 
    return existe
}

export const verificaSeSubCategoriaExiste = (nome, categorias) => {
    var nomeLocal = tiraEspacosDuplicados(nome) 

    var existe = false

    categorias.map(async (categoria, index) => {
        categoria.sub_categorias.map( async (subcategoria, index) => {
            if (subcategoria.nome.toLowerCase() === nomeLocal.toLowerCase()) {
                existe = true
            }    
        })
    })
 
    return existe
}