import React, { Component } from 'react'
import BovespaLista from '../bovespa/BovespaLista'

class Bovespa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            bovespaLista: true,
        }
    }

    componentWillMount() {
    }

    bovespaListaTrue() {
        this.setFalseAll()
        this.setState({
            bovespaLista: true
        })
    }

    setFalseAll() {
        this.setState({
            bovespaLista: false
        })
    }

    render() {

        if (this.state.bovespaLista === true) {
            return (<
                BovespaLista 
            />
            )
        }

    }

}

export default Bovespa