import React, { Component } from 'react'
import EventoLista from '../evento/EventoLista'
import EventoInclusao from '../evento/EventoInclusao'
import EventoConsulta from '../evento/EventoConsulta'
import EventoAlteracao from '../evento/EventoAlteracao'
class Evento extends Component {
  constructor(props) {
    super(props)
    this.state = {
      eventoLista: true,
      eventoInclusao: false,
      eventoConsulta: false,
      eventoAlteracao: false,
      evento: null
    }
  }

  componentWillMount() {
    global.eventosPrincipal = () => { this.eventoListaTrue() }
  }

  eventoListaTrue() {
    this.setFalseAll()
    this.setState({
      eventoLista: true
    })
  }

  eventoInclusaoTrue() {
    this.setFalseAll()
    this.setState({
      eventoInclusao: true
    })
  }

  eventoConsultaTrue(evento) {
    this.setState({evento: evento})
    this.setFalseAll()
    this.setState({
      eventoConsulta: true
    })
  }

  eventoAlteracaoTrue(evento) {
    this.setFalseAll()
    this.setState({ eventoAlteracao: true, evento: evento })
  }

  setFalseAll() {
    this.setState({
      eventoLista: false,
      eventoInclusao: false,
      eventoConsulta: false,
      eventoAlteracao: false
    })
  }


  render() {

    if (this.state.eventoLista === true) {
      return (<
        EventoLista
        eventoInclusao={() => { this.eventoInclusaoTrue() }}
        eventoConsulta={(evento) => { this.eventoConsultaTrue(evento) }}
        eventoAlteracao={(evento) => { this.eventoAlteracaoTrue(evento) }}
      />
      )
    }

    if (this.state.eventoInclusao === true) {
      return (<
        EventoInclusao
        volta={() => { this.eventoListaTrue() }}
      />
      )
    }

    if (this.state.eventoConsulta === true) {
      return (<
        EventoConsulta
        evento={this.state.evento}
        volta={() => { this.eventoListaTrue() }}
        altera={(evento) => { this.eventoAlterarTrue(evento) }}
      />
      )
    }

    if (this.state.eventoAlteracao === true) {
      return (<
        EventoAlteracao
        evento={this.state.evento}
        volta={() => { this.eventoListaTrue() }}
      />
      )
    }


    if (this.state.eventoLista === false &&
      this.state.eventoInclusao === false &&
      this.state.eventoAlteracao === false
    ) {
      return null
    }

  }

}

export default Evento