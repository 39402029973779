import React from 'react'
import { Component } from 'react';
import './style.css'

var meuTimeOut
class ModalAviso extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    handleClick() {
        clearTimeout(meuTimeOut)
        this.props.onClose()
    }

    render() {

        var timeOut = 3000

        if (this.props.timeOut !== undefined) {
            timeOut = this.props.timeOut
        }

        meuTimeOut = setTimeout(() => {
            this.props.onClose()
        }, timeOut)

        return (
            <div className="rgm_modal">
                <div className="card">
                    <div class="card-header bg-warning text-center border"><h4>Aviso</h4></div>
                    <div class="card-body">
                        <h5 class="card-title">{this.props.aviso}</h5>
                        <button type="button" className="close" aria-label="Close" onClick={() => this.handleClick()}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
        )
    }



}

export default ModalAviso