import React, { Component } from 'react'
import { verificaSeSubCategoriaExiste } from '../categoria/CategoriaUtil'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'


class SubCategoriaAlteracao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoria: props.categoria,
            subCategoria: props.subCategoria,
            nome: props.subCategoria.nome,
            butonDelete: null,
            categorias: [],
            salvaNome: props.subCategoria.nome,
            alerta: false,
            alertaSaida: false,
            alertaMsg: null,
            pedeConfirmacao: false

        }
    }

    componentWillMount() {
        this.listaCategorias()
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ categorias: responseJson.categorias })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    alteracaoSubCategoria() {
        if (this.state.nome.length < 3) {
            var mensagem = 'Subcategoria deve ter no mínimo 3 letras'
            alert(mensagem)
            return
        }

        if (this.state.salvaNome !== this.state.nome) {
            if (verificaSeSubCategoriaExiste(this.state.nome, this.state.categorias)) {
                mensagem = 'Esta descrição de subcategoria já existe'
                alert(mensagem)
                return
            }
        }

        var subCategoria = {
            nome: this.state.nome,
            id: this.state.subCategoria.id,
            categoria_id: this.state.subCategoria.categoria_id,
            nome_interno: this.state.subCategoria.nome_interno
        }

        let url = global.url + 'categoria/alterarSub'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(subCategoria)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Alteração concluida...' })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    handleChangeNome(event) {
        this.setState({ nome: event.target.value })
    }

    excluiSubCategoria() {
        this.setState({ pedeConfirmacao: true })

    }

    confirmaExcluiSubCategoria() {
        this.setState({ pedeConfirmacao: false })

        const nome = this.state.nome[0].toUpperCase() + this.state.nome.substr(1)   

        var subCategoria = {
            nome: nome,
            id: this.state.subCategoria.id,
            categoria_id: this.state.subCategoria.categoria_id,
            nome_interno: this.state.subCategoria.nome_interno
        }

        let url = global.url + 'categoria/excluirSub'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(subCategoria)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Exclusão concluida com sucesso' })
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderConfirmacao(),
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {
        return (
            <div className="container w-100 mt-5"   >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div className="row m-3" >
                        <div className="col">
                            <label className="ml-2" for="categoria">Categoria</label>
                            <input id="categoria" type="text" className="form-control" disabled value={this.state.categoria.nome} />
                        </div>
                        <div className="col">
                            <label className="ml-2" for="tipo">Tipo</label>
                            <input id="tipo" type="text" className="form-control" disabled value={this.state.categoria.tipo} />
                        </div>
                    </div>
                </form>

                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Alteração de subcategoria</h5>
                        </div>
                    </div>
                    <div className="form-group row m-2">
                        <label for="subCategoria" className="col-sm-2 col-form-label" >Subcategoria</label>
                        <div className="col-sm-10">
                            <input type="text" maxlength="40" className="form-control" id="subCategoria" onChange={this.handleChangeNome.bind(this)} value={this.state.nome} />
                        </div>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.alteracaoSubCategoria() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta(this.state.categoria) }}>Voltar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.excluiSubCategoria() }}>Excluir</button>

                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta(this.state.categoria)} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.confirmaExcluiSubCategoria()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a exclusão da subcategoria?" />
                </>
            )
        }
    }

}

export default SubCategoriaAlteracao