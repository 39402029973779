import React, { Component } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'
import 'moment/locale/pt-br'
import { formataDisplayEmReal, formataEmRealParaBD, preencheComboFatura } from '../../util/Utils'
import PagamentoCategoria from '../pagamento/PagamentoCategoria'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'

//upload
import { uniqueId } from "lodash";
import filesize from "filesize";
import Upload from '../upload/index'
import { Container, Content } from '../upload/styles'
import FileList from '../uploadFileList/index'
import api from "../uploadApi/api";
//upload

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}

var consideraNosRelatorios = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' }
]

var tipos = [
    { value: 'debito', label: 'Débito' },
    { value: 'credito', label: 'Crédito' }
]


var dataVencimentoSalva = null

var categoriaInicial = {
    categoria_id: null,
    sub_categoria_id: null,
    amigo_id: null,
    evento_id: null,
    descricao: null,
    tipo: null,
    comentarios: null,
    valor: 'R$ 0,00',
    labelData: 'Vencimento',
    entra_nas_estatisticas: true
}

class PagamentoAlteracao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: this.props.pagamento,
            amigos: [],
            eventos: [],
            categorias: [],
            subCategorias: [],
            categoria: JSON.parse(JSON.stringify(categoriaInicial)),
            categoriaLista: false,
            categoriasDebito: [],
            categoriasCredito: [],
            contas: [],
            conta: { tipo_conta: null },
            faturas: [],
            labelData: null,
            naoAlteraConta: false,
            pagamentosReembolsar: [],
            alerta: false,
            alertaMsg: null,
            confirma: false,
            podeReabrir: false,
            faturasLidas: [],
            anexos: [],
            selectedFileName: '',
            selectedFile: '',
            uploadedFiles: [],
            viewAnexo: false,
            viewAnexoTipo: '',
        }
    }

    componentWillMount() {

        var anexos = this.state.pagamento.transacao.anexos.concat(this.state.pagamento.anexos)
        this.setState({ anexos: anexos })

        this.buscaPagamentosParaSeremReembolsados()

        if (this.state.pagamento.parcela === this.state.pagamento.transacao.ultima_parcela_paga && this.state.pagamento.status === 'liquidado') {
            this.setState({ podeReabrir: true })
        }

        if (this.state.pagamento.tipo === 'credito' && this.state.pagamento.frequencia === 'unica' && this.state.pagamento.status === 'liquidado') {
            if (this.state.pagamento.fatura_id === null) {
                this.setState({ podeReabrir: true })
            }
        }

        if (this.state.pagamento.tipo === 'debito' && this.state.pagamento.frequencia === 'unica' && this.state.pagamento.status === 'liquidado') {
            if (this.state.pagamento.fatura_id === null) {
                this.setState({ podeReabrir: true })
            }
        }

        if (this.state.pagamento.fatura_id !== null) {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_vencimento)
            this.setState({ faturas: faturas })
            this.setState({ naoAlteraConta: true })
        }

        if (this.state.pagamento.status === 'liquidado') {
            this.setState({ naoAlteraConta: true })
            this.setState({ labelData: 'Pagamento' })
        } else {
            this.setState({ labelData: 'Vencimento' })
        }

        dataVencimentoSalva = this.state.pagamento.data_de_vencimento.valueOf()

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.frequencia = this.state.pagamento.transacao.frequencia
        pagamentoCPY.numero_de_parcelas = this.state.pagamento.transacao.numero_de_parcelas
        pagamentoCPY.valor = formataDisplayEmReal(this.state.pagamento.valor)
        if (this.state.pagamento.status === 'liquidado') {
            pagamentoCPY.data_de_vencimento = pagamentoCPY.data_de_pagamento.valueOf()
        }
        this.setState({ pagamento: pagamentoCPY })

        if (this.state.pagamento.categorias.length === 1) {
            this.setState({ categoria: this.state.pagamento.categorias[0] })
        }

        this.listaCategorias()
        this.listaContas()
        this.listaAmigos()
        this.listaEventos()
    }

    handleChangeDescricao(event) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.descricao = event.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeValor(event) {
        var valor = event.target.value

        if (valor.length === 1) {
            if (isNaN(valor)) {
                valor = '0'
            }
        }

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.valor = formataDisplayEmReal(valor)

        if (pagamentoCPY.valor.includes("-", 0)) {
            return
        }

        var categoriaCpy = JSON.parse(JSON.stringify(pagamentoCPY.categorias[0]))
        categoriaCpy.valor = pagamentoCPY.valor
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCpy)

        this.setState({ pagamento: pagamentoCPY })

    }

    handleChangeTipo(tipo) {
        // var pagamentoCPY = this.state.pagamento

        // pagamentoCPY.tipo = tipo.value

        // this.setState({ pagamento: pagamentoCPY })

        // if (tipo.value === 'credito') {
        //     this.setState({ categorias: this.state.categoriasCredito })
        // } else {
        //     this.setState({ categorias: this.state.categoriasDebito })
        // }
    }

    handleChangeVencimento(data_de_vencimento) {
        var dataInicial = moment().add(-1, 'year').format('YYYY-MM-DD')
        var dataFinal = moment().add(1, 'year').format('YYYY-MM-DD')
        var dataDigitada = moment(data_de_vencimento).format('YYYY-MM-DD')

        if (moment(dataDigitada).isAfter(dataFinal)) {
            return
        }

        if (moment(dataDigitada).isBefore(dataInicial)) {
            return
        }


        var dataVencimento = moment(data_de_vencimento).format('YYYY-MM-DD')

        if (this.state.pagamento.frequencia === 'recorrente' || this.state.pagamento.frequencia === 'mensal') {
            if (this.state.pagamento.parcela !== 1) {
                var dataInicio = moment(this.state.pagamento.data_de_vencimento).startOf('month').format('YYYY-MM-DD')
                var dataFim = moment(this.state.pagamento.data_de_vencimento).endOf('month').format('YYYY-MM-DD')
                if (dataVencimento < dataInicio || dataVencimento > dataFim) {
                    this.setState({ alerta: true, alertaMsg: 'Alteração da data de vencimento deve estar dentro do mes original' })
                    return
                }
            }
        }

        if (this.state.pagamento.fatura !== null) {
            if (this.state.pagamento.status === 'liquidado') {
                return
            }
        }

        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            var faturas = preencheComboFatura(moment(data_de_vencimento).format('YYYY-MM-DD'), this.state.conta.conta_cartoes_creditos.dia_fechamento, this.state.conta.conta_cartoes_creditos.dia_pagamento)
            this.setState({ faturas: faturas })
        }

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.data_de_vencimento = data_de_vencimento

        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            pagamentoCPY.fatura.fatura = faturas[0].value
        } else {
            pagamentoCPY.fatura = null
        }

        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeCategoria(categoria) {
        var categoriaCPY = this.state.categoria

        categoriaCPY.categoria_id = categoria.id
        categoriaCPY.sub_categoria_id = null
        categoriaCPY.amigo_id = null
        categoriaCPY.descricao = this.state.pagamento.descricao
        categoriaCPY.tipo = this.state.pagamento.tipo
        categoriaCPY.valor = this.state.pagamento.valor

        this.setState({
            categoria: categoriaCPY,
            subCategorias: categoria.sub_categorias
        })
    }

    handleChangeSubCategoria(subCategoria) {
        var categoriaCPY = this.state.categoria
        categoriaCPY.sub_categoria_id = subCategoria.id
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeAmigo(amigo) {
        // var categoria = this.state.categoria
        // categoria.amigo_id = amigo.id
        // this.setState({ categoria: categoria })

        var categoriaCPY = this.state.categoria
        categoriaCPY.amigo_id = amigo.id
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeEvento(evento) {
        // var categoria = this.state.categoria
        // categoria.evento_id = evento.id
        // this.setState({ categoria: categoria })

        var categoriaCPY = this.state.categoria
        categoriaCPY.evento_id = evento.id
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeConsideraNosRelatorios(event) {
        // var categoria = this.state.categoria
        // categoria.entra_nas_estatisticas = event.value
        // this.setState({ categoria: categoria })


        var categoriaCPY = this.state.categoria
        categoriaCPY.entra_nas_estatisticas = event.value
        this.setState({ categoria: categoriaCPY })

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.categorias = []
        pagamentoCPY.categorias.push(categoriaCPY)
        this.setState({ pagamento: pagamentoCPY })
    }


    handleChangeConta(conta) {

        alert('contas:' + JSON.stringify(this.state.contas))

        this.listaFaturas(conta.id)

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.conta_id = conta.id

        if (conta.tipo_conta === 'cartaoCredito') {
            var faturas = preencheComboFatura(this.state.pagamento.data_de_vencimento, conta.conta_cartoes_creditos.dia_fechamento, conta.conta_cartoes_creditos.dia_pagamento)
            this.setState({ faturas: faturas })
            pagamentoCPY.fatura = { fatura: null }
        } else {
            this.setState({ faturas: [] })
            pagamentoCPY.fatura = null
        }

        this.setState({ pagamento: pagamentoCPY })

        this.setState({ conta: conta })
    }

    handleChangeFrequencia(frequencia) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.frequencia = frequencia
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeParcelas(event) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.numero_de_parcelas = event.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangeFatura(fatura) {
        var faturaLida = this.state.faturasLidas.find(function (obj) { return obj.fatura === fatura.value })

        if (faturaLida !== undefined && faturaLida.status === 'liquidado') {
            this.setState({ alerta: true, alertaMsg: 'Esta fatura já esta liquidada, não pode ser alterada!!' })
            return
        }

        if (this.state.faturasLidas.length > 0) {
            if (this.state.faturasLidas[0].status === 'liquidado') {
                if (this.state.faturasLidas[0].fatura > fatura.value) {
                    this.setState({ alerta: true, alertaMsg: 'Tentativa de incluir fatura menor que uma fatura já liquidada' })
                    return
                }
            }
        }

        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.fatura.fatura = fatura.value
        this.setState({ pagamento: pagamentoCPY })
    }

    handleChangePagamentoIdReembolso(pagamentoReembolsado) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.pagamento_id_reembolso = pagamentoReembolsado.id
        this.setState({ pagamento: pagamentoCPY })
    }


    handleChangeComentarios(comentarios) {
        var pagamentoCPY = this.state.pagamento
        pagamentoCPY.comentarios = comentarios.target.value
        this.setState({ pagamento: pagamentoCPY })
    }

    avancado() {
        this.setState({ categoriaLista: true })
    }

    voltaDaLista(pagamento) {
        this.setState({ pagamento: pagamento, categoriaLista: false })

        if (pagamento.tipo === 'credito') {
            this.setState({ categorias: this.state.categoriasCredito })
        } else {
            this.setState({ categorias: this.state.categoriasDebito })
        }

        if (this.state.pagamento.categorias.length === 0) {
            this.setState({ categoria: JSON.parse(JSON.stringify(categoriaInicial)) })
        }

        if (this.state.pagamento.categorias.length !== 1) {
            return
        }

        if (this.state.pagamento.categorias.length === 1) {
            const categoriaCPY = this.state.pagamento.categorias[0]
            this.setState({ categoria: categoriaCPY })
        }

        var categorias = []
        if (pagamento.tipo === 'credito') {
            categorias = this.state.categoriasCredito
        } else {
            categorias = this.state.categoriasDebito
        }

        const categoria_id = this.state.pagamento.categorias[0].categoria_id
        const categoria = categorias.find(function (obj) { return obj.id === categoria_id })
        this.setState({ subCategorias: categoria.sub_categorias })

    }

    volta() {

        if (typeof this.props.voltaParaExtratoFatura === 'function') {
            this.props.voltaParaExtratoFatura()
        } else {
            if (typeof this.props.voltaParaExtratoConta === 'function') {
                this.props.voltaParaExtratoConta()
            } else {
                if (typeof this.props.voltaParaExtratoFaturaVindaDoPagamento === 'function') {
                    this.props.voltaParaExtratoFaturaVindaDoPagamento(this.state.pagamento)
                } else {
                    this.props.volta()
                }
            }
        }
    }

    handleChangeReembolsoDebito(event) {

        var pagamentoCPY = this.state.pagamento

        pagamentoCPY.receberei_reembolso = event.target.checked

        if (event.target.checked === false) {
            pagamentoCPY.pagamento_id_reembolso = null
        }

        this.setState({ pagamento: pagamentoCPY })

    }

    handleChangeReembolsoCredito(event) {

        var pagamentoCPY = this.state.pagamento

        pagamentoCPY.pagarei_reembolso = event.target.checked

        if (event.target.checked === false) {
            pagamentoCPY.pagamento_id_reembolso = null
        }

        this.setState({ pagamento: pagamentoCPY })

    }

    render() {

        if (this.state.categoriaLista === true) {
            return [
                this.renderCategoria()
            ]
        } else {
            return [
                this.renderAlert(),
                this.renderAlertaSaida(),
                this.renderConfirmacao(),
                this.renderComum(),
                this.renderDropZone(),
                this.renderAnexos()
            ]
        }
    }

    renderCategoria() {
        var operacao = ''

        if (this.state.pagamento.status === 'liquidado') {
            if (this.state.pagamento.fatura_id === null) {
                operacao = 'consulta'
            }
        }

        return (
            <
                PagamentoCategoria
                pagamento={this.state.pagamento}
                operacao={operacao}
                volta={(pagamento) => { this.voltaDaLista(pagamento) }}
            />
        )
    }

    handleUploadFile(event) {

        //https://www.youtube.com/watch?v=G5UZmvkLWSQ       Diego Fernandes  (Rocketseat)


        console.log('handleUploadFile handleUploadFile handleUploadFile handleUploadFile handleUploadFile handleUploadFile handleUploadFile handleUploadFile ')
        console.log(event.target)

        let files = event.target.files;

        this.setState({ selectedFileName: event.target.value });
        this.setState({ selectedFile: event.target.files[0] });

    }

    mostraAnexoTransacao() {
        this.setState({ viewAnexo: !this.state.viewAnexo, viewAnexoTipo: 'transacao' })
    }

    mostraAnexoPagamento() {
        this.setState({ viewAnexo: !this.state.viewAnexo, viewAnexoTipo: 'pagamento' })
    }

    renderComum() {

        var mostraAmigo = true

        if (this.state.amigos.length === 1) {
            mostraAmigo = false
        }

        if (this.state.pagamento.categorias.length !== 1) {
            mostraAmigo = false
        }

        var mostrarEvento = true

        if (this.state.eventos.length === 1) {
            mostrarEvento = false
        }

        if (this.state.pagamento.categorias.length !== 1) {
            mostrarEvento = false
        }

        var mostrarEntraNosRelatorios = true

        if (this.state.pagamento.categorias.length !== 1) {
            mostrarEntraNosRelatorios = false
        }

        var corUnica = 'btn-light'
        var corMensal = 'btn-light'
        var corRecorrente = 'btn-light'

        if (this.state.pagamento.frequencia === 'unica') {
            corUnica = 'btn-danger'
            corMensal = 'btn-light'
            corRecorrente = 'btn-light'
        }

        if (this.state.pagamento.frequencia === 'mensal') {
            corUnica = 'btn-light'
            corMensal = 'btn-danger'
            corRecorrente = 'btn-light'
        }

        if (this.state.pagamento.frequencia === 'recorrente') {
            corUnica = 'btn-light'
            corMensal = 'btn-light'
            corRecorrente = 'btn-danger'
        }

        //Input da parcela
        var inputParcelaNum = null
        var inputParcela = null

        var parcelaTotalDisable = null

        if (this.state.pagamento.status === 'liquidado') {
            parcelaTotalDisable = true
        } else {
            parcelaTotalDisable = false
        }

        var inputParcelaPreenchido =
            <div className="form-group col-md-1">
                <label for="parcelas">Parcelas</label>
                <input
                    disabled={parcelaTotalDisable}
                    onChange={this.handleChangeParcelas.bind(this)}
                    value={this.state.pagamento.numero_de_parcelas}
                    type="parcelas"
                    className="form-control"
                    id="parcelas"
                    placeholder="Parcelas" />
            </div>

        var inputParcelaNumero =
            <div className="form-group col-md-1">
                <label for="parcela">Parcela</label>
                <input
                    readOnly
                    value={this.state.pagamento.parcela}
                    type="text"
                    className="form-control"
                    id="parcela"
                    placeholder="Parcela" />
            </div>


        if (this.state.pagamento.frequencia === 'mensal') {
            inputParcelaNum = inputParcelaNumero
            inputParcela = inputParcelaPreenchido
        } else {
            inputParcelaNum = null
            inputParcela = null
        }

        //input fatura
        var inputFatura = null

        var varReadOnly = false

        if (this.state.pagamento.status === 'liquidado') {
            varReadOnly = true
        }

        if (this.state.pagamento.fatura_id !== null || this.state.faturas.length > 0) {
            var inputFaturaPreenchido =
                <div className="form-group col-md-2">
                    <label for="fatura" className="">Fatura</label>
                    <Select
                        isDisabled={varReadOnly}
                        styles={customStyles}
                        id="fatura"
                        name="fatura"
                        value={this.state.faturas.filter(option => option.value === this.state.pagamento.fatura.fatura)}
                        onChange={this.handleChangeFatura.bind(this)}
                        options={this.state.faturas}
                    />
                </div>
            inputFatura = inputFaturaPreenchido
        } else {
            inputFatura = null
        }

        var inibirCategoria = false
        var varDisabledReabrir = false

        if (this.state.pagamento.fatura_id === null) {
            if (this.state.pagamento.frequencia === 'mensal' || this.state.pagamento.frequencia === 'recorrente') {
                if (this.state.pagamento.status === 'liquidado') {
                    inibirCategoria = true
                }
            }
        }

        var inputCategoria =
            <div className="form-group col-md-4">
                <label for="categoria" className="">Categoria</label>
                <Select
                    styles={customStyles}
                    isDisabled={inibirCategoria}
                    id="categoria"
                    name="categoria"
                    getOptionLabel={({ nome }) => nome}
                    getOptionValue={({ id }) => id}
                    value={this.state.categorias.filter(option => option.id === this.state.categoria.categoria_id)}
                    onChange={this.handleChangeCategoria.bind(this)}
                    options={this.state.categorias}
                />
            </div>

        var inputSubCategoria =
            <div className="form-group col-md-4">
                <label for="subCategoria" className="">Subcategoria</label>
                <Select
                    readOnly={varDisabledReabrir}
                    styles={customStyles}
                    isDisabled={inibirCategoria}
                    id="subCategoria"
                    name="subCategoria"
                    getOptionLabel={({ nome }) => nome}
                    getOptionValue={({ id }) => id}
                    value={this.state.subCategorias.filter(option => option.id === this.state.categoria.sub_categoria_id)}
                    onChange={this.handleChangeSubCategoria.bind(this)}
                    options={this.state.subCategorias}
                />
            </div>


        var varDisabled = false

        if (this.state.pagamento.categorias.length > 1) {
            varDisabled = true
            inputCategoria =
                <div className="form-group col-md-4">
                    <label for="valor">Categoria</label>
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Usar avançado" />
                </div>

            inputSubCategoria =
                <div className="form-group col-md-4">
                    <label for="valor">Subcategoria</label>
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Usar avançado" />
                </div>

        }

        var linhaReembolso = null

        if (this.state.pagamento.tipo === 'debito') {
            linhaReembolso =
                <div className="form-check">
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            onChange={this.handleChangeReembolsoDebito.bind(this)}
                            className="form-check-input"
                            id="receberei_reembolso"
                            checked={this.state.pagamento.receberei_reembolso}></input>
                        Este débito será reembolsado
                    </label>
                </div>
        }

        linhaReembolso = null

        if (this.state.pagamento.tipo === 'credito') {
            linhaReembolso =
                <div className="form-check">
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            onChange={this.handleChangeReembolsoCredito.bind(this)}
                            className="form-check-input"
                            id="pagarei_reembolso"
                            checked={this.state.pagamento.pagarei_reembolso}></input>Este crédito vai pagar um reembolso</label>
                </div>

        }

        // if (this.state.pagamento.frequencia !== 'unica' && this.state.pagamento.tipo === 'credito') {
        if (this.state.pagamento.frequencia !== 'unica') {
            //if (this.state.pagamento.status !== 'liquidado') {
            linhaReembolso = null
            //}
        }

        //fim input variáveis

        var podeExcluir = false

        if (this.state.pagamento.frequencia === 'unica' && this.state.pagamento.status === 'emAberto') {
            podeExcluir = true
        }

        if (this.state.pagamento.fatura_id !== null) {
            if (this.state.pagamento.frequencia === 'unica') {
                if (this.state.pagamento.fatura.status === 'emAberto')
                    podeExcluir = true
            }
        }

        var botaoSalvar =
            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.alteraPagamento() }}>Salvar</button>

        //quando for parcelado/recorrente fora do cartão de crédito, e for liquidado disponibilizar apenas a função de reabrir
        if (this.state.pagamento.fatura_id === null) {
            if (this.state.pagamento.frequencia === 'mensal' || this.state.pagamento.frequencia === 'recorrente') {
                if (this.state.pagamento.status === 'liquidado') {
                    botaoSalvar = null
                    varDisabled = true
                    varDisabledReabrir = true
                    inibirCategoria = true
                }
            }
        }

        var tituloAnexoTransacao = 'Anexo visível em todos os pagamentos'
        var tituloAnexoPagamento = 'Anexo visível apenas neste pagamento'

        return (

            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Alteração de pagamento</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-2">
                            <label for="tipo" className="">Tipo</label>
                            <Select
                                isDisabled={varDisabledReabrir}
                                styles={customStyles}
                                id="tipo"
                                name="tipo"
                                value={tipos.filter(option => option.value === this.state.pagamento.tipo)}
                                onChange={this.handleChangeTipo.bind(this)}
                                options={tipos}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label for="valor">Valor</label>
                            <input
                                onChange={this.handleChangeValor.bind(this)}
                                readOnly={varDisabled}
                                value={this.state.pagamento.valor}
                                type="valor"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                        <div className="col-md-2">
                            <label className="" >{this.state.labelData}</label>
                            <DatePicker
                                readOnly={varDisabledReabrir}
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeVencimento"
                                selected={moment(this.state.pagamento.data_de_vencimento).toDate()}
                                onChange={this.handleChangeVencimento.bind(this)} />
                        </div>
                        <div className="form-group col-md-5">
                            <label for="descricao">Descrição</label>
                            <input
                                readOnly={varDisabledReabrir}
                                onChange={this.handleChangeDescricao.bind(this)}
                                value={this.state.pagamento.descricao}
                                type="text"
                                maxlength="50"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                        <button type="button" className="btn btn-link btn-sm text-right" title={tituloAnexoTransacao} onClick={() => { this.mostraAnexoTransacao() }}><i class="fa fa-paperclip fa-2x rgm-cor-link" aria-hidden="true"></i></button>
                    </div>
                    <div className="ml-3 form-row">
                        {inputCategoria}
                        {inputSubCategoria}
                        <div className="form-group col-md-3">
                            <label className="" >Avançado</label>
                            <div>
                                <button id="unica" type="button" className={"btn " + (corUnica) + " mr-1"} onClick={() => { this.avancado() }} ><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Opções</button>
                            </div>
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        {mostraAmigo === true &&
                            <div className="form-group col-md-4">
                                <label for="amigo" className="">Amigo</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled={varDisabled}
                                    id="amigo"
                                    name="amigo"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.amigos.filter(option => option.id === this.state.categoria.amigo_id)}
                                    onChange={this.handleChangeAmigo.bind(this)}
                                    options={this.state.amigos}
                                />
                            </div>
                        }
                        {mostrarEvento === true &&
                            <div className="form-group col-md-4">
                                <label for="evento" className="">Evento</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled={varDisabled}
                                    id="evento"
                                    name="evento"
                                    getOptionLabel={({ descricao }) => descricao}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.eventos.filter(option => option.id === this.state.categoria.evento_id)}
                                    onChange={this.handleChangeEvento.bind(this)}
                                    options={this.state.eventos}
                                />
                            </div>
                        }
                        {mostrarEntraNosRelatorios === true &&
                            <div className="form-group col-md-3">
                                <label for="tipo" className="">Considerar nos relatórios</label>
                                <Select
                                    styles={customStyles}
                                    id="consideraNoRelatorio"
                                    name="consideraNoRelatorio"
                                    value={consideraNosRelatorios.filter(option => option.value === this.state.categoria.entra_nas_estatisticas)}
                                    onChange={this.handleChangeConsideraNosRelatorios.bind(this).bind(this)}
                                    options={consideraNosRelatorios}
                                />
                            </div>
                        }

                    </div>
                    <div className="ml-3 form-row">
                        <div className="form-group col-md-4">
                            <label for="conta" className="">Conta</label>
                            <Select
                                readOnly={varDisabledReabrir}
                                styles={customStyles}
                                isDisabled={this.state.naoAlteraConta}
                                id="conta"
                                name="conta"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                selectedValue={this.state.conta_id}
                                value={this.state.contas.filter(option => option.id === this.state.pagamento.conta_id)}
                                onChange={this.handleChangeConta.bind(this)}
                                options={this.state.contas}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label className="" >Frequência</label>
                            <div>
                                <button disabled id="unica" type="button" className={"btn btn-sm " + (corUnica) + " mr-1"} onClick={() => { this.handleChangeFrequencia("unica") }} >Única</button>
                                <button disabled id="mensal" type="button" className={"btn btn-sm " + (corMensal) + " mr-1"} onClick={() => { this.handleChangeFrequencia("mensal") }} >Mensal</button>
                                <button disabled id="recorrente" type="button" className={"btn btn-sm " + (corRecorrente) + " mr-1"} onClick={() => { this.handleChangeFrequencia("recorrente") }} >Recorrente</button>
                            </div>
                        </div>
                        {inputParcelaNum}
                        {inputParcela}
                        {inputFatura}
                    </div>
                    <div className="mt-3 ml-2 form-row">
                        {linhaReembolso}
                        {this.state.pagamento.pagarei_reembolso &&
                            <div className="form-group col-md-4 m-0">
                                <Select
                                    styles={customStyles}
                                    placeholder="Selecione uma pagamento.."
                                    id="pagamentosReembolsar"
                                    name="pagamentosReembolsar"
                                    getOptionLabel={({ descricao, valor, data_de_pagamento }) => (descricao + ' | ' + formataDisplayEmReal(valor) + ' | ' + moment(data_de_pagamento).format('DD/MM/YYYY'))}
                                    getOptionValue={({ id }) => id}
                                    selectedValue={this.state.pagamento_id_reembolso}
                                    value={this.state.pagamentosReembolsar.filter(option => option.id === this.state.pagamento.pagamento_id_reembolso)}
                                    onChange={this.handleChangePagamentoIdReembolso.bind(this)}
                                    options={this.state.pagamentosReembolsar}
                                />
                            </div>
                        }
                    </div>
                    <div class="form-group col-md-11">
                        <label className="m-2" for="comment">Comentários</label>
                        <textarea
                            value={this.state.pagamento.comentarios}
                            className="form-control mt-2 ml-2"
                            rows="2" id="comum:comentarios"
                            onChange={this.handleChangeComentarios.bind(this)}>
                        </textarea>
                        {this.state.pagamento.frequencia === 'mensal' &&
                            <button type="button" className="btn btn-link btn-sm text-right" title={tituloAnexoPagamento} onClick={() => { this.mostraAnexoPagamento() }}><i class="fa fa-paperclip fa-2x rgm-cor-link" aria-hidden="true"></i></button>
                        }
                        {this.state.pagamento.frequencia === 'recorrente' &&
                            <button type="button" className="btn btn-link btn-sm text-right" title={tituloAnexoPagamento} onClick={() => { this.mostraAnexoPagamento() }}><i class="fa fa-paperclip fa-2x rgm-cor-link" aria-hidden="true"></i></button>
                        }
                    </div>
                    <div class="card-header border">
                        <div className="row ml-2">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => { this.volta() }}>Voltar</button>
                            {botaoSalvar}
                            {podeExcluir &&
                                <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.excluiPagamento() }}>Excluir</button>
                            }
                            {this.state.podeReabrir === true &&
                                <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.reabrirPagamento() }}>Reabrir</button>
                            }
                        </div>
                    </div>

                </form>
            </div>
        )
    }

    handleUpload = files => {
        const uploadedFiles = files.map(file => ({
            file,
            id: uniqueId(),
            anexo_id: null,
            anexo_tipo: this.state.viewAnexoTipo,
            name: file.name,
            readableSize: filesize(file.size),
            preview: URL.createObjectURL(file),
            progress: 0,
            uploaded: false,
            error: false,
            url: null
        }));

        this.setState({
            uploadedFiles: this.state.uploadedFiles.concat(uploadedFiles)
        });

        uploadedFiles.forEach(this.processUpload);
    };

    updateFile = (id, data) => {

        this.setState({
            uploadedFiles: this.state.uploadedFiles.map(uploadedFile => {
                return id === uploadedFile.id
                    ? { ...uploadedFile, ...data }
                    : uploadedFile;
            })
        });

        if (data.uploaded === true) {
            var uploadedFiles = this.state.uploadedFiles.map(uploadedFile => { return id === uploadedFile.id ? { ...uploadedFile, ...data } : uploadedFile })
            var anexos = this.state.anexos
            var anexo = {
                nome: uploadedFiles[0].name,
                size: 0,
                anexo_id: uploadedFiles[0].anexo_id,
                url: uploadedFiles[0].url
            }
            anexos.push(anexo)
            this.setState({ anexos: anexos })
        }

    };

    processUpload = uploadedFile => {
        const data = new FormData();

        data.append("file", uploadedFile.file, uploadedFile.name);

        const url = global.url + 'upload/inclui'

        var token = localStorage.getItem('EGR_TOKEN')

        api.post(url, data, {
            headers: {
                authorization: token
            },
            onUploadProgress: e => {
                const progress = parseInt(Math.round((e.loaded * 100) / e.total));

                this.updateFile(uploadedFile.id, {
                    progress
                });
            }
        }).then(response => {
            this.updateFile(uploadedFile.id, {
                uploaded: true,
                id: response.data._id,
                anexo_id: response.data.id,
                url: response.data.url
            });
        }).catch(() => {
            this.updateFile(uploadedFile.id, {
                error: true
            });
        });

    };


    renderDropZone() {

        if (this.state.viewAnexo === false) {
            return
        }

        const { uploadedFiles } = this.state;

        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Anexar documentos do tipo PDF</h5>
                        </div>
                    </div>
                    <Container>
                        <Content>
                            <Upload onUpload={this.handleUpload} />
                            {!!uploadedFiles.length && (
                                <FileList files={uploadedFiles} onDelete={this.handleDelete} />
                            )}
                        </Content>
                    </Container>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            if (this.state.podeReabrir === false) {
                return (
                    <>
                        <ModalConfirma confirma={() => this.confirmaExcluiPagamento()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a exclusão deste pagamento?" />
                    </>
                )
            } else {
                return (
                    <>
                        <ModalConfirma confirma={() => this.confirmaReaberturaPagamento()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a reabertura deste pagamento?" />
                    </>
                )
            }
        }
    }

   

    renderAnexos() {

        if (this.state.anexos.length > 0) {
            return (
                <div className="container w-100 mt-5 mb-0">
                    <div className="card row bg-transparent">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>Anexos</h5>
                            </div>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="border align-middle">Nome</th>
                                    <th scope="col" className="border align-middle">Tamanho</th>
                                    <th scope="col" className="border align-middle text-center">Ver anexo</th>
                                    <th scope="col" className="border align-middle text-center">Excluir</th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.anexos.map((anexo, index) => {
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado m-1">
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{anexo.nome}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber text-right">{filesize(anexo.size)}</td>
                                            <td className="text-center"> <a href={anexo.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i></a></td>
                                            <td className="text-center text-white align-middle">
                                                <button className="btn btn-link btn-sm text-center" onClick={() => this.excluirAnexo(anexo.id)}><i className="fa fa-trash rgm-cor-trash" aria-hidden="true"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    acessaNomeCategoria(categoriaListada) {
        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === categoriaListada.categoria_id })
        return categoria.nome
    }

    acessaNomeSubCategoria(categoriaListada) {
        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === categoriaListada.categoria_id })
        const subCategoria = categoria.sub_categorias.find(function (obj) { return obj.id === categoriaListada.sub_categoria_id })
        return subCategoria.nome
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaFaturas(conta_id) {
        let url = global.url + 'cartaoCredito/listaFaturas/' + conta_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({
                        faturasLidas: responseJson.faturas
                    })
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    selecionaCategorias(categoriasLista) {

        this.setState({ categoriasDivisaoTotal: JSON.parse(JSON.stringify(categoriasLista)) })
        this.setState({ categoriasTodas: JSON.parse(JSON.stringify(categoriasLista)) })

        var result = false

        var categoriasDebito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'debito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        var categoriasCredito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'credito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }

            return result

        })

        this.setState({ categoriasDebito: categoriasDebito })
        this.setState({ categoriasCredito: categoriasCredito })

        var categorias = null
        var categoria_id = null
        var subCategorias = null

        if (this.state.pagamento.categorias.length === 1) {
            if (this.state.pagamento.tipo === 'debito') {
                categorias = categoriasDebito
                categoria_id = this.state.categoria.categoria_id
                subCategorias = categorias.find(function (obj) { return obj.id === categoria_id })
                this.setState({ categorias: categorias, subCategorias: subCategorias.sub_categorias })
            } else {
                categorias = categoriasCredito
                categoria_id = this.state.categoria.categoria_id
                subCategorias = categorias.find(function (obj) { return obj.id === categoria_id })
                if (subCategorias === undefined) {
                    this.setState({ categorias: categorias, subCategorias: [] })
                } else {
                    this.setState({ categorias: categorias, subCategorias: subCategorias.sub_categorias })
                }
            }
        }

    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var conta_id = this.state.pagamento.conta_id
                    var conta = responseJson.contas.find(function (obj) { return obj.id === conta_id })
                    this.somenteContasAbertas(responseJson.contas)
                    this.setState({ conta: conta })
                    if (conta.tipo_conta === 'cartaoCredito') {
                        var faturas = preencheComboFatura(this.state.pagamento.data_de_vencimento, conta.conta_cartoes_creditos.dia_fechamento, conta.conta_cartoes_creditos.dia_pagamento)
                        this.setState({ faturas: faturas })
                    }
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    somenteContasAbertas(contasInput) {
        var contas = []

        contasInput.map(async (conta, index) => {
            if (conta.status === 'aberta') {
                contas.push(conta)
            }
        })

        this.setState({ contas: contas })
    }

    listaAmigos() {
        let url = global.url + 'amigo/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var amigo = {
                        id: null,
                        nome: '------'
                    }
                    responseJson.amigos.push(amigo)
                    this.setState({ amigos: responseJson.amigos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaEventos() {
        let url = global.url + 'evento/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var evento = {
                        id: null,
                        descricao: '------'
                    }
                    responseJson.eventos.push(evento)
                    this.setState({ eventos: responseJson.eventos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    buscaPagamentosParaSeremReembolsados() {
        let url = global.url + 'pagamento/buscaPagamentosParaSeremReembolsados/' + this.state.pagamento.pagamento_id_reembolso

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ pagamentosReembolsar: responseJson.pagamentos })

                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })


    }

    excluiPagamento() {
        this.setState({ pedeConfirmacao: true })
    }

    reabrirPagamento() {
        this.setState({ pedeConfirmacao: true })
    }

    excluirAnexo(id) {

        var pagamento = {
            id: this.state.pagamento.id,
            anexo_id: id
        }

        var url = null

        url = global.url + 'pagamento/excluirAnexo'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Exclusão concluída' })
                } else {
                    this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
                    return
                }
            })
    }

    confirmaExcluiPagamento() {

        this.setState({ pedeConfirmacao: false })

        var pagamento = {
            id: this.state.pagamento.id,
            transacao_id: this.state.pagamento.transacao_id,
            frequencia: this.state.pagamento.frequencia
        }

        var url = null

        if (this.state.pagamento.fatura === null) {
            url = global.url + 'pagamento/excluir'
        } else {
            url = global.url + 'fatura/excluiPagamentoDentroDeUmaFatura'
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Exclusão concluída' })
                } else {
                    this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
                    return
                }
            })
    }

    confirmaReaberturaPagamento() {

        this.setState({ pedeConfirmacao: false })

        var pagamento = {
            id: this.state.pagamento.id,
            transacao_id: this.state.pagamento.transacao_id,
            frequencia: this.state.pagamento.frequencia
        }

        var url = null

        url = global.url + 'pagamento/reabrir'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ resp: responseJson.resp })
                    this.setState({ alertaSaida: true, alertaMsg: 'Reabertura concluída' })
                } else {
                    this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
                    return
                }
            })
    }

    alteraPagamento() {

        if (this.state.pagamento.frequencia === 'mensal') {

            if (this.state.pagamento.numero_de_parcelas < 2) {
                this.setState({ alerta: true, alertaMsg: 'O número de parcelas não pode ser menor que 2 para uma frequencia mensal' })
                return
            }

            if (this.state.pagamento.numero_de_parcelas <= this.state.pagamento.transacao.ultima_parcela_paga) {
                this.setState({ alerta: true, alertaMsg: 'O número de parcelas é menor ou igual ao numero de parcelas já pagas' })
                return
            }

            if (this.state.pagamento.numero_de_parcelas > 120) {
                this.setState({ alerta: true, alertaMsg: 'O número máximo de parcelas permitidas é de 120 (10 anos)' })
                return
            }
        }

        if (formataEmRealParaBD(this.state.pagamento.valor) === 0) {
            this.setState({ alerta: true, alertaMsg: 'Valor obrigatório' })
            return
        }

        if (moment(this.state.pagamento.data_de_vencimento).format('YYYY-MM-DD') === 'Invalid date') {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento valida é obrigatória' })
            return
        }

        if (this.state.pagamento.descricao === null || this.state.pagamento.descricao.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Descrição deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.pagamento.categorias.length === 0) {
            this.setState({ alerta: true, alertaMsg: 'Categoria/Subcategoria obrigatória' })
            return
        }

        var valorTotalCategorias = 0
        this.state.pagamento.categorias.map(async (categoria, index) => {
            if (categoria.tipo === 'debito') {
                valorTotalCategorias = valorTotalCategorias + formataEmRealParaBD(categoria.valor)
            } else {
                valorTotalCategorias = valorTotalCategorias - formataEmRealParaBD(categoria.valor)
            }
        })

        if (valorTotalCategorias < 0) {
            valorTotalCategorias = valorTotalCategorias * -1
        }

        valorTotalCategorias = parseFloat(valorTotalCategorias).toFixed(2)
        var valorTotalPagamento = formataEmRealParaBD(this.state.pagamento.valor)
        valorTotalPagamento = parseFloat(valorTotalPagamento).toFixed(2)

        if (valorTotalCategorias !== valorTotalPagamento) {
            this.setState({ alerta: true, alertaMsg: 'Erro inesperado, operação não concluída - Por favor, retome a inclusão deste pagamento' })
            return
        }

        if (this.state.pagamento.conta_id === null) {
            this.setState({ alerta: true, alertaMsg: 'Conta obrigatória' })
            return
        }

        if (this.state.conta.tipo_conta === 'cartaoCredito' && this.state.pagamento.fatura.fatura === null) {
            this.setState({ alerta: true, alertaMsg: 'Você deve escolher uma fatura para este pagamento' })
            return
        }

        if (this.state.pagamento.pagarei_reembolso === true && this.state.pagamento.pagamento_id_reembolso === undefined) {
            this.setState({ alerta: true, alertaMsg: 'Pagamento de reembolso deve aponta para um débito' })
            return
        }

        if (this.state.pagamento.pagarei_reembolso === true && this.state.pagamento.pagamento_id_reembolso === null) {
            this.setState({ alerta: true, alertaMsg: 'Pagamento de reembolso deve aponta para um débito' })
            return
        }


        var categorias = []

        this.state.pagamento.categorias.map(async (categoria, index) => {
            if (categoria.valor === null) {
                categoria.valor = formataEmRealParaBD(this.state.pagamento.valor)
            } else {
                categoria.valor = formataEmRealParaBD(categoria.valor)
            }

            if (categoria.descricao === null) {
                categoria.descricao = this.state.pagamento.descricao
            }

            if (categoria.entra_nas_estatisticas !== true && categoria.entra_nas_estatisticas !== false) {
                categoria.entra_nas_estatisticas = true
            }

            categorias.push(categoria)
        })

        var fatura = null
        if (this.state.conta.tipo_conta === 'cartaoCredito') {
            fatura = this.state.pagamento.fatura.fatura
        }

        var data_de_pagamento = null
        var data_de_vencimento = this.state.pagamento.data_de_vencimento

        if (this.state.pagamento.status === 'liquidado') {
            data_de_pagamento = this.state.pagamento.data_de_vencimento.valueOf()
            data_de_vencimento = dataVencimentoSalva
        }

        var receberei_reembolso = this.state.pagamento.receberei_reembolso
        var pagarei_reembolso = this.state.pagamento.pagarei_reembolso
        var pagamento_id_reembolso = this.state.pagamento.pagamento_id_reembolso

        if (this.state.pagamento.frequencia !== 'unica') {
            receberei_reembolso = false
            pagarei_reembolso = false
            pagamento_id_reembolso = null
        }


        var pagamento = {
            id: this.state.pagamento.id,
            transacao_id: this.state.pagamento.transacao_id,
            fatura_id: this.state.pagamento.fatura_id,
            parcela: this.state.pagamento.parcela,
            descricao: this.state.pagamento.descricao,
            valor: formataEmRealParaBD(this.state.pagamento.valor),
            status: this.state.pagamento.status,
            data_de_vencimento_ano_mes: moment(data_de_vencimento).format('YYYY-MM-01'),
            data_de_vencimento: moment(data_de_vencimento).format('YYYY-MM-DD'),
            data_de_pagamento: data_de_pagamento,
            frequencia: this.state.pagamento.frequencia,
            numero_de_parcelas: this.state.pagamento.numero_de_parcelas,
            tipo: this.state.pagamento.tipo,
            receberei_reembolso: receberei_reembolso,
            pagarei_reembolso: pagarei_reembolso,
            pagamento_id_reembolso: pagamento_id_reembolso,
            comentarios: this.state.pagamento.comentarios,
            conta_id: this.state.pagamento.conta_id,
            fatura: fatura,
            categorias: categorias,
            transacao: this.state.pagamento.transacao,
            uploadedFiles: this.state.uploadedFiles
        }

        var url = null

        if (this.state.pagamento.fatura !== null) {
            url = global.url + 'fatura/alteraPagamentoDentroDeUmaFatura'
        } else {
            if (this.state.pagamento.status === 'liquidado') {
                url = global.url + 'pagamento/alterarLiquidado'
            } else {
                url = global.url + 'pagamento/alterar'
            }
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Alteração concluída' })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

}

export default PagamentoAlteracao