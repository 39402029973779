import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formataDisplayEmReal, formataEmRealParaBD, tiraEspacosDuplicados } from '../../util/Utils'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'

class EventoConsulta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            evento: props.evento,
            descricao: props.evento.descricao,
            valor_previsto: formataDisplayEmReal(props.evento.valor_previsto),
            valor: formataDisplayEmReal(props.evento.valor),
            comentarios: props.evento.comentarios,
            data_de_inicio: moment(props.evento.data_de_inicio).toDate(),
            data_de_fim: moment(props.evento.data_de_fim).toDate(),
            status: props.evento.status,
            alerta: false,
            alertaMsg: null,
            alertaSaida: false,
            pedeConfirmacao: false


        }
    }

    componentWillMount() {

    }

    excluiEvento() {

        if(this.state.evento.categorias.length > 0) {
            var mensagem = 'Evento não pode ser excluido, existem pagamentos associados a ele.'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return

        }

        this.setState({ pedeConfirmacao: true })

    }

    confirmaExcluiEvento() {
        this.setState({ pedeConfirmacao: false })

        let url = global.url + 'evento/excluir'

        var evento = {
            id: this.state.evento.id,
            descricao: tiraEspacosDuplicados(this.state.descricao),
            valor_previsto: formataEmRealParaBD(this.state.valor_previsto),
            valor: formataEmRealParaBD(this.state.valor),
            comentarios: this.state.comentarios,
            data_de_inicio: moment(this.state.data_de_inicio).format('YYYY-MM-DD'),
            data_de_fim: moment(this.state.data_de_fim).format('YYYY-MM-DD'),
            status: this.state.status
        }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(evento)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Exclusão concluida com sucesso' })
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })

    }



    alteraEvento() {
        var mensagem = null

        if (tiraEspacosDuplicados(this.state.descricao) == null || tiraEspacosDuplicados(this.state.descricao) === '') {
            mensagem = 'Descrição obrigatória'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        var data_de_inicio = moment(this.state.data_de_inicio).format('YYYY-MM-DD')

        if (data_de_inicio === 'Invalid date') {
            mensagem = 'Data inicio invalida'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        var data_de_fim = moment(this.state.data_de_fim).format('YYYY-MM-DD')

        if (data_de_fim === 'Invalid date') {
            mensagem = 'Data fim invalida'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        if (data_de_inicio > data_de_fim) {
            mensagem = 'Data de inicio não pode ser maior que data final'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        let url = global.url + 'evento/alterar'

        var evento = {
            id: this.state.evento.id,
            descricao: tiraEspacosDuplicados(this.state.descricao),
            valor_previsto: formataEmRealParaBD(this.state.valor_previsto),
            valor: formataEmRealParaBD(this.state.valor),
            comentarios: this.state.comentarios,
            data_de_inicio: moment(this.state.data_de_inicio).format('YYYY-MM-DD'),
            data_de_fim: moment(this.state.data_de_fim).format('YYYY-MM-DD'),
            status: this.state.status
        }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(evento)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Alteração concluida...' })
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }

    handleChangeDescricao(event) {
        this.setState({ descricao: event.target.value })
    }

    handleChangeDateInicio(date) {
        this.setState({ data_de_inicio: date });
    }

    handleChangeDateFim(date) {
        this.setState({ data_de_fim: date });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({ [id]: value });
    }

    handleChangeValor(event) {
        var valor_previsto = event.target.value

        if (valor_previsto.length === 1) {
            if (isNaN(valor_previsto)) {
                valor_previsto = '0'
            }
        }

        valor_previsto = formataDisplayEmReal(valor_previsto)

        if (valor_previsto.includes("-", 0)) {
            return
        }

        this.setState({ valor_previsto: valor_previsto })

    }

    handleChangeComentarios(comentarios) {
        this.setState({ comentarios: comentarios.target.value })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderConfirmacao(),
            this.renderPrincipal()
        ]
    }


    renderPrincipal() {
        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Alteração de evento</h5>
                        </div>
                    </div>
                    <div className="form-row mt-2">
                        <div className="form-group col-md-5 ml-3">
                            <label for="descricao" className="">Descrição</label>
                            <div className="col-30">
                                <input type="text" className="form-control" id="descricao" maxlength="50" onChange={this.handleChangeDescricao.bind(this)} value={this.state.descricao} placeholder="Descrição" />
                            </div>
                        </div>
                        <div className="form-group ml-3">
                            <label for="dataDeInicio" className="">Inicio</label>
                            <div className="">
                                <DatePicker
                                    className="form-control text-center"
                                    dateFormat="dd/MM/yyyy"
                                    id="dataDeInicio"
                                    selected={this.state.data_de_inicio}
                                    onChange={this.handleChangeDateInicio.bind(this)}
                                />
                            </div>
                        </div>
                        <div className="form-group ml-3">
                            <label for="dataDeFim" className="">Fim</label>
                            <div className="">
                                <DatePicker
                                    className="form-control text-center"
                                    dateFormat="dd/MM/yyyy"
                                    id="dataDeFim"
                                    selected={this.state.data_de_fim}
                                    onChange={this.handleChangeDateFim.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-2 ml-3">
                            <label className="" for="valor">Valor Previsto</label>
                            <input
                                onChange={this.handleChangeValor.bind(this)}
                                value={this.state.valor_previsto}
                                type="valor"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                    </div>
                    <div class="form-group col-md-11">
                        <label className="" for="comment">Comentários</label>
                        <textarea
                            value={this.state.comentarios}
                            className="form-control"
                            rows="2"
                            id="comum:comentarios"
                            onChange={this.handleChangeComentarios.bind(this)}>
                        </textarea>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button id="buttonGravar" type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.alteraEvento() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                            <button type="button" className="btn btn-danger btn-sm m-2" onClick={() => { this.excluiEvento() }}>Excluir</button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }
 
    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.confirmaExcluiEvento()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a exclusão do evento?" />
                </>
            )
        }
    }

}

export default EventoConsulta