import React, {
  Component
} from 'react'
import CategoriaLista from '../categoria/CategoriaLista'
import CategoriaInclusao from '../categoria/CategoriaInclusao'
import CategoriaAlteracao from '../categoria/CategoriaAlteracao'
import CategoriaMover from '../categoria/CategoriaMover'
import SubCategoriaLista from '../categoria/SubCategoriaLista'
import SubCategoriaInclusao from '../categoria/SubCategoriaInclusao'
import SubCategoriaAlteracao from '../categoria/SubCategoriaAlteracao'

class Categoria extends Component {
  constructor(props) {
    super(props)

    this.state = {
      categoriaLista: true,
      categoriaInclusao: false,
      categoriaAlteracao: false,
      categoriaMover: false,
      subCategoriaLista: false,
      subCategoriaInclusao: false,
      subCategoriaAlteracao: false,
      categoria: null,
      subCategoria: null
    }
  }

  componentWillMount() {
    global.categoriasPrincipal = () => { this.categoriaListaTrue() }
  }


  categoriaListaTrue() {
    this.setFalseAll()
    this.setState({
      categoriaLista: true
    })
  }

  categoriaInclusaoTrue() {
    this.setFalseAll()
    this.setState({
      categoriaInclusao: true
    })
  }

  categoriaAlteracaoTrue(categoria) {
    this.setFalseAll()
    this.setState({
      categoriaAlteracao: true,
      categoria: categoria
    })
  }

  categoriaMoverTrue() {
    this.setFalseAll()
    this.setState({
      categoriaMover: true
    })
  }

  subCategoriaListaTrue(categoria) {
    this.setFalseAll()
    this.setState({
      subCategoriaLista: true,
      categoria: categoria
    })
  }

  subCategoriaInclusaoTrue(categoria) {
    this.setFalseAll()
    this.setState({
      subCategoriaInclusao: true,
      categoria: categoria
    })
  }

  subCategoriaAlteracaoTrue(categoria, subCategoria) {
    this.setFalseAll()
    this.setState({
      subCategoriaAlteracao: true,
      categoria: categoria,
      subCategoria: subCategoria
    })
  }
  

  setFalseAll() {
    this.setState({
      categoriaLista: false,
      categoriaInclusao: false,
      categoriaAlteracao: false,
      categoriaMover: false,
      subCategoriaLista: false,
      subCategoriaInclusao: false,
      subCategoriaAlteracao: false
    })
  }

  render() {

    if (this.state.categoriaLista === true) {
      return (<
        CategoriaLista
        categoriaInclusao={() => { this.categoriaInclusaoTrue() }}
        categoriaAlteracao={(categoria) => { this.categoriaAlteracaoTrue(categoria) }}
        categoriaMover={() => { this.categoriaMoverTrue() }}
        subCategoriaLista={(categoria) => { this.subCategoriaListaTrue(categoria) }}

      />
      )
    }

    if (this.state.categoriaAlteracao === true) {
      return (<
        CategoriaAlteracao
        categoria={this.state.categoria}
        volta={(categoria) => { this.categoriaListaTrue(categoria) }}
      />
      )
    }

    if (this.state.categoriaMover === true) {
      return (<
        CategoriaMover
        volta={(categoria) => { this.categoriaListaTrue(categoria) }}
      />
      )
    }

    if (this.state.categoriaInclusao === true) {
      return (<
        CategoriaInclusao
        volta={() => { this.categoriaListaTrue() }}
      />
      )
    }

    if (this.state.subCategoriaLista === true) {
      return (<
        SubCategoriaLista
        categoria={this.state.categoria}
        subCategoriaInclusao={(categoria) => { this.subCategoriaInclusaoTrue(categoria) }}
        subCategoriaAlteracao={(categoria, subCategoria) => { this.subCategoriaAlteracaoTrue(categoria, subCategoria) }}
        volta={() => { this.categoriaListaTrue() }}
      />
      )
    }

    if (this.state.subCategoriaInclusao === true) {
      return (<
        SubCategoriaInclusao
        categoria={this.state.categoria}
        volta={(categoria) => { this.subCategoriaListaTrue(categoria) }}
      />
      )
    }

    if (this.state.subCategoriaAlteracao === true) {
      return (<
        SubCategoriaAlteracao
        categoria={this.state.categoria}
        subCategoria={this.state.subCategoria}
        volta={(categoria) => { this.subCategoriaListaTrue(categoria) }}
      />
      )
    }

    if (this.state.categoriaLista === false &&
      this.state.categoriaInclusao === false &&
      this.state.categoriaAlteracao === false &&
      this.state.categoriaMover === false &&
      this.state.subCategoriaLista === false &&
      this.state.subCategoriaInclusao === false &&
      this.state.subCategoriaAlteracao === false
    ) {
      return null
    }
  }

}

export default Categoria