import React from 'react'
import moment from 'moment'
import { Component } from 'react'
import { formataEmRealDisplayDoBD } from '../../util/Utils'
import ModalInclusaoRapidaLancamentoContaCorrente from '../modal/ModalInclusaoRapidaLancamentoContaCorrente'

class ContaListaExtrato extends Component {
    constructor(props) {
        super(props)
        this.state = {
            conta: this.props.conta,
            saldos: [],
            incluiRapido: false,
        }
    }

    componentWillMount() {
        this.listaContas();
    }

    listaContas() {
        let url = global.url + 'conta/listaSaldos/' + this.state.conta.id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ saldos: responseJson.saldos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    corDoLancamento(valor) {
        if (valor >= 0) {
            return 'rgm-cor-positivo'
        } else {
            return 'text-white'
        }
    }

    contaExtratos(conta) { 
        this.props.contaListaExtrato(conta)
    }

    mesAnoPorExtenso(data) {
        var mesExtenso = moment(data).format('MMM');
        var anoExtenso = moment(data).format('YYYY');

        return anoExtenso + ' - ' + mesExtenso.toUpperCase()

    }

    render() {
        return [
            this.renderIncluiRapido(),
            this.renderPrincipal()
        ]
    }

    renderPrincipal() {

        var contaRemunerada = false

        if (this.state.conta.tipo_conta === 'contaCorrente') {
            if (this.state.conta.conta_correntes.possui_conta_remunerada) {
                contaRemunerada = true
            }
        }
 
        return (
            <div className="container w-100 mt-5"    >
                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.setState({ incluiRapido: true })}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Lançamento</button>
                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.contaTransferir(this.state.conta.id) }}>Transferir</button>
                <div className="card bg-transparent">
                    <div class="card-header rgm-bg-azul-esverdiado text-white border">
                        <div className="row align-middle text-white m-2">
                            <h5>{this.state.conta.nome}</h5>
                        </div>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr className="bg-info">
                                <th scope="col" className="border text-center">Ano Mês</th>
                                {contaRemunerada &&
                                    <th scope="col" className="border text-right">Saldo (conta)</th>
                                }
                                {contaRemunerada &&
                                    <th scope="col" className="border text-right">Saldo (conta remunerada)</th>
                                }
                                <th scope="col" className="border text-right">Saldo total</th>
                                <th className="border text-center">Extrato</th>
                            </tr>
                        </thead>
                        <tbody className="rgm-bg-azul-esverdiado">
                            {this.state.saldos.map((saldo, index) => {
                                return (
                                    <tr className="text-white">
                                        <td className="border text-center align-middle"><h5>{this.mesAnoPorExtenso(saldo.data)}</h5></td>
                                        {contaRemunerada &&
                                            <td className={'border text-right align-middle '  + this.corDoLancamento(saldo.saldoConta)}><h5>{formataEmRealDisplayDoBD(parseFloat(saldo.saldoConta))}</h5></td>
                                        }
                                        {contaRemunerada &&
                                            <td className={'border text-right align-middle '  + this.corDoLancamento(saldo.saldoContaRemunerada)}><h5>{formataEmRealDisplayDoBD(parseFloat(saldo.saldoContaRemunerada))}</h5></td>
                                        }
                                        <td className={'border text-right align-middle ' + this.corDoLancamento(saldo.saldoTotal)} ><h5>{formataEmRealDisplayDoBD(parseFloat(saldo.saldoTotal))}</h5></td>

                                        <td className="align-middle text-center align-middle">
                                            <button className="btn btn-link btn-sm m-2" onClick={() => this.props.contaExtrato(saldo)}  >
                                                <h5><i class="fa fa-list rgm-cor-link" title="Extrato"></i></h5>
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )

    }

    volta() {
        this.setState({ incluiRapido: false })
        this.listaContas()
    }

    renderIncluiRapido() {
        if (this.state.incluiRapido === true) {
            return (
                <>
                    <ModalInclusaoRapidaLancamentoContaCorrente volta={() => this.volta()} conta_id={this.state.conta.id} />
                </>
            )
        }
    }

}

export default ContaListaExtrato