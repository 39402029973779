import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { formataDisplayEmReal, formataEmRealParaBD } from '../../util/Utils'
import ModalAviso from '../modal/ModalAviso'

class EventoConsulta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            evento: props.evento,
            descricao: props.evento.descricao,
            valor_previsto: formataDisplayEmReal(props.evento.valor_previsto),
            valor: formataDisplayEmReal(props.evento.valor),
            comentarios: props.evento.comentarios,
            data_de_inicio: moment(props.evento.data_de_inicio).toDate(),
            data_de_fim: moment(props.evento.data_de_fim).toDate(),
            status: props.evento.status,
            alerta: false,
            alertaMsg: null
        }
    }


    incluiEvento() {
        var mensagem = null

        if (this.state.descricao == null || this.state.descricao === '' || ' ' === this.state.descricao.substring(0, 1)) {
            mensagem = 'você não digitou o descricao ou o descricao começou com espaço'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        var data_de_inicio = moment(this.state.data_de_inicio).format('YYYY-MM-DD')

        if (data_de_inicio === 'Invalid date') {
            mensagem = 'Data inicio invalida'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        var data_de_fim = moment(this.state.data_de_fim).format('YYYY-MM-DD')

        if (data_de_fim === 'Invalid date') {
            mensagem = 'Data fim invalida'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        if (data_de_inicio > data_de_fim) {
            mensagem = 'Data de inicio não pode ser maior que data final'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        let url = global.url + 'evento/incluir'

        var evento = {
            descricao: this.state.descricao,
            valor_previsto: formataEmRealParaBD(this.state.valor_previsto),
            valor: this.state.valor,
            comentarios: this.state.comentarios,
            data_de_inicio: moment(this.state.data_de_inicio).format('YYYY-MM-DD'),
            data_de_fim: moment(this.state.data_de_fim).format('YYYY-MM-DD'),
            status: this.state.status
        }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(evento)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ resp: responseJson.resp })
                this.setState({ alertaSaida: true, alertaMsg: 'inclusão concluida...' })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    handleChangeDescricao(event) {
        this.setState({ descricao: event.target.value })
    }

    handleChangeDateInicio(date) {
        this.setState({ data_de_inicio: date });
    }

    handleChangeDateFim(date) {
        this.setState({ data_de_fim: date });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({ [id]: value });
    }

    handleChangeValor(event) {
        var valor_previsto = event.target.value

        if (valor_previsto.length === 1) {
            if (isNaN(valor_previsto)) {
                valor_previsto = '0'
            }
        }

        valor_previsto = formataDisplayEmReal(valor_previsto)

        this.setState({ valor_previsto: valor_previsto })

    }

    handleChangeComentarios(comentarios) {
        this.setState({ comentarios: comentarios.target.value })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderComum()
        ]
    }


    renderComum() {
        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Consulta de evento</h5>
                        </div>
                    </div>
                    <div className="form-row mt-2">
                        <div className="form-group col-md-5 ml-3">
                            <label for="descricao" className="">Descrição</label>
                            <div className="col-30">
                                <input 
                                type="text" 
                                className="form-control" 
                                id="descricao" 
                                maxlength="50" 
                                value={this.state.descricao} placeholder="Descrição" />
                            </div>
                        </div>
                        <div className="form-group ml-3">
                            <label for="dataDeInicio" className="">Inicio</label>
                            <div className="">
                                <DatePicker
                                    className="form-control text-center"
                                    dateFormat="dd/MM/yyyy"
                                    id="dataDeInicio"
                                    selected={this.state.data_de_inicio}
                                />
                            </div>
                        </div>
                        <div className="form-group ml-3">
                            <label for="dataDeFim" className="">Fim</label>
                            <div className="">
                                <DatePicker
                                    className="form-control text-center"
                                    dateFormat="dd/MM/yyyy"
                                    id="dataDeFim"
                                    selected={this.state.data_de_fim}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-2 ml-3">
                            <label className="" for="valor">Valor Previsto</label>
                            <input
                                value={this.state.valor_previsto}
                                type="valor"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                    </div>
                    <div class="form-group col-md-11">
                        <label className="" for="comment">Comentários</label>
                        <textarea
                            value={this.state.comentarios}
                            className="form-control"
                            rows="2" 
                            id="comum:comentarios">
                        </textarea>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm ml-3" onClick={() => { this.props.volta() }}>Voltar</button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

}

export default EventoConsulta