import React, { Component } from 'react'
import moment from 'moment'
import '../loader.css'
import { formataDisplayEmReal } from '../../util/Utils'
import { Line, Bar } from "react-chartjs-2";

var t1dr, t1cr = 0
var t2dr, t2cr = 0
var t3dr, t3cr = 0
var t4dr, t4cr = 0

var totalGeral = 0

const data = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            label: 'Crédito',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: 'rgba(75,192,192,1)'
        },
        {
            label: 'Débito',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: 'red',
            backgroundColor: 'red'
        },
        {
            label: 'Média Crédito',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: 'rgba(195,245,211,1)',
            backgroundColor: 'rgba(195,245,211,1)'
        },
        {
            label: 'Média Débito',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: 'rgba(247,197,197,1)',
            backgroundColor: 'rgba(247,197,197,1)'
        },
        {
            label: 'Crédito (ant)',
            data: [1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000],
            fill: false,
            borderColor: 'rgba(255,204,0,1)',
            backgroundColor: 'rgba(1255,204,0,1)'
        },
        {
            label: 'Débito(ant)',
            data: [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200],
            fill: false,
            borderColor: 'rgba(250,128,14,1)',
            backgroundColor: 'rgba(250,128,14,1)'
        },

    ]
}

const dataBar = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            label: 'Crédito',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            backgroundColor: 'rgba(75,192,192,0.2)'
        },
        {
            label: 'Débito',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            backgroundColor: 'red'
        },
        {
            label: 'Crédito',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            backgroundColor: 'rgba(75,192,192,0.2)'
        },
        {
            label: 'Débito',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            backgroundColor: 'red'
        },
        {
            label: 'Crédito (ant)',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            borderColor: 'rgba(255,204,0,1)',
            backgroundColor: 'rgba(1255,204,0,1)'
        },
        {
            label: 'Débito (ant)',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: 'rgba(250,128,14,1)',
            backgroundColor: 'rgba(250,128,14,1)'
        }
    ]
}

// trimestre

const dataTri = {
    labels: ['1tri JAN-MAR', '2tri ABR-JUN', '3tri JUL-SET', '4tri OUT-DEZ'],
    datasets: [
        {
            label: 'Crédito',
            data: [null, null, null, null],
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            backgroundColor: ''
        },
        {
            label: 'Débito',
            data: [null, null, null, null],
            fill: false,
            borderColor: 'red',
            backgroundColor: ''
        },
        {
            label: 'Média Crédito',
            data: [null, null, null, null],
            fill: false,
            borderColor: 'rgba(195,245,211,1)',
            backgroundColor: 'rgba(195,245,211,1)'
        },
        {
            label: 'Média Débito',
            data: [null, null, null, null],
            fill: false,
            borderColor: 'rgba(247,197,197,1)',
            backgroundColor: 'rgba(247,197,197,1)'
        },
        {
            label: 'Crédito (ant)',
            data: [1000, 2000, 3000, 4000],
            fill: false,
            borderColor: 'rgba(255,204,0,1)',
            backgroundColor: 'rgba(1255,204,0,1)'
        },
        {
            label: 'Débito(ant)',
            data: [100, 200, 300, 400],
            fill: false,
            borderColor: 'rgba(250,128,14,1)',
            backgroundColor: 'rgba(250,128,14,1)'
        },

    ]
}

const dataTriBar = {
    labels: ['1tri JAN-MAR', '2tri ABR-JUN', '3tri JUL-SET', '4tri OUT-DEZ'],
    datasets: [
        {
            label: 'Crédito',
            data: [null, null, null, null],
            backgroundColor: 'rgba(75,192,192,0.2)'
        },
        {
            label: 'Débito',
            data: [null, null, null, null],
            fill: false,
            backgroundColor: 'red'
        },
        {
            label: 'Crédito (ant)',
            data: [null, null, null, null],
            borderColor: 'rgba(255,204,0,1)',
            backgroundColor: 'rgba(1255,204,0,1)'
        },
        {
            label: 'Débito (ant)',
            data: [null, null, null, null],
            fill: false,
            borderColor: 'rgba(250,128,14,1)',
            backgroundColor: 'rgba(250,128,14,1)'
        }
    ]
}



class FluxoDeCaixa extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: data,
            dataBar: dataBar,
            dataTri: dataTri,
            dataTriBar: dataTriBar,
            mediaCredito: 0,
            mediaDebito: 0,
            mediaTriCredito: 0,
            mediaTriDebito: 0,
            usuario: null,
            amigosLista: [],
            loader: true,
            listaDebito: [],
            listaCredito: [],
            categoriasTotal: [],
            listaCategoriaMesAno: [],
            cabecCategoriaMesAno: '',
            ano: '',
            mostra: 'line',
            lista: 'anual',
            anoAnterior: parseInt(moment().format('YYYY')) - 1,
            anoAtual: parseInt(moment().format('YYYY')),
            anoPosterior: parseInt(moment().format('YYYY')) + 1,
            anoInibir: parseInt(moment().format('YYYY')) + 2
        }
    }

    componentWillMount() {
        this.listaCategorias()
        this.acessaUsuario()
        this.listaPagamentos()
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ categoriasTotal: responseJson.categorias })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }


    acessaUsuario() {
        let url = global.url + 'usuario/consulta'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                this.setState({ usuario: responseJson.usuario })
            })
    }

    listaPagamentos() {
        let url = global.url + 'relatorio/fluxoDeCaixaNew/' + this.state.anoAtual

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustaValores(responseJson)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    listaPagamentosAnoAnterior() {
        let anoAnterior = this.state.anoAtual - 1
        let url = global.url + 'relatorio/fluxoDeCaixaNew/' + anoAnterior

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.ajustaValoresAnoAnterior(responseJson)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    listaPagamentosCategoriaAnoMes(categoria, anoMes) {

        let categ = { categoria: categoria }

        let desc = this.acessaNomeCategoria(categ);


        this.setState({ cabecCategoriaMesAno: `${desc} ${anoMes.toString().padStart(2, '0')}/${this.state.anoAtual}` })

        let pesquisa = `${categoria}-${anoMes}-${this.state.anoAtual}`

        this.setState({ pesquisas: pesquisa.split('-') })

        let url = global.url + 'relatorio/fluxoDeCaixa2/' + pesquisa

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ listaCategoriaMesAno: responseJson.pagamentos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })

    }

    ajustaValores(responseJson) {

        var debito = JSON.parse(JSON.stringify(responseJson.debito))

        debito.sort((a, b) => {
            var compA = ('00000000000000000000' + a.meses[12].total).slice(-20)
            var compB = ('00000000000000000000' + b.meses[12].total).slice(-20)

            if (compA < compB) {
                return -1
            }

            if (compA > compB) {
                return 1
            }

            return 0
        })

        var mediaDebito = parseFloat(responseJson.resumoDebito.meses[12].total / 12).toFixed(2)
        var mediaCredito = parseFloat(responseJson.resumoCredito.meses[12].total / 12).toFixed(2)
        var mediaTriDebito = parseFloat(responseJson.resumoDebito.meses[12].total / 4).toFixed(2)
        var mediaTriCredito = parseFloat(responseJson.resumoCredito.meses[12].total / 4).toFixed(2)

        this.setState({ mediaDebito: mediaDebito, mediaCredito: mediaCredito, mediaTriDebito: mediaTriDebito, mediaTriCredito: mediaTriCredito })

        data.datasets[0].data[0] = responseJson.resumoCredito.meses[0].valor
        data.datasets[0].data[1] = responseJson.resumoCredito.meses[1].valor
        data.datasets[0].data[2] = responseJson.resumoCredito.meses[2].valor
        data.datasets[0].data[3] = responseJson.resumoCredito.meses[3].valor
        data.datasets[0].data[4] = responseJson.resumoCredito.meses[4].valor
        data.datasets[0].data[5] = responseJson.resumoCredito.meses[5].valor
        data.datasets[0].data[6] = responseJson.resumoCredito.meses[6].valor
        data.datasets[0].data[7] = responseJson.resumoCredito.meses[7].valor
        data.datasets[0].data[8] = responseJson.resumoCredito.meses[8].valor
        data.datasets[0].data[9] = responseJson.resumoCredito.meses[9].valor
        data.datasets[0].data[10] = responseJson.resumoCredito.meses[10].valor
        data.datasets[0].data[11] = responseJson.resumoCredito.meses[11].valor

        data.datasets[1].data[0] = responseJson.resumoDebito.meses[0].valor
        data.datasets[1].data[1] = responseJson.resumoDebito.meses[1].valor
        data.datasets[1].data[2] = responseJson.resumoDebito.meses[2].valor
        data.datasets[1].data[3] = responseJson.resumoDebito.meses[3].valor
        data.datasets[1].data[4] = responseJson.resumoDebito.meses[4].valor
        data.datasets[1].data[5] = responseJson.resumoDebito.meses[5].valor
        data.datasets[1].data[6] = responseJson.resumoDebito.meses[6].valor
        data.datasets[1].data[7] = responseJson.resumoDebito.meses[7].valor
        data.datasets[1].data[8] = responseJson.resumoDebito.meses[8].valor
        data.datasets[1].data[9] = responseJson.resumoDebito.meses[9].valor
        data.datasets[1].data[10] = responseJson.resumoDebito.meses[10].valor
        data.datasets[1].data[11] = responseJson.resumoDebito.meses[11].valor

        data.datasets[2].data[0] = mediaCredito
        data.datasets[2].data[1] = mediaCredito
        data.datasets[2].data[2] = mediaCredito
        data.datasets[2].data[3] = mediaCredito
        data.datasets[2].data[4] = mediaCredito
        data.datasets[2].data[5] = mediaCredito
        data.datasets[2].data[6] = mediaCredito
        data.datasets[2].data[7] = mediaCredito
        data.datasets[2].data[8] = mediaCredito
        data.datasets[2].data[9] = mediaCredito
        data.datasets[2].data[10] = mediaCredito
        data.datasets[2].data[11] = mediaCredito

        data.datasets[3].data[0] = mediaDebito
        data.datasets[3].data[1] = mediaDebito
        data.datasets[3].data[2] = mediaDebito
        data.datasets[3].data[3] = mediaDebito
        data.datasets[3].data[4] = mediaDebito
        data.datasets[3].data[5] = mediaDebito
        data.datasets[3].data[6] = mediaDebito
        data.datasets[3].data[7] = mediaDebito
        data.datasets[3].data[8] = mediaDebito
        data.datasets[3].data[9] = mediaDebito
        data.datasets[3].data[10] = mediaDebito
        data.datasets[3].data[11] = mediaDebito


        dataBar.datasets[0].data[0] = responseJson.resumoCredito.meses[0].valor
        dataBar.datasets[0].data[1] = responseJson.resumoCredito.meses[1].valor
        dataBar.datasets[0].data[2] = responseJson.resumoCredito.meses[2].valor
        dataBar.datasets[0].data[3] = responseJson.resumoCredito.meses[3].valor
        dataBar.datasets[0].data[4] = responseJson.resumoCredito.meses[4].valor
        dataBar.datasets[0].data[5] = responseJson.resumoCredito.meses[5].valor
        dataBar.datasets[0].data[6] = responseJson.resumoCredito.meses[6].valor
        dataBar.datasets[0].data[7] = responseJson.resumoCredito.meses[7].valor
        dataBar.datasets[0].data[8] = responseJson.resumoCredito.meses[8].valor
        dataBar.datasets[0].data[9] = responseJson.resumoCredito.meses[9].valor
        dataBar.datasets[0].data[10] = responseJson.resumoCredito.meses[10].valor
        dataBar.datasets[0].data[11] = responseJson.resumoCredito.meses[11].valor

        dataBar.datasets[1].data[0] = responseJson.resumoDebito.meses[0].valor
        dataBar.datasets[1].data[1] = responseJson.resumoDebito.meses[1].valor
        dataBar.datasets[1].data[2] = responseJson.resumoDebito.meses[2].valor
        dataBar.datasets[1].data[3] = responseJson.resumoDebito.meses[3].valor
        dataBar.datasets[1].data[4] = responseJson.resumoDebito.meses[4].valor
        dataBar.datasets[1].data[5] = responseJson.resumoDebito.meses[5].valor
        dataBar.datasets[1].data[6] = responseJson.resumoDebito.meses[6].valor
        dataBar.datasets[1].data[7] = responseJson.resumoDebito.meses[7].valor
        dataBar.datasets[1].data[8] = responseJson.resumoDebito.meses[8].valor
        dataBar.datasets[1].data[9] = responseJson.resumoDebito.meses[9].valor
        dataBar.datasets[1].data[10] = responseJson.resumoDebito.meses[10].valor
        dataBar.datasets[1].data[11] = responseJson.resumoDebito.meses[11].valor

        //trimestre
        dataTri.datasets[0].data[0] = parseFloat(parseFloat(responseJson.resumoCredito.meses[0].valor) + parseFloat(responseJson.resumoCredito.meses[1].valor) + parseFloat(responseJson.resumoCredito.meses[2].valor)).toFixed(2)
        dataTri.datasets[0].data[1] = parseFloat(parseFloat(responseJson.resumoCredito.meses[3].valor) + parseFloat(responseJson.resumoCredito.meses[4].valor) + parseFloat(responseJson.resumoCredito.meses[5].valor)).toFixed(2)
        dataTri.datasets[0].data[2] = parseFloat(parseFloat(responseJson.resumoCredito.meses[6].valor) + parseFloat(responseJson.resumoCredito.meses[7].valor) + parseFloat(responseJson.resumoCredito.meses[8].valor)).toFixed(2)
        dataTri.datasets[0].data[3] = parseFloat(parseFloat(responseJson.resumoCredito.meses[9].valor) + parseFloat(responseJson.resumoCredito.meses[10].valor) + parseFloat(responseJson.resumoCredito.meses[11].valor)).toFixed(2)

        dataTri.datasets[1].data[0] = parseFloat(parseFloat(responseJson.resumoDebito.meses[0].valor) + parseFloat(responseJson.resumoDebito.meses[1].valor) + parseFloat(responseJson.resumoDebito.meses[2].valor)).toFixed(2)
        dataTri.datasets[1].data[1] = parseFloat(parseFloat(responseJson.resumoDebito.meses[3].valor) + parseFloat(responseJson.resumoDebito.meses[4].valor) + parseFloat(responseJson.resumoDebito.meses[5].valor)).toFixed(2)
        dataTri.datasets[1].data[2] = parseFloat(parseFloat(responseJson.resumoDebito.meses[6].valor) + parseFloat(responseJson.resumoDebito.meses[7].valor) + parseFloat(responseJson.resumoDebito.meses[8].valor)).toFixed(2)
        dataTri.datasets[1].data[3] = parseFloat(parseFloat(responseJson.resumoDebito.meses[9].valor) + parseFloat(responseJson.resumoDebito.meses[10].valor) + parseFloat(responseJson.resumoDebito.meses[11].valor)).toFixed(2)

        dataTri.datasets[2].data[0] = mediaTriCredito
        dataTri.datasets[2].data[1] = mediaTriCredito
        dataTri.datasets[2].data[2] = mediaTriCredito
        dataTri.datasets[2].data[3] = mediaTriCredito

        dataTri.datasets[3].data[0] = mediaTriDebito
        dataTri.datasets[3].data[1] = mediaTriDebito
        dataTri.datasets[3].data[2] = mediaTriDebito
        dataTri.datasets[3].data[3] = mediaTriDebito


        dataTriBar.datasets[0].data[0] = parseFloat(parseFloat(responseJson.resumoCredito.meses[0].valor) + parseFloat(responseJson.resumoCredito.meses[1].valor) + parseFloat(responseJson.resumoCredito.meses[2].valor)).toFixed(2)
        dataTriBar.datasets[0].data[1] = parseFloat(parseFloat(responseJson.resumoCredito.meses[3].valor) + parseFloat(responseJson.resumoCredito.meses[4].valor) + parseFloat(responseJson.resumoCredito.meses[5].valor)).toFixed(2)
        dataTriBar.datasets[0].data[2] = parseFloat(parseFloat(responseJson.resumoCredito.meses[6].valor) + parseFloat(responseJson.resumoCredito.meses[7].valor) + parseFloat(responseJson.resumoCredito.meses[8].valor)).toFixed(2)
        dataTriBar.datasets[0].data[3] = parseFloat(parseFloat(responseJson.resumoCredito.meses[9].valor) + parseFloat(responseJson.resumoCredito.meses[10].valor) + parseFloat(responseJson.resumoCredito.meses[11].valor)).toFixed(2)

        dataTriBar.datasets[1].data[0] = parseFloat(parseFloat(responseJson.resumoDebito.meses[0].valor) + parseFloat(responseJson.resumoDebito.meses[1].valor) + parseFloat(responseJson.resumoDebito.meses[2].valor)).toFixed(2)
        dataTriBar.datasets[1].data[1] = parseFloat(parseFloat(responseJson.resumoDebito.meses[3].valor) + parseFloat(responseJson.resumoDebito.meses[4].valor) + parseFloat(responseJson.resumoDebito.meses[5].valor)).toFixed(2)
        dataTriBar.datasets[1].data[2] = parseFloat(parseFloat(responseJson.resumoDebito.meses[6].valor) + parseFloat(responseJson.resumoDebito.meses[7].valor) + parseFloat(responseJson.resumoDebito.meses[8].valor)).toFixed(2)
        dataTriBar.datasets[1].data[3] = parseFloat(parseFloat(responseJson.resumoDebito.meses[9].valor) + parseFloat(responseJson.resumoDebito.meses[10].valor) + parseFloat(responseJson.resumoDebito.meses[11].valor)).toFixed(2)


        var ano = moment().format('YYYY')

        this.setState({ ano: ano })

        this.setState({ data: JSON.parse(JSON.stringify(data)), dataBar: JSON.parse(JSON.stringify(dataBar)) })
        this.setState({ dataTri: JSON.parse(JSON.stringify(dataTri)), dataTriBar: JSON.parse(JSON.stringify(dataTriBar)) })

        this.setState({ resumoDebito: responseJson.resumoDebito })
        this.setState({ resumoCredito: responseJson.resumoCredito })
        this.setState({ listaDebito: debito })
        this.setState({ listaCredito: responseJson.credito })
        this.setState({ loader: false })

        this.listaPagamentosAnoAnterior();
    }

    ajustaValoresAnoAnterior(responseJson) {

        var debito = JSON.parse(JSON.stringify(responseJson.debito))

        debito.sort((a, b) => {
            var compA = ('00000000000000000000' + a.meses[12].total).slice(-20)
            var compB = ('00000000000000000000' + b.meses[12].total).slice(-20)

            if (compA < compB) {
                return -1
            }

            if (compA > compB) {
                return 1
            }

            return 0
        })

        let anoAnterior = this.state.anoAtual - 1

        data.datasets[4].label = 'Crédito-' + anoAnterior
        data.datasets[5].label = 'Débito-' + anoAnterior


        var mediaDebito = parseFloat(responseJson.resumoDebito.meses[12].total / 12).toFixed(2)
        var mediaCredito = parseFloat(responseJson.resumoCredito.meses[12].total / 12).toFixed(2)
        var mediaTriDebito = parseFloat(responseJson.resumoDebito.meses[12].total / 4).toFixed(2)
        var mediaTriCredito = parseFloat(responseJson.resumoCredito.meses[12].total / 4).toFixed(2)

        data.datasets[4].data[0] = responseJson.resumoCredito.meses[0].valor
        data.datasets[4].data[1] = responseJson.resumoCredito.meses[1].valor
        data.datasets[4].data[2] = responseJson.resumoCredito.meses[2].valor
        data.datasets[4].data[3] = responseJson.resumoCredito.meses[3].valor
        data.datasets[4].data[4] = responseJson.resumoCredito.meses[4].valor
        data.datasets[4].data[5] = responseJson.resumoCredito.meses[5].valor
        data.datasets[4].data[6] = responseJson.resumoCredito.meses[6].valor
        data.datasets[4].data[7] = responseJson.resumoCredito.meses[7].valor
        data.datasets[4].data[8] = responseJson.resumoCredito.meses[8].valor
        data.datasets[4].data[9] = responseJson.resumoCredito.meses[9].valor
        data.datasets[4].data[10] = responseJson.resumoCredito.meses[10].valor
        data.datasets[4].data[11] = responseJson.resumoCredito.meses[11].valor

        data.datasets[5].data[0] = responseJson.resumoDebito.meses[0].valor
        data.datasets[5].data[1] = responseJson.resumoDebito.meses[1].valor
        data.datasets[5].data[2] = responseJson.resumoDebito.meses[2].valor
        data.datasets[5].data[3] = responseJson.resumoDebito.meses[3].valor
        data.datasets[5].data[4] = responseJson.resumoDebito.meses[4].valor
        data.datasets[5].data[5] = responseJson.resumoDebito.meses[5].valor
        data.datasets[5].data[6] = responseJson.resumoDebito.meses[6].valor
        data.datasets[5].data[7] = responseJson.resumoDebito.meses[7].valor
        data.datasets[5].data[8] = responseJson.resumoDebito.meses[8].valor
        data.datasets[5].data[9] = responseJson.resumoDebito.meses[9].valor
        data.datasets[5].data[10] = responseJson.resumoDebito.meses[10].valor
        data.datasets[5].data[11] = responseJson.resumoDebito.meses[11].valor

        dataBar.datasets[4].label = 'Crédito-' + anoAnterior
        dataBar.datasets[5].label = 'Débito-' + anoAnterior

        dataBar.datasets[4].data[0] = responseJson.resumoCredito.meses[0].valor
        dataBar.datasets[4].data[1] = responseJson.resumoCredito.meses[1].valor
        dataBar.datasets[4].data[2] = responseJson.resumoCredito.meses[2].valor
        dataBar.datasets[4].data[3] = responseJson.resumoCredito.meses[3].valor
        dataBar.datasets[4].data[4] = responseJson.resumoCredito.meses[4].valor
        dataBar.datasets[4].data[5] = responseJson.resumoCredito.meses[5].valor
        dataBar.datasets[4].data[6] = responseJson.resumoCredito.meses[6].valor
        dataBar.datasets[4].data[7] = responseJson.resumoCredito.meses[7].valor
        dataBar.datasets[4].data[8] = responseJson.resumoCredito.meses[8].valor
        dataBar.datasets[4].data[9] = responseJson.resumoCredito.meses[9].valor
        dataBar.datasets[4].data[10] = responseJson.resumoCredito.meses[10].valor
        dataBar.datasets[4].data[11] = responseJson.resumoCredito.meses[11].valor

        dataBar.datasets[5].data[0] = responseJson.resumoDebito.meses[0].valor
        dataBar.datasets[5].data[1] = responseJson.resumoDebito.meses[1].valor
        dataBar.datasets[5].data[2] = responseJson.resumoDebito.meses[2].valor
        dataBar.datasets[5].data[3] = responseJson.resumoDebito.meses[3].valor
        dataBar.datasets[5].data[4] = responseJson.resumoDebito.meses[4].valor
        dataBar.datasets[5].data[5] = responseJson.resumoDebito.meses[5].valor
        dataBar.datasets[5].data[6] = responseJson.resumoDebito.meses[6].valor
        dataBar.datasets[5].data[7] = responseJson.resumoDebito.meses[7].valor
        dataBar.datasets[5].data[8] = responseJson.resumoDebito.meses[8].valor
        dataBar.datasets[5].data[9] = responseJson.resumoDebito.meses[9].valor
        dataBar.datasets[5].data[10] = responseJson.resumoDebito.meses[10].valor
        dataBar.datasets[5].data[11] = responseJson.resumoDebito.meses[11].valor

        //trimestre

        dataTri.datasets[4].label = 'Crédito-' + anoAnterior
        dataTri.datasets[5].label = 'Débito-' + anoAnterior


        dataTri.datasets[4].data[0] = parseFloat(parseFloat(responseJson.resumoCredito.meses[0].valor) + parseFloat(responseJson.resumoCredito.meses[1].valor) + parseFloat(responseJson.resumoCredito.meses[2].valor)).toFixed(2)
        dataTri.datasets[4].data[1] = parseFloat(parseFloat(responseJson.resumoCredito.meses[3].valor) + parseFloat(responseJson.resumoCredito.meses[4].valor) + parseFloat(responseJson.resumoCredito.meses[5].valor)).toFixed(2)
        dataTri.datasets[4].data[2] = parseFloat(parseFloat(responseJson.resumoCredito.meses[6].valor) + parseFloat(responseJson.resumoCredito.meses[7].valor) + parseFloat(responseJson.resumoCredito.meses[8].valor)).toFixed(2)
        dataTri.datasets[4].data[3] = parseFloat(parseFloat(responseJson.resumoCredito.meses[9].valor) + parseFloat(responseJson.resumoCredito.meses[10].valor) + parseFloat(responseJson.resumoCredito.meses[11].valor)).toFixed(2)

        dataTri.datasets[5].data[0] = parseFloat(parseFloat(responseJson.resumoDebito.meses[0].valor) + parseFloat(responseJson.resumoDebito.meses[1].valor) + parseFloat(responseJson.resumoDebito.meses[2].valor)).toFixed(2)
        dataTri.datasets[5].data[1] = parseFloat(parseFloat(responseJson.resumoDebito.meses[3].valor) + parseFloat(responseJson.resumoDebito.meses[4].valor) + parseFloat(responseJson.resumoDebito.meses[5].valor)).toFixed(2)
        dataTri.datasets[5].data[2] = parseFloat(parseFloat(responseJson.resumoDebito.meses[6].valor) + parseFloat(responseJson.resumoDebito.meses[7].valor) + parseFloat(responseJson.resumoDebito.meses[8].valor)).toFixed(2)
        dataTri.datasets[5].data[3] = parseFloat(parseFloat(responseJson.resumoDebito.meses[9].valor) + parseFloat(responseJson.resumoDebito.meses[10].valor) + parseFloat(responseJson.resumoDebito.meses[11].valor)).toFixed(2)

        dataTriBar.datasets[2].label = 'Crédito-' + anoAnterior
        dataTriBar.datasets[3].label = 'Débito-' + anoAnterior


        dataTriBar.datasets[2].data[0] = parseFloat(parseFloat(responseJson.resumoCredito.meses[0].valor) + parseFloat(responseJson.resumoCredito.meses[1].valor) + parseFloat(responseJson.resumoCredito.meses[2].valor)).toFixed(2)
        dataTriBar.datasets[2].data[1] = parseFloat(parseFloat(responseJson.resumoCredito.meses[3].valor) + parseFloat(responseJson.resumoCredito.meses[4].valor) + parseFloat(responseJson.resumoCredito.meses[5].valor)).toFixed(2)
        dataTriBar.datasets[2].data[2] = parseFloat(parseFloat(responseJson.resumoCredito.meses[6].valor) + parseFloat(responseJson.resumoCredito.meses[7].valor) + parseFloat(responseJson.resumoCredito.meses[8].valor)).toFixed(2)
        dataTriBar.datasets[2].data[3] = parseFloat(parseFloat(responseJson.resumoCredito.meses[9].valor) + parseFloat(responseJson.resumoCredito.meses[10].valor) + parseFloat(responseJson.resumoCredito.meses[11].valor)).toFixed(2)

        dataTriBar.datasets[3].data[0] = parseFloat(parseFloat(responseJson.resumoDebito.meses[0].valor) + parseFloat(responseJson.resumoDebito.meses[1].valor) + parseFloat(responseJson.resumoDebito.meses[2].valor)).toFixed(2)
        dataTriBar.datasets[3].data[1] = parseFloat(parseFloat(responseJson.resumoDebito.meses[3].valor) + parseFloat(responseJson.resumoDebito.meses[4].valor) + parseFloat(responseJson.resumoDebito.meses[5].valor)).toFixed(2)
        dataTriBar.datasets[3].data[2] = parseFloat(parseFloat(responseJson.resumoDebito.meses[6].valor) + parseFloat(responseJson.resumoDebito.meses[7].valor) + parseFloat(responseJson.resumoDebito.meses[8].valor)).toFixed(2)
        dataTriBar.datasets[3].data[3] = parseFloat(parseFloat(responseJson.resumoDebito.meses[9].valor) + parseFloat(responseJson.resumoDebito.meses[10].valor) + parseFloat(responseJson.resumoDebito.meses[11].valor)).toFixed(2)

        this.setState({ data: JSON.parse(JSON.stringify(data)), dataBar: JSON.parse(JSON.stringify(dataBar)) })
        this.setState({ dataTri: JSON.parse(JSON.stringify(dataTri)), dataTriBar: JSON.parse(JSON.stringify(dataTriBar)) })

    }

    acessaNomeCategoria(categoriaListada) {
        const categoria = this.state.categoriasTotal.find(function (obj) { return obj.id === categoriaListada.categoria })

        if (categoria === undefined) {
            return categoriaListada.categoria
        }

        return categoria.nome
    }

    mudaVisao() {

        if (this.state.mostra === 'bar') {
            this.setState({ mostra: 'line' })
        } else {
            this.setState({ mostra: 'bar' })
        }

    }

    render() {
        if (this.state.loader === true) {
            return [
                this.renderLoad()
            ]
        } else {
            return [
                this.renderComum(),
                this.renderCategoriaAnoMes(),
                this.renderComumTrimestre(),
                this.renderGraficoGeral(),
                this.renderGraficoGeralTrimestral(),
                this.renderRelatorioPorCategoria()
            ]
        }
    }

    fechaDetalhe() {
        this.setState({ listaCategoriaMesAno: [] })
    }

    renderCategoriaAnoMes() {

        if (this.state.listaCategoriaMesAno.length === 0) {
            return
        }

        return (
            <div className="container-fluid w-100 mt-5">
                <div class="row justify-content-md-center">
                    <div className="card row bg-transparent">
                        <div className="form-group row mt-0 mb-0">
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.fechaDetalhe() }}>Fecha</button>
                            </div>
                        </div>
                        <div class="card-header w-auto border rgm-bg-azul-esverdiado text-white">
                            <h5>{this.state.cabecCategoriaMesAno} </h5>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col">id</th>
                                    <th scope="col">tipo</th>
                                    <th scope="col">Vencimento Ano/mes</th>
                                    <th scope="col">Vencimento</th>
                                    <th scope="col">pagamento</th>
                                    <th scope="col">entra</th>
                                    <th scope="col">Frequência</th>
                                    <th scope="col">Parcela</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col" className="text-right">Valor</th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.listaCategoriaMesAno.map((pagamento, index) => {
                                    this.acumulaValores(pagamento, index)
                                    return (
                                        <tr className={'rgm-bg-azul-esverdiado text-white '}>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{pagamento.id}</td>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{pagamento.tipo}</td>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{pagamento.data_de_vencimento_ano_mes}</td>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{pagamento.data_de_vencimento}</td>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{pagamento.data_de_pagamento}</td>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{(pagamento.entra_nas_estatisticas ? 'sim' : 'não')}</td>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{pagamento.frequencia}</td>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{pagamento.parcela}</td>
                                            <td className={"align-middle rgm-diminuirParaCaber"}>{pagamento.descricao}</td>
                                            <td className={"text-right align-middle"}>{formataDisplayEmReal(pagamento.valor)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="text-center" COLSPAN={1}>Totais</th>
                                    <th scope="col" className="text-right" COLSPAN="1">{formataDisplayEmReal(totalGeral)}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    acumulaValores(pagamento, index) {

        if (index === 0) {
            totalGeral = 0
        }

        totalGeral = parseFloat(totalGeral) + parseFloat(pagamento.valor)

        totalGeral = parseFloat(totalGeral).toFixed(2)
    }


    corResumo(valor) {

        let styleobjDebiTot = { fontSize: 13, color: 'white', fontWeight: 'bold' }
        let styleobjCredTot = { fontSize: 13, color: 'yellow', fontWeight: 'bold' }

        if (valor < 0) {
            return styleobjDebiTot
        } else {
            return styleobjCredTot
        }

    }

    mudaVisaoGeral() {
        if (this.state.lista === 'anual') {
            this.setState({ lista: 'trimestral' })
        } else {
            this.setState({ lista: 'anual' })
        }
    }

    setAnoAnterior() {
        this.setState({ anoAnterior: (this.state.anoAnterior - 1) })
        this.setState({ anoAtual: (this.state.anoAtual - 1) }, function () { this.listaPagamentos() })
        this.setState({ anoPosterior: (this.state.anoPosterior - 1) })
    }

    cliqueDebito(debito, mes) {
        this.listaPagamentosCategoriaAnoMes(debito.categoria, mes);
    }

    cliqueCredito(credito, mes) {
        this.listaPagamentosCategoriaAnoMes(credito.categoria, mes);
    }

    setAnoPosterior() {

        if (this.state.anoPosterior === this.state.anoInibir) {
            return
        }


        this.setState({ anoAnterior: (this.state.anoAnterior + 1) })
        this.setState({ anoAtual: (this.state.anoAtual + 1) }, function () { this.listaPagamentos() })
        this.setState({ anoPosterior: (this.state.anoPosterior + 1) })
    }

    setAnoAtual() {
        this.setState({ anoAnterior: (parseInt(moment().format('YYYY')) - 1) })
        this.setState({ anoAtual: (parseInt(moment().format('YYYY')) + 0) }, function () { this.listaPagamentos() })
        this.setState({ anoPosterior: (parseInt(moment().format('YYYY')) + 1) })
    }

    corMedia(media) {
        let styleobj = { fontSize: 13 }
        let styleobjMedia = { fontSize: 13, color: 'black', fontWeight: 'bold' }
        if (media) {
            return styleobjMedia
        } else {
            return styleobj
        }
    }

    renderComum() {

        if (this.state.lista !== 'anual') {
            return
        }

        let styleobj = { fontSize: 13 }
        let styleobjMedia = { fontSize: 13, color: 'blue' }
        let styleobjDebi = { fontSize: 13, color: 'white', fontWeight: 'bold' }
        let styleobjCred = { fontSize: 13, color: 'yellow', fontWeight: 'bold' }


        var jan = parseFloat(this.state.resumoCredito.meses[0].valor - this.state.resumoDebito.meses[0].valor).toFixed(2)
        var fev = parseFloat(this.state.resumoCredito.meses[1].valor - this.state.resumoDebito.meses[1].valor).toFixed(2)
        var mar = parseFloat(this.state.resumoCredito.meses[2].valor - this.state.resumoDebito.meses[2].valor).toFixed(2)
        var abr = parseFloat(this.state.resumoCredito.meses[3].valor - this.state.resumoDebito.meses[3].valor).toFixed(2)
        var mai = parseFloat(this.state.resumoCredito.meses[4].valor - this.state.resumoDebito.meses[4].valor).toFixed(2)
        var jun = parseFloat(this.state.resumoCredito.meses[5].valor - this.state.resumoDebito.meses[5].valor).toFixed(2)
        var jul = parseFloat(this.state.resumoCredito.meses[6].valor - this.state.resumoDebito.meses[6].valor).toFixed(2)
        var ago = parseFloat(this.state.resumoCredito.meses[7].valor - this.state.resumoDebito.meses[7].valor).toFixed(2)
        var set = parseFloat(this.state.resumoCredito.meses[8].valor - this.state.resumoDebito.meses[8].valor).toFixed(2)
        var out = parseFloat(this.state.resumoCredito.meses[9].valor - this.state.resumoDebito.meses[9].valor).toFixed(2)
        var nov = parseFloat(this.state.resumoCredito.meses[10].valor - this.state.resumoDebito.meses[10].valor).toFixed(2)
        var dez = parseFloat(this.state.resumoCredito.meses[11].valor - this.state.resumoDebito.meses[11].valor).toFixed(2)
        var tot = parseFloat(this.state.resumoCredito.meses[12].total - this.state.resumoDebito.meses[12].total).toFixed(2)

        return (
            <div>
                <div className="container-fluid w-100 mt-5 mr-5">
                    <div class="row justify-content-sm-center">
                        <div className="form-group row mt-0 mb-0">
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.mudaVisaoGeral() }}>Trimestral</button>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-sm-center">
                        <div className="card row bg-transparent mt-1 text-white">

                            <div class="card-header mb-2 rgm-bg-azul-esverdiado text-white border">
                                <span className='align-middle'>Relatório | Fluxo de caixa - Anual |&nbsp;</span>
                                <div className="btn-group" role="group" aria-label="Exemplo básico">
                                    <button type="button" class="btn btn-secondary" onClick={() => this.setAnoAnterior()}>{this.state.anoAnterior}</button>
                                    <button type="button" class="btn btn-primary">{this.state.anoAtual}</button>
                                    <button type="button" class="btn btn-secondary" onClick={() => this.setAnoPosterior()}>{this.state.anoPosterior}</button>
                                    <button type="button" class="btn btn-secondary" onClick={() => this.setAnoAtual()}>Hoje</button>
                                </div>
                                <span className='align-middle'>&nbsp; Médias mensais &nbsp;</span>
                                <button type="button" className="btn btn-primary btn-sm m-2">{formataDisplayEmReal(this.state.mediaCredito)}</button>
                                <button type="button" className="btn btn-danger btn-sm m-2">{formataDisplayEmReal(this.state.mediaDebito)}</button>
                            </div>

                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col">Categorias</th>
                                        <th scope="col" className="text-center">Janeiro</th>
                                        <th scope="col" className="text-center">Fevereiro</th>
                                        <th scope="col" className="text-center">Março</th>
                                        <th scope="col" className="text-center">Abril</th>
                                        <th scope="col" className="text-center">Maio</th>
                                        <th scope="col" className="text-center">Junho</th>
                                        <th scope="col" className="text-center">Julho</th>
                                        <th scope="col" className="text-center">Agosto</th>
                                        <th scope="col" className="text-center">Setembro</th>
                                        <th scope="col" className="text-center">Outubro</th>
                                        <th scope="col" className="text-center">Novembro</th>
                                        <th scope="col" className="text-center">Dezembro</th>
                                        <th scope="col" className="text-center">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listaDebito.map((debito, index) => {
                                        return (
                                            <tr className="rgm-bg-azul-esverdiado text-white">
                                                <td className="align-middle text-white rgm-diminuirParaCaber" style={styleobj}>{this.acessaNomeCategoria(debito)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 1)} style={this.corMedia(debito.meses[0].media)}>{formataDisplayEmReal(debito.meses[0].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 2)} style={this.corMedia(debito.meses[1].media)}>{formataDisplayEmReal(debito.meses[1].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 3)} style={this.corMedia(debito.meses[2].media)}>{formataDisplayEmReal(debito.meses[2].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 4)} style={this.corMedia(debito.meses[3].media)}>{formataDisplayEmReal(debito.meses[3].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 5)} style={this.corMedia(debito.meses[4].media)}>{formataDisplayEmReal(debito.meses[4].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 6)} style={this.corMedia(debito.meses[5].media)}>{formataDisplayEmReal(debito.meses[5].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 7)} style={this.corMedia(debito.meses[6].media)}>{formataDisplayEmReal(debito.meses[6].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 8)} style={this.corMedia(debito.meses[7].media)}>{formataDisplayEmReal(debito.meses[7].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 9)} style={this.corMedia(debito.meses[8].media)}>{formataDisplayEmReal(debito.meses[8].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 10)} style={this.corMedia(debito.meses[9].media)}>{formataDisplayEmReal(debito.meses[9].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 11)} style={this.corMedia(debito.meses[10].media)}>{formataDisplayEmReal(debito.meses[10].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueDebito(debito, 12)} style={this.corMedia(debito.meses[11].media)}>{formataDisplayEmReal(debito.meses[11].valor)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(debito.meses[12].total)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                <tbody>
                                    <tr className="rgm-bg-azul-esverdiado text-white">
                                        <td className="align-middle text-white rgm-diminuirParaCaber" style={styleobj}>TOTAL DOS DÉBITOS</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[0].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[1].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[2].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[3].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[4].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[5].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[6].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[7].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[8].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[9].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[10].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[11].valor)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[12].total)}</td>
                                    </tr>
                                </tbody>
                                <br />
                                <tbody>
                                    {this.state.listaCredito.map((credito, index) => {
                                        return (
                                            <tr className="rgm-bg-azul-esverdiado text-white">
                                                <td className="rgm-diminuirParaCaber" style={styleobj}>{this.acessaNomeCategoria(credito)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 1)} style={styleobj}>{formataDisplayEmReal(credito.meses[0].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 2)}style={styleobj}>{formataDisplayEmReal(credito.meses[1].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 3)}style={styleobj}>{formataDisplayEmReal(credito.meses[2].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 4)}style={styleobj}>{formataDisplayEmReal(credito.meses[3].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 5)}style={styleobj}>{formataDisplayEmReal(credito.meses[4].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 6)}style={styleobj}>{formataDisplayEmReal(credito.meses[5].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 7)}style={styleobj}>{formataDisplayEmReal(credito.meses[6].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 8)}style={styleobj}>{formataDisplayEmReal(credito.meses[7].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 9)}style={styleobj}>{formataDisplayEmReal(credito.meses[8].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 10)}style={styleobj}>{formataDisplayEmReal(credito.meses[9].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 11)}style={styleobj}>{formataDisplayEmReal(credito.meses[10].valor)}</td>
                                                <td className="text-right" onClick={() => this.cliqueCredito(credito, 12)}style={styleobj}>{formataDisplayEmReal(credito.meses[11].valor)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(credito.meses[12].total)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                <tbody>
                                    <tr className="rgm-bg-azul-esverdiado text-white">
                                        <td className="align-middle text-white rgm-diminuirParaCaber" style={styleobj}>TOTAL DOS CRÉDITOS</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[0].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[1].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[2].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[3].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[4].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[5].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[6].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[7].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[8].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[9].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[10].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[11].valor)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[12].total)}</td>
                                    </tr>
                                </tbody>
                                <br />
                                <tbody>
                                    <tr className="rgm-bg-azul-esverdiado text-white">
                                        <td className="align-middle text-white rgm-diminuirParaCaber" style={styleobj}>RESULTADO</td>
                                        <td className="text-right" style={this.corResumo(jan)}>{formataDisplayEmReal(jan)}</td>
                                        <td className="text-right" style={this.corResumo(fev)}>{formataDisplayEmReal(fev)}</td>
                                        <td className="text-right" style={this.corResumo(mar)}>{formataDisplayEmReal(mar)}</td>
                                        <td className="text-right" style={this.corResumo(abr)}>{formataDisplayEmReal(abr)}</td>
                                        <td className="text-right" style={this.corResumo(mai)}>{formataDisplayEmReal(mai)}</td>
                                        <td className="text-right" style={this.corResumo(jun)}>{formataDisplayEmReal(jun)}</td>
                                        <td className="text-right" style={this.corResumo(jul)}>{formataDisplayEmReal(jul)}</td>
                                        <td className="text-right" style={this.corResumo(ago)}>{formataDisplayEmReal(ago)}</td>
                                        <td className="text-right" style={this.corResumo(set)}>{formataDisplayEmReal(set)}</td>
                                        <td className="text-right" style={this.corResumo(out)}>{formataDisplayEmReal(out)}</td>
                                        <td className="text-right" style={this.corResumo(nov)}>{formataDisplayEmReal(nov)}</td>
                                        <td className="text-right" style={this.corResumo(dez)}>{formataDisplayEmReal(dez)}</td>
                                        <td className="text-right" style={this.corResumo(tot)}>{formataDisplayEmReal(tot)}</td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col">Categorias</th>
                                        <th scope="col" className="text-center">Janeiro</th>
                                        <th scope="col" className="text-center">Fevereiro</th>
                                        <th scope="col" className="text-center">Março</th>
                                        <th scope="col" className="text-center">Abril</th>
                                        <th scope="col" className="text-center">Maio</th>
                                        <th scope="col" className="text-center">Junho</th>
                                        <th scope="col" className="text-center">Julho</th>
                                        <th scope="col" className="text-center">Agosto</th>
                                        <th scope="col" className="text-center">Setembro</th>
                                        <th scope="col" className="text-center">Outubro</th>
                                        <th scope="col" className="text-center">Novembro</th>
                                        <th scope="col" className="text-center">Dezembro</th>
                                        <th scope="col" className="text-center">Total</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    renderComumTrimestre() {

        if (this.state.lista !== 'trimestral') {
            return
        }

        let styleobj = { fontSize: 13 }
        let styleobjDebi = { fontSize: 13, color: 'white', fontWeight: 'bold' }
        let styleobjCred = { fontSize: 13, color: 'yellow', fontWeight: 'bold' }


        var jan = parseFloat(this.state.resumoCredito.meses[0].valor - this.state.resumoDebito.meses[0].valor)
        var fev = parseFloat(this.state.resumoCredito.meses[1].valor - this.state.resumoDebito.meses[1].valor)
        var mar = parseFloat(this.state.resumoCredito.meses[2].valor - this.state.resumoDebito.meses[2].valor)
        var t1resumo = parseFloat(jan + fev + mar).toFixed(2)
        var abr = parseFloat(this.state.resumoCredito.meses[3].valor - this.state.resumoDebito.meses[3].valor)
        var mai = parseFloat(this.state.resumoCredito.meses[4].valor - this.state.resumoDebito.meses[4].valor)
        var jun = parseFloat(this.state.resumoCredito.meses[5].valor - this.state.resumoDebito.meses[5].valor)
        var t2resumo = parseFloat(abr + mai + jun).toFixed(2)
        var jul = parseFloat(this.state.resumoCredito.meses[6].valor - this.state.resumoDebito.meses[6].valor)
        var ago = parseFloat(this.state.resumoCredito.meses[7].valor - this.state.resumoDebito.meses[7].valor)
        var set = parseFloat(this.state.resumoCredito.meses[8].valor - this.state.resumoDebito.meses[8].valor)
        var t3resumo = parseFloat(jul + ago + set).toFixed(2)
        var out = parseFloat(this.state.resumoCredito.meses[9].valor - this.state.resumoDebito.meses[9].valor)
        var nov = parseFloat(this.state.resumoCredito.meses[10].valor - this.state.resumoDebito.meses[10].valor)
        var dez = parseFloat(this.state.resumoCredito.meses[11].valor - this.state.resumoDebito.meses[11].valor)
        var t4resumo = parseFloat(out + nov + dez).toFixed(2)
        var tot = parseFloat(this.state.resumoCredito.meses[12].total - this.state.resumoDebito.meses[12].total).toFixed(2)

        return (
            <div>
                <div className="container-fluid w-100 mt-5 mr-5">
                    <div class="row justify-content-sm-center">
                        <div className="form-group row mt-0 mb-0">
                            <div className="col-sm-12">
                                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.mudaVisaoGeral() }}>Anual</button>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div className="card row bg-transparent mt-1 text-white">
                            <div class="card-header rgm-bg-azul-esverdiado text-white border">
                                <div className="row align-middle m-2">
                                    <h5>Relatório | Fluxo de caixa - Trimestral | {this.state.anoAtual}</h5>
                                </div>
                            </div>

                            <table className="table table table-bordered">
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col">Categorias</th>
                                        <th scope="col" className="text-center">1TRI JAN-MAR</th>
                                        <th scope="col" className="text-center">2TRI ABR-JUN</th>
                                        <th scope="col" className="text-center">3TRI JUL-SET</th>
                                        <th scope="col" className="text-center">4TRI OUT-DEZ</th>
                                        <th scope="col" className="text-center">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listaDebito.map((debito, index) => {
                                        var t1d = parseFloat(parseFloat(debito.meses[0].valor) + parseFloat(debito.meses[1].valor) + parseFloat(debito.meses[2].valor)).toFixed(2)
                                        var t2d = parseFloat(parseFloat(debito.meses[3].valor) + parseFloat(debito.meses[4].valor) + parseFloat(debito.meses[5].valor)).toFixed(2)
                                        var t3d = parseFloat(parseFloat(debito.meses[6].valor) + parseFloat(debito.meses[7].valor) + parseFloat(debito.meses[8].valor)).toFixed(2)
                                        var t4d = parseFloat(parseFloat(debito.meses[9].valor) + parseFloat(debito.meses[10].valor) + parseFloat(debito.meses[11].valor)).toFixed(2)
                                        t1dr = parseFloat(parseFloat(this.state.resumoDebito.meses[0].valor) + parseFloat(this.state.resumoDebito.meses[1].valor) + parseFloat(this.state.resumoDebito.meses[2].valor)).toFixed(2)
                                        t2dr = parseFloat(parseFloat(this.state.resumoDebito.meses[3].valor) + parseFloat(this.state.resumoDebito.meses[4].valor) + parseFloat(this.state.resumoDebito.meses[5].valor)).toFixed(2)
                                        t3dr = parseFloat(parseFloat(this.state.resumoDebito.meses[6].valor) + parseFloat(this.state.resumoDebito.meses[7].valor) + parseFloat(this.state.resumoDebito.meses[8].valor)).toFixed(2)
                                        t4dr = parseFloat(parseFloat(this.state.resumoDebito.meses[9].valor) + parseFloat(this.state.resumoDebito.meses[10].valor) + parseFloat(this.state.resumoDebito.meses[11].valor)).toFixed(2)
                                        return (
                                            <tr className="rgm-bg-azul-esverdiado text-white">
                                                <td className="align-middle text-white rgm-diminuirParaCaber" style={styleobj}>{this.acessaNomeCategoria(debito)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(t1d)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(t2d)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(t3d)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(t4d)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(debito.meses[12].total)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                <tbody>
                                    <tr className="rgm-bg-azul-esverdiado text-white">
                                        <td className="align-middle text-white rgm-diminuirParaCaber" style={styleobj}>TOTAL DOS DÉBITOS</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(t1dr)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(t2dr)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(t3dr)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(t4dr)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(this.state.resumoDebito.meses[12].total)}</td>
                                    </tr>
                                </tbody>
                                <br />
                                <tbody>
                                    {this.state.listaCredito.map((credito, index) => {
                                        var t1c = parseFloat(parseFloat(credito.meses[0].valor) + parseFloat(credito.meses[1].valor) + parseFloat(credito.meses[2].valor)).toFixed(2)
                                        var t2c = parseFloat(parseFloat(credito.meses[3].valor) + parseFloat(credito.meses[4].valor) + parseFloat(credito.meses[5].valor)).toFixed(2)
                                        var t3c = parseFloat(parseFloat(credito.meses[6].valor) + parseFloat(credito.meses[7].valor) + parseFloat(credito.meses[8].valor)).toFixed(2)
                                        var t4c = parseFloat(parseFloat(credito.meses[9].valor) + parseFloat(credito.meses[10].valor) + parseFloat(credito.meses[11].valor)).toFixed(2)
                                        t1cr = parseFloat(parseFloat(this.state.resumoCredito.meses[0].valor) + parseFloat(this.state.resumoCredito.meses[1].valor) + parseFloat(this.state.resumoCredito.meses[2].valor)).toFixed(2)
                                        t2cr = parseFloat(parseFloat(this.state.resumoCredito.meses[3].valor) + parseFloat(this.state.resumoCredito.meses[4].valor) + parseFloat(this.state.resumoCredito.meses[5].valor)).toFixed(2)
                                        t3cr = parseFloat(parseFloat(this.state.resumoCredito.meses[6].valor) + parseFloat(this.state.resumoCredito.meses[7].valor) + parseFloat(this.state.resumoCredito.meses[8].valor)).toFixed(2)
                                        t4cr = parseFloat(parseFloat(this.state.resumoCredito.meses[9].valor) + parseFloat(this.state.resumoCredito.meses[10].valor) + parseFloat(this.state.resumoCredito.meses[11].valor)).toFixed(2)
                                        return (
                                            <tr className="rgm-bg-azul-esverdiado text-white">
                                                <td className="rgm-diminuirParaCaber" style={styleobj}>{this.acessaNomeCategoria(credito)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(t1c)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(t2c)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(t3c)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(t4c)}</td>
                                                <td className="text-right" style={styleobj}>{formataDisplayEmReal(credito.meses[12].total)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                <tbody>
                                    <tr className="rgm-bg-azul-esverdiado text-white">
                                        <td className="align-middle text-white rgm-diminuirParaCaber" style={styleobj}>TOTAL DOS CRÉDITOS</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(t1cr)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(t2cr)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(t3cr)}</td>
                                        <td className="text-right" style={styleobjDebi}>{formataDisplayEmReal(t4cr)}</td>
                                        <td className="text-right" style={styleobjCred}>{formataDisplayEmReal(this.state.resumoCredito.meses[12].total)}</td>
                                    </tr>
                                </tbody>
                                <br />
                                <tbody>
                                    <tr className="rgm-bg-azul-esverdiado text-white">
                                        <td className="align-middle text-white rgm-diminuirParaCaber" style={styleobj}>RESULTADO</td>
                                        <td className="text-right" style={this.corResumo(t1resumo)}>{formataDisplayEmReal(t1resumo)}</td>
                                        <td className="text-right" style={this.corResumo(t2resumo)}>{formataDisplayEmReal(t2resumo)}</td>
                                        <td className="text-right" style={this.corResumo(t3resumo)}>{formataDisplayEmReal(t3resumo)}</td>
                                        <td className="text-right" style={this.corResumo(t4resumo)}>{formataDisplayEmReal(t4resumo)}</td>
                                        <td className="text-right" style={this.corResumo(tot)}>{formataDisplayEmReal(tot)}</td>
                                    </tr>
                                </tbody>
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col">Categorias</th>
                                        <th scope="col" className="text-center">1TRI JAN-MAR</th>
                                        <th scope="col" className="text-center">2TRI ABR-JUN</th>
                                        <th scope="col" className="text-center">3TRI JUL-SET</th>
                                        <th scope="col" className="text-center">4TRI OUT-DEZ</th>
                                        <th scope="col" className="text-center">Total</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderGraficoGeral() {
        if (this.state.lista !== 'anual') {
            return
        }

        if (this.state.mostra === 'line') {
            return (
                <div className="container w-100 mt-5"  >
                    <div className="card-header bg-info border mt-2">
                        <h7>Gráfico Despesas x Receitas {this.state.anoAtual}</h7>
                        <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}><i class="fa fa-bar-chart" aria-hidden="true"></i></button>
                    </div>
                    <div className="jumbotron"  >
                        <Line data={this.state.data} />
                    </div>
                </div >
            )
        } else {
            if (this.state.mostra === 'bar') {
                return (
                    <div className="container w-100 mt-5"  >
                        <div className="card-header bg-info border mt-2">
                            <h7>Gráfico Despesas x Receitas {this.state.anoAtual}</h7>
                            <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}><i class="fa fa-line-chart" aria-hidden="true"></i></button>
                        </div>
                        <div className="jumbotron"  >
                            <Bar data={this.state.dataBar} />
                        </div>
                    </div >
                )
            }
        }
    }

    renderGraficoGeralTrimestral() {
        if (this.state.lista !== 'trimestral') {
            return
        }

        if (this.state.mostra === 'line') {
            return (
                <div className="container w-100 mt-5"  >
                    <div className="card-header bg-info border mt-2">
                        <h7>Gráfico Despesas x Receitas {this.state.anoAtual}</h7>
                        <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}><i class="fa fa-bar-chart" aria-hidden="true"></i></button>
                    </div>
                    <div className="jumbotron"  >
                        <Line data={this.state.dataTri} />
                    </div>
                </div >
            )
        } else {
            if (this.state.mostra === 'bar') {
                return (
                    <div className="container w-100 mt-5"  >
                        <div className="card-header bg-info border mt-2">
                            <h7>Gráfico Despesas x Receitas {this.state.anoAtual}</h7>
                            <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}><i class="fa fa-line-chart" aria-hidden="true"></i></button>
                        </div>
                        <div className="jumbotron"  >
                            <Bar data={this.state.dataTriBar} />
                        </div>
                    </div >
                )
            }
        }
    }

    renderLoad() {
        return (
            <div className="container w-100 mt-5"    >
                <div className="card-header bg-info border mt-2">
                    <h7>Lista de pagamentos</h7>
                </div>
                <div className="loader text-center mt-5"></div>
                <span className="text-warning">Aguarde... Carregando...</span>
            </div>
        )
    }

    renderRelatorioPorCategoria() {

        if (this.state.listaCategoriaMesAno.length > 0) {
            return (
                <div className="container-fluid w-100 mt-5">
                    <div class="row justify-content-md-center">
                        <div className="card row bg-transparent">
                            <div class="card-header border rgm-bg-azul-esverdiado text-white">
                                <h5>Transações da categoria</h5>
                            </div>
                            <table className="table table-bordered mt-0">
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col" className="text-center"><i class="fa fa-info" aria-hidden="true"></i></th>
                                        <th scope="col">Descrição</th>
                                        <th scope="col" className="text-center">Pagamento</th>
                                        <th scope="col">Conta</th>
                                        <th scope="col">Frequência</th>
                                        <th scope="col" className="text-center">Parcela</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col" className="text-right">Valor</th>
                                    </tr>
                                </thead>
                                <tbody className="rgm-bg-azul-esverdiado">
                                    {this.state.listaCategoriaMesAno.map((pagamento, index) => {
                                        // this.acumulaValores(pagamento, index)
                                        return (
                                            <tr className={'rgm-bg-azul-esverdiado text-white '}>
                                                <td className="text-center text-white align-middle">
                                                    <button className="btn btn-link btn-sm text-center" onClick={() => { this.pagamentoConsulta(pagamento) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                                </td>
                                                <td className={"align-middle rgm-diminuirParaCaber "}>{pagamento.descricao}</td>
                                                <td className="text-center align-middle text-white">{moment(pagamento.data_de_pagamento).format('DD/MM/YYYY')}</td>
                                                <td className="align-middle text-white rgm-diminuirParaCaber ">{pagamento.conta_id}</td>
                                                <td className="align-middle text-white">{pagamento.frequencia}</td>
                                                <td className="text-center align-middle text-white">{'parcela'}</td>
                                                <td className="align-middle text-white">{pagamento.tipo}</td>
                                                <td className={"text-right align-middle "}>{formataDisplayEmReal(pagamento.valor)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                                {this.state.listaCategoriaMesAno.length === 0 &&
                                    <tbody>
                                        <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="8">
                                            <span>Não existem pagamentos para sua seleção</span>
                                        </td>
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }

}

export default FluxoDeCaixa