import React, {
    Component
} from 'react'
import ContaLista from '../conta/ContaLista'
import ContaListaExtrato from '../conta/ContaListaExtrato'
import FaturaListaExtrato from '../fatura/FaturaListaExtrato'
import FaturaExtrato from '../fatura/FaturaExtrato'
import FaturaPagamentoAvulso from '../fatura/FaturaPagamentoAvulso'
import ContaExtrato from '../conta/ContaExtrato'
import ContaInclusao from '../conta/ContaInclusao'
import ContaAlteracao from '../conta/ContaAlteracao'
import ContaAplicarContaRemunerada from '../conta/ContaAplicarContaRemunerada'
import ContaResgatarContaRemunerada from '../conta/ContaResgatarContaRemunerada'
import ContaTransferir from '../conta/ContaTransferir'
import ContaConsulta from '../conta/ContaConsulta'
import PagamentoConsulta from '../pagamento/PagamentoConsulta'
import PagamentoConsultaFatura from '../pagamento/PagamentoConsultaFatura'
import PagamentoAlteracao from '../pagamento/PagamentoAlteracao'

var voltaPara = null

class Conta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: null,
            contaLista: true,
            contaInclusao: false,
            contaAlteracao: false,
            contaConsulta: false,
            contaListaExtrato: false,
            contaAplicarContaRemunerada: false,
            contaResgatarContaRemunerada: false,
            contaTransferir: false,
            faturaListaExtrato: false,
            faturaExtrato: false,
            faturaPagamentoAvulso: false,
            contaExtrato: false,
            conta: null,
            pagamentoConsulta: false,
            pagamentoConsultaFatura: false,
            pagamentoAlteracao: false,
            fatura: null
        }
    }

    componentWillMount() {
        global.contasPrincipal = () => {this.contaListaTrue()}

    }

    contaListaTrue() {
        this.setFalseAll()
        this.setState({
            contaLista: true
        })
    }

    contaInclusaoTrue() {
        this.setFalseAll()
        this.setState({
            contaInclusao: true
        })
    }

    contaAlteracaoTrue(conta) {
        this.setFalseAll()
        this.setState({
            contaAlteracao: true,
            conta: conta
        })
    }

    contaAplicarContaRemunerada(conta) {
        this.setFalseAll()
        this.setState({
            contaAplicarContaRemunerada: true,
            conta: conta
        })
    }

    contaResgatarContaRemunerada(conta) {
        this.setFalseAll()
        this.setState({
            contaResgatarContaRemunerada: true,
            conta: conta
        })
    }

    contaTransferir(conta) {
        this.setFalseAll()
        this.setState({
            contaTransferir: true,
            conta: conta
        })
    }


    contaConsultaTrue(conta) {
        this.setFalseAll()
        this.setState({
            contaConsulta: true,
            conta: conta
        })
    }

    contaAplicarContaRemuneradaTrue(conta_id) {
        this.setFalseAll()
        this.setState({
            contaAplicarContaRemunerada: true,
            conta_id: conta_id
        })
    }

    contaResgatarContaRemuneradaTrue(conta_id) {
        this.setFalseAll()
        this.setState({
            contaResgatarContaRemunerada: true,
            conta_id: conta_id
        })
    }

    contaTransferirTrue(conta_id) {
        this.setFalseAll()
        this.setState({
            contaTransferir: true,
            conta_id: conta_id
        })
    }

    contaListaExtratoTrue(conta) {
        this.setFalseAll()
        this.setState({
            contaListaExtrato: true,
            conta: conta
        })
    }

    faturaListaExtratoTrue(conta) {
        this.setFalseAll()
        this.setState({
            faturaListaExtrato: true,
            conta: conta
        })
    }

    faturaExtratoTrue(faturaParm, pagamento) {

        if (pagamento !== undefined) {
            this.setState({ pagamento: pagamento })
        } else {
        }


        var fatura = null

        if (faturaParm === undefined) {
            fatura = this.state.fatura
        } else {
            fatura = faturaParm
        }

        this.setFalseAll()
        this.setState({
            faturaExtrato: true,
            fatura: fatura
        })
    }

    faturaPagamentoAvulsoTrue(faturaParm) {

        var fatura = faturaParm

        this.setFalseAll()
        this.setState({
            faturaPagamentoAvulso: true,
            fatura: fatura
        })
    }

    contaExtratoTrue(saldoParm) {

        var saldo = null

        if (saldoParm === undefined) {
            saldo = this.state.saldo
        } else {
            saldo = saldoParm
        }

        var saldoW = null

        if (saldo === undefined) {
            saldoW = this.state.saldo
        } else {
            saldoW = saldo
        }

        this.setFalseAll()
        this.setState({
            contaExtrato: true,
            saldo: saldoW
        })
    }

    pagamentoConsultaTrue(pagamentoInp) {

        var pagamento = pagamentoInp

        if (pagamentoInp === undefined) {
            pagamento = this.state.pagamento
        }

        this.setFalseAll()
        this.setState({
            pagamentoConsulta: true,
            pagamento: pagamento
        })
    }

    pagamentoConsultaFaturaTrue(pagamentoInp) {

        var pagamento = pagamentoInp

        if (pagamentoInp === undefined) {
            pagamento = this.state.pagamento
        }

        this.setFalseAll()
        this.setState({
            pagamentoConsultaFatura: true,
            pagamento: pagamento
        })
    }

    pagamentoAlteracaoTrue(pagamentoInp) {

        var pagamento = pagamentoInp

        if (pagamentoInp === undefined) {
            pagamento = this.state.pagamento
        }

        this.setFalseAll()
        this.setState({
            pagamentoAlteracao: true,
            pagamento: pagamento
        })
    }

    setFalseAll() {
        this.setState({
            contaLista: false,
            contaInclusao: false,
            contaAlteracao: false,
            contaConsulta: false,
            contaAplicarContaRemunerada: false,
            contaResgatarContaRemunerada: false,
            contaTransferir: false,
            contaListaExtrato: false,
            faturaListaExtrato: false,
            faturaExtrato: false,
            faturaPagamentoAvulso: false,
            contaExtrato: false,
            pagamentoConsulta: false,
            pagamentoConsultaFatura: false,
            pagamentoAlteracao: false
        })

    }


    render() {
        if (this.state.contaLista === true) {
            return (<
                ContaLista contaInclusao={
                    () => {
                        this.contaInclusaoTrue()
                    }
                }
                contaConsulta={
                    (conta) => {
                        this.contaConsultaTrue(conta)
                    }
                }
                contaListaExtrato={
                    (conta) => {
                        this.contaListaExtratoTrue(conta)
                    }
                }
                faturaListaExtrato={
                    (conta) => {
                        this.faturaListaExtratoTrue(conta)
                    }
                }
            />
            )
        }

        if (this.state.contaInclusao === true) {
            return (<
                ContaInclusao volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.contaAlteracao === true) {
            return (<
                ContaAlteracao conta={
                    this.state.conta
                }
                volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.contaAplicarContaRemunerada === true) {
            return (<
                ContaAplicarContaRemunerada conta_id={
                    this.state.conta_id
                }
                volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.contaResgatarContaRemunerada === true) {
            return (<
                ContaResgatarContaRemunerada conta_id={
                    this.state.conta_id
                }
                volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.contaTransferir === true) {
            return (<
                ContaTransferir conta_id={
                    this.state.conta_id
                }
                volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.contaConsulta === true) {
            return (<
                ContaConsulta conta={
                    this.state.conta
                }
                contaAlteracao={
                    (conta) => {
                        this.contaAlteracaoTrue(conta)
                    }
                }
                volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.contaListaExtrato === true) {
            return (<
                ContaListaExtrato conta={
                    this.state.conta
                }
                contaExtrato={
                    (saldo) => {
                        this.contaExtratoTrue(saldo)
                    }
                }
                contaTransferir={
                    (conta_id) => {
                        this.contaTransferirTrue(conta_id)
                    }
                }
                volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.faturaListaExtrato === true) {
            return (<
                FaturaListaExtrato conta={
                    this.state.conta
                }
                faturaExtrato={
                    (fatura) => {
                        this.faturaExtratoTrue(fatura)
                    }
                }
                volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        } 

        if (this.state.faturaExtrato === true) {
            //voltaPara = 'voltaParaExtratoFatura'
            voltaPara = 'voltaParaExtratoFatura'
            return (<
                FaturaExtrato fatura={
                    this.state.fatura
                }
                faturaPagamentoAvulso={
                    (fatura) => {
                        this.faturaPagamentoAvulsoTrue(fatura)
                    }
                }
                pagamentoConsulta={
                    (pagamento) => {
                        this.pagamentoConsultaTrue(pagamento)
                    }
                }
                volta={
                    (conta) => {
                        this.faturaListaExtratoTrue(conta)
                    }
                }
            />
            )
        }

        if (this.state.faturaPagamentoAvulso === true) {
            //voltaPara = 'voltaParaExtratoFatura'
            voltaPara = 'voltaParaExtratoFatura'
            return (<
                FaturaPagamentoAvulso fatura={
                    this.state.fatura
                }
                volta={
                    (conta) => {
                        this.faturaExtratoTrue(conta)
                    }
                }
            />
            )
        }

        if (this.state.contaExtrato === true) {
            voltaPara = 'voltaParaExtratoConta'
            return (<
                ContaExtrato saldo={
                    this.state.saldo
                }
                pagamentoConsulta={
                    (pagamento) => {
                        this.pagamentoConsultaTrue(pagamento)
                    }
                }
                pagamentoConsultaFatura={
                    (pagamento) => {
                        this.pagamentoConsultaFaturaTrue(pagamento)
                    }
                }
                contaAplicarContaRemunerada={
                    (conta_id) => {
                        this.contaAplicarContaRemuneradaTrue(conta_id)
                    }
                }
                contaResgatarContaRemunerada={
                    (conta_id) => {
                        this.contaResgatarContaRemuneradaTrue(conta_id)
                    }
                }
                contaTransferir={
                    (conta_id) => {
                        this.contaTransferirTrue(conta_id)
                    }
                }
                volta={
                    () => {
                        this.contaListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.pagamentoConsulta === true) {
            if (voltaPara === 'voltaParaExtratoFatura') {
                return (<
                    PagamentoConsulta pagamento={
                        this.state.pagamento
                    }
                    pagamentoAlteracao={
                        (pagamento) => {
                            this.pagamentoAlteracaoTrue(pagamento)
                        }
                    }
                    voltaParaExtratoFatura={
                        () => {
                            this.faturaExtratoTrue()
                        }
                    }
                />
                )
            }

            if (voltaPara === 'voltaParaExtratoConta') {
                return (<
                    PagamentoConsulta pagamento={
                        this.state.pagamento
                    }
                    pagamentoAlteracao={
                        (pagamento) => {
                            this.pagamentoAlteracaoTrue(pagamento)
                        }
                    }
                    voltaParaExtratoConta={
                        () => {
                            this.contaExtratoTrue()
                        }
                    }
                />
                )
            }
        }

        if (this.state.pagamentoConsultaFatura === true) {
            if (voltaPara === 'voltaParaExtratoFatura') {
                return (<
                    PagamentoConsultaFatura pagamento={this.state.pagamento}
                    pagamentoAlteracao={
                        (pagamento) => {
                            this.pagamentoAlteracaoTrue(pagamento)
                        }
                    }
                    voltaParaExtratoFatura={
                        () => {
                            this.faturaExtratoTrue()
                        }
                    }
                    faturaExtrato={
                        (fatura, pagamento) => {
                            this.faturaExtratoTrue(fatura, pagamento)
                        }
                    }
                />
                )
            }

            if (voltaPara === 'voltaParaExtratoConta') {
                return (<
                    PagamentoConsultaFatura pagamento={
                        this.state.pagamento
                    }
                    pagamentoAlteracao={
                        (pagamento) => {
                            this.pagamentoAlteracaoTrue(pagamento)
                        }
                    }
                    voltaParaExtratoConta={
                        () => {
                            this.contaExtratoTrue()
                        }
                    }
                    faturaExtrato={
                        (fatura, pagamento) => {
                            this.faturaExtratoTrue(fatura, pagamento)
                        }
                    }
                />
                )
            }
        }

        if (this.state.pagamentoAlteracao === true) {
            if (voltaPara === 'voltaParaExtratoFatura') {
                return (<
                    PagamentoAlteracao pagamento={
                        this.state.pagamento
                    }
                    voltaParaExtratoFatura={
                        () => {
                            this.faturaExtratoTrue()
                        }
                    }
                    volta={
                        () => {
                            this.faturaExtratoTrue()
                        }
                    }
                />
                )
            }

            if (voltaPara === 'voltaParaExtratoConta') {
                return (<
                    PagamentoAlteracao pagamento={
                        this.state.pagamento
                    }
                    voltaParaExtratoConta={
                        () => {
                            this.contaExtratoTrue()
                        }
                    }
                    volta={
                        () => {
                            this.contaExtratoTrue()
                        }
                    }
                />
                )
            }
        }

        if (this.state.contaLista === false &&
            this.state.contaInclusao === false &&
            this.state.contaAlteracao === false &&
            this.state.contaConsulta === false &&
            this.state.contaListaExtrato === false &&
            this.state.faturaListaExtrato === false &&
            this.state.faturaExtrato === false &&
            this.state.faturaPagamentoAvulso === false &&
            this.state.contaExtrato === false &&
            this.state.contaAplicarContaRemunerada === false &&
            this.state.contaResgatarContaRemunerada === false &&
            this.state.contaTransferir === false &&
            this.state.pagamentoConsulta === false &&
            this.state.pagamentoConsultaFatura === false &&
            this.state.pagamentoAlteracao === false
        ) {
            console.log('aqui......')
            return null
        }
    }
}

export default Conta