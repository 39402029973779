import React from 'react'
import moment from 'moment'
import { Component } from 'react'
import { formataEmRealDisplayDoBD, formataDisplayEmReal } from '../../util/Utils'
//import "./styles.css"
import { Line, Bar } from "react-chartjs-2";
import ModalAviso from '../modal/ModalAviso'


const data = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            label: 'Saldo',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)'
        },
        {
            label: 'Saldo médio',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: '#742774'
        }
    ]
}

const dataCredito = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            label: '????',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: true,
            backgroundColor: 'rgba(128,0,0,0.7)',
            borderColor: 'rgba(128,0,0,1)'
        },
    ]
}

class ContaLista extends Component {
    constructor(props) {
        super(props)
        this.state = {
            contas: [],
            saldos: [],
            mostraLista: true,
            mostraGraficoGeral: false,
            alertaMsg: null,
            alerta: false,
            sub_cabec: 'abertas',
            sortStatus: false,
            dataCredito: null
        }
    }

    componentWillMount() {
        this.setState({ dataCredito: dataCredito })
        this.inicializaMatrizGrafico()
        this.listaContas()
        this.saldoGeralDeContasCorrentes()
        this.listaSaldosCartoesAno()
    }

    inicializaMatrizGrafico() {
        var dataInicial = moment().startOf('year').format('YYYY-MM-DD')
        var dataFinal = moment().endOf('year').format('YYYY-MM-01')

        for (var i = 0; dataInicial <= dataFinal; i++) {
            data.labels[i] = dataInicial
            dataInicial = moment(dataInicial).add(1, 'month').format('YYYY-MM-DD')
        }
    }

    listaSaldosCartoesAno() {
        let url = global.url + 'conta/listaSaldosCartoesAno'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.carregaSaldosCartao(responseJson.saldos)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    carregaSaldosCartao(saldos) {

        dataCredito.datasets[0].label = '#Saldos#'

        for (let index = 0; index < saldos.length; index++) {
            dataCredito.datasets[0].data[index] = saldos[index];
        }

        this.setState({ dataCredito: dataCredito })

    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contasGeral: responseJson.contas })
                    this.separaContas(responseJson.contas)
                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })
    }

    separaContas(contasGeral) {

        var contasAbertas = []
        var contasFechadas = []
        var contasFavoritas = []
        var contasInvestimentos = []

        contasGeral.map(async (conta, index) => {
            if (conta.tipo_conta === 'contaInvestimento') {
                contasInvestimentos.push(conta)
            } else {
                if (conta.status === 'fechada') {
                    contasFechadas.push(conta)
                } else {
                    contasAbertas.push(conta)
                    if (conta.favorita === true) {
                        contasFavoritas.push(conta)
                    }
                }
            }
        })

        this.setState({
            contasFavoritas: contasFavoritas,
            contasAbertas: contasAbertas,
            contasFechadas: contasFechadas,
            contasInvestimentos: contasInvestimentos,
            contas: contasAbertas,
        })
    }


    saldoGeralDeContasCorrentes() {
        let url = global.url + 'conta/saldoGeralDeContasCorrentes'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ saldos: responseJson.saldos })
                    this.carregaDadosGrafico(responseJson.saldos)
                } else {
                    //this.setState({
                    // mensagem: responseJson.mensagem
                    //})
                }
            })
    }

    carregaDadosGrafico(saldos) {

        var qtdSaldoMedio = 0
        var valSaldoMedio = 0

        var indexInicioMedia = -1

        saldos.map(async (saldo, index) => {
            var indexGrafico = data.labels.findIndex(function (data) { return data === saldo.data })
            if (indexGrafico > -1) {
                if (indexInicioMedia === -1) {
                    indexInicioMedia = indexGrafico
                }
                qtdSaldoMedio++
                data.datasets[0].data[indexGrafico] = parseFloat(saldo.saldo).toFixed(2)
                valSaldoMedio = valSaldoMedio + parseFloat(saldo.saldo)
            }
        })

        valSaldoMedio = valSaldoMedio / qtdSaldoMedio

        valSaldoMedio = parseFloat(valSaldoMedio).toFixed(2)

        for (var i = 0; i !== qtdSaldoMedio; i++) {
            data.datasets[1].data[indexInicioMedia] = valSaldoMedio
            indexInicioMedia++
        }

        for (i = 0; i < 12; i++) {
            data.labels[i] = moment(data.labels[i]).format('MMM')
        }

    }


    corDoValor(conta) {

        if (conta.tipo_conta === 'cartaoCredito') {
            if (conta.saldoTotalLista <= 0) {
                return 'font-italic'
            } else {
                return ''
            }
        } else {
            if (conta.saldoTotalLista < 0) {
                return 'font-italic'
            } else {
                return ''
            }
        }

    }

    contaListaExtrato(conta) {
        if (conta.tipo_conta === 'cartaoCredito') {
            this.props.faturaListaExtrato(conta)
        } else {
            this.props.contaListaExtrato(conta)
        }
    }

    mudaVisao() {
        this.saldoGeralDeContasCorrentes()
        var mostraLista = !this.state.mostraLista
        var mostraGraficoGeral = !this.state.mostraGraficoGeral
        this.setState({ mostraLista: mostraLista, mostraGraficoGeral: mostraGraficoGeral })
    }

    corDoLancamento(conta) {
        if (conta.saldoTotalLista >= 0) {
            return 'rgm-cor-positivo'
        } else {
            return 'text-white'
        }
    }

    contasAbertas() {
        this.setState({ contas: this.state.contasAbertas, sub_cabec: 'abertas' })
    }

    contasFechadas() {
        this.setState({ contas: this.state.contasFechadas, sub_cabec: 'fechadas' })
    }

    contasFavoritas() {
        this.setState({ contas: this.state.contasFavoritas, sub_cabec: 'favoritas' })
    }


    contasInvestimentos() {
        this.setState({ contas: this.state.contasInvestimentos, sub_cabec: 'Investimentos' })
    }

    render() {

        if (this.state.mostraLista === true) {
            return [
                this.renderListaContaCorrente(),
                this.renderListaCartaoCredito(),
                this.renderGraficoCartaoDeCredito(),
            ]
        }

        if (this.state.mostraGraficoGeral === true) {
            return [
                this.renderGraficoGeral(),
            ]
        }

    }

    descricaoCartaoCredito(conta) {
        return conta.nome
    }

    renderListaContaCorrente() {

        var valorTotal = 0

        if (this.state.sub_cabec === 'Investimentos') {
            this.state.contas.map((conta, index) => {
                if (conta.tipo_conta === 'contaInvestimento') {
                    valorTotal = parseFloat(valorTotal) + parseFloat(conta.saldoTotalLista)
                }
                return 0
            })
        } else {
            this.state.contas.map((conta, index) => {
                if (conta.tipo_conta === 'contaCorrente' || conta.tipo_conta === 'contaDinheiro') {
                    valorTotal = parseFloat(valorTotal) + parseFloat(conta.saldoTotalLista)
                }
                return 0
            })
        }


        valorTotal = parseFloat(valorTotal).toFixed(2)

        return (
            <div className="container w-100 mt-5">
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.props.contaInclusao()}><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Contas</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}><i class="fa fa-line-chart" aria-hidden="true"></i></button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.contasAbertas()}>Contas abertas</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.contasFechadas()}>Contas fechadas</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.contasFavoritas()}>Contas Favoritas</button>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.contasInvestimentos()}>Investimentos</button>
                <div className="card bg-transparent">
                    <div class="card-header border rgm-bg-azul-esverdiado text-white">
                        <div className="row align-middle text-white  m-2">
                            <h5>Lista de contas {this.state.sub_cabec}</h5>
                        </div>
                    </div>
                    <table className="table table-bordered mt-0">
                        <thead>
                            <tr className="bg-info">
                                <th className="text-center" scope="col"><i class="fa fa-info" aria-hidden="true"></i></th>
                                <th className="text-center" scope="col">Tipo</th>
                                <th className="text-center" scope="col">Banco</th>
                                <th className="text-center" scope="col">Nome</th>
                                <th className="text-center" scope="col">Saldo Bancário</th>
                                <th className="text-center" scope="col">Extrato</th>
                            </tr>
                        </thead>
                        <tbody className="rgm-bg-azul-esverdiado">
                            {this.state.contas.map((conta, index) => {
                                if (conta.tipo_conta === 'contaCorrente' || conta.tipo_conta === 'contaDinheiro' || conta.tipo_conta === 'contaInvestimento') {
                                    return (
                                        <tr className="text-white">
                                            <td className="text-center align-middle">
                                                <button className="btn btn-link btn-sm m-2 text-center align-middle" onClick={() => { this.props.contaConsulta(conta) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                            </td>
                                            <td className="align-middle" >{conta.tipo_conta_desc}</td>
                                            <td className="align-middle" >{conta.band_banco_desc}</td>
                                            <td className="align-middle" >{conta.nome}</td>
                                            <td className={"align-middle font-weight-bold " + this.corDoLancamento(conta)} style={{ textAlign: 'right' }}>{formataEmRealDisplayDoBD(conta.saldoTotalLista)}</td>
                                            <td className="align-middle text-center" id="buttonClickConta">
                                                <button className="btn btn-link btn-sm m-2" onClick={() => this.contaListaExtrato(conta)} >
                                                    <i className="fa fa-list rgm-cor-link" title="Extrato"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                }
                            })
                            }
                        </tbody>
                        <thead>
                            <tr className="bg-info">
                                <th scope="col" className="text-center" COLSPAN="4">Total</th>
                                <th scope="col" className="text-center" COLSPAN="2">{formataDisplayEmReal(valorTotal)}</th>
                            </tr>
                        </thead>
                        {this.state.contas.length === 0 &&
                            <tbody>
                                <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="6">
                                    <span>Não existem contas cadastradas</span>
                                </td>
                            </tbody>
                        }
                    </table>
                </div>
            </div>
        )

    }

    sortDiaFech() {

        var sort1 = 0
        var sort2 = 0

        if (this.state.sortStatus === true) {
            sort1 = 1
            sort2 = -1
            this.setState({ sortStatus: false })
        } else {
            sort1 = -1
            sort2 = 1
            this.setState({ sortStatus: true })
        }

        var contas = this.state.contas.sort((a, b) => {
            if (a.tipo_conta !== 'cartaoCredito' || b.tipo_conta !== 'cartaoCredito') {
                return 0
            } else {
                if (a.conta_cartoes_creditos.dia_fechamento < b.conta_cartoes_creditos.dia_fechamento) {
                    return sort1
                } else {
                    if (a.conta_cartoes_creditos.dia_fechamento > b.conta_cartoes_creditos.dia_fechamento) {
                        return sort2
                    } else {
                        return 0
                    }
                }
            }
        })

        this.setState({ contas: contas })

    }

    sortDiaPaga() {

        var sort1 = 0
        var sort2 = 0

        if (this.state.sortStatus === true) {
            sort1 = 1
            sort2 = -1
            this.setState({ sortStatus: false })
        } else {
            sort1 = -1
            sort2 = 1
            this.setState({ sortStatus: true })
        }

        var contas = this.state.contas.sort((a, b) => {
            if (a.tipo_conta !== 'cartaoCredito' || b.tipo_conta !== 'cartaoCredito') {
                return 0
            } else {
                if (a.conta_cartoes_creditos.dia_pagamento < b.conta_cartoes_creditos.dia_pagamento) {
                    return sort1
                } else {
                    if (a.conta_cartoes_creditos.dia_pagamento > b.conta_cartoes_creditos.dia_pagamento) {
                        return sort2
                    } else {
                        return 0
                    }
                }
            }
        })

        this.setState({ contas: contas })

    }

    renderListaCartaoCredito() {

        var existeCartao = false

        var valorTotal = 0
        var valorLimite = 0
        var valorDisponivel = 0

        this.state.contas.map((conta, index) => {
            if (conta.tipo_conta === 'cartaoCredito') {
                existeCartao = true
                valorTotal = parseFloat(valorTotal) + parseFloat(conta.saldoTotalLista)
                valorLimite = parseFloat(valorLimite) + parseFloat(conta.conta_cartoes_creditos.limite)
                valorDisponivel = parseFloat(valorDisponivel) + parseFloat(conta.saldoDisponivelLista)
            }
            return 0
        })

        valorTotal = parseFloat(valorTotal).toFixed(2)
        valorLimite = parseFloat(valorLimite).toFixed(2)
        valorDisponivel = parseFloat(valorDisponivel).toFixed(2)

        return (
            <div className="container-fluid w-100 mt-5">
                <div class="row justify-content-md-center">
                    <div className="card bg-transparent">
                        <div class="card-header border rgm-bg-azul-esverdiado text-white">
                            <div className="row align-middle text-white  m-2">
                                <h5>Lista de cartões de crédito {this.state.sub_cabec}</h5>
                            </div>
                        </div>
                        <table className="table table-bordered mt-0">
                            {existeCartao === true &&
                                <thead>
                                    <tr className="bg-info">
                                        <th className="text-center" scope="col"><i class="fa fa-info" aria-hidden="true"></i></th>
                                        <th className="text-center" scope="col">Bandeira</th>
                                        <th className="text-center" scope="col">Nome</th>
                                        <th className="text-center" scope="col">Cartão</th>
                                        <th className="text-center" scope="col" onClick={() => { this.sortDiaFech() }}>Fecha</th>
                                        <th className="text-center" scope="col" onClick={() => { this.sortDiaPaga() }}>Paga</th>
                                        <th className="text-center" scope="col" >Limite</th>
                                        <th className="text-center" scope="col">Disponível</th>
                                        <th className="text-center" scope="col">Saldo total a pagar</th>
                                        <th className="text-center" scope="col">Extrato</th>
                                    </tr>
                                </thead>
                            }
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.contas.map((conta, index) => {
                                    if (conta.tipo_conta === 'cartaoCredito') {
                                        return (
                                            <tr className="text-white">
                                                <td className="text-center align-middle">
                                                    <button className="btn btn-link btn-sm m-2 text-center align-middle" onClick={() => { this.props.contaConsulta(conta) }}><i class="fa fa-info rgm-cor-link" aria-hidden="true"></i></button>
                                                </td>
                                                <td className="align-middle" >{conta.band_banco_desc}</td>
                                                <td className="align-middle" data-toggle="tooltip" data-placement="top" title={'Melhor dia:' + conta.conta_cartoes_creditos.dia_fechamento} >{this.descricaoCartaoCredito(conta)}</td>
                                                <td className="align-middle text-center" >{conta.conta_cartoes_creditos.cartao_inicio}******{conta.conta_cartoes_creditos.cartao_fim} </td>
                                                <td className="align-middle text-right"  >{conta.conta_cartoes_creditos.dia_fechamento}</td>
                                                <td className="align-middle text-right"  >{conta.conta_cartoes_creditos.dia_pagamento}</td>
                                                <td className="align-middle text-right" >{formataDisplayEmReal(conta.conta_cartoes_creditos.limite)}</td>
                                                <td className="align-middle text-right" >{formataDisplayEmReal(conta.saldoDisponivelLista)}</td>
                                                <td className={"align-middle font-weight-bold " + this.corDoLancamento(conta)} style={{ textAlign: 'right' }}>{formataEmRealDisplayDoBD(conta.saldoTotalLista)}</td>
                                                <td className="align-middle text-center" id="buttonClickConta">
                                                    <button className="btn btn-link btn-sm m-2" onClick={() => this.contaListaExtrato(conta)} >
                                                        <i className="fa fa-list rgm-cor-link" title="Extrato"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        )

                                    }
                                })
                                }
                            </tbody>
                            {existeCartao &&
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col" className="text-center" COLSPAN="6">Total</th>
                                        <th scope="col" className="text-center" COLSPAN="1">{formataDisplayEmReal(valorLimite)}</th>
                                        <th scope="col" className="text-center" COLSPAN="1">{formataDisplayEmReal(valorDisponivel)}</th>
                                        <th scope="col" className="text-center" COLSPAN="2">{formataDisplayEmReal(valorTotal)}</th>
                                    </tr>
                                </thead>
                            }
                            {this.state.contas.length === 0 &&
                                <tbody>
                                    <td className="rgm-bg-azul-esverdiado text-white text-center" COLSPAN="6">
                                        <span>Não existem contas cadastradas</span>
                                    </td>
                                </tbody>
                            }
                        </table>
                    </div>
                </div>
            </div>
        )

    }

    renderGraficoGeral() {
        var anoCabec = moment().format('YYYY')

        return (
            <div className="container w-100 mt-5">

                <div className="card-header bg-info border mt-2">
                    <h7>Gráfico geral dos saldos bancários do ano {anoCabec}</h7>
                </div>
                <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}>Volta para lista</button>
                <div className="jumbotron">
                    <Line data={data} />
                </div>
            </div>
        )
    }

    renderGraficoCartaoDeCredito() {
        var anoCabec = moment().format('YYYY')

        return (
            <div className="container w-100 mt-5">

                <div className="card-header bg-info border mt-2">
                    <h7>Gráfico geral dos cartões de crédito</h7>
                </div>
                {/* <button className="btn btn-primary btn-sm m-2" onClick={() => this.mudaVisao()}>Volta para lista</button> */}
                <div className="jumbotron">
                    <Bar data={this.state.dataCredito} />
                </div>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }


}

export default ContaLista