import { tiraEspacosDuplicados } from '../../util/Utils'

export const verificaSeContaExiste = (nome, contas) => {
 
    var nomeLocal = tiraEspacosDuplicados(nome) 

    var existe = false

    contas.map(async (conta, index) => {

        if (conta.nome.toLowerCase() === nomeLocal.toLowerCase()) {
            existe = true
        }

    })
 
    return existe
}