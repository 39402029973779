import React, { Component } from 'react'
import PagamentoLista from '../pagamento/PagamentoLista'
import PagamentoInclusao from '../pagamento/PagamentoInclusao'
import PagamentoAlteracao from '../pagamento/PagamentoAlteracao'
import PagamentoPagar from '../pagamento/PagamentoPagar'
import PagamentoPagarFatura from '../pagamento/PagamentoPagarFatura'
import PagamentoConsulta from '../pagamento/PagamentoConsulta'
import PagamentoConsultaFatura from '../pagamento/PagamentoConsultaFatura'
import FaturaExtrato from '../fatura/FaturaExtrato'


class Pagamento extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamentoLista: true,
            pagamentoInclusao: false,
            pagamentoAlteracao: false,
            pagamentoPagar: false,
            pagamentoPagarFatura: false,
            pagamentoConsulta: false,
            pagamentoConsultaFatura: false,
            faturaExtrato: false,
            retornaExtrato: false,
            pagamento: null,
            fatura_id: null
        }
    }

    componentWillMount() {
        global.pagamentosPrincipal = () => {this.pagamentoListaTrue()}
    }

    pagamentoListaTrue() {
        this.setFalseAll()
        this.setState({
            pagamentoLista: true
        })
    }

    pagamentoInclusaoTrue() {
        this.setFalseAll()
        this.setState({
            pagamentoInclusao: true
        })
    }

    pagamentoAlteracaoTrue(pagamento) {
        this.setFalseAll()
        this.setState({
            pagamentoAlteracao: true,
            pagamento: pagamento
        })
    }

    pagamentoConsultaTrue(pagamento, retornaExtrato) {
        if (retornaExtrato === undefined || retornaExtrato === false) {
            this.setState({
                retornaExtrato: false
            })
        } else {
            this.setState({
                retornaExtrato: true
            })
        }

        this.setFalseAll()
        this.setState({
            pagamentoConsulta: true,
            pagamento: pagamento
        })
    }

    pagamentoPagarTrue(pagamento) {
        this.setFalseAll()
        this.setState({
            pagamentoPagar: true,
            pagamento: pagamento
        })
    }

    pagamentoPagarFaturaTrue(pagamento) {
        this.setFalseAll()
        this.setState({
            pagamentoPagarFatura: true,
            pagamento: pagamento
        })
    }

    pagamentoConsultaFaturaTrue(pagamento) {

        var pagamentoLocal = null

        if (pagamento === undefined) {
            pagamentoLocal = this.state.pagamento
        } else {
            pagamentoLocal = pagamento
        }

        this.setFalseAll()
        this.setState({
            pagamentoConsultaFatura: true,
            pagamento: pagamentoLocal
        })
    }

    extratoCartaoTrue(fatura, pagamento) {
        this.setFalseAll()
        this.setState({
            faturaExtrato: true,
            fatura: fatura,
            pagamento: pagamento
        })
    }

    setFalseAll() {
        this.setState({
            pagamentoLista: false,
            pagamentoInclusao: false,
            pagamentoAlteracao: false,
            pagamentoPagar: false,
            pagamentoPagarFatura: false,
            pagamentoConsulta: false,
            pagamentoConsultaFatura: false,
            faturaExtrato: false
        })
    }

    voltaParaExtratoFaturaVindaDoPagamentoTrue(pagamento) {
        this.extratoCartaoTrue(pagamento.fatura_id)
    }

    render() {
        if (this.state.pagamentoLista === true) {
            return (<
                PagamentoLista pagamentoInclusao={
                    () => {
                        this.pagamentoInclusaoTrue()
                    }
                }
                pagamentoConsulta={
                    (pagamento, retornaExtrato) => {
                        this.pagamentoConsultaTrue(pagamento, retornaExtrato)
                    }
                }
                pagamentoPagar={
                    (pagamento) => {
                        this.pagamentoPagarTrue(pagamento)
                    }
                }
                pagamentoPagarFatura={
                    (pagamento) => {
                        this.pagamentoPagarFaturaTrue(pagamento)
                    }
                }
                pagamentoConsultaFatura={
                    (pagamento) => {
                        this.pagamentoConsultaFaturaTrue(pagamento)
                    }
                }

            />
            )
        }

        if (this.state.pagamentoInclusao === true) {
            return (<
                PagamentoInclusao volta={
                    () => {
                        this.pagamentoListaTrue()
                    }
                }
            />
            )
        }

        if (this.state.pagamentoAlteracao === true) {
            if (this.state.retornaExtrato === true) {
                return (<
                    PagamentoAlteracao pagamento={
                        this.state.pagamento
                    }
                    voltaParaExtratoFaturaVindaDoPagamento={
                        (pagamento) => {
                            this.voltaParaExtratoFaturaVindaDoPagamentoTrue(pagamento)
                        }
                    }

                />
                )
            }

            if (this.state.retornaExtrato === false) {
                return (<
                    PagamentoAlteracao pagamento={
                        this.state.pagamento
                    }
                    volta={
                        () => {
                            this.pagamentoListaTrue()
                        }
                    }
                />
                )
            }
        }

        if (this.state.pagamentoConsulta === true) {
            var volta = null

            if (this.state.retornaExtrato === true) {
                volta = (fatura_id) => {
                    this.extratoCartaoTrue(fatura_id)
                }
            } else {
                volta = () => {
                    this.pagamentoListaTrue()
                }
            }

            if (this.state.retornaExtrato === true) {
                return (<
                    PagamentoConsulta pagamento={
                        this.state.pagamento
                    }
                    pagamentoAlteracao={
                        (pagamento) => {
                            this.pagamentoAlteracaoTrue(pagamento)
                        }
                    }
                    volta={
                        volta
                    }
                    voltaParaExtratoFaturaVindaDoPagamento={
                        (pagamento) => {
                            this.voltaParaExtratoFaturaVindaDoPagamentoTrue(pagamento)
                        }
                    }
                />
                )
            }

            if (this.state.retornaExtrato === false) {
                return (<
                    PagamentoConsulta pagamento={
                        this.state.pagamento
                    }
                    pagamentoAlteracao={
                        (pagamento) => {
                            this.pagamentoAlteracaoTrue(pagamento)
                        }
                    }
                    volta={
                        volta
                    }
                />
                )
            }
        }

        if (this.state.pagamentoPagar === true) {
            volta = () => {
                this.pagamentoListaTrue()
            }

            return (<
                PagamentoPagar pagamento={
                    this.state.pagamento
                }
                pagamentoAlteracao={
                    (pagamento) => {
                        this.pagamentoAlteracaoTrue(pagamento)
                    }
                }
                volta={
                    volta
                }
            />
            )
        }

        if (this.state.pagamentoPagarFatura === true) {
            volta = () => {
                this.pagamentoListaTrue()
            }

            return (<
                PagamentoPagarFatura pagamento={
                    this.state.pagamento
                }
                pagamentoAlteracao={
                    (pagamento) => {
                        this.pagamentoAlteracaoTrue(pagamento)
                    }
                }
                volta={
                    volta
                }
            />
            )
        }

        if (this.state.pagamentoConsultaFatura === true) {
            return (<
                PagamentoConsultaFatura pagamento={
                    this.state.pagamento
                }
                volta={
                    () => {
                        this.pagamentoListaTrue()
                    }
                }
                faturaExtrato={
                    (fatura, pagamento) => {
                        this.extratoCartaoTrue(fatura, pagamento)
                    }
                }
            />
            )
        }

        if (this.state.faturaExtrato === true) {
            return (<
                FaturaExtrato fatura={
                    this.state.fatura
                }
                pagamentoConsulta={
                    (pagamento, retornaExtrato) => {
                        this.pagamentoConsultaTrue(pagamento)
                    }
                }
                voltaPagamentoConsultaFatura={
                    () => {
                        this.pagamentoConsultaFaturaTrue()
                    }
                }
            />
            )
        }

        if (this.state.pagamentoLista === false &&
            this.state.pagamentoInclusao === false &&
            this.state.pagamentoAlteracao === false &&
            this.state.pagamentoConsulta === false &&
            this.state.faturaExtrato === false &&
            this.state.pagamentoPagar === false &&
            this.state.pagamentoPagarFatura === false
        ) {
            return null
        }
    }
}

export default Pagamento