import React, { Component } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'
import 'moment/locale/pt-br';
import { formataDisplayEmReal } from '../../util/Utils'
import ModalAviso from '../modal/ModalAviso'

class PagamentoConsultaFatura extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: this.props.pagamento,
            contas: [],
            conta: { tipo_conta: null },
            cartaoCreditoNome: null
        }
    }

    componentWillMount() {
        this.listaContas()
    }

    volta() {

        if (typeof this.props.voltaParaExtratoFatura === 'function') {
            this.props.voltaParaExtratoFatura(this.state.pagamento)
        } else {
            if (typeof this.props.voltaParaExtratoConta === 'function') {
                this.props.voltaParaExtratoConta(this.state.pagamento)
            } else {
                if (typeof this.props.voltaParaExtratoFaturaVindaDoPagamento === 'function') {
                    this.props.voltaParaExtratoFaturaVindaDoPagamento(this.state.pagamento)
                } else {
                    this.props.volta()
                }
            }
        }
    }

    render() {
        return [
            this.renderAlert(),
            this.renderComum()
        ]
    }

    renderComum() {

        return (

            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Consulta de fatura</h5>
                        </div>
                    </div>

                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-2">
                            <label for="valor">Valor</label>
                            <input
                                readOnly="true"
                                value={formataDisplayEmReal(this.state.pagamento.valor)}
                                type="text"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                        <div className="col-md-2">
                            <label className="" >Vencimento</label>
                            <DatePicker
                                disabled
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeVencimento"
                                selected={moment(this.state.pagamento.data_de_vencimento).toDate()}
                            />
                        </div>
                        <div className="form-group col-md-5">
                            <label for="descricao">Descrição</label>
                            <input
                                readOnly="true"
                                value={this.state.pagamento.descricao}
                                type="text"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        <div className="form-group col-md-4">
                            <label for="conta" className="">Cartao de crédito</label>
                            <input
                                readOnly="true"
                                value={this.state.cartaoCreditoNome}
                                type="text"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="conta" className="">Conta para débito</label>
                            <Select
                                isDisabled="true"
                                id="conta"
                                name="conta"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                selectedValue={this.state.conta_id}
                                value={this.state.contas.filter(option => option.id === this.state.pagamento.conta_id)}
                                options={this.state.contas}
                            />
                        </div>

                    </div>
                    <div class="form-group col-md-11">
                        <label className="m-2" for="comment">Comentários</label>
                        <textarea
                            readOnly="true"
                            value={this.state.comentarios}
                            className="form-control m-2"
                            rows="2" id="comum:comentarios">
                        </textarea>
                    </div>
                    <hr />
                    <div className="row ml-3 mb-2">
                        <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.volta() }}>Voltar</button>
                        {typeof this.props.faturaExtrato === 'function' &&
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.faturaExtrato(this.state.pagamento.fatura, this.state.pagamento) }}>Extrato</button>
                        }
                    </div>
                </form>
            </div>
        )

    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contas: responseJson.contas })
                    this.acessaCartaoDeCredito(responseJson.contas)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    acessaCartaoDeCredito(contas) {
        var cartaoCreditoId = this.state.pagamento.fatura.conta_id

        contas.map(async (conta, index) => {
            if (conta.tipo_conta === 'cartaoCredito' && conta.id === cartaoCreditoId) {
                this.setState({ cartaoCreditoNome: conta.nome })
            }
        })

    }

}

export default PagamentoConsultaFatura