
export const extensoTipo = (tipo) => {

    var extenso = null

    if (tipo === 'debito') {
        extenso = 'Débito'
    }

    if (tipo === 'credito') {
        extenso = 'Crédito'
    }

    return extenso
}

export const extensoFrequencia = (frequencia) => {

    var extenso = null

    if (frequencia === 'mensal') {
        extenso = 'Mensal'
    }

    if (frequencia === 'unica') {
        extenso = 'Única'
    }

    if (frequencia === 'recorrente') {
        extenso = 'Recorrente'
    }


    return extenso
}

export const extensoTipoDeConta = (tipoDeConta) => {

    var extenso = null

    if (tipoDeConta === 'contaCorrente') {
        extenso = 'Conta corrente'
    }

    if (tipoDeConta === 'contaDinheiro') {
        extenso = 'Dinheiro'
    }

    if (tipoDeConta === 'cartaoCredito') {
        extenso = 'Cartão de crédito'
    }


    return extenso
}

export const extensoStatus = (status) => {

    var extenso = null

    if (status === 'emAberto') {
        extenso = 'Em aberto'
    }

    if (status === 'liquidado') {
        extenso = 'Liquidado'
    }

    return extenso
}

export const extensoUsuarioStatus = (status) => {

    var extenso = 'Status não previsto = ' + status

    if (status === 'experimentando') {
        extenso = 'Em experiência'
    }

    if (status === 'preAssinante') {
        extenso = 'Pre assinatura'
    }

    if (status === 'assinante') {
        extenso = 'Assinante ativo'
    }

    if (status === 'desativado') {
        extenso = 'Assinante desativado'
    }

    if (status === 'faltaPagamento') {
        extenso = 'Pagamento em atraso'
    }
    
    return extenso
}

//status => experimentando, assinante, desativado, faltaPagamento 


