import React, { Component } from 'react'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'
import 'moment/locale/pt-br';
import { formataDisplayEmReal, formataEmRealParaBD, preencheComboDataOrcamento, formataDisplayEmPercentual } from '../../util/Utils'
import { extensoTipo } from '../../util/extenso'
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
} 


var quaisMesesAtualizar = [
    { value: 'true', label: 'Atualizar este mês e os seguintes' },
    { value: 'false', label: 'Atualizar apenas este mês' }
]


class OrcamentoAlteracao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orcamento: this.props.orcamento,
            comboDatasOrcamento: [],
            categoriasOrcamento: this.props.orcamento.categorias,
            renderAlteraCategoria: false,
            categoria: null,
            metaPercentual: 0.00,
            possoIncluirMaisOrcamentos: [],
            alerta: false,
            alertaMsg: null,
            confirma: false,
            pedeConfirmacao: false,
            atualizaTudo: null
        }
    }

    componentWillMount() {
        this.listaCategorias()
        this.possoIncluirMaisOrcamentos()

    }

    handleChangeData(data) {

        var orcamentoCPY = this.state.orcamento
        orcamentoCPY.data = data.value
        this.setState({ orcamento: orcamentoCPY })
    }

    handleChangeReceitas(event) {
        var receita = event.target.value

        var result = receita.indexOf ('-')

        if (result !== -1) {
            receita = '0'
        }
       
        if (receita.length === 0) {
            receita = '0'
        }
 
        if (receita.length === 1) {
            if (isNaN(receita)) {
                receita = '0'
            }
        }

        var orcamentoCPY = this.state.orcamento
        orcamentoCPY.receita_meta = formataDisplayEmReal(receita)

        if (orcamentoCPY.receita_meta.includes("-", 0)) {
            receita = '0'
        }

        this.setState({ orcamento: orcamentoCPY })

    }

    handleChangeMeta(event) {
        var meta = event.target.value

        var result = meta.indexOf ('-')

        if (result !== -1) {
            meta = '0'
        }

        if (meta.length === 0) {
            meta = '0'
        } 

        if (meta.length === 1) {
            if (isNaN(meta)) {
                meta = '0'
            }
        }

        var orcamentoCPY = this.state.orcamento
        orcamentoCPY.despesa_meta = formataDisplayEmReal(meta)

        if (orcamentoCPY.despesa_meta.includes("-", 0)) {
            return
        }

        this.setState({ orcamento: orcamentoCPY })

        var percentual = ((formataEmRealParaBD(orcamentoCPY.despesa_meta) / formataEmRealParaBD(this.state.orcamento.receita_meta) * 100)).toFixed(2)

        if (percentual === 'NaN') {
            percentual = '0'
        }

        this.setState({ metaPercentual: percentual })

    }

    handleChangeTeste(event) {
    }

    ligaRenderAlteraCategoria(categoria) {
        this.setState({ renderAlteraCategoria: true, categoria: categoria })
    }

    desligaRenderAlteraCategoria() {
        this.setState({ renderAlteraCategoria: false, renderAlteraSubcategoria: false })
    }

    ligaRenderAlteraSubcategoria(subcategoria) {
        this.setState({ renderAlteraSubcategoria: true, subcategoria: subcategoria })
    }

    desligaRenderAlteraSubcategoria() {
        this.setState({ renderAlteraSubcategoria: false })
    }

    formataPercentual(valor) {

        return formataDisplayEmPercentual(valor)


    }

    handleChangeMetaCategoria(event) {
        var meta = event.target.value

        var result = meta.indexOf ('-')

        if (result !== -1) {
            meta = '0'
        }

        if (meta.length === 0) {
            meta = '0'
        }

        if (meta.length === 1) {
            if (isNaN(meta)) {
                meta = '0'
            }
        }

        var categoriaCPY = this.state.categoria
        categoriaCPY.meta = formataDisplayEmReal(meta)

        if (categoriaCPY.meta.includes("-", 0)) {
            return
        }

        if (categoriaCPY.meta.length > 14) {
            return
        } else {
            this.setState({ categoria: categoriaCPY })
        }

    }

    handleChangeMetaSubcategoria(event) {
        var meta = event.target.value

        var result = meta.indexOf ('-')

        if (result !== -1) {
            meta = '0'
        }

        if (meta.length === 0) {
            meta = '0'
        }

        if (meta.length === 1) {
            if (isNaN(meta)) {
                meta = '0'
            }
        }

        var subcategoriaCPY = this.state.subcategoria
        subcategoriaCPY.meta = formataDisplayEmReal(meta)

        if (subcategoriaCPY.meta.includes("-", 0)) {
            return
        }

        if (subcategoriaCPY.meta.length > 14) {
            return
        } else {
            this.setState({ subcategoria: subcategoriaCPY })
        }

        var valorTotal = 0

        this.state.categoria.sub_categorias.map(async (subCategoria, index) => {
            valorTotal = valorTotal + formataEmRealParaBD(subCategoria.meta)
        })


        if (valorTotal > formataEmRealParaBD(this.state.categoria.meta)) {
            var categoriaCpy = this.state.categoria
            valorTotal = parseFloat(valorTotal).toFixed(2)
            var valorTotalDisp = formataDisplayEmReal(valorTotal.toString())
            categoriaCpy.meta = valorTotalDisp
            this.setState({ categoria: categoriaCpy })
        }

    }
    handleChangeAtualizaTudo(opcao) {

        this.setState({ atualizaTudo: opcao.value })

    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderConfirmacao(),
            this.renderComum(),
            this.renderAlteraCategoria(),
            this.renderAlteraSubcategoria(),
            this.renderListaSubcategoria(),
            this.renderCategorias()
        ]
    }

    renderComum() {
        return (
            <div className="container w-100 mt-5 mb-3"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Alteração de orçamento</h5>
                        </div>
                    </div>

                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-2 text-center">
                            <label for="fatura" className="">Mês Ano</label>
                            <input
                                readOnly="true"
                                value={moment(this.state.orcamento.data).format('MMMM/YYYY')}
                                type="text"
                                className="form-control text-center"
                                id='data'
                            />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <label for="receita">Total de receitas</label>
                            <input
                                onChange={this.handleChangeReceitas.bind(this)}
                                value={this.state.orcamento.receita_meta}
                                type="text"
                                className="form-control  text-center"
                                id="receita"
                            />
                        </div>
                        <div className="form-group col-md-2 text-center">
                            <label for="met">Total de gastos</label>
                            <input
                                onChange={this.handleChangeMeta.bind(this)}
                                value={this.state.orcamento.despesa_meta}
                                type="text"
                                className="form-control  text-center"
                                id="meta"
                            />
                        </div>
                        <div className="form-group col-md-1 text-center">
                            <label for="metaPercentual">%</label>
                            <input
                                readOnly="true"
                                value={this.formataPercentual(this.state.metaPercentual)}
                                type="text"
                                className="form-control text-center"
                                id="metaPercentual"
                            />
                        </div>

                        <div className="form-group col-md-4 text-center">
                            <label for="atualizaTudo" className="">Abrangência</label>
                            <Select
                                styles={customStyles}
                                id="atualizaTudo"
                                name="atualizaTudo"
                                value={quaisMesesAtualizar.filter(option => option.value === this.state.atualizaTudo)}
                                onChange={this.handleChangeAtualizaTudo.bind(this)}
                                options={quaisMesesAtualizar}
                            />
                        </div>

                    </div>
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.incluiOrcamento() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderCategorias() {
        if (this.state.renderAlteraCategoria === false) {
            return (
                <div className="container w-100 mt-0"    >
                    <div className="bordermt-2">
                        <div className="card bg-transparent">
                            <div class="card-header rgm-bg-azul-esverdiado text-white border">
                                <div className="row align-middle m-2">
                                    <h5>Categorias</h5>
                                </div>
                            </div>
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col">Categoria</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Meta</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Percentual</th>
                                        <th scope="col" className="text-center">Alterar meta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.categoriasOrcamento.map((categoria, index) => {
                                        return (
                                            <tr className="rgm-bg-azul-esverdiado">
                                                <td className="align-middle text-white">{categoria.nome}</td>
                                                <td className="align-middle text-white">{extensoTipo(categoria.tipo)}</td>
                                                <td className="align-middle text-white" style={{ textAlign: 'right' }}>{categoria.meta}</td>
                                                <td className="align-middle text-white" style={{ textAlign: 'right' }}>{categoria.metaPercentual}%</td>
                                                <td className="text-center align-middle text-white"><button className="btn btn-link btn-sm m-2 text-center" onClick={() => this.ligaRenderAlteraCategoria(categoria)}><i class="fa fa-pencil rgm-cor-link" aria-hidden="true"></i></button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderAlteraCategoria() {
        if (this.state.renderAlteraCategoria === true) {
            return (
                <div className="container w-100 mt-5">
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div class="card-header border">
                            <div className="row">
                                <h5>Altera meta da categoria</h5>
                            </div>
                        </div>

                        <div className="ml-3 form-row mt-2">
                            <div className="form-group col-md-6">
                                <label for="receita">Categoria</label>
                                <input
                                    readOnly="true"
                                    value={this.state.categoria.nome}
                                    type="receita"
                                    className="form-control"
                                    id="receita"
                                    placeholder="Nome" />
                            </div>
                            <div className="form-group col-md-2 text-center">
                                <label for="receita">Meta</label>
                                <input
                                    onChange={this.handleChangeMetaCategoria.bind(this)}
                                    value={this.state.categoria.meta}
                                    type="receita"
                                    className="form-control text-center"
                                    id="receita"
                                    placeholder="Nome" />
                            </div>
                        </div>
                        <hr />
                        <div class="card-header border">
                            <div className="row">
                                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.desligaRenderAlteraCategoria() }}>Voltar</button>
                            </div>
                        </div>
                    </form>
                </div>
            )
        }
    }

    renderAlteraSubcategoria() {
        if (this.state.renderAlteraSubcategoria === true) {
            return (
                <div className="container w-100 mt-5"    >
                    <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                        <div class="card-header border">
                            <div className="row">
                                <h5>Altera meta da subcategoria</h5>
                            </div>
                        </div>
                        <div className="ml-3 form-row mt-2">
                            <div className="form-group col-md-6">
                                <label for="receita">Subcategoria</label>
                                <input
                                    readOnly="true"
                                    value={this.state.subcategoria.nome}
                                    type="receita"
                                    className="form-control"
                                    id="receita"
                                    placeholder="Nome" />
                            </div>
                            <div className="form-group col-md-2 text-center">
                                <label for="receita">Meta</label>
                                <input
                                    onChange={this.handleChangeMetaSubcategoria.bind(this)}
                                    value={this.state.subcategoria.meta}
                                    type="receita"
                                    className="form-control text-center"
                                    id="receita"
                                    placeholder="Nome" />
                            </div>
                        </div>
                        <hr />
                        <div class="card-header border">
                            <div className="row">
                                <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.desligaRenderAlteraSubcategoria() }}>Voltar</button>
                            </div>
                        </div>
                    </form>
                </div>
            )
        }
    }

    renderListaSubcategoria() {
        if (this.state.renderAlteraCategoria === true) {
            return (
                <div className="container w-100 mt-5"    >
                    <div className="bordermt-2">
                        <div className="card bg-transparent">
                            <div class="card-header rgm-bg-azul-esverdiado text-white border">
                                <div className="row align-middle text-white m-2">
                                    <h5>Subcategorias</h5>
                                </div>
                            </div>

                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col">Subcategoria</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Meta</th>
                                        <th scope="col" style={{ textAlign: 'right' }}>Percentual</th>
                                        <th scope="col" className="text-center">Alterar meta</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.categoria.sub_categorias.map((subcategoria, index) => {
                                        return (
                                            <tr className="rgm-bg-azul-esverdiado">
                                                <td className="text-white">{subcategoria.nome}</td>
                                                <td className="text-white" style={{ textAlign: 'right' }}>{subcategoria.meta}</td>
                                                <td className="text-white" style={{ textAlign: 'right' }}>{subcategoria.metaPercentual}%</td>
                                                <td className="text-center text-white"><button className="btn btn-link btn-sm m-2 text-center" onClick={() => this.ligaRenderAlteraSubcategoria(subcategoria)}><i class="fa fa-pencil rgm-cor-link" aria-hidden="true"></i></button></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.confirma()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a alteração do orçamento?" />
                </>
            )
        }
    }
    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    selecionaCategorias(categoriasLista) {

        var result = false

        var categoriasSelecionadas = categoriasLista.filter((obj) => {
            if (obj.tipo === 'debito' || obj.tipo === 'credito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }

            return result

        })

        var categoriasOrcamento = []

        categoriasSelecionadas.map(async (categoria, index) => {
            var categoriaNew = JSON.parse(JSON.stringify(categoria))

            var categoriaLida = null

            this.state.orcamento.categorias.map(async (categoriaBd, index2) => {
                if (categoriaBd.id === categoria.id) {
                    categoriaLida = JSON.parse(JSON.stringify(categoriaBd))
                }
            })

            if (categoriaLida !== null) {
                categoriaNew.meta = categoriaLida.meta
                categoriaNew.metaPercentual = '0.00'
            } else {
                categoriaNew.meta = '0.00'
                categoriaNew.metaPercentual = '0.00'
            }


            categoriaNew.meta = formataDisplayEmReal(categoriaNew.meta)
            categoriaNew.metaPercentual = categoriaNew.metaPercentual.toString().replace('.', ',')

            var subCategoriasNew = []
            categoriaNew.sub_categorias.map(async (subCategoria, index) => {
                var subCategoriaAlt = JSON.parse(JSON.stringify(subCategoria))
                var subCategoriaLida = null
                if (categoriaLida !== null) {
                    categoriaLida.sub_categorias.map(async (subCategoriaBd, index2) => {
                        if (subCategoriaAlt.id === subCategoriaBd.id) {
                            subCategoriaLida = subCategoriaBd
                        }
                    })
                }

                if (subCategoriaLida !== null) {
                    subCategoriaAlt.meta = subCategoriaLida.meta
                    subCategoriaAlt.metaPercentual = '0.00'
                } else {
                    subCategoriaAlt.meta = '0.00'
                    subCategoriaAlt.metaPercentual = '0.00'
                }

                subCategoriaAlt.meta = formataDisplayEmReal(subCategoriaAlt.meta)
                subCategoriaAlt.metaPercentual = subCategoriaAlt.metaPercentual.toString().replace('.', ',')
                subCategoriasNew.push(subCategoriaAlt)
            })

            categoriaNew.sub_categorias = subCategoriasNew

            categoriasOrcamento.push(categoriaNew)

        })



        var orcamentoNew = JSON.parse(JSON.stringify(this.state.orcamento))

        var percentual = ((formataEmRealParaBD(orcamentoNew.despesa_meta) / formataEmRealParaBD(orcamentoNew.receita_meta) * 100)).toFixed(2)

        orcamentoNew.receita_meta = formataDisplayEmReal(orcamentoNew.receita_meta)
        orcamentoNew.despesa_meta = formataDisplayEmReal(orcamentoNew.despesa_meta)

        this.setState({ categoriasOrcamento: categoriasOrcamento, orcamento: orcamentoNew, metaPercentual: percentual })

    }

    possoIncluirMaisOrcamentos() {
        let url = global.url + 'orcamento/possoIncluirMaisOrcamentos/'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ possoIncluirMaisOrcamentos: responseJson.possoIncluirMaisOrcamentos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }


    incluiOrcamento() {

        if (this.state.orcamento.data === null) {
            this.setState({ alerta: true, alertaMsg: 'Data obrigatória' })
            return
        }

        if (formataEmRealParaBD(this.state.orcamento.receita_meta) === 0) {
            this.setState({ alerta: true, alertaMsg: 'Receita obrigatória' })
            return
        }

        if (formataEmRealParaBD(this.state.orcamento.despesa_meta) === 0) {
            this.setState({ alerta: true, alertaMsg: 'Meta de gastos obrigatória' })
            return
        }

        if (this.state.atualizaTudo == null) {
            this.setState({ alerta: true, alertaMsg: 'Abrangência da atualiação é obrigatória' })
            return
        }

        var temMetaCategoriaDebito = false
        var temMetaCategoriaCredito = false

        this.state.categoriasOrcamento.map(async (categoria, index) => {
            if (formataEmRealParaBD(categoria.meta) > 0) {
                if (categoria.tipo === 'debito') {
                    temMetaCategoriaDebito = true
                } else {
                    temMetaCategoriaCredito = true
                }
            }
        })

        // if (temMetaCategoriaDebito === false) {
        //     this.setState({ alerta: true, alertaMsg: 'Você precisa definir uma meta em pelo menos uma categoria de débito' })
        //     return
        // }

        // if (temMetaCategoriaCredito === false) {
        //     this.setState({ alerta: true, alertaMsg: 'Você precisa definir uma meta em pelo menos uma categoria de crédito' })
        //     return
        // }

        this.setState({ pedeConfirmacao: true })

    }

    confirma() {

        this.setState({ pedeConfirmacao: false })

        var orcamento = {
            id: this.state.orcamento.id,
            data: this.state.orcamento.data,
            receita_meta: this.state.orcamento.receita_meta,
            receita_realizada: this.state.orcamento.receita_realizada,
            despesa_meta: this.state.orcamento.despesa_meta,
            despesa_realizada: this.state.orcamento.despesa_realizada,
            categorias: this.state.categoriasOrcamento,
            atualizaTudo: this.state.atualizaTudo
        }

        var url = global.url + 'orcamento/alterar'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(orcamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Alteração concluída' })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

}

export default OrcamentoAlteracao