import React, { Component } from 'react'
import '../usuario/UsuarioNew.css'
import ModalAviso from '../modal/ModalAviso'
import { titleize } from '../../util/Utils'

class UsuarioNew extends Component {
    constructor(props) {
        super(props);
        //this.handleSubmit = this.handleSubmit.bind(this);
        this.handleNomeChange = this.handleNomeChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handlePassword2Change = this.handlePassword2Change.bind(this);
        this.state = {
            nome: undefined,
            email: undefined,
            password: undefined,
            password2: undefined,
            comentarios: null,
            mensagem: undefined,
            hidden: undefined
        };
    }

    incluiUsuario() {
        //e.preventDefault();

        if (this.state.nome === undefined || this.state.password === undefined || this.state.password2 === undefined || this.state.email === undefined) {
            this.setState({ alerta: true, alertaMsg: 'nome, email e senha são obrigatórios' })
            return
        }

        if (this.state.email !== 'rgm1301@gmail.com' &&                  //eu
            this.state.email !== 'rgm1301@hotmail.com' &&                //eu
            this.state.email !== 'murilomuga65@gmail.com' &&             //Muga
            this.state.email !== 'creisjuridico@gmail.com' &&            //Claudia
            this.state.email !== 'grazielareissimplicio@gmail.com' &&    //Graziela
            this.state.email !== 'ca.sarauza@gmail.com' &&               //Camila
            this.state.email !== 'carolinadlmartins@gmail.com' &&        //Carolina
            this.state.email !== 'camila_oliveiraa@live.com' &&          //Camila
            this.state.email !== 'martalmartins@gmail.com' &&            //Marta
            this.state.email !== 'luizdonizeti@gmail.com' &&             //Doni
            this.state.email !== 'vanessagarciadossantos57@gmail.com' && //Vanessa 
            this.state.email !== 'vinicius.garcias@yahoo.com.br' &&      //Vinicius 
            this.state.email !== 'tatifp@yahoo.com.br' &&                //Tati 
            this.state.email !== 'vinicius.marzo@icloud.com' &&          //Vinicius Marzo 
            this.state.email !== 'ernanigs@gmail.com' &&                 //Ernani
            this.state.email !== 'lucianomoriconi@hotmail.com' &&        //Luciano Moricone 
            this.state.email !== 'rgm1301@hotmail.com' &&                //V A G O 
            this.state.email !== 'rgm1301@hotmail.com' &&                //V A G O 
            this.state.email !== 'rgm1301@hotmail.com' &&                //V A G O 
            this.state.email !== 'rgm1301@hotmail.com' &&                //V A G O 
            this.state.email !== 'teste@gmail.com'                       //T E S T E
        ) {
            this.setState({ alerta: true, alertaMsg: 'Sistema em fase de teste, ainda não disponível' })
            this.incluiUsuarioInteressado()
            return
        }

        if (this.state.password !== this.state.password2) {
            this.setState({ alerta: true, alertaMsg: 'Senhas devem ser iguais' })
            return
        }

        if (this.state.email === 'teste@gmail.com') {
            if (this.state.password !== '20210199') {
                this.setState({ alerta: true, alertaMsg: 'Sistema em fase de teste, ainda não disponível' })
                return
            }
        }

        this.setState({ mensagem: undefined });

        let dataToSend = {
            userData: {
                nome: this.state.nome,
                email: this.state.email,
                password: this.state.password,
                comentarios: this.state.comentarios
            }
        }

        let url = global.url + 'user/inclui';

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response.json();
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.props.offLogged()
                } else {
                    this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
                }
            })
    }

    incluiUsuarioInteressado() {

        let dataToSend = {
            userData: {
                nome: this.state.nome,
                email: this.state.email,
            }
        }

        let url = global.url + 'user/incluiInteressado';

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(dataToSend),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                return response.json();
            })
            .then(responseJson => {
            })
    }

    handleNomeChange(e) {

        var nome = titleize(e.target.value)

        this.setState({ nome: nome })
    }

    handleEmailChange(e) {
        this.setState({
            email: e.target.value
        });
    }

    handlePasswordChange(e) {
        this.setState({
            password: e.target.value
        });
    }

    handlePassword2Change(e) {
        this.setState({
            password2: e.target.value
        });
    }

    handleChangeComentarios(e) {
        this.setState({
            comentarios: e.target.value
        });
    }

    render() {
        return [
            this.renderAlert(),
            this.renderComum()
        ]
    }

    renderComum() {
        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Criar um usuário</h5>
                        </div>
                    </div>
                    <div className="form-group m-3">
                        <div className="form-row">
                            <div className="col-md-6">
                                <label for="nome">Nome</label>
                                <input type="text" onChange={this.handleNomeChange} className="form-control text-capitalize" name="nome" id="nome" value={this.state.nome} placeholder="digite seu nome" />
                            </div>
                            <div className="col-md-6">
                                <label for="email">E-mail</label>
                                <input type="email" onChange={this.handleEmailChange} className="form-control" name="email" id="email" value={this.state.email} placeholder="Digite seu email" />
                            </div>
                        </div>
                    </div>
                    <div className="form-group m-3">
                        <div className="form-row">
                            <div className="col-md-6">
                                <label for="password">Senha</label>
                                <input type="password" maxLength="8" onChange={this.handlePasswordChange} className="form-control" name="senha" id="password" value={this.state.password} placeholder="Senha" />
                            </div>
                            <div className="col-md-6">
                                <label for="password">Repita sua senha</label>
                                <input type="password" maxLength="8" onChange={this.handlePassword2Change} className="form-control" name="senha2" id="password2" value={this.state.password2} placeholder="Repita sua senha" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-11">
                        <label className="m-2" for="comment">Comentários</label>
                        <textarea
                            value={this.state.comentarios}
                            className="form-control mt-2 ml-2"
                            rows="2" id="comentarios"
                            onChange={this.handleChangeComentarios.bind(this)}>
                        </textarea>
                    </div>
                    <div class="card-header border">
                        <div className="row ml-2">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => { this.incluiUsuario() }}>Registrar-se</button>
                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.props.offLogged() }}>Página de login</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }
}

export default UsuarioNew