import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ModalAviso from '../modal/ModalAviso'
import ModalConfirma from '../modal/ModalConfirma'
import { titleize } from '../../util/Utils'

var validator = require("email-validator");

class AmigoInclusao extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nome: '',
            email: '',
            data_de_nascimento: null,
            compartilhavel: false,
            dependente_direto_ir: false,
            dependente_na_fonte_ir: false,
            dependente_declaracao_anual_ir: false,
            alerta: false,
            alertaMsg: null,
            confirma: false
        }
    }

    incluiAmigo() {

        var nome = this.state.nome.trim()

        nome = nome.toLowerCase().replace(/(?:^|\s)(?!da|de|do)\S/g, l => l.toUpperCase()) //primeiras letras em maiusculo

        if (nome.length === 0) {
            this.setState({ alerta: true, alertaMsg: 'Nome é obrigatório.' })
            return
        }

        if (nome.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Nome deve ter no mínimo 3 letras' })
            return
        }

        var email = this.state.email

        if (email != null) {
            if (email === '' || ' ' === email.substring(0, 1)) {
                email = null
            }
        }

        if (email != null) {
            if (!validator.validate(email)) {
                this.setState({ alerta: true, alertaMsg: 'Email, se preenchido deve ser válido.' })
                return
            }
        }

        var dataMoment = moment(this.state.data_de_nascimento).format('YYYY-MM-DD')

        if (dataMoment === 'Invalid date') {
            dataMoment = null
        }

        if (dataMoment !== null) {
            if (moment() < moment(this.state.data_de_nascimento)) {
                this.setState({ alerta: true, alertaMsg: 'Data de nascimento invalida' })
                return
            }
        }

        let url = global.url + 'amigo/incluir';

        var amigo = {
            nome: nome,
            email: this.state.email,
            data_de_nascimento: dataMoment,
            dependente_na_fonte_ir: this.state.dependente_na_fonte_ir,
            dependente_declaracao_anual_ir: this.state.dependente_declaracao_anual_ir,
            dependente_direto_ir: this.state.dependente_direto_ir,
            compartilhavel: this.state.compartilhavel
        };

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(amigo)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Inclusão concluida...' })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    handleChangeNome(event) {

        var nome = titleize(event.target.value)

        this.setState({ nome: nome })
    }

    handleChangeEmail(event) {
        this.setState({ email: event.target.value })
    }

    handleChangeDateNiver(date) {
        this.setState({ data_de_nascimento: date });
    }

    handleChange(event) {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const id = target.id;

        this.setState({ [id]: value });
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderConfirmacao(),
            this.renderComum()
        ]

    }


    renderComum() {
        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Inclusão de amigo</h5>
                        </div>
                    </div>

                    <div className="form-group row">
                        <label for="nome" className="col-sm-2 col-form-label ml-2 mt-3">Nome</label>
                        <div className="col-sm-8">
                            <input type="text" className="form-control mt-3" id="nome" onChange={this.handleChangeNome.bind(this)} value={this.state.nome} placeholder="nome" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="email" className="col-sm-2 col-form-label ml-2">Email</label>
                        <div className="col-sm-6">
                            <input type="email" className="form-control" id="email" onChange={this.handleChangeEmail.bind(this)} value={this.state.email} placeholder="Email" />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label for="nascimento" className="col-sm-2 col-form-label ml-2">Nascimento</label>
                        <div className="col-sm-2">
                            <DatePicker
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeNascimento"
                                selected={this.state.data_de_nascimento}
                                onChange={this.handleChangeDateNiver.bind(this)}
                            />
                        </div>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button id="buttonGravar" type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.incluiAmigo() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                        </div>
                    </div>

                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.confirma()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a inclusão deste amigo?" />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

}

export default AmigoInclusao 