import React, { Component } from 'react'
import moment from 'moment'
import { Switch, Route, Link, HashRouter } from 'react-router-dom';
import ModalAviso from '../modal/ModalAviso'
import ModalPreAssinante from '../modal/ModalPreAssinante'
import Categoria from '../categoria/Categoria';
import Construcao from '../_emConstrucao/Construcao';
import Evento from '../evento/Evento';
import Orcamento from '../orcamento/Orcamento';
import Amigo from '../amigo/Amigo';
import Adm from '../adm/Adm';
import Conta from '../conta/Conta';
import Pagamento from '../pagamento/Pagamento';
import Pesquisa from '../pesquisa/Pesquisa';
import Usuario from '../usuario/Usuario';
import Relatorio from '../relatorio/Relatorio';
import Bovespa from '../bovespa/Bovespa';
import Rogar from '../../assets/img/Rogar5.png'
import '../menu/menu.css'
import '../../rgm.css'
class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      usuario: { nome: 'xxx', email: '', status: '' },
      preAssinante: false,
      linkUsuario: <Link to="/" className="nav-link"></Link>,
      pagamentoTextPrimary: '',
      contaTextPrimary: '',
      amigoTextPrimary: '',
      categoriaTextPrimary: '',
      eventoTextPrimary: '',
      orcamentoTextPrimary: '',
      relatorioTextPrimary: 'text-primary',
      euTextPrimary: '',
      admTextPrimary: '',

    }
  }

  componentWillMount() { //antes de montar
    this.acessaUsuario()
  }

  componentDidMount() { //depois de montar
    window.addEventListener('storage', (function (e) {
      this.props.offLogOff()
    }).bind(this))

  }


  acessaUsuario() {
    let url = global.url + 'usuario/consulta'

    var token = localStorage.getItem('EGR_TOKEN')

    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      }
    })
      .then(response => {
        return response.json()
      })
      .then(responseJson => {
        this.obtemFase(responseJson.usuario)
        this.setState({ usuario: responseJson.usuario })
        global.usuarioEmail = responseJson.usuario.email
        if (responseJson.usuario.status === 'preAssinante' ||
          responseJson.usuario.status === 'preAssinanteFaltaPagamento' ||
          responseJson.usuario.status === 'faltaPagamento') {
          this.setState({ preAssinante: true })
        }
      })

  }

  faseAvaliacao() {

    var dias = moment(this.state.usuario.data_fim_assinatura).diff(moment(), 'days');

    this.setState({ alerta: true, alertaMsg: 'Sua avaliação termina em ' + dias + ' dias, espero que você esteja gostando' })

  }

  assinaturaAtiva() {

    var dias = moment(this.state.usuario.data_fim_assinatura).diff(moment(), 'days');

    this.setState({ alerta: true, alertaMsg: 'Sua assinatura termina em ' + dias + ' dias, aproveita sua estadia!' })

  }

  assinar() {
    this.setState({ preAssinante: true })
  }

  atualizaTelaAssinatura() {
    global.telaAssinatura = global.telaAssinatura - 1

    if (global.telaAssinatura > 0) {
      if (this.state.usuario.status === 'preAssinanteFaltaPagamento' || this.state.usuario.status === 'preAssinante' || this.state.usuario.status === 'faltaPagamento') {
        this.setState({ preAssinante: true })
      }
    }
  }

  limpaCor() {

    this.setState({
      pagamentoTextPrimary: '',
      pesquisaTextPrimary: '',
      contaTextPrimary: '',
      amigoTextPrimary: '',
      categoriaTextPrimary: '',
      eventoTextPrimary: '',
      orcamentoTextPrimary: '',
      relatorioTextPrimary: '',
      bovespaTextPrimary: '',
      euTextPrimary: '',
      admTextPrimary: '',
    })



  }

  pagamentosPrincipal() {
    this.limpaCor()
    this.setState({ pagamentoTextPrimary: 'text-primary' })
    this.atualizaTelaAssinatura()
    global.pagamentosPrincipal()
  }

  pesquisaPrincipal() {
    this.limpaCor()
    this.setState({ pesquisaTextPrimary: 'text-primary' })
    this.atualizaTelaAssinatura()
    global.pesquisasPrincipal()
  }

  contasPrincipal() {
    this.limpaCor()
    this.setState({ contaTextPrimary: 'text-primary' })
    this.atualizaTelaAssinatura()
    global.contasPrincipal()
  }

  amigosPrincipal() {
    this.limpaCor()
    this.setState({ amigoTextPrimary: 'text-primary' })
    this.atualizaTelaAssinatura()
    global.amigosPrincipal()
  }

  categoriasPrincipal() {
    this.limpaCor()
    this.setState({
      categoriaTextPrimary: 'text-primary'
    })
    this.atualizaTelaAssinatura()
    global.categoriasPrincipal()
  }

  eventosPrincipal() {
    this.limpaCor()
    this.setState({
      eventoTextPrimary: 'text-primary'
    })
    this.atualizaTelaAssinatura()
    global.eventosPrincipal()
  }

  orcamentosPrincipal() {
    this.limpaCor()
    this.setState({ orcamentoTextPrimary: 'text-primary' })
    this.atualizaTelaAssinatura()
    global.orcamentosPrincipal()
  }

  relatoriosPrincipal() {
    this.limpaCor()
    this.setState({ relatorioTextPrimary: 'text-primary' })
    this.atualizaTelaAssinatura()
    global.relatoriosPrincipal()
  }

  bovespaPrincipal() {
    this.limpaCor()
    this.setState({ bovespaTextPrimary: 'text-primary' })
    this.atualizaTelaAssinatura()
    global.relatoriosPrincipal()
  }

  euPrincipal() {
    this.limpaCor()
    this.setState({ euTextPrimary: 'text-primary' })
    this.atualizaTelaAssinatura()
    global.relatoriosPrincipal()
  }

  admPrincipal() {
    this.limpaCor()
    this.setState({ admTextPrimary: 'text-primary' })
  }

  obtemFase(usuario) {
    if (usuario.status === 'experimentando') {
      //this.setState({linkUsuario:  <Link to="/" className="nav-link text-success">Em fase de avaliação, obrigado!!</Link>})
      this.setState({ linkUsuario: <button type="button" className="btn btn-outline-primary btn-sm m-2" onClick={() => { this.faseAvaliacao() }}>Em fase de avaliação, obrigado!!</button> })
    }

    if (usuario.status === 'preAssinante' || usuario.status === 'preAssinanteFaltaPagamento') {
      this.setState({ linkUsuario: <button type="button" className="btn btn-outline-warning btn-sm m-2" onClick={() => { this.assinar() }}>Fase de avaliação concluída, clique aqui!</button> })
    }

    if (usuario.status === 'assinante') {
      this.setState({ linkUsuario: <button type="button" className="btn btn-outline-primary btn-sm m-2" onClick={() => { this.assinaturaAtiva() }}>Assinatura ativa</button> })
    }

    if (usuario.status === 'desativado') {
      this.setState({ linkUsuario: <Link to="/" className="nav-link text-danger">Assinatura congelada, volte a usar!</Link> })
    }

    if (usuario.status === 'faltaPagamento') {
      this.setState({ linkUsuario: <button type="button" className="btn btn-outline-danger btn-sm m-2" onClick={() => { this.assinar() }}>Verifique sua assinatura.</button> })
    }

  }

  //https://blackrockdigital.github.io/startbootstrap-simple-sidebar/#

  render() {
    return [
      this.renderAlert(),
      this.renderPreAssinante(),
      this.renderPrincipal()
    ]

  }

  renderPrincipal() {
    return (
      <div>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <a className="navbar-brand" href="#"><img src={Rogar} alt="10px" /></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
            <div className="text-center bg-light">
              <img src={Rogar} alt="10px" />
            </div>
          </button>

          <div className="collapse navbar-collapse" id="navbarsExampleDefault">
            <HashRouter>
              <ul className="navbar-nav mr-auto">
                <li className="nav-item active text-primary align-middle" onClick={() => { this.pagamentosPrincipal() }}>
                  <Link to="/pagamento" className={"nav-link " + this.state.pagamentoTextPrimary}>Pagamentos</Link>
                </li>
                <li className="nav-item active text-primary align-middle" onClick={() => { this.pesquisaPrincipal() }}>
                  <Link to="/pesquisa" className={"nav-link " + this.state.pesquisaTextPrimary}>Pesquisa</Link>
                </li>
                <li className="nav-item active align-middle" onClick={() => { this.contasPrincipal() }}>
                  <Link id="clickContaMenu" to="/conta" className={"nav-link " + this.state.contaTextPrimary}>Conta</Link>
                </li>
                <li className="nav-item active text-primary align-middle" onClick={() => { this.amigosPrincipal() }}>
                  <Link id="clickAmigosMenu" to="/amigo" className={"nav-link " + this.state.amigoTextPrimary}>Amigo</Link>
                </li>
                <li className="nav-item active text-primary align-middle" onClick={() => { this.categoriasPrincipal() }}>
                  <Link to="/categoria" className={"nav-link " + this.state.categoriaTextPrimary}>Categoria</Link>
                </li>
                <li className="nav-item active text-primary align-middle" onClick={() => { this.eventosPrincipal() }}>
                  <Link to="/evento" className={"nav-link " + this.state.eventoTextPrimary}>Evento</Link>
                </li>
                <li className="nav-item active text-primary align-middle" onClick={() => { this.orcamentosPrincipal() }}>
                  <Link to="/orcamento" className={"nav-link " + this.state.orcamentoTextPrimary}>Orçamento</Link>
                </li>
                <li className="nav-item active text-primary align-middle" onClick={() => { this.relatoriosPrincipal() }}>
                  <Link to="/relatorio" className={"nav-link " + this.state.relatorioTextPrimary}>Relatórios</Link>
                </li>
                {this.state.usuario.email === 'rgm1301@gmail.com' &&
                  <li className="nav-item active text-primary align-middle" onClick={() => { this.bovespaPrincipal() }}>
                    <Link to="/bovespa" className={"nav-link " + this.state.bovespaTextPrimary}>Bovespa</Link>
                  </li>
                }
                <li className="nav-item active text-primary align-middle" onClick={() => { this.euPrincipal() }}>
                  <Link to="/usuario" className={"nav-link " + this.state.euTextPrimary}>{this.state.usuario.nome}</Link>
                </li>
                {this.state.usuario.email === 'rgm1301@gmail.com' &&
                  <li className="nav-item active text-primary align-middle" onClick={() => { this.admPrincipal() }}>
                    <Link to="/adm" className={"nav-link " + this.state.admTextPrimary}>Administrativo</Link>
                  </li>
                }
                <li className="nav-item active">
                  {this.state.linkUsuario}
                </li>
              </ul>
            </HashRouter>
            <form className="form-inline my-2 my-lg-0">
              <button className="btn btn-primary" onClick={() => { this.props.offLogOff() }} >Sair</button>
            </form>
          </div>
        </nav>
        <div className="container-fluid w-100">
          <h1>.</h1>
          <HashRouter>
            <Switch>
              <Route path="/construcao" component={Construcao} />
              <Route path="/categoria" component={Categoria} />
              <Route path="/evento" component={Evento} />
              <Route path="/orcamento" component={Orcamento} />
              <Route path="/amigo" component={Amigo} />
              <Route path="/adm" component={Adm} />
              <Route path="/conta" component={Conta} />
              <Route path="/pagamento" component={Pagamento} />
              <Route path="/pesquisa" component={Pesquisa} />
              <Route path="/usuario" render={props => <Usuario logOff={() => { this.props.offLogOff() }} />} />
              <Route path="/relatorio" component={Relatorio} />
              <Route path="/bovespa" component={Bovespa} />
            </Switch>
          </HashRouter>
        </div>
      </div>
    )
  }

  renderAlert() {
    if (this.state.alerta) {
      return (
        <>
          <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
        </>
      )
    }
  }

  renderPreAssinante() {
    if (this.state.preAssinante) {
      return (
        <>
          <ModalPreAssinante aviso={this.state.alertaMsg} status={this.state.usuario.status} onClose={() => this.setState({ preAssinante: false })} />
        </>
      )
    }
  }

}

export default Menu