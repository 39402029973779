import React, { Component } from 'react'
import AdmLista from '../adm/AdmLista'
import AdmAlteracao from '../adm/AdmAlteracao'
import '../../rgm.css'

class Adm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            admLista: true,
            admAlteracao: false,

        }
    }

    admListaTrue() {
        this.setFalseAll()
        this.setState({ admLista: true })
    }

    admAlteracaoTrue(usuario) {
        this.setFalseAll()
        this.setState({ admAlteracao: true, usuario: usuario })
    }

    setFalseAll() {
        this.setState({
            admLista: false,
            admAlteracao: false
        })
    }

    render() {

        if (this.state.admLista === true) {
            return (<
                AdmLista
                admAlteracao={(usuario) => { this.admAlteracaoTrue(usuario) }}
            />
            )
        }

        if (this.state.admAlteracao === true) {
            return (<
                AdmAlteracao
                usuario={this.state.usuario}
                desconecta={() => { this.props.logOff() }}
                volta={(usuario) => { this.admConsultaTrue(usuario) }}
                admListaTrue={() => { this.admListaTrue() }}

            />
            )
        }

        if (this.state.admLista === false &&
            this.state.admAlteracao === false
        ) {
            return null
        }

    }

}

export default Adm