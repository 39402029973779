import React, { Component } from 'react'
import ModalAviso from '../modal/ModalAviso'
class UsuarioAlteracaoSenha extends Component {
    constructor(props) {
        super(props)
        this.state = {
            password: '',
            passwordNew: '',
            passwordnew2: ''
        }
    }

    componentWillMount() { }

    alteraSenha() {
        var mensagem = null

        if (this.state.password == null || this.state.password === '') {
            mensagem = 'Senha atual é obrigatória'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        if (this.state.passwordNew == null || this.state.passwordNew === '') {
            mensagem = 'Senha nova é obrigatória'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        if (this.state.passwordNew == null || this.state.passwordNew2 === '') {
            mensagem = 'Repetição da senha nova é obrigatória'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }

        if (this.state.passwordNew !== this.state.passwordNew2) {
            mensagem = 'Senha nova e repetição da senha nova devem ser iguais'
            this.setState({ alerta: true, alertaMsg: mensagem })
            return
        }



        let senhas = {
            password: this.state.password,
            passwordNew: this.state.passwordNew,
            passwordNew2: this.state.passwordNew2
        }

        let url = global.url + 'usuario/alteraSenha'

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(senhas)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ alertaSaida: true, alertaMsg: 'Mudança de senha concluída!!' })
            } else {
                this.setState({ alerta: true, alertaMsg: responseJson.mensagem })
            }
        })
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value })
    }

    handlePasswordChangeNew(event) {
        this.setState({ passwordNew: event.target.value })
    }

    handlePasswordChangeNew2(event) {
        this.setState({ passwordNew2: event.target.value })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderComum()
        ]
    }

    renderComum() {

        return (
            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border m-2  text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Alteração de senha</h5>
                        </div>
                    </div>
                    <div className="form-group m-3">
                        <div className="form-row">
                            <div className="col-md-6">
                                <label for="password">Senha atual</label>
                                <input type="password" maxlength="8" onChange={this.handlePasswordChange.bind(this)} className="form-control" name="password" id="password" value={this.state.password} placeholder="Senha" />
                                senha aberta: <span>{this.senha}</span>
                            </div>
                        </div>
                    </div>
                    <div className="form-group m-3">
                        <div className="form-row">
                            <div className="col-md-6">
                                <label for="passwordNew">Nova senha</label>
                                <input type="password" maxlength="8" onChange={this.handlePasswordChangeNew.bind(this)} className="form-control" name="passwordNew" id="passwordNew" value={this.state.passwordNew} placeholder="Senha" />
                                senha aberta:  <span>{this.senhaNew}</span>
                            </div>
                            <div className="col-md-6">
                                <label for="passwordNew2">Repita sua nova senha</label>
                                <input type="password" maxlength="8" onChange={this.handlePasswordChangeNew2.bind(this)} className="form-control" name="passwordNew2" id="passwordNew2" value={this.state.passwordNew2} placeholder="Repita sua senha" />
                                senha aberta:  <span>{this.senhaNew2}</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="card-header border">
                        <div className="row">
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.alteraSenha() }}>Salvar</button>
                            <button type="button" className="btn btn-primary btn-sm m-2" onClick={() => { this.props.volta() }}>Voltar</button>
                        </div>
                    </div>
                </form >
            </div >
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

}
export default UsuarioAlteracaoSenha