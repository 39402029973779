import React, { Component } from 'react'
import Select from 'react-select'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'
import 'moment/locale/pt-br';
import { formataDisplayEmReal } from '../../util/Utils'
import PagamentoCategoria from '../pagamento/PagamentoCategoria'
import ModalAlteracaoContaFatura from '../modal/ModalAlteracaoContaFatura'
import ModalConfirma from '../modal/ModalConfirma'
import ModalAviso from '../modal/ModalAviso'
import { extensoFrequencia, extensoTipo } from '../../util/extenso'
import fileSize from 'filesize';
import { Line } from "react-chartjs-2";

const dataModelo = {
    labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    datasets: [
        {
            label: 'Valor',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)'
        },
        {
            label: 'Valor médio',
            data: [null, null, null, null, null, null, null, null, null, null, null, null],
            fill: false,
            borderColor: '#742774'
        }
    ]
}

var data = JSON.parse(JSON.stringify(dataModelo))

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}

var consideraNosRelatorios = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' }
]

var tipos = [
    { value: 'debito', label: 'Débito' },
    { value: 'credito', label: 'Crédito' }
]

var categoriaInicial = {
    id: null,
    categoria_id: null,
    sub_categoria_id: null,
    amigo_id: null,
    evento_id: null,
    descricao: null,
    tipo: null,
    comentarios: null,
    valor: 'R$ 0,00',
    entra_nas_estatisticas: true,
}

class PagamentoConsulta extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pagamento: this.props.pagamento,
            amigos: [],
            eventos: [],
            categoriasTodas: [],
            categorias: [],
            subCategorias: [],
            categoria: JSON.parse(JSON.stringify(categoriaInicial)),
            categoriaLista: false,
            contas: [],
            conta: { tipo_conta: null },
            faturas: [],
            pagamentosReembolsar: [],
            alteraContaFatura: false,
            botaoAlterarContaFatura: null,
            botaoInterromper: null,
            parcelamento: null,
            transacao: null,
            pagamentos: [],
            literalTransferencia: '*****',
            corTransf: '',
            anexos: [],
            dadosGrafico: JSON.parse(JSON.stringify(dataModelo))
        }
    }

    componentWillMount() {
        data = JSON.parse(JSON.stringify(dataModelo))
        var anexos = this.state.pagamento.transacao.anexos.concat(this.state.pagamento.anexos)
        this.setState({ anexos: anexos })

        this.listaContas()
        //this.listaPagamentosDeUmaTransacao() //esta depois que carrega a conta.
        this.buscaPagamentosParaSeremReembolsados()

        if (this.state.pagamento.categorias.length === 1) {
            this.setState({ categoria: this.state.pagamento.categorias[0] })
        }

        this.listaCategorias()
        this.listaAmigos()
        this.listaEventos()

    }

    inicializaMatrizGrafico() {
        var dataInicial = moment().startOf('year').format('YYYY-MM-DD')
        var dataFinal = moment().format('YYYY-MM-01')

        for (var i = 0; dataInicial <= dataFinal; i++) {
            data.labels[i] = dataInicial
            dataInicial = moment(dataInicial).add(1, 'month').format('YYYY-MM-DD')
        }
    }

    carregaDadosGrafico(pagamentos) {  //aqui

        this.inicializaMatrizGrafico()

        var qtdSaldoMedio = 0
        var valSaldoMedio = 0

        var indexInicioMedia = -1

        pagamentos.map(async (pagamento, index) => {
            var indexGrafico = data.labels.findIndex(function (data) { return data === moment(pagamento.data_de_vencimento).format('YYYY-MM-01') })

            if (indexGrafico > -1) {
                if (indexInicioMedia === -1) {
                    indexInicioMedia = indexGrafico
                }
                qtdSaldoMedio++
                data.datasets[0].data[indexGrafico] = parseFloat(pagamento.valor).toFixed(2)
                valSaldoMedio = valSaldoMedio + parseFloat(pagamento.valor)
            }
        })

        valSaldoMedio = valSaldoMedio / qtdSaldoMedio

        valSaldoMedio = parseFloat(valSaldoMedio).toFixed(2)

        for (var i = 0; i !== qtdSaldoMedio; i++) {
            data.datasets[1].data[indexInicioMedia] = valSaldoMedio
            indexInicioMedia++
        }

        for (i = 0; i < 12; i++) {
            data.labels[i] = moment(data.labels[i]).format('MMM')
        }

        //alert('pagamentos2222 ==>' + data.datasets[0].data[0])

        this.setState({ dadosGrafico: data }, () => { this.render() })

    }

    habilitaAlteracao() {

        var botaoInterromperRecorrente =
            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.interromperRecorrente(this.state.pagamento) }}>Interromper recorrente</button>

        var botaoInterromperMensal =
            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.interromperParcelado(this.state.pagamento) }}>Interromper parcelado</button>

        var botaoAlterar = <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.pagamentoAlteracao(this.state.pagamento) }}>Alterar</button>

        var botaoAlterarContaFatura = null

        if (this.state.pagamento.frequencia === 'unica') {

            if (this.state.pagamento.fatura_id !== null && this.state.pagamento.fatura.status === 'emAberto') {
                botaoAlterarContaFatura =
                    <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.alterarContaFatura(this.state.pagamento) }}>Alterar conta/fatura</button>
            }

            this.setState({ botaoAlterarContaFatura: botaoAlterarContaFatura })
        }

        if (this.state.pagamento.frequencia === 'mensal' || this.state.pagamento.frequencia === 'recorrente') {

            if (this.state.pagamento.fatura_id !== null && this.state.pagamento.fatura.status === 'emAberto') {
                botaoAlterarContaFatura =
                    <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.alterarContaFatura(this.state.pagamento) }}>Alterar conta/fatura</button>
            }

            var literal = null

            if (this.state.pagamento.frequencia === 'recorrente') {
                var ate = '999'
                if (this.state.pagamento.transacao.numero_de_parcelas !== null) {
                    ate = ('000' + this.state.pagamento.transacao.numero_de_parcelas).slice(-3)
                }
                literal = ('000' + this.state.pagamento.parcela).slice(-3) + '/' + ate
            } else {
                if (this.state.pagamento.transacao.numero_de_parcelas === null) {
                    literal = ''
                } else {
                    literal = ('000' + this.state.pagamento.parcela).slice(-3) + '/' + ('000' + this.state.pagamento.transacao.numero_de_parcelas).slice(-3)
                }
            }

            if (this.state.pagamento.sub_categoria_nome_interno === 'saltado') {
                literal = literal + ' *** ESTE PAGAMENTO FOI SALTADO ***'
            }

            if (this.podeAlterar(this.state.pagamento) === false) {
                this.setState({ botaoAlterar: null, botaoInterromper: null, botaoAlterarContaFatura: null })
            } else {
                var botaoInterromper = null

                if (this.state.pagamento.frequencia === 'mensal') {
                    botaoInterromper = botaoInterromperMensal
                } else {
                    botaoInterromper = botaoInterromperRecorrente
                }

                if (this.state.pagamento.fatura_id === null && this.state.pagamento.status === 'liquidado') {
                    botaoInterromper = null
                }

                this.setState({ botaoAlterar: botaoAlterar, botaoInterromper: botaoInterromper, botaoAlterarContaFatura: botaoAlterarContaFatura })
            }

            var parcelamento =
                <div className="row ml-3 mb-2">
                    <h6 className="ml-2">Parcela {literal}</h6>
                </div>
            this.setState({ parcelamento: parcelamento })
        } else {
            this.setState({ botaoAlterar: botaoAlterar })
        }

        if (typeof this.props.pagamentoAlteracao !== 'function') {
            this.setState({ botaoAlterar: null })
        }

    }

    podeAlterar(pagamento) {

        if (this.state.pagamento.fatura_id !== null) {
            return this.podeAlterarDentroDaFatura(pagamento)
        } else {
            return this.podeAlterarForaDeFatura(pagamento)
        }
    }

    podeAlterarDentroDaFatura(pagamento) {

        if (pagamento.fatura.status === 'liquidado') {
            return false
        }

        if (pagamento.parcela === 1) {
            return true
        }

        var parcela = (pagamento.parcela - 1)

        var retorno = false

        console.log('this.state.transacao')
        console.log(this.state.transacao)

        this.state.transacao.pagamentos.map(async (pagamento, index) => {
            if (pagamento.parcela === parcela) {
                if (pagamento.fatura !== null) {
                    if (pagamento.fatura.status === 'liquidado') {
                        retorno = true
                    }
                } else {
                    if (pagamento.status === 'liquidado') {
                        retorno = true
                    }
                }
            }
        })

        return retorno

    }

    podeAlterarForaDeFatura(pagamento) {

        var parcela = 0

        if (pagamento.status === 'liquidado') {
            parcela = (pagamento.parcela + 1)
            this.state.transacao.pagamentos.map(async (pagamento, index) => {
                if (pagamento.parcela === parcela) {
                    if (pagamento.status === 'liquidado') {
                        return false
                    } else {
                        return true
                    }
                }
            })
        } else {
            parcela = (pagamento.transacao.ultima_parcela_paga + 1)
            if (parcela === pagamento.parcela) {
                return true
            } else {
                return false
            }
        }
    }

    listaPagamentosDeUmaTransacao() {
        let url = global.url + 'pagamento/listaPagamentosDeUmaTransacao/' + this.state.pagamento.transacao_id

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ transacao: responseJson.transacao }, () => {
                        this.habilitaAlteracao()
                        this.ajustaListaPgto(responseJson.transacao.pagamentos)
                    })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    ajustaListaPgto(pagamentos) {

        var pagamentosSort = pagamentos.sort((a, b) => {
            if (a.parcela < b.parcela) {
                return -1
            } else {
                if (a.parcela > b.parcela) {
                    return 1
                } else {
                    return 0
                }
            }
        })

        this.carregaDadosGrafico(pagamentosSort)

        this.setState({ pagamentos: pagamentosSort })

        if (this.state.pagamento.sub_categoria_nome_interno === 'transferencia') {

            var indDeb = 0
            var indCred = 0

            if (pagamentos[0].tipo === 'debito') {
                indDeb = 0
            }

            if (pagamentos[1].tipo === 'debito') {
                indDeb = 1
            }

            if (pagamentos[0].tipo === 'credito') {
                indCred = 0
            }

            if (pagamentos[1].tipo === 'credito') {
                indCred = 1
            }


            if (this.state.pagamento.tipo === 'debito') {
                var contaCred = this.state.contas.filter(option => option.id === pagamentos[indCred].conta_id)
                if (contaCred.length > 0) {
                    this.setState({ literalTransferencia: 'Valor transferido para conta: ' + contaCred[0].nome, corTransf: 'text-danger' })
                }
            }

            if (this.state.pagamento.tipo === 'credito') {
                var contaDeb = this.state.contas.filter(option => option.id === pagamentos[indDeb].conta_id)
                if (contaDeb.length > 0) {
                    this.setState({ literalTransferencia: 'Valor recebido da conta: ' + contaDeb[0].nome, corTransf: 'text-primary' })
                }
            }

        }


    }


    avancado() {
        this.setState({ categoriaLista: true })
    }

    voltaDaLista(pagamento) {
        this.setState({ pagamento: pagamento, categoriaLista: false })

        if (this.state.pagamento.categorias.length !== 1) {
            return
        }

        if (this.state.pagamento.categorias.length === 1) {
            const categoriaCPY = this.state.pagamento.categorias[0]
            this.setState({ categoria: categoriaCPY })
        }

        const categoria_id = this.state.pagamento.categorias[0].categoria_id

        const categoria = this.state.categorias.find(function (obj) { return obj.id === categoria_id })

        this.setState({ subCategorias: categoria.sub_categorias })

    }

    volta() {

        if (typeof this.props.voltaParaExtratoFatura === 'function') {
            this.props.voltaParaExtratoFatura()
        } else {
            if (typeof this.props.voltaParaExtratoConta === 'function') {
                this.props.voltaParaExtratoConta()
            } else {
                if (typeof this.props.voltaParaExtratoFaturaVindaDoPagamento === 'function') {
                    this.props.voltaParaExtratoFaturaVindaDoPagamento(this.state.pagamento)
                } else {
                    this.props.volta()
                }
            }
        }
    }

    pagamentoAlteracao(pagamento) {

        if (pagamento.fatura_id === null || pagamento.fatura.status === 'emAberto') {
            this.props.pagamentoAlteracao(pagamento)
        } else {
            this.setState({ alerta: true, alertaMsg: 'Fatura fechada, não pode ser alterada' })
        }


    }

    interromperRecorrente(pagamento) {
        this.setState({ pedeConfirmacao: true })

    }

    interromperParcelado(pagamento) {
        this.setState({ pedeConfirmacao: true })
    }

    alterarContaFatura(pagamento) {
        this.setState({ alteraContaFatura: true })
    }


    render() {

        if (this.state.pagamento.sub_categoria_nome_interno === 'transferencia') {
            return [
                this.renderAlertaSaida(),
                this.renderTransferencia()
            ]
        } else {
            if (this.state.categoriaLista === true) {
                return [this.renderCategoria()]
            } else {
                return [
                    this.renderConfirmacao(),
                    this.renderAlertaSaida(),
                    this.renderModalAlteracaoContaFatura(),
                    this.renderComum(),
                    this.renderAnexos(),
                    this.renderCategoriaLista(),
                    this.renderGraficoGeral(),
                    this.renderLista()
                ]
            }
        }
    }

    renderTransferencia() {

        return (

            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row m-2">
                            <h5>Consulta de tranferencia</h5>
                        </div>
                    </div>

                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-2">
                            <label for="tipo" className="">Tipo</label>
                            <Select
                                isDisabled='true'
                                id="tipo"
                                name="tipo"
                                value={tipos.filter(option => option.value === this.state.pagamento.tipo)}
                                options={tipos}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label for="valor">Valor</label>
                            <input
                                readOnly="true"
                                value={formataDisplayEmReal(this.state.pagamento.valor)}
                                type="text"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                        <div className="col-md-2">
                            <label className="" >Vencimento</label>
                            <DatePicker
                                disabled
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeVencimento"
                                selected={moment(this.state.pagamento.data_de_vencimento).toDate()}
                            />
                        </div>
                        <div className="form-group col-md-5">
                            <label for="descricao">Descrição</label>
                            <input
                                readOnly="true"
                                value={this.state.pagamento.descricao}
                                type="text"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        <div className="form-group col-md-4">
                            <label for="conta" className="">Conta</label>
                            <Select
                                isDisabled="true"
                                id="conta"
                                name="conta"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                selectedValue={this.state.conta_id}
                                value={this.state.contas.filter(option => option.id === this.state.pagamento.conta_id)}
                                options={this.state.contas}
                            />
                        </div>
                    </div>

                    <div className="row ml-3 mb-2">
                        <h6 className={"ml-2 font-weight-bold " + this.state.corTransf}>{this.state.literalTransferencia}</h6>
                    </div>

                    <div class="form-group col-md-11">
                        <label className="m-2" for="comment">Comentários</label>
                        <textarea
                            readOnly="true"
                            value={this.state.pagamento.comentarios}
                            className="form-control mt-2 ml-2"
                            rows="2" id="comum:comentarios">
                        </textarea>
                    </div>
                    <div class="card-header border">
                        <div className="row ml-2">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => { this.volta() }}>Voltar</button>
                            <button type="button" className="btn btn-primary btn-sm ml-2" onClick={() => { this.deletarTransferencia() }}>Excluir Transferencia</button>
                        </div>
                    </div>
                </form>
            </div >
        )

    }

    renderCategoria() {
        return (
            <
                PagamentoCategoria
                pagamento={this.state.pagamento}
                operacao="consulta"
                volta={(pagamento) => { this.voltaDaLista(pagamento) }}
            />
        )
    }
    renderModalAlteracaoContaFatura() {
        if (this.state.alteraContaFatura === true) {
            return (
                <ModalAlteracaoContaFatura pagamento={this.state.pagamento} volta={() => { this.volta() }} cancela={() => { this.setState({ alteraContaFatura: false }) }} />
            )
        }

    }

    renderComum() {

        var mostraAmigo = true

        if (this.state.amigos.length === 1) {
            mostraAmigo = false
        }

        if (this.state.pagamento.categorias.length !== 1) {
            mostraAmigo = false
        }

        if (this.state.pagamento.sub_categoria_nome_interno !== 'saltado') {
            if (this.state.pagamento.categorias[0].amigo_id === null) {
                mostraAmigo = false
            }
        }

        var mostrarEvento = true

        if (this.state.eventos.length === 1) {
            mostrarEvento = false
        }

        if (this.state.pagamento.categorias.length !== 1) {
            mostrarEvento = false
        }

        if (this.state.pagamento.sub_categoria_nome_interno !== 'saltado') {
            if (this.state.pagamento.categorias[0].evento_id === null) {
                mostrarEvento = false
            }
        }
        var mostrarEntraNosRelatorios = true

        if (this.state.pagamento.categorias.length !== 1) {
            mostrarEntraNosRelatorios = false
        }

        var linhaReembolso = null

        if (this.state.pagamento.tipo === 'debito') {
            linhaReembolso =
                <div className="form-check">
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="receberei_reembolso"
                            checked={this.state.pagamento.receberei_reembolso}></input>
                        Este débito será reembolsado
                    </label>
                </div>
        }

        linhaReembolso = null

        if (this.state.pagamento.tipo === 'credito') {
            linhaReembolso =
                <div className="form-check">
                    <label className="form-check-label">
                        <input
                            type="checkbox"
                            className="form-check-input"
                            id="pagarei_reembolso"
                            checked={this.state.pagamento.pagarei_reembolso}></input>Este crédito vai pagar um reembolso</label>
                </div>


        }

        if (this.state.pagamento.frequencia !== 'unica') {
            linhaReembolso = null
        }

        var corUnica = 'btn-light'
        var corMensal = 'btn-light'
        var corRecorrente = 'btn-light'

        if (this.state.pagamento.frequencia === 'unica') {
            corUnica = 'btn-danger'
            corMensal = 'btn-light'
            corRecorrente = 'btn-light'
        }

        if (this.state.pagamento.frequencia === 'mensal') {
            corUnica = 'btn-light'
            corMensal = 'btn-danger'
            corRecorrente = 'btn-light'
        }

        if (this.state.pagamento.frequencia === 'recorrente') {
            corUnica = 'btn-light'
            corMensal = 'btn-light'
            corRecorrente = 'btn-danger'
        }

        //Input da parcela
        var inputParcela = null
        var inputParcelaNum = null

        var inputParcelaPreenchido =
            <div className="form-group col-md-1">
                <label for="parcelas">Parcelas</label>
                <input
                    readOnly="true"
                    value={this.state.pagamento.transacao.numero_de_parcelas}
                    type="parcelas"
                    className="form-control"
                    id="parcelas"
                    placeholder="Parcelas" />
            </div>

        var inputParcelaNumero =
            <div className="form-group col-md-1">
                <label for="parcela">Parcela</label>
                <input
                    readOnly="true"
                    value={this.state.pagamento.parcela}
                    type="text"
                    className="form-control"
                    id="parcela"
                    placeholder="Parcela" />
            </div>

        if (this.state.pagamento.frequencia === 'mensal') {
            inputParcelaNum = inputParcelaNumero
            inputParcela = inputParcelaPreenchido
        } else {
            inputParcelaNum = null
            inputParcela = null
        }

        //input fatura
        var inputFatura = null

        if (this.state.pagamento.fatura_id !== null) {
            var inputFaturaPreenchido =
                <div className="form-group col-md-2">
                    <label for="fatura" className="">Fatura</label>
                    <input
                        readOnly="true"
                        value={moment(this.state.pagamento.fatura.fatura).format('MM/YYYY')}
                        type="text"
                        className="form-control"
                        id="fatura"
                        placeholder="Fatura" />
                </div>
        }


        if (this.state.pagamento.fatura_id !== null) {
            inputFatura = inputFaturaPreenchido
        } else {
            inputFatura = null
        }

        var inputCategoria =
            <div className="form-group col-md-4">
                <label for="categoria" className="">Categoria</label>
                <Select
                    isDisabled="true"
                    id="categoria"
                    name="categoria"
                    getOptionLabel={({ nome }) => nome}
                    getOptionValue={({ id }) => id}
                    value={this.state.categorias.filter(option => option.id === this.state.categoria.categoria_id)}
                    options={this.state.categorias}
                />
            </div>

        var inputSubCategoria =
            <div className="form-group col-md-4">
                <label for="subCategoria" className="">Subcategoria</label>
                <Select
                    isDisabled="true"
                    id="subCategoria"
                    name="subCategoria"
                    getOptionLabel={({ nome }) => nome}
                    getOptionValue={({ id }) => id}
                    value={this.state.subCategorias.filter(option => option.id === this.state.categoria.sub_categoria_id)}
                    options={this.state.subCategorias}
                />
            </div>



        if (this.state.pagamento.categorias !== undefined && this.state.pagamento.categorias.length > 1) {
            inputCategoria =
                <div className="form-group col-md-4">
                    <label for="valor">Categoria</label>
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Usar avançado" />
                </div>

            inputSubCategoria =
                <div className="form-group col-md-4">
                    <label for="valor">Subcategoria</label>
                    <input
                        disabled
                        type="text"
                        className="form-control"
                        id=""
                        placeholder="Usar avançado" />
                </div>

        }

        var cabecPagamentoIdReembolso = null

        var mostraPagamentoIdReembolso = false

        if (this.state.pagamento.pagarei_reembolso === true) {
            cabecPagamentoIdReembolso = 'Pagamento a ser reembolsado'
            mostraPagamentoIdReembolso = true
        }

        if (this.state.pagamento.receberei_reembolso === true && this.state.pagamento.pagamento_id_reembolso !== null) {
            cabecPagamentoIdReembolso = 'Reembolsado por este crédito'
            mostraPagamentoIdReembolso = true
        }

        //fim input variaveis

        return (

            <div className="container w-100 mt-5"    >
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Consulta de pagamento</h5>
                        </div>
                    </div>

                    <div className="ml-3 form-row mt-2">
                        <div className="form-group col-md-2">
                            <label for="tipo" className="">Tipo</label>
                            <Select
                                isDisabled='true'
                                id="tipo"
                                name="tipo"
                                value={tipos.filter(option => option.value === this.state.pagamento.tipo)}
                                options={tipos}
                            />
                        </div>
                        <div className="form-group col-md-2">
                            <label for="valor">Valor</label>
                            <input
                                readOnly="true"
                                value={formataDisplayEmReal(this.state.pagamento.valor)}
                                type="text"
                                className="form-control"
                                id="valor"
                                placeholder="Nome" />
                        </div>
                        <div className="col-md-2">
                            <label className="" >Vencimento</label>
                            <DatePicker
                                disabled
                                className="form-control text-center"
                                dateFormat="dd/MM/yyyy"
                                id="dataDeVencimento"
                                selected={moment(this.state.pagamento.data_de_vencimento).toDate()}
                            />
                        </div>
                        <div className="form-group col-md-5">
                            <label for="descricao">Descrição</label>
                            <input
                                readOnly="true"
                                value={this.state.pagamento.descricao}
                                type="text"
                                className="form-control"
                                id="descricao"
                                placeholder="Descrição" />
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        {inputCategoria}
                        {inputSubCategoria}
                        <div className="form-group col-md-3">
                            <label className="" >Avançado</label>
                            <div>
                                <button id="unica" type="button" className={"btn " + (corUnica) + " mr-1"} onClick={() => { this.avancado() }} ><i class="fa fa-plus" aria-hidden="true"></i>&nbsp;Opções</button>
                            </div>
                        </div>
                    </div>
                    <div className="ml-3 form-row">
                        {mostraAmigo === true &&
                            <div className="form-group col-md-4">
                                <label for="amigo" className="">Amigo</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled="true"
                                    id="amigo"
                                    name="amigo"
                                    getOptionLabel={({ nome }) => nome}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.amigos.filter(option => option.id === this.state.categoria.amigo_id)}
                                    options={this.state.amigos}
                                />
                            </div>
                        }
                        {mostrarEvento === true &&
                            <div className="form-group col-md-4">
                                <label for="evento" className="">Evento</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled="true"
                                    id="evento"
                                    name="evento"
                                    getOptionLabel={({ descricao }) => descricao}
                                    getOptionValue={({ id }) => id}
                                    value={this.state.eventos.filter(option => option.id === this.state.categoria.evento_id)}
                                    options={this.state.eventos}
                                />
                            </div>
                        }
                        {mostrarEntraNosRelatorios === true &&
                            <div className="form-group col-md-3">
                                <label for="tipo" className="">Considerar nos relatórios</label>
                                <Select
                                    styles={customStyles}
                                    isDisabled="true"
                                    id="consideraNoRelatorio"
                                    name="consideraNoRelatorio"
                                    value={consideraNosRelatorios.filter(option => option.value === this.state.categoria.entra_nas_estatisticas)}
                                    options={consideraNosRelatorios}
                                />
                            </div>
                        }
                    </div>

                    <div className="ml-3 form-row">
                        <div className="form-group col-md-4">
                            <label for="conta" className="">Conta</label>
                            <Select
                                isDisabled="true"
                                id="conta"
                                name="conta"
                                getOptionLabel={({ nome }) => nome}
                                getOptionValue={({ id }) => id}
                                selectedValue={this.state.conta_id}
                                value={this.state.contas.filter(option => option.id === this.state.pagamento.conta_id)}
                                options={this.state.contas}
                            />
                        </div>
                        <div className="form-group col-md-3">
                            <label className="" >Frequência</label>
                            <div>
                                <button disabled id="unica" type="button" className={"btn btn-sm " + (corUnica) + " mr-1"} onClick={() => { this.handleChangeFrequencia("unica") }} >Única</button>
                                <button disabled id="mensal" type="button" className={"btn btn-sm " + (corMensal) + " mr-1"} onClick={() => { this.handleChangeFrequencia("mensal") }} >Mensal</button>
                                <button disabled id="recorrente" type="button" className={"btn btn-sm " + (corRecorrente) + " mr-1"} onClick={() => { this.handleChangeFrequencia("recorrente") }} >Recorrente</button>
                            </div>
                        </div>
                        {inputParcelaNum}
                        {inputParcela}
                        {inputFatura}
                    </div>
                    <div className="mt-3 ml-2 form-row">
                        {linhaReembolso}
                        {mostraPagamentoIdReembolso &&
                            <div className="form-group col-md-4 m-0">
                                <Select
                                    placeholder="Selecione uma pagamento.."
                                    isDisabled='true'
                                    id="pagamentosReembolsar"
                                    name="pagamentosReembolsar"
                                    getOptionLabel={({ descricao, valor }) => (descricao + ' ' + formataDisplayEmReal(valor))}
                                    getOptionValue={({ id }) => id}
                                    selectedValue={this.state.pagamento_id_reembolso}
                                    value={this.state.pagamentosReembolsar.filter(option => option.id === this.state.pagamento.pagamento_id_reembolso)}
                                    options={this.state.pagamentosReembolsar}
                                />
                            </div>
                        }
                    </div>

                    <div class="form-group col-md-11">
                        <label className="m-2" for="comment">Comentários</label>
                        <textarea
                            readOnly="true"
                            value={this.state.pagamento.comentarios}
                            className="form-control mt-2 ml-2"
                            rows="2" id="comum:comentarios">
                        </textarea>
                    </div>
                    {this.state.parcelamento}
                    <div class="card-header border">
                        <div className="row ml-2">
                            <button type="button" className="btn btn-primary btn-sm" onClick={() => { this.volta() }}>Voltar</button>
                            {this.state.botaoAlterar}
                            {this.state.botaoInterromper}
                            {this.state.botaoAlterarContaFatura}
                        </div>
                    </div>
                </form>
            </div >
        )

    }

    corLinha(pagamento) {

        if (pagamento.parcela === this.state.pagamento.parcela) {
            return 'text-white font-weight-bold'
        } else {
            return 'text-white'
        }

    }

    corDescricao(pagamento) {

        var corDoTexto = null
        var venceHoje = false

        if (pagamento.data_de_vencimento < pagamento.data_de_pagamento) {
            corDoTexto = 'text-warning'
        } else {
            if (pagamento.data_de_vencimento === moment().format('YYYY-MM-DD')) {
                venceHoje = true
                corDoTexto = 'text-white'
            } else {
                corDoTexto = 'text-white'
            }
        }


        if (pagamento.sub_categoria_nome_interno === 'fatura') {
            return corDoTexto + ' font-weight-bold'
        } else {
            return corDoTexto
        }
    }

    obtemDescricao(pagamento) {

        if (pagamento.data_de_vencimento < pagamento.data_de_pagamento) {
            var dias = moment(pagamento.data_de_pagamento).diff(moment(pagamento.data_de_vencimento), 'days');
            return pagamento.descricao + ' ** pago com atraso de ' + dias + ' dias **'
        } else {
            return pagamento.descricao
        }
    }

    formataData(data) {

        if (data === null) {
            return '-'
        } else {
            return moment(data).format('DD/MM/YYYY')
        }
    }

    obtemParcela(pagamento) {

        if (pagamento.frequencia === 'unica') {
            return ''
        } else {
            if (pagamento.frequencia === 'recorrente') {
                return ('000' + pagamento.parcela).slice(-3) + '/999'
            } else {
                return ('000' + pagamento.parcela).slice(-3) + '/' + ('000' + this.state.transacao.numero_de_parcelas).slice(-3)
            }

        }

    }

    nomeConta(conta_id) {
        const conta = this.state.contas.find(conta => conta.id === conta_id);

        if (conta === undefined) {
            return ' '
        } else {
            return conta.nome

        }
    }

    corDoLancamento(categoria) {

        if (categoria.tipo === 'credito') {
            return 'rgm-cor-positivo'
        } else {
            return 'text-white'
        }
    }

    renderGraficoGeral() {

        // alert('pagamentos00000 ==>' + this.state.dadosGrafico.datasets[0].data[0])
        // alert('pagamentos00001 ==>' + this.state.dadosGrafico.datasets[0].data[1])
        // alert('pagamentos00002 ==>' + this.state.dadosGrafico.datasets[0].data[2])
        // alert('pagamentos00003 ==>' + this.state.dadosGrafico.datasets[0].data[3])
        // alert('pagamentos00004 ==>' + this.state.dadosGrafico.datasets[0].data[4])
        // alert('pagamentos00005 ==>' + this.state.dadosGrafico.datasets[0].data[5])

        if (this.state.dadosGrafico.datasets[0].data[0] !== null ||
            this.state.dadosGrafico.datasets[0].data[1] !== null ||
            this.state.dadosGrafico.datasets[0].data[2] !== null ||
            this.state.dadosGrafico.datasets[0].data[3] !== null ||
            this.state.dadosGrafico.datasets[0].data[4] !== null ||
            this.state.dadosGrafico.datasets[0].data[5] !== null ||
            this.state.dadosGrafico.datasets[0].data[6] !== null ||
            this.state.dadosGrafico.datasets[0].data[7] !== null ||
            this.state.dadosGrafico.datasets[0].data[8] !== null ||
            this.state.dadosGrafico.datasets[0].data[9] !== null ||
            this.state.dadosGrafico.datasets[0].data[10] !== null ||
            this.state.dadosGrafico.datasets[0].data[11] !== null
        ) {
            return (
                <div className="container w-100 mt-5">

                    <div className="card-header bg-info border mt-2">
                        <h7>Gráfico</h7>
                    </div>
                    <div className="jumbotron">
                        <Line data={this.state.dadosGrafico} />
                    </div>
                </div>
            )
        }
    }


    renderLista() {
        if (this.state.pagamento.frequencia === 'recorrente' || this.state.pagamento.frequencia === 'mensal') {
            return (
                <div className="container-fluid w-100 mt-5">
                    <div class="row justify-content-md-center">
                        <div className="card row bg-transparent">
                            <div class="card-header border rgm-bg-azul-esverdiado text-white">
                                <h5>Pagamentos de uma Série</h5>
                            </div>
                            <table className="table table-bordered mt-0">
                                <thead>
                                    <tr className="bg-info">
                                        <th scope="col">Descrição</th>
                                        <th scope="col" className="text-center">Vencimento</th>
                                        <th scope="col" className="text-center">Pagamento</th>
                                        <th scope="col">Parcela</th>
                                        <th scope="col">Conta</th>
                                        <th scope="col">Frequência</th>
                                        <th scope="col">Tipo</th>
                                        <th scope="col" className="text-right">Valor</th>
                                    </tr>
                                </thead>
                                <tbody className="rgm-bg-azul-esverdiado">
                                    {this.state.pagamentos.map((pagamento, index) => {
                                        return (
                                            <tr className={'rgm-bg-azul-esverdiado ' + this.corLinha(pagamento)}>
                                                <td className={"align-middle rgm-diminuirParaCaber "}>{this.obtemDescricao(pagamento)}</td>
                                                <td className="text-center align-middle ">{moment(pagamento.data_de_vencimento).format('DD/MM/YYYY')}</td>
                                                <td className="text-center align-middle ">{this.formataData(pagamento.data_de_pagamento)}</td>
                                                <td className="text-center align-middle ">{this.obtemParcela(pagamento)}</td>
                                                <td className="align-middle rgm-diminuirParaCaber ">{this.nomeConta(pagamento.conta_id)}</td>
                                                <td className="align-middle ">{extensoFrequencia(pagamento.frequencia)}</td>
                                                <td className="align-middle ">{extensoTipo(pagamento.tipo)}</td>
                                                <td className={"text-right align-middle "}>{formataDisplayEmReal(pagamento.valor)}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )
        }
    }

    renderAnexos() {

        if (this.state.anexos.length > 0) {
            return (
                <div className="container w-100 mt-5 mb-0">
                    <div className="card row bg-transparent">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>Anexos</h5>
                            </div>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="border align-middle">Nome</th>
                                    <th scope="col" className="border align-middle">Tamanho</th>
                                    <th scope="col" className="border align-middle text-center">Ver anexo</th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.anexos.map((anexo, index) => {
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado m-1">
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{anexo.nome}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber text-right">{fileSize(anexo.size)}</td>
                                            <td className="text-center"> <a href={anexo.url} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-text-o" aria-hidden="true"></i></a></td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }


    acessaNomeCategoria(categoriaListada) {
        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === categoriaListada.categoria_id })

        if (categoria === undefined) {
            return '---'
        }

        return categoria.nome
    }

    acessaNomeSubCategoria(categoriaListada) {
        const categoria = this.state.categoriasTodas.find(function (obj) { return obj.id === categoriaListada.categoria_id })

        if (categoria === undefined) {
            return '---'
        }

        const subCategoria = categoria.sub_categorias.find(function (obj) { return obj.id === categoriaListada.sub_categoria_id })

        if (subCategoria === undefined) {
            return '---'
        }

        return subCategoria.nome
    }

    acessaNomeAmigo(categoria) {

        if (categoria.amigo_id === undefined || categoria.amigo_id === null) {
            return ''
        }

        var amigo = this.state.amigos.filter(option => option.id === categoria.amigo_id)

        if (amigo[0] === undefined) {
            return ''
        } else {
            return amigo[0].nome
        }
    }


    renderCategoriaLista() {

        if (this.state.pagamento.categorias.length > 1) {
            return (
                <div className="container w-100 mt-5 mb-0">
                    <div className="card row bg-transparent">
                        <div class="card-header rgm-bg-azul-esverdiado text-white border">
                            <div className="row align-middle m-2">
                                <h5>Lista de categorias dentro do pagamento | <span className="text-white">{formataDisplayEmReal(this.state.pagamento.valor)}</span></h5>
                            </div>
                        </div>
                        <table className="table table-bordered mt-0">
                            <thead>
                                <tr className="bg-info">
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Tipo</h6></th>
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Categoria</h6></th>
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Subcategoria</h6></th>
                                    <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Descrição</h6></th>
                                    {this.state.amigos.length > 0 &&
                                        <th scope="col" className="border align-middle" COLSPAN="1" ROWSPAN="2"><h6>Amigo</h6></th>
                                    }
                                    <th scope="col" className="border align-middle text-center" COLSPAN="1" ROWSPAN="2"><h6>Valor</h6></th>
                                </tr>
                            </thead>
                            <tbody className="rgm-bg-azul-esverdiado">
                                {this.state.pagamento.categorias.map((categoria, index) => {
                                    return (
                                        <tr className="rgm-bg-azul-esverdiado m-1">
                                            <td className="align-middle text-white">{extensoTipo(categoria.tipo)}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{this.acessaNomeCategoria(categoria)}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{this.acessaNomeSubCategoria(categoria)}</td>
                                            <td className="align-middle text-white rgm-diminuirParaCaber">{categoria.descricao}</td>
                                            {this.state.amigos.length > 0 &&
                                                <td className="align-middle text-white rgm-diminuirParaCaber">{this.acessaNomeAmigo(categoria)}</td>
                                            }
                                            <td className={"align-middle text-right " + this.corDoLancamento(categoria)}>{formataDisplayEmReal(categoria.valor)}</td>
                                        </tr>
                                    )
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            )
        } else {
            return null
        }
    }

    renderConfirmacao() {
        if (this.state.pedeConfirmacao) {
            return (
                <>
                    <ModalConfirma confirma={() => this.interrompe()} cancela={() => this.setState({ pedeConfirmacao: false })} msgConfirmacao="Confirma a interrupção do pagamento?" />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.volta()} />
                </>
            )
        }
    }

    listaCategorias() {
        let url = global.url + 'categoria/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.selecionaCategorias(responseJson.categorias)
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    selecionaCategorias(categoriasLista) {

        this.setState({ categoriasDivisaoTotal: JSON.parse(JSON.stringify(categoriasLista)) })
        this.setState({ categoriasTodas: JSON.parse(JSON.stringify(categoriasLista)) })

        var result = false

        var categoriasDebito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'debito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }


            return result

        })

        var categoriasCredito = categoriasLista.filter((obj) => {
            if (obj.tipo === 'credito') {
                result = true
            } else {
                result = false
            }

            if (obj.nome_interno === 'dividir') {
                result = false
            }

            if (obj.nome_interno === 'especial') {
                result = false
            }

            return result

        })

        this.setState({ categoriasDebito: categoriasDebito })
        this.setState({ categoriasCredito: categoriasCredito })

        var categorias = null
        var categoria_id = null
        var subCategorias = null


        if (this.state.pagamento.categorias.length === 1) {
            if (this.state.pagamento.tipo === 'debito') {
                categorias = categoriasDebito
                categoria_id = this.state.categoria.categoria_id
                subCategorias = categorias.find(function (obj) { return obj.id === categoria_id })
                this.setState({ categorias: categorias, subCategorias: subCategorias.sub_categorias })
            } else {
                categorias = categoriasCredito
                categoria_id = this.state.categoria.categoria_id
                subCategorias = categorias.find(function (obj) { return obj.id === categoria_id })
                if (subCategorias === undefined) {
                    this.setState({ categorias: categorias, subCategorias: [] })
                } else {
                    this.setState({ categorias: categorias, subCategorias: subCategorias.sub_categorias })
                }
            }
        }

    }

    listaContas() {
        let url = global.url + 'conta/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ contas: responseJson.contas })
                    this.listaPagamentosDeUmaTransacao()
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaAmigos() {
        let url = global.url + 'amigo/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var amigo = {
                        id: null,
                        nome: '------'
                    }
                    responseJson.amigos.push(amigo)
                    this.setState({ amigos: responseJson.amigos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    listaEventos() {
        let url = global.url + 'evento/lista'

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    var evento = {
                        id: null,
                        descricao: '------'
                    }
                    responseJson.eventos.push(evento)
                    this.setState({ eventos: responseJson.eventos })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }



    interrompe() {

        this.setState({ pedeConfirmacao: false })

        var url = null

        if (this.state.pagamento.fatura_id === null) {
            url = global.url + 'pagamento/interrompe'
        } else {
            url = global.url + 'fatura/interrompe'
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(this.state.pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Pagamento interrompido com sucesso' })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    deletarTransferencia() {

        var url = global.url + 'pagamento/deletarTransferencia'

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: localStorage.getItem('EGR_TOKEN')
            },
            body: JSON.stringify(this.state.pagamento)
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ alertaSaida: true, alertaMsg: 'Transferencia excluida com sucesso' })
                } else {
                    this.setState({ mensagem: responseJson.mensagem })
                }
            })
    }

    buscaPagamentosParaSeremReembolsados() {
        let url = global.url + 'pagamento/buscaPagamentosParaSeremReembolsados/' + this.state.pagamento.pagamento_id_reembolso

        var token = localStorage.getItem('EGR_TOKEN')

        fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                authorization: token
            }
        })
            .then(response => {
                return response.json()
            })
            .then(responseJson => {
                if (responseJson.status === 'ok') {
                    this.setState({ pagamentosReembolsar: responseJson.pagamentos })

                } else {
                    this.setState({
                        mensagem: responseJson.mensagem
                    })
                }
            })


    }

}

export default PagamentoConsulta