import React, { Component } from 'react'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import 'moment/locale/pt-br'
import { formataDisplayEmReal, formataEmRealParaBD } from '../../util/Utils'
import ModalAviso from '../modal/ModalAviso'

class ContaResgatarContaRemunerada extends Component {
  constructor(props) {
    super(props)
    this.state = {
      conta_id: this.props.conta_id,
      descricao: 'Resgate da conta remunerada',
      valor: 'R$ 0,00',
      data_de_vencimento: moment().toDate()
    }
  }

  componentWillMount() {

  }

  handleChangeDescricao(event) {
    this.setState({ descricao: event.target.value })
  }

  handleChangeDateVenc(data_de_vencimento) {
    var data_de_vencimentoLocal = moment(data_de_vencimento).toDate()
    this.setState({ data_de_vencimento: data_de_vencimentoLocal })
  }

  handleChangeValor(event) {

    if (formataDisplayEmReal(event.target.value).includes("-", 0)) {
      return
    }

    this.setState({ valor: formataDisplayEmReal(event.target.value) })
  }

  render() {
    return [
      this.renderAlertaSaida(),
      this.renderPrincipal()
    ]
  }

  renderPrincipal() {

    return (
      <div className="container w-100 mt-5">
        <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
          <div class="card-header border">
            <div className="row">
              <h5>Resgate da conta remunerada</h5>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row ml-2 mt-2">
              <div className="col-md-6">
                <label>Descrição</label>
                <input
                  type="text"
                  disabled
                  className="form-control"
                  id="descricao"
                  maxlength="50"
                  value={this.state.descricao}
                  onChange={this.handleChangeDescricao.bind(this)}
                  placeholder="Digite a descrição"
                />
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>Valor</label>
                  <input
                    type="text"
                    className="form-control"
                    id="valor"
                    value={this.state.valor}
                    onChange={this.handleChangeValor.bind(this)}
                    placeholder="Valor"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label for="data_de_vencimento">Data</label>
                  <div>
                    <DatePicker
                      className="form-control text-center"
                      dateFormat="dd/MM/yyyy"
                      id="data_de_vencimento"
                      selected={this.state.data_de_vencimento}
                      onChange={this.handleChangeDateVenc.bind(this)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-header border">
            <div className="row">
              <button id="buttonVolta" type="button" className="btn btn-primary m-2" onClick={() => this.props.volta()} title="Voltar">Voltar</button>
              <button id="buttonAplica" type="button" className="btn btn-primary m-2" onClick={() => this.resgatar()} title="Transfere">Resgatar</button>
            </div>
          </div>
        </form>
      </div>
    )
  }

  renderAlertaSaida() {
    if (this.state.alertaSaida) {
      return (
        <>
          <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
        </>
      )
    }
  }


  resgatar() {
    let url = global.url + 'transferencia/resgatarContaRemunerada'

    var aplicacao = {
      conta_id: this.state.conta_id,
      descricao: this.state.descricao,
      data_de_vencimento: moment(this.state.data_de_vencimento).format('YYYY-MM-DD'),
      valor: formataEmRealParaBD(this.state.valor)
    }

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
      body: JSON.stringify(aplicacao)
    }).then(response => {
      return response.json()
    }).then(responseJson => {
      if (responseJson.status === 'ok') {
        this.setState({ resp: responseJson.resp })
        this.setState({ alertaSaida: true, alertaMsg: 'Resgate efetuato' })
        
        this.props.volta()
      } else {
        this.setState({ mensagem: responseJson.mensagem })
      }
    })
  }
}

export default ContaResgatarContaRemunerada
