import React, { Component } from 'react'
import Select from 'react-select'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'moment/locale/pt-br'
import { formataDisplayEmReal, formataEmRealParaBD } from '../../util/Utils'
import ModalAviso from '../modal/ModalAviso'

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: 'green',
        color: state.isSelected ? 'yellow' : 'black',
        backgroundColor: state.isSelected ? 'green' : 'white'
    }),
    control: (provided) => ({
        ...provided
    })
}

class FaturaPagamentoAvulso extends Component {
    constructor(props) {
        super(props)
        this.state = {
            fatura: this.props.fatura,
            contaDebito: this.props.conta_id,
            contaCredito: null,
            descricao: 'Pagamento Avulso de cartão',
            //data_de_vencimento: moment(),
            data_de_vencimento: moment().toDate(),
            valor: 0,
            contas: [],
            valorDisplay: formataDisplayEmReal('0'),
            alerta: false,
            alertaMsg: null,
            dataInicial: moment().format('YYYY-MM-DD'),
            dataFinal: moment().format('YYYY-MM-DD')
        }
    }

    componentWillMount() {
        this.listaContas()

        var dataInicial = moment(this.state.fatura.data_de_vencimento).add(-1, 'month').add(1, 'day').format('YYYY-MM-DD')
        var dataFinal = moment(this.state.fatura.data_de_vencimento).format('YYYY-MM-DD')

        this.setState({ dataInicial: dataInicial, dataFinal: dataFinal })

    }

    listaContas() {
        let url = global.url + 'conta/lista'

        fetch(url, {
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') }
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.retiraConta(this.state.contaDebito, responseJson.contas, (contas) => {
                    this.setState({ contas: contas })
                })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    retiraConta(contaDebito, contas, callback) {
        var contaRetirada = []
        contas.forEach(conta => {
            if (conta.tipo_conta === 'contaCorrente' || conta.tipo_conta === 'contaDinheiro') {
                if (conta.id !== contaDebito) {
                    contaRetirada.push(conta)
                }
            }
        })

        callback(contaRetirada)

    }

    handleChangeDescricao(event) {
        this.setState({ descricao: event.target.value })
    }

    handleChangeDateVenc(data_de_vencimento) {
        var data_de_vencimentoLocal = moment(data_de_vencimento).toDate()
        this.setState({ data_de_vencimento: data_de_vencimentoLocal })
    }

    handleChangeValor(event) {
        this.setState({ valor: formataEmRealParaBD(event.target.value) })
        this.setState({ valorDisplay: formataDisplayEmReal(event.target.value) })
    }

    handleChangeConta(contaSel) {
        this.setState({ contaDebito: contaSel.id })
    }

    transfere() {

        if (this.state.descricao === null || this.state.descricao.length < 3) {
            this.setState({ alerta: true, alertaMsg: 'Descrição deve ter no mínimo 3 letras' })
            return
        }

        if (this.state.valor <= 0) {
            this.setState({ alerta: true, alertaMsg: 'Valor deve ser positivo e maior que zeros' })
            return
        }

        if (moment(this.state.data_de_vencimento).format('YYYY-MM-DD') === 'Invalid date') {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento valida é obrigatória' })
            return
        }

        if (moment(this.state.data_de_vencimento).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')) {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento não poder ser maior que a data corrente' })
            return
        }

        if (moment(this.state.data_de_vencimento).format('YYYY-MM-DD') < this.state.dataInicial || moment(this.state.data_de_vencimento).format('YYYY-MM-DD') < this.state.dataInicial) {
            this.setState({ alerta: true, alertaMsg: 'Data de vencimento deve estar entre ' + moment(this.state.dataInicial).format('DD/MM/YYYY') + ' e ' + moment(this.state.dataFinal).format('DD/MM/YYYY') })
            return
        }

        if (this.state.contaDebito === null) {
            this.setState({ alerta: true, alertaMsg: 'Conta obrigatória' })
            return
        }

        let url = global.url + 'transferencia/pagamentoAvulso'

        var pagamentoAvulso = {
            contaDebito: this.state.contaDebito,
            descricao: this.state.descricao,
            data_de_vencimento: moment(this.state.data_de_vencimento).format('YYYY-MM-DD'),
            valor: this.state.valor,
            fatura: this.state.fatura
        }

        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: localStorage.getItem('EGR_TOKEN') },
            body: JSON.stringify(pagamentoAvulso)
        }).then(response => {
            return response.json()
        }).then(responseJson => {
            if (responseJson.status === 'ok') {
                this.setState({ resp: responseJson.resp })
                this.setState({ alertaSaida: true, alertaMsg: 'Pagamento avulso concluído...' })
            } else {
                this.setState({ mensagem: responseJson.mensagem })
            }
        })
    }

    render() {
        return [
            this.renderAlert(),
            this.renderAlertaSaida(),
            this.renderComum()
        ]
    }

    renderComum() {

        return (
            <div className="container w-100 mt-5">
                <form className="card rgm-bg-azul-esverdiado border mt-2 text-white">
                    <div class="card-header border">
                        <div className="row">
                            <h5>Pagamento Avulso de cartão de crédito</h5>
                        </div>
                    </div>
                    <div className="ml-3 form-row mt-2">

                        <div className="form-group">
                            <div className="form-row">
                                <div className="col-md-6">
                                    <label className="small">Descrição</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="descricao"
                                        maxlength="50"
                                        value={this.state.descricao}
                                        onChange={this.handleChangeDescricao.bind(this)}
                                        placeholder="Digite a descrição"
                                    />
                                </div>
                                <div className="col-md-3">
                                    <div className="form-group">
                                        <label>Valor</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="valor"
                                            value={this.state.valorDisplay}
                                            onChange={this.handleChangeValor.bind(this)}
                                            placeholder="Valor"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div>
                                        <label>Data</label>
                                        <div>
                                            <DatePicker
                                                id="data_de_vencimento"
                                                dateFormat="dd/MM/yyyy"
                                                className="form-control"
                                                selected={this.state.data_de_vencimento}
                                                onChange={this.handleChangeDateVenc.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Conta débito</label>
                                        <Select
                                            styles={customStyles}
                                            id="conta"
                                            name="conta"
                                            getOptionLabel={({ nome }) => nome}
                                            getOptionValue={({ id }) => id}
                                            selectedValue={this.state.conta_id}
                                            value={this.state.contas.filter(option => option.id === this.state.contaDebito)}
                                            onChange={this.handleChangeConta.bind(this)}
                                            options={this.state.contas}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-header border">
                        <div className="row">
                            <button id="buttonVolta" type="button" className="btn  btn-primary btn-sm m-2" onClick={() => this.props.volta()} title="Voltar">Voltar</button>
                            <button id="buttonTransferir" type="button" className="btn btn-primary btn-sm m-2" onClick={() => this.transfere()} title="Transfere">Pagar</button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    renderAlert() {
        if (this.state.alerta) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.setState({ alerta: false })} />
                </>
            )
        }
    }

    renderAlertaSaida() {
        if (this.state.alertaSaida) {
            return (
                <>
                    <ModalAviso aviso={this.state.alertaMsg} onClose={() => this.props.volta()} />
                </>
            )
        }
    }

}

export default FaturaPagamentoAvulso